import { find } from "lodash";
import { FC, useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { EditButton } from "../../../components/button";
import { AdjustableCard, GraphBasedExpandableCard } from "../../../components/card";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Pill } from "../../../components/pill";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { GetGitHubIntegrationDocument, GetGitHubIntegrationsDocument, GitHubIntegration, useDeleteGitHubIntegrationMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { OAuthElements, OAuthLoginCard } from "../common/oauth-login-card";
import classNames from "classnames";
import { ClassNames } from "../../../components/classes";

export const GITHUB_INTEGRATION_ICON = {
    component: Icons.Logos.GitHub,
    bgClassName: "bg-white",
};

type IGitHubOAuthIntegrationsLoginCardProps = {
    name?: string;
    hideTitle?: boolean;
    githubIntegration?: GitHubIntegration;
    onCancel: () => void,
    onDelete?: () => void;
}

export const GitHubOAuthIntegrationsLoginCard: FC<IGitHubOAuthIntegrationsLoginCardProps> = ({ name, hideTitle, githubIntegration, onCancel, onDelete }) => {
    const scopes = useMemo(() => githubIntegration?.Scopes ?? [], [githubIntegration]);
    return (<OAuthLoginCard element={OAuthElements.GITHUB} icon={Icons.Logos.GitHub} name={name}
            label={"GitHub OAuth"} onCancel={onCancel} scopes={[
                {
                    scope: "repo",
                    description: "Scope to clone repositories inside developer environment and CI/CD pipeline.",
                    checked: find(scopes, scope => scope === "repo") != null,
                },
                {
                    scope: "admin:repo_hook",
                    description: "Scope to create hooks such that Clidey hooks can be triggered",
                    checked: find(scopes, scope => scope === "admin:repo_hook") != null,
                },
                {
                    scope: "read:user",
                    description: "getting user's profile picture to enhance UX",
                    checked: find(scopes, scope => scope === "read:user") != null,
                }
            ]} hideTitle={hideTitle} onDelete={onDelete} id={githubIntegration?.Id} />)
}

type IGitHubIntegrationsCardProps = {
    refetch: () => void;
    githubIntegration: GitHubIntegration;
}

const GitHubIntegrationIntro: FC<{ githubIntegration: GitHubIntegration }> = (props) => {
    return <div className="flex flex-col gap-2 grow">
        <div className={classNames(ClassNames.Text, "text-md mt-2")}>
            {props.githubIntegration.Name}
        </div>
        <div className="flex gap-1">
            {
                props.githubIntegration.Scopes.length > 0 &&
                <>
                    <Pill id={props.githubIntegration.Scopes[0]} label={props.githubIntegration.Scopes[0]} />
                    {
                        props.githubIntegration.Scopes.length > 1 &&
                        <Pill id={`${props.githubIntegration.Scopes.length - 1}+`} label={`${props.githubIntegration.Scopes.length - 1}+`} />
                    }
                </>
            }
        </div>
    </div>
}

export const GitHubIntegrationsCard: FC<IGitHubIntegrationsCardProps> = (props) => {
    const [deleteGitHubIntegration] = useDeleteGitHubIntegrationMutation();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleOpen = useCallback(() => {
        toggleCardExpansion.current?.(true);
    }, [toggleCardExpansion]);

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.(false);
    }, [toggleCardExpansion]);

    const handleDelete = useCallback(() => {
        deleteGitHubIntegration({
            variables: {
                id: props.githubIntegration.Id,
            },
            onCompleted: () => {
                notify("GitHub integration deleted successfully!", "success");
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to delete GitHub integration", "error");
            },
        });
    }, [props, deleteGitHubIntegration]);

    return (
        <GraphBasedExpandableCard id={props.githubIntegration.Id} type={GraphElements.GitHubIntegration} icon={GITHUB_INTEGRATION_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle} highlight={searchParams.get("id") === props.githubIntegration.Id}>
            <>
                <GitHubIntegrationIntro githubIntegration={props.githubIntegration} />
                <div className="flex">
                    <EditButton onClick={handleOpen} />
                </div>
            </>
            <div className="flex flex-col grow">
                <div className={classNames(ClassNames.Text, "text-lg mt-4")}>
                    Update GitHub Integration
                </div>
                <GitHubOAuthIntegrationsLoginCard name={props.githubIntegration.Name} onCancel={handleCancel} hideTitle={true} githubIntegration={props.githubIntegration} onDelete={handleDelete} />
            </div>
        </GraphBasedExpandableCard>
    )
}

export const GitHubIntegrationDashboardCard: FC<{ gitHubIntegration: GitHubIntegration }> = (props) => {
    return <AdjustableCard showTools={true} icon={GITHUB_INTEGRATION_ICON}>
        <GitHubIntegrationIntro githubIntegration={props.gitHubIntegration} />
    </AdjustableCard>
}

export const transformGitHubIntegrationData: IDataTransform<GitHubIntegration>  = (data?: { GitHubIntegration: GitHubIntegration[]}) => data?.GitHubIntegration ?? [];

export const GitHubIntegrationGraphCard: FC<IGraphCardProps<GitHubIntegration>> = (props) => {
    return <GraphCardLoader loader={GetGitHubIntegrationDocument} transform={data => transformGitHubIntegrationData(data)[0]} {...props}>
        {(data: GitHubIntegration) => (<GitHubIntegrationDashboardCard gitHubIntegration={data} />)}
    </GraphCardLoader>;
}

export const AddGitHubIntegrationDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="GitHub Integration"
        query={GetGitHubIntegrationsDocument}
        transform={transformGitHubIntegrationData} type={GraphElements.GitHubIntegration}
        icon={Icons.Logos.GitHub}
        link={InternalRoutes.Integrations.CreateIntegrations.path} />
    );
}
import { FC } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { Registry, useGetRegistriesQuery } from "../../../generated/graphql";
import { CreateRegistryCard, RegistryCard } from "./registry-card";

type IRegistryPageProps = {
  isCreating?: boolean;
}

export const RegistryPage: FC<IRegistryPageProps> = (props) => {
  const getAllRegistriesQueryResponse = useGetRegistriesQuery();
  return <Page routes={[InternalRoutes.Container.Registry]}>
    <CreateRegistryCard isCreating={props.isCreating} refetch={getAllRegistriesQueryResponse.refetch} />
    {
      getAllRegistriesQueryResponse.data?.Registry.map(registry => (
        <RegistryCard key={registry.Id} registry={registry as Registry} refetch={getAllRegistriesQueryResponse.refetch} />
      ))
    }
  </Page>
}
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Cell, Pie, PieChart as RechartPieChart, Tooltip } from 'recharts';
import { FILL_COLORS } from "./common";
import { ClassNames } from '../classes';

type IPieChartProps = {
    label?: ReactNode;
    height: number;
    width: number;
    data: { name: string; value: number }[];
    tooltips?: Record<string, string>;
};

export const PieChart: FC<IPieChartProps> = ({ height, width, data, label, tooltips = {} }) => {
  return (
    <div className="w-full h-full group/pie-chart">
      <div className="flex relative">
        <div className="flex flex-col w-full items-center">
          <div className="flex justify-center items-center relative">
            <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
              <div className={classNames(ClassNames.Text, "text-lg")}>{label}</div>
            </div>
            <RechartPieChart height={height} width={width} className="transition-all !cursor-pointer hover:scale-105">
              <Pie data={data} innerRadius={height/3} outerRadius={width/3.2} fill="#8884d8" paddingAngle={1} dataKey="value">
                {data.map((datum, index) => (
                  <Cell className={classNames("stroke-none", FILL_COLORS[index % FILL_COLORS.length])} key={`cell-${datum.name}`} />
              ))}
              </Pie>
              <Tooltip content={(data) => (<div className="text-neutral-600 dark:text-neutral-300 text-sm bg-white/5 dark:bg-black/5 backdrop-blur-lg shadow shadow-black/10 dark:shadow-white/10 p-4 rounded-lg flex flex-col gap-1 !z-50">
                {data.payload?.[0]?.name} ({tooltips[data.payload?.[0]?.name ?? ""] ?? data.payload?.[0]?.value})
              </div>)} />
            </RechartPieChart>
          </div>
        </div>
      </div>
    </div>
  );
};

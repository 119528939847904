import { FC } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { useGetEnvironmentVariablesQuery } from "../../../generated/graphql";
import { CreateEnvironmentVariableCard, EnvironmentVariableCard, IEnvironmentVariable, transformEnvironmentVariableData } from "./environment-variable-card";

type IEnvironmentVariablePageProps = {
    isCreating?: boolean;
}

export const EnvironmentVariablePage: FC<IEnvironmentVariablePageProps> = (props) => {
    const getAllEnvironmentVariablesResponse = useGetEnvironmentVariablesQuery();

    return (
        <Page routes={[InternalRoutes.Config.EnvironmentVariable]}>
            <CreateEnvironmentVariableCard isCreating={props.isCreating} refetch={getAllEnvironmentVariablesResponse.refetch} />
            {
                transformEnvironmentVariableData(getAllEnvironmentVariablesResponse.data).map((environmentVariable: IEnvironmentVariable) => (
                    <EnvironmentVariableCard key={environmentVariable.Id} environmentVariable={environmentVariable} refetch={getAllEnvironmentVariablesResponse.refetch} />
                ))
            }
        </Page>
    )
}
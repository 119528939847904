import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { forEach } from "lodash";
import { cloneElement, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Edge, Node, ReactFlowProps, ReactFlowProvider, useEdgesState, useNodesState, useReactFlow } from "reactflow";
import { useImmer } from "use-immer";
import { AnimatedButton, DeleteButton, UpdateButton } from "../../../components/button";
import { ClassNames } from "../../../components/classes";
import { LoadingPage, Page } from "../../../components/container";
import { Graph, IGraphInstance, IGraphProps } from "../../../components/graph/graph";
import { createEdge, createNode } from "../../../components/graph/utils";
import { InputWithlabel } from "../../../components/input";
import { LogViewer } from "../../../components/log-viewer";
import { Pill } from "../../../components/pill";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { CreateFlowStep, Flow, FlowRun, useCreateFlowShareableStatusMutation, useDeleteFlowMutation, useGetFlowLazyQuery, useGetFlowRunsQuery, useGetFlowStepsLazyQuery, useListenFlowRunStatusSubscription, useRunFlowMutation, useUpdateFlowMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { copyToClipboard, getCenterPosition } from "../../../utils/functions";
import { FlowGraphViewContext, IFlowGraphData, IFlowGraphViewCache } from "./context";
import { FlowRunCard } from "./flow-run-card";
import { FlowElements } from "./flow-step-card";
import { DockerBuildFlowStepCard } from "./steps/docker-build-flow-step-card";
import { DockerPushFlowStepCard } from "./steps/docker-push-flow-step-card";
import { GitChangeBranchFlowStepCard } from "./steps/git-change-branch-flow-step-card";
import { GitHubPullFlowStepCard } from "./steps/git-pull-flow-step-card";
import { StartingSelectorFlowStep } from "./steps/start-selector-flow-step";


type IGraphFlowCardProps = Pick<IGraphProps, "nodes" | "setNodes" | "onNodesChange" | "edges" | "setEdges" | "onEdgesChange" | "onReady"> & ReactFlowProps;

export const GraphFlowCard: FC<IGraphFlowCardProps> = ({ nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange, ...props}) => {
    const { setViewport } = useReactFlow();
    const nodeTypes = useMemo(() => ({
        [FlowElements.Starting]: StartingSelectorFlowStep,
        [FlowElements.GitPull]: GitHubPullFlowStepCard,
        [FlowElements.GitChangeBranch]: GitChangeBranchFlowStepCard,
        [FlowElements.DockerBuild]: DockerBuildFlowStepCard,
        [FlowElements.DockerPush]: DockerPushFlowStepCard,
    }), []);

    const handleInit = useCallback(() => {
        if (nodes.length === 0) {
            return;
        }
        setViewport(getCenterPosition(nodes[0], window.innerHeight, window.innerWidth / 2));
    }, [nodes, setViewport]);

    return (
        <Graph nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            minZoom={0.5}
            maxZoom={1}
            onInit={handleInit}
            {...props} />
    )
}

export const ExploreFlow: FC<{ flow: Flow, refetch: () => void }> = (props) => {
    const [runFlow, { loading: runFlowLoading }] = useRunFlowMutation();
    const [getFlowSteps, ] = useGetFlowStepsLazyQuery();
    const [updateFlow, { loading: updateLoading }] = useUpdateFlowMutation();
    const [deleteFlow, { loading: deleteLoading }] = useDeleteFlowMutation();
    const [createFlowShareableStatus, ] = useCreateFlowShareableStatusMutation();
    const [cache, setCache] = useImmer<IFlowGraphViewCache>({});
    const [name, setName] = useState(props.flow.Name);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const reactFlowRef = useRef<IGraphInstance>();
    const { data: flowRuns, refetch: getFlowRunsRefetch } = useGetFlowRunsQuery({
        variables: {
            flowId: props.flow.Id,
        }
    });
    const [activeFlowRun, setActiveFlowRun] = useState<FlowRun>();
    const navigate = useNavigate();

    const handleOnReady = useCallback((instance: IGraphInstance) => {
        reactFlowRef.current = instance;
    }, []);

    useEffect(() => {
        getFlowSteps({
            variables: {
                flowId: props.flow.Id,
            },
            onCompleted(data) {
                const newNodes: Node[] = data?.FlowSteps?.map((flowStep, i: number) => {
                    const node = createNode({
                        id: flowStep.Id,
                        type: flowStep.Type,
                        data: {},
                    }, {
                        x: i * 400,
                        y: 0,
                    });
                    switch(flowStep.Type) {
                        case FlowElements.GitPull:
                            node.data = flowStep.GitPull;
                            break;
                        case FlowElements.GitChangeBranch:
                            node.data = flowStep.GitChangeBranch;
                            break;
                        case FlowElements.DockerBuild:
                            node.data = flowStep.DockerBuild;
                            break;
                        case FlowElements.DockerPush:
                            node.data = flowStep.DockerPush;
                            break;
                    }
                    return node;
                }) ?? [];
                setNodes(newNodes);
                const newEdges: Edge[] = [];
                forEach(newNodes, (nd, i) => {
                    if (i >= (newNodes.length - 1) ) {
                        return;
                    }
                    newEdges.push(createEdge(nd.id, newNodes[i+1].id));
                });
                setEdges(newEdges);
                setTimeout(() => {
                    reactFlowRef.current?.layout("dagre");
                }, 300);
            },
        });
    }, [getFlowSteps, props.flow.Id, setEdges, setNodes]);

    const handleCopyStatusLink = useCallback(() => {
        createFlowShareableStatus({
            variables: {
                id: props.flow.Id,
            },
            onCompleted(data) {
                if (data?.CreateFlowShareableStatus?.Link == null) {
                    return notify("Unable to copy status shareable link", "error");
                }
                copyToClipboard(data.CreateFlowShareableStatus.Link);
                notify("Copied status shareable link", "success");
            },
        });
    }, [createFlowShareableStatus, props.flow.Id]);

    const handleUpdate = useCallback(() => {
        const steps: CreateFlowStep[] = nodes.map(nd => cache[nd.id].getForm());
        updateFlow({
            variables: {
                id: props.flow.Id,
                name,
                steps,
                resource: {
                    LimitsCPU: 100,
                    LimitsMemory: 100,
                    RequestsCPU: 100,
                    RequestsMemory: 100,
                },
            },
            onCompleted: () => {
                notify("Flow updated successfully", "success");
                getFlowRunsRefetch();
            },
            onError: () => {
                notify("Unable to update flow", "error");
            },
        });
    }, [nodes, updateFlow, props.flow.Id, name, cache, getFlowRunsRefetch]);

    const handleDelete = useCallback(() => {
        deleteFlow({
            variables: {
                id: props.flow.Id,
            },
            onCompleted: () => {
                notify("Flow deleted successfully", "success");
                navigate(InternalRoutes.CI_CD.Flow.path);
            },
            onError: () => {
                notify("Unable to delete flow", "error");
            },
        });
    }, [deleteFlow, navigate, props.flow.Id]);

    const handleSetCacheItem = useCallback((key: string, value: IFlowGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    const handleRun = useCallback(() => {
        runFlow({
            variables: {
                id: props.flow.Id,
            },
            onCompleted() {
                notify("Running flow!", "success");
                getFlowRunsRefetch();
            },
        });
    }, [runFlow, props.flow.Id, getFlowRunsRefetch]);

    useListenFlowRunStatusSubscription({
        variables: {
            flowId: props.flow.Id,
        },
        onData() {
            getFlowRunsRefetch();
        },
        onError() {},
    });


    return <>
        <AnimatePresence mode="wait">
            {activeFlowRun != null && <motion.div className="fixed top-0 left-0 h-full w-2/3 bg-white/5 dark:bg-black/5 backdrop-blur-3xl p-8 z-10"
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 100 }}
                exit={{ x: -100, opacity: 0 }}>
                <div className="flex flex-col gap-4">
                    <div className="flex gap-2 items-center justify-between">
                        <div className={classNames(ClassNames.Text, "text-3xl")}>
                            {name}
                        </div>
                        <div onClick={() => setActiveFlowRun(undefined)}>
                            {cloneElement(Icons.Cancel, {
                                className: classNames(ClassNames.Text, "w-8 h-8 cursor-pointer transition-all hover:scale-125")
                            })}
                        </div>
                    </div>
                    <LogViewer logs={activeFlowRun.Logs} />
                </div>
            </motion.div>}
        </AnimatePresence>
        <div className="flex flex-col gap-8 w-full h-full">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                    <div className={classNames(ClassNames.Text, "text-3xl")}>
                        {props.flow.Name}
                    </div>
                    {
                        flowRuns?.FlowRuns?.[0]?.Status != null &&
                        <Pill id={flowRuns.FlowRuns[0].Status} label={flowRuns.FlowRuns[0].Status} />
                    }
                </div>
                <div className="flex items-center gap-2">
                    <DeleteButton onClick={handleDelete} loading={deleteLoading} />
                </div>
            </div>
            <div className="flex gap-8 h-[80vh]">
                <div className="flex flex-col gap-2 w-3/4">
                    <div className="flex justify-start gap-4 w-full">
                        <InputWithlabel label="Name" value={name} setValue={setName} />
                        <div className="flex gap-2 grow items-start justify-end mt-1">
                            <AnimatedButton label="Copy Flow Status" onClick={handleCopyStatusLink} icon={Icons.Clipboard}  />
                            <UpdateButton loading={updateLoading} onClick={handleUpdate} />
                        </div>
                    </div>
                    <FlowGraphViewContext.Provider value={{
                        cache,
                        setCacheItem: handleSetCacheItem,
                        removeCacheItem: handleRemoveCacheItem,
                    }}>
                        <ReactFlowProvider>
                            <GraphFlowCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                                edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} onReady={handleOnReady} />
                        </ReactFlowProvider>
                    </FlowGraphViewContext.Provider>
                </div>
                <div className="flex flex-col gap-4 w-1/4">
                    <div className="flex justify-between items-center">
                        <div className={classNames(ClassNames.Text, "text-2xl")}>Previous runs</div>
                        <AnimatedButton label="Run" icon={Icons.Play} onClick={handleRun} loading={runFlowLoading} />
                    </div>
                    <div className="flex flex-col gap-2 overflow-y-auto h-full">
                        {
                            flowRuns?.FlowRuns != null && flowRuns.FlowRuns.length === 0
                            ? <div className="flex flex-col h-full items-center justify-center gap-4 text-lg">
                                <div className={classNames(ClassNames.Text, "flex items-center gap-2")}>
                                    {cloneElement(Icons.Play, {
                                        className: "w-6 h-6",
                                    })} 
                                    Start by running the Flow
                                </div>
                                <AnimatedButton label="Run" icon={Icons.Play} onClick={handleRun} loading={runFlowLoading} />
                            </div>
                            : flowRuns?.FlowRuns.map((flowRun, i) => (
                                <FlowRunCard key={flowRun.Id} name={`Run ${flowRuns.FlowRuns.length - i}`} flowRun={flowRun} onClick={() => setActiveFlowRun(flowRun)} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export const ExploreFlowPage: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [getFlow, { data: quickContainer, loading, refetch }] = useGetFlowLazyQuery()
    const navigate = useNavigate();

    useEffect(() => {
        if (id == null) {
            return;
        }
        getFlow({
            variables: {
                id,
            },
            onError() {
                navigate(InternalRoutes.CI_CD.Flow.path);
            },
        });
    }, [getFlow, id, navigate]);

    if (id == null) {
        return <Navigate to={InternalRoutes.Dashboard.path} />
    }

    if (loading || quickContainer?.Flow == null) {
        return <LoadingPage />
    }

    return <Page routes={[InternalRoutes.CI_CD.Flow, InternalRoutes.CI_CD.ExploreFlow]}>
        <ExploreFlow flow={quickContainer.Flow[0] as Flow} refetch={refetch} />
    </Page>
}
import { forEach } from "lodash";
import { FC, KeyboardEvent, ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { Icons } from "../config/icons";
import { SearchInput } from "./search";
import { ClassNames } from "./classes";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const ANSI_CODES: Record<string, (element: ReactElement) => ReactElement> = {
    "\u001B[30m": (element: ReactElement) => <span className="text-black">{element}</span>,
    "\u001B[31m": (element: ReactElement) => <span className="text-red-500">{element}</span>,
    "\u001B[32m": (element: ReactElement) => <span className="text-green-500">{element}</span>,
    "\u001B[33m": (element: ReactElement) => <span className="text-yellow-500">{element}</span>,
    "\u001B[34m": (element: ReactElement) => <span className="text-blue-500">{element}</span>,
    "\u001B[35m": (element: ReactElement) => <span className="text-purple-500">{element}</span>,
    "\u001B[36m": (element: ReactElement) => <span className="text-cyan-500">{element}</span>,
    "\u001B[37m": (element: ReactElement) => <span className="text-slate-500">{element}</span>,
    "\u001B[0m": (element: ReactElement) => <span className="text-neutral-800 dark:text-neutral-300">{element}</span>,
    "\u001B[1m": (element: ReactElement) => <strong>{element}</strong>,
    "\u001B[21m": (element: ReactElement) => <span className="font-normal">{element}</span>,
    "\u001B[4m": (element: ReactElement) => <span className="underline">{element}</span>,
    "\u001B[24m": (element: ReactElement) => <span className="no-underline">{element}</span>,
     "\u001B[38;5;0m": (element: ReactElement) => <span className="text-black">{element}</span>,
     "\u001B[38;5;1m": (element: ReactElement) => <span className="text-red-500">{element}</span>,
     "\u001B[38;5;2m": (element: ReactElement) => <span className="text-green-500">{element}</span>,
     "\u001B[38;5;3m": (element: ReactElement) => <span className="text-yellow-500">{element}</span>,
     "\u001B[38;5;4m": (element: ReactElement) => <span className="text-blue-500">{element}</span>,
     "\u001B[38;5;5m": (element: ReactElement) => <span className="text-purple-500">{element}</span>,
     "\u001B[38;5;6m": (element: ReactElement) => <span className="text-cyan-500">{element}</span>,
     "\u001B[38;5;7m": (element: ReactElement) => <span className="text-slate-500">{element}</span>,
     "\u001B[38;5;8m": (element: ReactElement) => <span className="text-neutral-500">{element}</span>,
     "\u001B[38;5;9m": (element: ReactElement) => <span className="text-red-800">{element}</span>,
     "\u001B[38;5;16m": (element: ReactElement) => <span className="text-rainbow-16">{element}</span>,
     "\u001B[38;5;17m": (element: ReactElement) => <span className="text-rainbow-17">{element}</span>,
     "\u001B[38;5;232m": (element: ReactElement) => <span className="text-neutral-200">{element}</span>,
     "\u001B[38;5;233m": (element: ReactElement) => <span className="text-neutral-300">{element}</span>,
}

type ILogViewerProps = {
    className?: string;
    logs: string;
    actions?: ReactNode;
}

// eslint-disable-next-line no-control-regex
const ANSI_ESCAPE_PATTERN = /\u001B\[\d{1,3}(;\d{1,2}){0,2}m/g;

export const LogViewer: FC<ILogViewerProps> = (props) => {
    const [searchIndex, setSearchIndex] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const logRef = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState("");
    const logs = useMemo(() => {
        if (props.logs.length === 0) {
            return [];
        }
        const newLogs: ReactElement[] = [];
        forEach(props.logs.split("\n"), (log: string) => {
            let pattern;
            const patterns: RegExpExecArray[] = [];
            while ((pattern = ANSI_ESCAPE_PATTERN.exec(log)) != null) {
                if (pattern[0] in ANSI_CODES) {
                    patterns.push(pattern);
                }
            }
            let logLine: ReactElement = <></>;
            if (patterns.length > 0) {
                for (let i = patterns.length - 1; i >=0; i--) {
                    const pattern = patterns[i];
                    if (i === patterns.length - 1) {
                        logLine = ANSI_CODES[pattern[0]](<>{log.substring(pattern[0].length + pattern.index)}</>);
                    } else {
                        logLine = ANSI_CODES[pattern[0]](<>{log.substring(pattern[0].length + pattern.index, patterns[i+1].index)}{logLine}</>);
                    }
                }
            } else {
                logLine = <>{log}</>
            }
            newLogs.push(<div key={log} className={classNames(ClassNames.Text, "pl-1 text-sm transition-all rounded-sm text-wrap")}>
                {logLine}
            </div>);
        });
        return newLogs;
    }, [props.logs]);

    const handleKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (logRef.current == null) {
            return;
        }
        let interval: NodeJS.Timeout;
        if (e.key === "Enter") {
            setSearchIndex(i => i+1);
            const searchText = search.toLowerCase();
            let index = 0;
            for (const childNode of logRef.current.childNodes) {
                if (childNode instanceof HTMLElement) {
                    const text = childNode.textContent?.toLowerCase();
                    if (text != null && searchText != null && text.includes(searchText)) {
                        if (index === searchIndex+1) {
                            childNode.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                            });
                            childNode.classList.add("bg-yellow-50");
                            childNode.classList.add("dark:bg-yellow-800");
                            interval = setTimeout(() => {
                                childNode.classList.remove("bg-yellow-50");
                                childNode.classList.remove("dark:bg-yellow-800");
                            }, 3000);
                            return;
                        }
                        index++;
                    }
                }
            };
        }
        
        return () => {
            if (interval != null) {
                clearInterval(interval);
            }
        }
    }, [logRef, search, searchIndex, setSearchIndex]);

    const handleSearchChange = useCallback((newValue: string) => {
        setSearchIndex(-1);
        setSearch(newValue);
    }, []);

    if (logs.length === 0) {
        return <div className={classNames(ClassNames.Text, "flex justify-center items-center flex-row grow h-full w-full gap-1 text-md")}>
            {Icons.SadSmile} No logs found
        </div>
    }

    return (
        <div className="flex flex-col grow gap-1 w-full" ref={ref}>
            <div className="flex flex-row gap-2 w-full justify-start items-center">
                {props.actions}
                <SearchInput search={search} setSearch={handleSearchChange} placeholder="Search through logs     [Press Enter]" inputProps={{
                    onKeyUp: handleKeyUp,
                }} />
            </div>
            <div className={twMerge(classNames(ClassNames.Text, "flex flex-col max-h-[calc(100vh-160px)] overflow-y-auto", props.className))} ref={logRef}>
                {logs}
            </div>
        </div>
    )
}
import { find } from "lodash";
import { FC, useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { EditButton } from "../../../components/button";
import { AdjustableCard, GraphBasedExpandableCard } from "../../../components/card";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Pill } from "../../../components/pill";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { DigitalOceanIntegration, GetDigitalOceanIntegrationDocument, GetDigitalOceanIntegrationsDocument, useDeleteDigitalOceanIntegrationMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { OAuthElements, OAuthLoginCard } from "../common/oauth-login-card";


export const DIGITAL_OCEAN_INTEGRATION_ICON = {
    component: Icons.Logos.DigitalOcean,
    bgClassName: "bg-white",
};

type IDigitalOceanOAuthIntegrationsLoginCardProps = {
    name?: string;
    hideTitle?: boolean;
    digitalOceanIntegration?: DigitalOceanIntegration;
    onCancel: () => void,
    onDelete?: () => void;
}

export const DigitalOceanOAuthIntegrationsLoginCard: FC<IDigitalOceanOAuthIntegrationsLoginCardProps> = ({ name, hideTitle, digitalOceanIntegration, onCancel, onDelete }) => {
    const scopes = useMemo(() => digitalOceanIntegration?.Scopes ?? [], [digitalOceanIntegration]);
    
    return (<OAuthLoginCard element={OAuthElements.DIGITAL_OCEAN} icon={Icons.Logos.DigitalOcean} name={name}
            label={"DigitalOcean OAuth"} onCancel={onCancel} scopes={[
                {
                    scope: "read",
                    description: "Scope to only read resources from Digital Ocean.",
                    checked: find(scopes, scope => scope === "read") != null,
                },
                {
                    scope: "write",
                    description: "Scope to write to Digital Ocean. This is used to create CI/CD flows, and quick containers.",
                    checked: find(scopes, scope => scope === "write") != null,
                },
            ]} hideTitle={hideTitle} onDelete={onDelete} id={digitalOceanIntegration?.Id} />)
}

type IDigitalOceanIntegrationsCardProps = {
    refetch: () => void;
    digitalOceanIntegration: DigitalOceanIntegration;
}

const DigitalOceanIntegrationIntro: FC<{ digitalOceanIntegration: DigitalOceanIntegration }> = (props) => {
    return <div className="flex flex-col gap-2 grow">
        <div className="text-md mt-2">
            {props.digitalOceanIntegration.Name}
        </div>
        <div className="flex gap-1">
            {
                props.digitalOceanIntegration.Scopes.length > 0 &&
                <>
                    <Pill id={props.digitalOceanIntegration.Scopes[0]} label={props.digitalOceanIntegration.Scopes[0]} />
                    {
                        props.digitalOceanIntegration.Scopes.length > 1 &&
                        <Pill id={`${props.digitalOceanIntegration.Scopes.length - 1}+`} label={`${props.digitalOceanIntegration.Scopes.length - 1}+`} />
                    }
                </>
            }
        </div>
    </div>
}

export const DigitalOceanIntegrationsCard: FC<IDigitalOceanIntegrationsCardProps> = (props) => {
    const [deleteDigitalOceanIntegration] = useDeleteDigitalOceanIntegrationMutation();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleOpen = useCallback(() => {
        toggleCardExpansion.current?.(true);
    }, [toggleCardExpansion]);

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.(false);
    }, [toggleCardExpansion]);

    const handleDelete = useCallback(() => {
        deleteDigitalOceanIntegration({
            variables: {
                id: props.digitalOceanIntegration.Id,
            },
            onCompleted: () => {
                notify("Digital Ocean integration deleted successfully!", "success");
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to delete Digital Ocean integration", "error");
            },
        });
    }, [props, deleteDigitalOceanIntegration]);


    return (
        <GraphBasedExpandableCard id={props.digitalOceanIntegration.Id} type={GraphElements.DigitalOceanIntegration} icon={DIGITAL_OCEAN_INTEGRATION_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle} highlight={searchParams.get("id") === props.digitalOceanIntegration.Id}>
            <>
                <DigitalOceanIntegrationIntro digitalOceanIntegration={props.digitalOceanIntegration} />
                <div className="flex">
                    <EditButton onClick={handleOpen} />
                </div>
            </>
            <div className="flex flex-col grow">
                <div className="text-lg mt-4">
                    Update Digital Ocean Integration
                </div>
                <DigitalOceanOAuthIntegrationsLoginCard name={props.digitalOceanIntegration.Name} onCancel={handleCancel} hideTitle={true} digitalOceanIntegration={props.digitalOceanIntegration} onDelete={handleDelete} />
            </div>
        </GraphBasedExpandableCard>
    )
}

export const DigitalOceanIntegrationDashboardCard: FC<{ digitalOcean: DigitalOceanIntegration }> = (props) => {
    return <AdjustableCard showTools={true} icon={DIGITAL_OCEAN_INTEGRATION_ICON}>
        <DigitalOceanIntegrationIntro digitalOceanIntegration={props.digitalOcean} />
    </AdjustableCard>
}

export const transformDigitalOceanIntegrationData: IDataTransform<DigitalOceanIntegration>  = (data?: { DigitalOceanIntegration: DigitalOceanIntegration[]}) => data?.DigitalOceanIntegration ?? [];

export const DigitalOceanIntegrationGraphCard: FC<IGraphCardProps<DigitalOceanIntegration>> = (props) => {
    return <GraphCardLoader loader={GetDigitalOceanIntegrationDocument} transform={data => transformDigitalOceanIntegrationData(data)[0]} {...props}>
        {data => (<DigitalOceanIntegrationDashboardCard digitalOcean={data} />)}
    </GraphCardLoader>;
}

export const AddDigitalOceanIntegrationDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Digital Ocean Integration"
        query={GetDigitalOceanIntegrationsDocument}
        transform={transformDigitalOceanIntegrationData} type={GraphElements.DigitalOceanIntegration}
        icon={Icons.Logos.DigitalOcean}
        link={InternalRoutes.Integrations.CreateIntegrations.path} />
    );
}
import { FC, useContext, useEffect } from "react"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { CreateOperationResultHookStep, HookStepType, OperationResultHookStep } from "../../../../generated/graphql"
import { HookGraphViewContext } from "../context"
import { HooksStepCard } from "../hooks-step-card"


function getDefaultForm(operationResult?: OperationResultHookStep, disabled?: boolean): IFormVariable[] {
    return [
        {
            name: "operation",
            label: "Result Type",
            type: "dropdown",
            fieldType: "text",
            validate: (events: string) => events.length > 0,
            defaultIcon: Icons.Calendar,
            errorMessage: "Operation type is required",
            dropdownProps: {
                items: [
                    {
                        id: "Success",
                        label: "Success",
                    },
                    {
                        id: "Failure",
                        label: "Failure",
                    }
                ],
            },
            defaultValue: operationResult?.Type,
            disabled,
        },
        {
            name: "condition",
            label: "Condition",
            type: "dropdown",
            fieldType: "text",
            validate: () => true,
            defaultIcon: Icons.Cog,
            errorMessage: "Condition is required",
            dropdownProps: {
                items: [
                    {
                        id: "Any",
                        label: "Any",
                    },
                    {
                        id: "All",
                        label: "All",
                    }
                ],
            },
            defaultValue: operationResult != null && operationResult.Condition.length === 0 ? "Any" : operationResult?.Condition,
            disabled,
        },
    ]
}


export type ICreateOperationResultHookStep = {
    Type: HookStepType.OperationResult;
    OperationResult: CreateOperationResultHookStep;
}

export const OperationResultHookStepCard: FC<IGraphCardProps<OperationResultHookStep>> = (props) => {
    const { setCacheItem } = useContext(HookGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();
    const disabled = props.data?.Status != null;

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertOperationResultFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HooksStepCard label="Operations results condition" {...props} disableActions={disabled}>
            <Form variables={getDefaultForm(props.data, disabled)} {...formProps} />
        </HooksStepCard>
    )
}

const convertOperationResultFormToStep = (form: IForm): ICreateOperationResultHookStep => {
    return {
        Type: HookStepType.OperationResult,
        OperationResult: {
            Type: form.operation,
            Condition: form.condition,
        }
    }
}
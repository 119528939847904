import { CI_CD_FLOW_ICON } from "../page/ci-cd/flow/flow-card";
import { CI_CD_HOOK_ICON } from "../page/ci-cd/hooks/hooks-card";
import { CONFIG_DOMAIN_ICON } from "../page/config/domain/domain-card";
import { CONFIG_ENVIRONMENT_VARIABLE_ICON } from "../page/config/environment-variable/environment-variable-card";
import { CONTAINER_REGISTRY_IMAGE_ICON } from "../page/container/registry-image/registry-image-card";
import { CONTAINER_REGISTRY_ICON } from "../page/container/registry/registry-card";
import { DEPLOY_CLUSTER_ICON } from "../page/deploy/cluster/cluster-card";
import { DEPLOY_CLIDEY_QUICK_CONTAINER_ICON } from "../page/deploy/quick-container/quick-container-card";
import { DIGITAL_OCEAN_INTEGRATION_ICON } from "../page/integrations/digital-ocean-integrations/digital-ocean-integrations-card";
import { DOCKER_IO_REGISTRY_ICON } from "../page/integrations/docker-io-integrations/docker-io-integrations-card";
import { GITHUB_INTEGRATION_ICON } from "../page/integrations/github-integrations/github-integrations-card";
import { GOOGLE_INTEGRATION_ICON } from "../page/integrations/google/google-integrations-card";
import { MAIL_ICON } from "../page/integrations/mail/mail-integrations-card";
import { GraphElements } from "./constants";
import { InternalRoutes } from "./internal-routes";

export function getGlobalIcon(type: GraphElements) {
    switch (type) {
        case GraphElements.Registry: return CONTAINER_REGISTRY_ICON;
        case GraphElements.RegistryImage: return CONTAINER_REGISTRY_IMAGE_ICON;
        case GraphElements.Domain: return CONFIG_DOMAIN_ICON;
        case GraphElements.EnvironmentVariable: return CONFIG_ENVIRONMENT_VARIABLE_ICON;
        case GraphElements.Hook: return CI_CD_HOOK_ICON;
        case GraphElements.Flow: return CI_CD_FLOW_ICON;
        case GraphElements.QuickContainer: return DEPLOY_CLIDEY_QUICK_CONTAINER_ICON;
        case GraphElements.Cluster: return DEPLOY_CLUSTER_ICON;
        case GraphElements.GitHubIntegration: return GITHUB_INTEGRATION_ICON;
        case GraphElements.DigitalOceanIntegration: return DIGITAL_OCEAN_INTEGRATION_ICON;
        case GraphElements.GoogleIntegration: return GOOGLE_INTEGRATION_ICON;
        case GraphElements.DockerIORegistryIntegration: return DOCKER_IO_REGISTRY_ICON;
        case GraphElements.MailIntegration: return MAIL_ICON;
    } 
}

export function getGlobalLink(type: GraphElements) {
    let link: string;
    switch (type) {
        case GraphElements.Registry:
            link = InternalRoutes.Container.Registry.path;
            break;
        case GraphElements.RegistryImage:
            link = InternalRoutes.Container.Image.path;
            break;
        case GraphElements.Flow:
            link = InternalRoutes.CI_CD.Flow.path;
            break;
        case GraphElements.Hook:
            link = InternalRoutes.CI_CD.Hooks.path;
            break;
        case GraphElements.QuickContainer:
            link = InternalRoutes.Deployment.QuickContainer.path;
            break;
        case GraphElements.Domain:
            link = InternalRoutes.Config.Domain.path;
            break;
        case GraphElements.Cluster:
            link = InternalRoutes.Deployment.Cluster.path;
            break;
        case GraphElements.EnvironmentVariable:
            link = InternalRoutes.Config.EnvironmentVariable.path;
            break;
        default:
            link = InternalRoutes.Integrations.Integrations.path;
            break;
    }
    return link;
}
import { map } from "lodash"
import { FC, useContext, useEffect } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { InternalRoutes } from "../../../../config/internal-routes"
import { CreateMailHookStep, GetMailIntegrationsDocument, HookStepType, MailHookStep } from "../../../../generated/graphql"
import { transformMailIntegrationData } from "../../../integrations/mail/mail-integrations-card"
import { HookGraphViewContext } from "../context"
import { HooksStepCard } from "../hooks-step-card"
import { HandlebarCodeEditor, useGetHandlebarSuggestions } from "./handlebar-code-editor"
import { ICodeEditorSuggestion } from "../../../../components/editor"


function getDefaultForm(suggestions: ICodeEditorSuggestion[], navigate: NavigateFunction, mail?: MailHookStep): IFormVariable[] {
    return [
        {
            name: "mailId",
            label: "Mail Integration",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Mail integration is required",
            query: GetMailIntegrationsDocument,
            transform(data) {
                return map(transformMailIntegrationData(data ?? []), ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Mail,
                }));
            },
            defaultIcon: Icons.Mail,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a mail integration" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Integrations.CreateIntegrations.path);
                },
            },
            defaultValue: mail?.MailIntegration.Id,
        },
        {
            name: "to",
            label: "To",
            fieldType: "text[]",
            validate: (value: string) => value.length > 0,
            errorMessage: "To is required",
            defaultValue: mail?.To,
        },
        {
            name: "subject",
            label: "Subject",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Subject is required",
            defaultValue: mail?.Subject,
        },
        {
            name: "body",
            label: "Body",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Body is required",
            defaultValue: mail?.Body,
            onRender: (value: string, setValue: (content: string) => void) => {
                return <HandlebarCodeEditor value={value} setValue={setValue} suggestions={suggestions} />
            },
        },
    ]
}

export type IMailHookStep = {
    Type: HookStepType.Mail;
    Mail: CreateMailHookStep;
};

export type ICreateMailHookStep = IMailHookStep;

export const MailHookStepCard: FC<IGraphCardProps<MailHookStep>> = (props) => {
    const navigate = useNavigate();
    const { setCacheItem } = useContext(HookGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertMailFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const suggestions = useGetHandlebarSuggestions(props.id);

    return (
        <HooksStepCard label="Mail" {...props} className="h-[450px] w-[400px]">
            <Form variables={getDefaultForm(suggestions, navigate, props.data)} {...formProps} />
        </HooksStepCard>
    )
}

const convertMailFormToStep = (form: IForm): ICreateMailHookStep => {
    return {
        Type: HookStepType.Mail,
        Mail: {
            Body: form.body,
            To: form.to,
            Subject: form.subject,
            MailId: form.mailId,
        },
    }
}
import classNames from "classnames";
import { find, map } from "lodash";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Position, getIncomers, useReactFlow } from "reactflow";
import { Card } from "../../../../components/card";
import { ClassNames } from "../../../../components/classes";
import { IDropdownItem } from "../../../../components/dropdown";
import { CustomHandle } from "../../../../components/graph/custom-handle";
import { IGraphCardProps } from "../../../../components/graph/graph";
import { Search } from "../../../../components/search";
import { Icons } from "../../../../config/icons";
import { HookStepType } from "../../../../generated/graphql";
import { HookGraphViewContext } from "../context";

const allStepsMap = {
    [HookStepType.GitWebHook]: {
        id: HookStepType.GitWebHook,
        label: "Git Web Hook",
        icon: Icons.Logos.GitHub,
    },
    [HookStepType.FlowOperation]: {
        id: HookStepType.FlowOperation,
        label: "Flow Operations",
        icon: Icons.CI_CD.Flow.Default,
    },
    [HookStepType.OperationResult]: {
        id: HookStepType.OperationResult,
        label: "Operations Results Handler",
        icon: Icons.CI_CD.Flow.Default,
    },
    [HookStepType.QuickContainerOperation]: {
        id: HookStepType.QuickContainerOperation,
        label: "Quick Container Operations",
        icon: Icons.Deploy.QuickContainer.Default,
    },
    [HookStepType.GitPullRequestComment]: {
        id: HookStepType.GitPullRequestComment,
        label: "Git Pull Request Comment",
        icon: Icons.Logos.GitHub,
    },
    [HookStepType.GitRelease]: {
        id: HookStepType.GitRelease,
        label: "Git Release",
        icon: Icons.Logos.GitHub,
    },
    [HookStepType.Mail]: {
        id: HookStepType.Mail,
        label: "Mail",
        icon: Icons.Mail,
    },
}

export const StartingSelectorHooksStep: FC<IGraphCardProps> = (props) => {
    const [steps, setSteps] = useState<IDropdownItem[]>([]);
    const { setNodes, getNodes, getEdges, getNode } = useReactFlow();
    const { cache } = useContext(HookGraphViewContext);
    const handleSelect = useCallback((item: IDropdownItem) => {
        setNodes(nodes => {
            for (const node of nodes) {
                if (node.id === props.id) {
                    node.type = item.id;
                }
            }
            return nodes;
        });
    }, [props, setNodes]);


    useEffect(() => {
        const node = getNode(props.id);
        if (node == null) {
            return;
        }
        const nodes = getNodes();
        const edges = getEdges();
        const nodesSet = new Set(map(nodes, node => node.type));
        const references = getIncomers(node, nodes, edges);
        setSteps(() => {
            const stepsToAdd = [];
            if (!nodesSet.has(HookStepType.GitWebHook)) {
                stepsToAdd.push(allStepsMap[HookStepType.GitWebHook]);
            }
            if (nodesSet.has(HookStepType.GitWebHook) && find(references, (reference) => reference.type === HookStepType.FlowOperation) == null) {
                stepsToAdd.push(allStepsMap[HookStepType.FlowOperation]);
            }
            if (find(references, (reference) => reference.type === HookStepType.FlowOperation || reference.type === HookStepType.QuickContainerOperation) != null) {
                stepsToAdd.push(allStepsMap[HookStepType.OperationResult]);
            }
            if (nodesSet.has(HookStepType.GitWebHook)) {
                const gitWebHook = nodes.find(node => node.type === HookStepType.GitWebHook)!;
                if (gitWebHook != null && gitWebHook.id in cache) {
                    const form = cache[gitWebHook.id].getForm();
                    if (form.Type === HookStepType.GitWebHook && form.GitWebHook.Events.find(event => event.includes("pull_request")) != null) {
                        stepsToAdd.push(allStepsMap[HookStepType.GitPullRequestComment]);
                        stepsToAdd.push(allStepsMap[HookStepType.GitRelease]);
                    } 
                }
            }
            if (nodesSet.has(HookStepType.FlowOperation) && find(references, (reference) => reference.type === HookStepType.QuickContainerOperation) == null) {
                stepsToAdd.push(allStepsMap[HookStepType.QuickContainerOperation]);
            }
            if (nodesSet.has(HookStepType.GitWebHook)) {
                stepsToAdd.push(allStepsMap[HookStepType.Mail]);
            }
            return stepsToAdd;
        });
    }, [props.id, getEdges, getNodes, getNode, cache]);

    return (<>
        <CustomHandle type="target" position={Position.Left} />
        <Card icon={{
            component: Icons.Add,
            bgClassName: "bg-teal-500",
        }} className="relative w-[300px] h-[300px] group/hook-step-selector">
            <div className="flex flex-col gap-1 my-4 grow">
                <div className={classNames(ClassNames.Text, "text-lg my-2")}>Create step</div>
                <Search label="Search for steps" className="flex-grow" items={steps} isOpen={true} onSelect={handleSelect} noItemsLabel="No steps found" dropdownProps={{
                    scrollContainerClassName: "h-28",
                }} />
            </div>
        </Card>
        <CustomHandle type="source" position={Position.Right} />
    </>)
}

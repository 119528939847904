import classNames from "classnames";
import { remove } from "lodash";
import parseArgs from "minimist";
import { FC, cloneElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { Icons } from "../../config/icons";
import { ClassNames } from "../classes";
import { InputWithlabel } from "../input";
import { Loading } from "../loading";
import { Tooltip } from "../tooltip";
import { Command, IExecutionResult } from "./commands/command";
import { DockerCommand } from "./commands/docker";


const CHECK_CIRCLE = cloneElement(Icons.CheckCircle, {
    className: "w-6 h-6 stroke-green-500",
});

const EXCLAMATION_CIRCLE = cloneElement(Icons.ExclamationCircle, {
    className: "w-6 h-6 stroke-red-500",
});

const EXCLAMATION_TRIANGLE = cloneElement(Icons.ExclamationTriangle, {
    className: "w-6 h-6 stroke-orange-500",
});

const CommandInput: FC<{ result: IExecutionResult, command: string, setCommand: (command: string) => void }> = ({ result, command, setCommand }) => {
    const [start, end] = useMemo(() => {
        const labelIndex = command.indexOf(result.label);
        if (labelIndex === -1) {
            return [-1, -1];
        }
        const start = labelIndex+result.label.length;
        return [start, command.indexOf(" ", start)];
    }, [command, result.label]);

    const handleChange = useCallback((updatedValue: string) => {
        if (start === -1 || end === -1) {
            const index = command.lastIndexOf(" ");
            setCommand(command.slice(0, index) + " " + result.label + updatedValue + command.slice(index));
            return;
        }
        setCommand(command.slice(0, start) + updatedValue + command.slice(end));
    }, [command, end, result.label, setCommand, start]);

    return <InputWithlabel
        label={result.label}
        value={command.slice(start, end)}
        setValue={handleChange}
        icon={result.icon}
    />
}

type ICommandResultSteps = {
    command: string;
    setCommand: (command: string) => void;
    results: IExecutionResult[];
    onReRun?: () => void;
    onClose?: () => void;
}

const CommandResultSteps: FC<ICommandResultSteps> = ({ results, command, setCommand, onReRun, onClose }) => {
    const navigate = useNavigate();

    const getStatus = useCallback((executionResult: IExecutionResult) => {
        switch (executionResult.status) {
            case "pending":
                return <Loading hideText={true} className="h-6 w-6" />
            case "done":
                return CHECK_CIRCLE;
            case "failed":
                return EXCLAMATION_CIRCLE;
            case "missing-info":
                return EXCLAMATION_TRIANGLE;
        }
    }, []);

    const firstIndexMissingInfo = useMemo(() => {
        return results.findIndex(result => result.status === "missing-info");
    }, [results]);

    return (
        <>
            <div key="executing-steps-intro" className={classNames(ClassNames.Text, "text-lg border-b border-black/20 dark:border-white/20 pb-2")}>
                Executing steps
            </div>
            {results.map((result, i) => (
                <>
                    {i === firstIndexMissingInfo && (
                        <div key="missing-partial-information" className={classNames(ClassNames.Text, "text-sm border-b border-black/20 dark:border-white/20 pb-1")}>
                            Missing partial information:
                        </div>
                    )}
                    {result.status === "missing-info" ? (
                        <div key={`${result.label}-${i}`} className="flex flex-col w-full px-2 py-1">
                            <CommandInput command={command} setCommand={setCommand} result={result} />
                        </div>
                    ) : (
                        <div
                            key={`${result.id}-${i}`}
                            className={classNames(
                                "flex justify-between items-center gap-2 p-2 rounded-lg",
                                {
                                    "cursor-pointer hover:bg-black/10 dark:hover:bg-white/10": result.link != null,
                                }
                            )}
                            onClick={result.link == null ? undefined : () => navigate(result.link!)}
                        >
                            <div className={classNames(ClassNames.Text, "flex gap-2 items-center grow")}>
                                {cloneElement(result.icon, {
                                    classNames: "w-6 h-6 stroke-neutral-700",
                                })}
                                <span className="text-sm">{result.label}</span>
                            </div>
                            <div>
                                {result.tooltip != null ? (
                                    <Tooltip tooltip={result.tooltip}>{getStatus(result)}</Tooltip>
                                ) : (
                                    getStatus(result)
                                )}
                            </div>
                        </div>
                    )}
                </>
            ))}
            {firstIndexMissingInfo > 0 && <div className={classNames(ClassNames.Text, "text-sm m-2 p-2 bg-white/5 rounded-lg")}>
                New Command: {command}
            </div>}
            <div className={classNames("flex items-center", {
                "justify-between": onClose != null,
                "justify-end": onClose == null,
            })}>
                { onClose != null &&
                    <div className={classNames(ClassNames.Button, ClassNames.Text)} onClick={onClose}>
                        {cloneElement(Icons.Cancel, {
                            className: "w-4 h-4",
                        })}
                        <span className="text-sm">Close</span>
                    </div>
                }
                {firstIndexMissingInfo > 0 && <div className={classNames(ClassNames.Button, ClassNames.Text)} onClick={onReRun}>
                    {Icons.Play}
                    <span className="text-sm">Run again</span>
                </div>}
            </div>
        </>
    );
};

type ICommandInformation = { valid: false } | { valid: true, mainCommand: string, subCommand: string, options: Record<string, string>, parameters: string[] };

function getCommandInformation(command: string): ICommandInformation {
    const args = command.split(" ");
    if (args.length <= 2) {
        return { valid: false };
    }
    const mainCommand = args[0];
    const subCommand = args[1];
    const options = parseArgs(args.slice(2));
    const parameters = options._;
    options._ = [];
    return {
        valid: true,
        mainCommand,
        subCommand,
        options,
        parameters,
    }
}


const allCommands: Command[] = [
    new DockerCommand()
];

export const useCommandPalette = (props: { command: string }) => {
    const [commandExecutionResults, setCommandExecutionResults] = useImmer<IExecutionResult[]>([]);
    const [runCommandStatus, setRunCommandStatus] = useState(false);

    const resetSteps = useCallback(() => {
        setCommandExecutionResults([]);
        setRunCommandStatus(false);
    }, [setCommandExecutionResults]);

    const handleRunCommand = useCallback(async () => {
        setRunCommandStatus(true);
        for (let command of allCommands) {
            const response = getCommandInformation(props.command);
            if (!response.valid) {
                continue;
            }
            const { subCommand, parameters, options } = response;
            for await (const result of command.execute(subCommand, parameters, options)) {
                setCommandExecutionResults(results => {
                    if (result.status !== "missing-info") {
                        const id = result.id.split(":")[0];
                        remove(results, oldResult => oldResult.id.split(":")[0] === id && oldResult.status === "missing-info");
                    }
                    const foundResultIndex = results.findIndex(currentResult => currentResult.id === result.id);
                    if (foundResultIndex !== -1) {
                        results[foundResultIndex] = result;
                    } else {
                        results.push(result);
                    }
                });
            }
            break;
        }
    }, [props.command, setCommandExecutionResults]);

    const isValidCommand = useCallback((command: string) => {
        const response = getCommandInformation(command);
        if (!response.valid) {
            return false;
        }
        const { mainCommand, parameters, subCommand } = response;
        return allCommands.some(command => command.isValid(mainCommand) && command.isValidSubCommand(subCommand) && command.hasValidParameters(parameters));
    }, []);

    return {
        commandExecutionResults,
        runCommandStatus,
        setRunCommandStatus,
        handleRunCommand,
        resetSteps,
        isValidCommand,
    }
}

type ICommandPalette = {
    className?: string;
    show?: boolean;
    command: string;
    setCommand: (command: string) => void;
    onClose?: () => void;
}

export const CommandPalette: FC<ICommandPalette & ReturnType<typeof useCommandPalette>> = ({ className, show, commandExecutionResults, command,  setCommand, handleRunCommand, onClose }) => {
    return <div className={classNames("absolute top-[40px] bg-white/10 backdrop-blur-2xl rounded-md shadow-xl w-full min-h-[150px] flex flex-col gap-2 p-4", {
        "hidden": !show,
    }, className)}>
        <CommandResultSteps results={commandExecutionResults}
            command={command} setCommand={setCommand}
            onReRun={handleRunCommand} onClose={onClose} />
    </div>
}
import { once, startCase, toLower } from "lodash";
import { Edge, Node, getIncomers } from "reactflow";
import { ICodeEditorSuggestion } from "../components/editor";
import { FlowElements } from "../page/ci-cd/flow/flow-step-card";
import { HookStepType } from "../generated/graphql";

export function setCookie(name: string, value: string, days: number) {
  let expires = "";
  if (days != null) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function createStub(name: string) {
  return name.split(" ").map(word => word.toLowerCase()).join("-");
}

export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function toTitleCase(str: string) {
  return startCase(toLower(str));
}

export function isNumeric(value: string) {
  if (value.length === 0) return false;
  return /^\d+$/.test(value);
}

export function getCenterPosition(node: Node, viewportHeight: number, viewportWidth: number, zoom=1) {
  return {
    x: -node.position.x - ((node.width ?? 400) / 2) + (viewportWidth / 2),
    y: -node.position.y - ((node.height ?? 400) / 2) + (viewportHeight / 2),
    zoom,
  };
}

export function getAllReferencesRecursively(node: Node, nodes: Node[], edges: Edge[]): Node[] {
  const references: Node[] = [];
  let currentNode: Node | null = node;
  while (currentNode != null) {
      const incomers: Node[] = getIncomers(currentNode, nodes, edges);
      references.push(...incomers);
      if (incomers.length > 0) {
          currentNode = incomers[0];
      } else {
          currentNode = null;
      }
  }
  return references;
}

export function getOptional(item?: string) {
  return item != null && item.length > 0 ? item : undefined;
}

export function getOptionalNumber(item?: string) {
  return item != null && item.length > 0 ? Number.parseInt(item) : undefined;
}

export function copyToClipboard(text: string) {
  setTimeout(() => {
    navigator.clipboard.writeText(text);
  }, 0);
}

export function getExposedVariableSuggestions(element: HookStepType | FlowElements, id: string, exposedVariables: string[]): ICodeEditorSuggestion[] {
  return exposedVariables.map(variable => ({
    label: `{{${element}.${id}.${variable}}}`,
    documentation: `Use previous ${element} step ${variable} value dynamically`,
  }));
}

export function createRedirectLink(link: string, id: string) {
  return `${link}?id=${id}`;
}

export const getInstallNamespace = once(() => {
  return window.location.hostname.split(".")[0];
});

export function getDate(dateString: string) {
    const currentDate = new Date(dateString);
    return currentDate.toLocaleDateString('en-US', {
      year: "numeric",
      month: "long",
      day: "2-digit"
    });
}
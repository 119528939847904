import { find, map } from "lodash";
import { FC, useCallback, useRef, useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { CancelButton, DeleteButton, EditButton, SubmitButton, UpdateButton } from "../../../components/button";
import { AdjustableCard, Error, CreateCard, GraphBasedExpandableCard } from "../../../components/card";
import { IDropdownItem } from "../../../components/dropdown";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Search } from "../../../components/search";
import { PanelSelector } from "../../../components/selector";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { GetDockerIoRegistryIntegrationsDocument, GetRegistriesDocument, GetRegistryDocument, Registry, useCreateRegistryMutation, useDeleteRegistryMutation, useGetDockerIoRegistryIntegrationsQuery, useUpdateRegistryMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { getOptional } from "../../../utils/functions";
import { ClassNames } from "../../../components/classes";
import classNames from "classnames";

export const CONTAINER_REGISTRY_ICON = {
    component: Icons.Container.Registry.Default,
    bgClassName: "bg-teal-500",
}

enum Panels {
    Starting = "Starting",
    DockerIO = "DockerIO",
}

const integrations: IDropdownItem[] = [
    {
        id: Panels.DockerIO,
        label: "Docker IO",
        icon: Icons.Logos.DockerIO,
    },
]

function getDefaultForm(navigate: NavigateFunction, registry?: Registry): IFormVariable[] {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: registry?.Name,
        },
        {
            name: "type",
            label: "Registry Type",
            fieldType: "text",
            type: "dropdown",
            validate: (value: string) => value.length > 0,
            errorMessage: "Registry type is required",
            defaultIcon: Icons.Container.Registry.Default,
            dropdownProps: {
                items: [
                    {
                        id: "DockerIO",
                        label: "DockerHub",
                    },
                    {
                        id: "PublicDockerIO",
                        label: "Public Docker Hub",
                    },
                ],
            },
            defaultValue: registry?.Type,
        },
        {
            name: "dockerIOId",
            label: "Docker Hub Integration",
            fieldType: "text",
            type: "query",
            validate: (value: string) => value.length > 0,
            errorMessage: "Docker Hub integration is required",
            defaultValue: registry?.DockerIO?.Id,
            query: GetDockerIoRegistryIntegrationsDocument,
            transform(data) {
                return map(data?.DockerIORegistryIntegration ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Container.Registry.Default,
                }));
            },
            defaultIcon: Icons.Container.Registry.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create an integration" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Integrations.CreateIntegrations.path);
                },
            },
            hide(form) {
                return form.type !== "DockerIO";
            },
        },
        {
            name: "namespace",
            label: "Docker Hub Namespace",
            fieldType: "text",
            validate: (namespace: string) => namespace.length > 0,
            errorMessage: "DockerHub namespace is required",
            defaultValue: registry?.Namespace,
            defaultIcon: Icons.Container.Registry.Default,
            hide(form) {
                return form.type !== "PublicDockerIO";
            },
        },
    ]
}

type ICreateRegistryCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

export const CreateRegistryCard: FC<ICreateRegistryCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createRegistry, { loading }] = useCreateRegistryMutation();
    const ref = useRef<Function>();
    const navigate = useNavigate();
    const [selectedPanelId, setSelectedPanelId] = useState<string>(Panels.DockerIO);
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSelect = useCallback((item: IDropdownItem) => {
        setSelectedPanelId(item.id)
    }, []);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createRegistry({
            variables: {
                name: form.name,
                type: form.type,
                dockerIOId: getOptional(form.dockerIOId),
                namespace: getOptional(form.namespace),
            },
            onCompleted: () => {
                notify("Registry created successfully", "success");
                ref.current?.();
                props.refetch();
            },
            onError: () => {
                notify("Unable to create registry", "error");
            }
        });
    }, [isFormValid, getForm, createRegistry, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating} label="Registry" icon={{
                component: Icons.Add,
                bgClassName: CONTAINER_REGISTRY_ICON.bgClassName,
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<Error error={error} />}>
                <PanelSelector selectedPanelId={selectedPanelId} panels={[
                    {
                        id: Panels.Starting,
                        component: <div className="flex flex-col gap-1 my-4 grow">
                            <div className="text-lg my-2">Create registry</div>
                            <Search label="Search your integration" className="flex-grow" items={integrations} isOpen={true} onSelect={handleSelect} noItemsLabel="No integrations found" />
                            <div className="flex justify-end">
                                <CancelButton onClick={() => ref.current?.()} />
                            </div>
                        </div>,
                    },
                    {
                        id: Panels.DockerIO,
                        component: <>
                            <div className="flex flex-col gap-1 my-4 grow">
                                <Form variables={getDefaultForm(navigate)} {...formProps} />
                            </div>
                            <div className="flex justify-end items-center gap-2">
                                <CancelButton onClick={handleClose} />
                                <SubmitButton loading={loading} onClick={handleSubmit} />
                            </div>
                        </>
                    },
                ]} />
            </CreateCard>
        </>
    )
}

export const RegistryIntro: FC<{ registry: Registry }> = (props) => {
    const getDockerIORegistryIntegrationsResponse = useGetDockerIoRegistryIntegrationsQuery();
    const selectedDockerIOIntegrationImage = find(getDockerIORegistryIntegrationsResponse.data?.DockerIORegistryIntegration ?? [], ({ Id }) => props.registry.DockerIO?.Id === Id);

    return <div className="flex flex-col grow mt-2">
        <div className={classNames(ClassNames.Text, "text-md")}>
            {props.registry.Name}
        </div>
        <div className={classNames(ClassNames.Text, "text-xs mt-2")}>
            <strong>Type:</strong> {find(integrations, integration => integration.id === props.registry.Type)?.label}
        </div>
        {
            selectedDockerIOIntegrationImage != null &&
            <div className={classNames(ClassNames.Text, "text-xs")}>
                <strong>Docker IO:</strong> {props.registry.DockerIO?.Name}
            </div>
        }
    </div>
}

type RegistryCardProps = {
    registry: Registry;
    refetch: () => void;
}

export const RegistryCard: FC<RegistryCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteRegistry, { loading: deleteLoading }] = useDeleteRegistryMutation();
    const [updateRegistry, { loading: updateLoading }] = useUpdateRegistryMutation();
    const navigate = useNavigate();
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        const form = getForm();
        setError("");
        updateRegistry({
            variables: {
                id: props.registry.Id,
                name: form.name,
                type: form.type,
                dockerIOId: getOptional(form.dockerIOId),
                namespace: getOptional(form.namespace),
            },
            onCompleted: () => {
                notify("Registry updated successfully", "success");
                props.refetch();
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update registry", "error");
            }
        });
    }, [isFormValid, getForm, updateRegistry, props]);

    const handleDelete = useCallback(() => {
        deleteRegistry({
            variables: {
                Id: props.registry.Id,
            },
            onCompleted: () => {
                notify("Registry deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete registry", "error");
            },
        });
    }, [props, deleteRegistry]);


    return <GraphBasedExpandableCard id={props.registry.Id} type={GraphElements.Registry} icon={CONTAINER_REGISTRY_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<Error error={error} />} highlight={searchParams.get("id") === props.registry.Id}>
        <>
            <div className="flex flex-col grow">
                <div className={classNames(ClassNames.Text, "text-md mt-2")}>
                    {props.registry.Name}
                </div>
            </div>
            <div className="flex">
                <EditButton onClick={() => toggleCardExpansion.current?.(true)} />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(navigate, props.registry)} {...formProps} />
            </div>
            <div className="flex justify-between items-center">
                <DeleteButton loading={deleteLoading} onClick={handleDelete} />
                <div className="flex items-center gap-2">
                    <CancelButton onClick={handleCancel} />
                    <UpdateButton loading={updateLoading} onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const RegistryDashboardCard: FC<{ registry: Registry }> = (props) => {
    return <AdjustableCard showTools={true} icon={CONTAINER_REGISTRY_ICON}>
        <RegistryIntro registry={props.registry} />
    </AdjustableCard>
}

export const transformRegistryData: IDataTransform<Registry>  = (data: { Registry: Registry[]}) => data.Registry;

export const RegistryGraphCard: FC<IGraphCardProps<Registry>> = (props) => {
    return <GraphCardLoader loader={GetRegistryDocument} transform={data => transformRegistryData(data)[0]} {...props}>
        {(data: Registry) => (<RegistryDashboardCard registry={data} />)}
    </GraphCardLoader>;
}

export const AddRegistryDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Registry"
        query={GetRegistriesDocument}
        transform={transformRegistryData} type={GraphElements.Registry}
        icon={Icons.Container.Registry.Default}
        link={InternalRoutes.Container.CreateRegistry.path} />
    );
}
import classNames from "classnames";
import { map } from "lodash";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactFlowProvider, getIncomers, getOutgoers, useEdgesState, useNodesState } from "reactflow";
import { useImmer } from "use-immer";
import { v4 } from "uuid";
import { CreateButton } from "../../../components/button";
import { Error } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { Page } from "../../../components/container";
import { createNode } from "../../../components/graph/utils";
import { InputWithlabel } from "../../../components/input";
import { InternalRoutes } from "../../../config/internal-routes";
import { CreateHookStep, HookStepType, useCreateHookMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { HookGraphViewContext, IHookGraphData, IHookGraphViewCache } from "./context";
import { GraphHooksCard } from "./hooks-card";


export const CreateHooksPage: FC = () => {
    const [createHook, { loading }] = useCreateHookMutation();
    const [cache, setCache] = useImmer<IHookGraphViewCache>({});
    const [name, setName] = useState("");
    const [nodes, setNodes, onNodesChange] = useNodesState([
        createNode({
            id: v4(),
            type: HookStepType.Starting,
            data: undefined,
            deletable: false,
        })
    ]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const navigate = useNavigate();
    const [error, setError] = useState<string>();

    const handleSubmit = useCallback(() => {
        if (name.length === 0) {
            return setError("Name is required");
        }
        if (nodes.length === 1 && nodes[0].type === HookStepType.Starting) {
            return setError("At least one step is required");
        }
        setError(undefined);
        const steps: CreateHookStep[] = nodes.map(nd => {
            const nodeData = cache[nd.id].getForm();
            const references = map(getIncomers(nd, nodes, edges), node => node.id);
            const dependents = map(getOutgoers(nd, nodes, edges), node => node.id);
            return {
                ...nodeData,
                NodeId: nd.id,
                References: references,
                Dependents: dependents,
            }
        });
        createHook({
            variables: {
                name,
                steps,
            },
            onCompleted: () => {
                notify("Hook created successfully", "success");
                navigate(InternalRoutes.CI_CD.Hooks.path);
            },
            onError: () => {
                notify("Unable to create hook", "error");
            },
        });
    }, [cache, createHook, edges, name, navigate, nodes]);

    const handleSetCacheItem = useCallback((key: string, value: IHookGraphData) => {
        setCache(c => {
            c[key] = value;
        });
}, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    return <Page routes={[InternalRoutes.CI_CD.Hooks, InternalRoutes.CI_CD.CreateHook]}>
        <div className="flex flex-col gap-8 w-full h-full">
            <HookGraphViewContext.Provider value={{
                cache,
                setCacheItem: handleSetCacheItem,
                removeCacheItem: handleRemoveCacheItem,
            }}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                        <div className={classNames(ClassNames.Text, "text-3xl")}>
                            Create New Hook
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <CreateButton onClick={handleSubmit} loading={loading} />
                    </div>
                </div>
                <div className="flex gap-8 h-[80vh]">
                    <div className="flex flex-col gap-2 w-full">
                        <div className="flex justify-start gap-4 w-full">
                            <InputWithlabel label="Name" value={name} setValue={setName} />
                            <div className="flex gap-2  grow items-start justify-end mt-1">
                                <Error error={error} />
                            </div>
                        </div>
                        <HookGraphViewContext.Provider value={{
                            cache,
                            setCacheItem: handleSetCacheItem,
                            removeCacheItem: handleRemoveCacheItem,
                        }}>
                                <ReactFlowProvider>
                                    <GraphHooksCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                                        edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} />
                                </ReactFlowProvider>
                        </HookGraphViewContext.Provider>
                    </div>
                </div>
            </HookGraphViewContext.Provider>
        </div>
    </Page>
}
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { FC, useCallback, useMemo, useState } from "react";
import { AnimatedButton } from "../../components/button";
import { PieChart } from "../../components/charts/pie-chart";
import { ClassNames } from "../../components/classes";
import { LoadingPage, Page } from "../../components/container";
import { Icons } from "../../config/icons";
import { InternalRoutes } from "../../config/internal-routes";
import { useGetTierQuery, useGetUsageQuery, useUpdateTierMutation } from "../../generated/graphql";
import { TierSelection } from "./tier";
import { createStub } from "../../utils/functions";

export const AccountPage: FC = () => {
    const { data: tierData, loading: tierLoading, error: tierError, refetch: refetchTier } = useGetTierQuery();
    const { data: usageData, loading: usageLoading, refetch: refetchUsage } = useGetUsageQuery();
    const [updateTier, { loading: updateTierLoading }] = useUpdateTierMutation();
    const [showTier, setShowTier] = useState(false);

    const handleClick = useCallback((tierType: string) => {
        updateTier({
            variables: {
                tierType,
            },
            onCompleted() {
                setShowTier(false);
                refetchTier();
                refetchUsage();
            }
        });
    }, [refetchTier, refetchUsage, updateTier]);

    const price = useMemo(() => {
        switch(tierData?.Tier.TierType) {
            case "Trial": return "Free";
            case "Essential": return "$10";
            case "Startup": return "$25";
        }
        return "";
    }, [tierData?.Tier.TierType]);

    const pieCharts = useMemo(() => {
        if (tierData?.Tier == null || usageData?.Usage == null) {
            return [];
        } 
        const charts = [
            {
                label: "CPU",
                percentageLeft: (usageData.Usage.CPU/tierData.Tier.CPU)*100,
                data: [{name: "CPU Left", value: tierData.Tier.CPU - usageData.Usage.CPU},{name: "CPU Used", value: usageData.Usage.CPU}],
                tooltips: {
                    "CPU Left": `${tierData.Tier.CPU - usageData.Usage.CPU} milli core`,
                    "CPU Used": `${usageData.Usage.CPU} milli core`,
                } as Record<string, string>,
            },
            {
                label: "RAM",
                percentageLeft: (usageData.Usage.RAM/tierData.Tier.RAM)*100,
                data: [{name: "RAM Left", value: tierData.Tier.RAM - usageData.Usage.RAM},{name: "RAM Used", value: usageData.Usage.RAM}],
                tooltips: {
                    "RAM Left": `${tierData.Tier.RAM - usageData.Usage.RAM} MB`,
                    "RAM Used": `${usageData.Usage.RAM} MB`,
                } as Record<string, string>,
            },
            {
                label: "Hooks",
                percentageLeft: (usageData.Usage.Hooks/tierData.Tier.Hooks)*100,
                data: [{name: "Hooks Left", value: tierData.Tier.Hooks - usageData.Usage.Hooks},{name: "Hooks Used", value: usageData.Usage.Hooks}],
                tooltips: {
                    "Hooks Left": `${tierData.Tier.Hooks - usageData.Usage.Hooks}`,
                    "Hooks Used": `${usageData.Usage.Hooks}`,
                } as Record<string, string>,
            },
            {
                label: "Flows",
                percentageLeft: (usageData.Usage.Flows/tierData.Tier.Flows)*100,
                data: [{name: "Flows Left", value: tierData.Tier.Flows - usageData.Usage.Flows},{name: "Flows Used", value: usageData.Usage.Flows}],
                tooltips: {
                    "Flows Left": `${tierData.Tier.Flows - usageData.Usage.Flows}`,
                    "Flows Used": `${usageData.Usage.Flows}`,
                } as Record<string, string>,
            },
            {
                label: "Domains",
                percentageLeft: (usageData.Usage.Domains/tierData.Tier.Domains)*100,
                data: [{name: "Domains Left", value: tierData.Tier.Domains - usageData.Usage.Domains},{name: "Domains Used", value: usageData.Usage.Domains}],
                tooltips: {
                    "Domains Left": `${tierData.Tier.Domains - usageData.Usage.Domains}`,
                    "Domains Used": `${usageData.Usage.Domains}`,
                } as Record<string, string>,
            },
            {
                label: "Environment Variables",
                percentageLeft: (usageData.Usage.EnvironmentVariables/tierData.Tier.EnvironmentVariables)*100,
                data: [{name: "Environment Variables Left", value: tierData.Tier.EnvironmentVariables - usageData.Usage.EnvironmentVariables},{name: "Environment Variables Used", value: usageData.Usage.EnvironmentVariables}],
                tooltips: {
                    "Environment Variables Left": `${tierData.Tier.EnvironmentVariables - usageData.Usage.EnvironmentVariables}`,
                    "Environment Variables Used": `${usageData.Usage.EnvironmentVariables}`,
                } as Record<string, string>,
            },
            {
                label: "Quick Containers",
                percentageLeft: (usageData.Usage.QuickContainers/tierData.Tier.QuickContainers)*100,
                data: [{name: "Quick Containers Left", value: tierData.Tier.QuickContainers - usageData.Usage.QuickContainers},{name: "Quick Containers Used", value: usageData.Usage.QuickContainers}],
                tooltips: {
                    "Quick Containers Left": `${tierData.Tier.QuickContainers - usageData.Usage.QuickContainers}`,
                    "Quick Containers Used": `${usageData.Usage.QuickContainers}`,
                } as Record<string, string>,
            },
            {
                label: "Clusters",
                percentageLeft: (usageData.Usage.Clusters/tierData.Tier.Clusters)*100,
                data: [{name: "Clusters Left", value: tierData.Tier.Clusters - usageData.Usage.Clusters},{name: "Clusters Used", value: usageData.Usage.Clusters}],
                tooltips: {
                    "Clusters Left": `${tierData.Tier.Clusters - usageData.Usage.Clusters}`,
                    "Clusters Used": `${usageData.Usage.Clusters}`,
                } as Record<string, string>,
            },
        ];
        if (tierData.Tier.Storage > 0) {
            charts.splice(2, 0, {
                label: "Storage",
                percentageLeft: (usageData.Usage.Storage/tierData.Tier.Storage)*100,
                data: [{name: "Storage Left", value: tierData.Tier.Storage - usageData.Usage.Storage},{name: "Storage Used", value: usageData.Usage.Storage}],
                tooltips: {
                    "Storage Left": `${tierData.Tier.Storage - usageData.Usage.Storage} GB`,
                    "Storage Used": `${usageData.Usage.Storage} GB`,
                } as Record<string, string>,
            });
        }
        return charts;
    }, [tierData?.Tier, usageData?.Usage]);

    const [ overview, firstCharts, secondCharts ] = useMemo(() => {
        if (pieCharts.length === 9) {
            return [pieCharts.slice(0, 3),pieCharts.slice(3, 6),pieCharts.slice(6)];
        }
        return [pieCharts.slice(0, 2),pieCharts.slice(2, 5),pieCharts.slice(5)];
    }, [pieCharts]);

    if (tierError != null) {
        return <TierSelection loading={updateTierLoading} onClick={handleClick} />
    }

    if (tierLoading || usageLoading) {
        return <LoadingPage />
    }

    return <Page routes={[InternalRoutes.Account]} className="flex-col gap-8 flex-nowrap">
        <AnimatePresence mode="wait">
            { showTier && <TierSelection loading={updateTierLoading} onClick={handleClick} onCancel={() => setShowTier(false)} />}
        </AnimatePresence>
        <div className="flex gap-8 w-full">
            <div className="flex flex-col gap-4 w-1/3">
                <div className={classNames(ClassNames.Text, "text-3xl")}>
                    Tier
                </div>
                <div className={classNames(ClassNames.Text, "border border-white/5 rounded-3xl h-full p-8 flex justify-between items-center")}>
                    <div className={classNames(ClassNames.Text, "text-3xl")}>{tierData?.Tier.TierType}</div>
                    <div className="flex">
                        <AnimatedButton label="Change" icon={Icons.RightChevron} onClick={() => setShowTier(true)} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-4 w-1/3">
                <div className={classNames(ClassNames.Text, "text-3xl")}>
                    Estimated Due
                </div>
                <div className={classNames(ClassNames.Text, "text-base")}>
                    This is an estimated balance you owe, reflecting your usage so far this month, adjusted for any credits and prepayments.
                </div>
                <div className={classNames(ClassNames.Text, "text-5xl")}>
                    {price}
                </div>
            </div>
        </div>
        <div className="flex flex-col gap-4">
            <div className={classNames(ClassNames.Text, "text-3xl")}>
                Current Usage
            </div>
            <div className="flex w-fit my-8 items-center gap-12">
                <div className="flex gap-2 items-center">
                    {
                        overview.map(pieChart => (
                            <div className="flex flex-col gap-2">
                                <PieChart key={createStub(pieChart.label)} label={<span className={classNames({
                                    "text-red-500": pieChart.percentageLeft > 100,
                                })}>{pieChart.percentageLeft.toFixed(1)}%</span>} height={150} width={200} data={pieChart.data} tooltips={pieChart.tooltips} />
                                <div className={classNames("flex justify-center items-center", ClassNames.Text, "text-base text-center")}>
                                    {pieChart.label}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center">
                        {
                            firstCharts.map(pieChart => (
                                <div className="flex flex-col gap-2 flex-[350px]">
                                    <PieChart key={createStub(pieChart.label)} label={<span className={classNames("text-sm", {
                                        "text-red-500": pieChart.percentageLeft > 100,
                                    })}>{pieChart.percentageLeft.toFixed(1)}%</span>} height={100} width={120} data={pieChart.data} tooltips={pieChart.tooltips} />
                                    <div className={classNames("flex justify-center items-center", ClassNames.Text, "text-base text-center")}>
                                        {pieChart.label}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="flex gap-2 items-center">
                        {
                            secondCharts.map(pieChart => (
                                <div className="flex flex-col gap-2 flex-[350px]">
                                    <PieChart key={createStub(pieChart.label)} label={<span className={classNames("text-sm", {
                                        "text-red-500": pieChart.percentageLeft > 100,
                                    })}>{pieChart.percentageLeft.toFixed(1)}%</span>} height={100} width={120} data={pieChart.data} tooltips={pieChart.tooltips} />
                                    <div className={classNames("flex justify-center items-center", ClassNames.Text, "text-base text-center")}>
                                        {pieChart.label}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-col gap-4">
            <div className={classNames(ClassNames.Text, "text-3xl")}>
                Transactions
            </div>
            <div className={classNames(ClassNames.Text, "text-lg flex gap-2 h-[200px]")}>
                {Icons.Card} No transactions
            </div>
            {/* <Card icon={{
                component: Icons.Dollar,
                bgClassName: "bg-emerald-500",
            }} tag={<div className={classNames(ClassNames.Text, "text-sm")}>$2,341.11</div>}>
                <div className="flex flex-col justify-between grow pt-2">
                    <div className="flex flex-col grow">
                        <div className={classNames(ClassNames.Text, "text-lg")}>{getDate(new Date().toString())}</div>
                        <div className={classNames(ClassNames.Text, "text-sm")}>(Mastercard 6084)</div>
                    </div>
                    <div className="flex justify-between items-center">
                        <AnimatedButton icon={Icons.Download} label="Download invoice" />
                    </div>
                </div>
            </Card> */}
        </div>
        <div className="flex flex-col gap-4">
            <div className={classNames(ClassNames.Text, "text-3xl")}>
                Payment methods
            </div>
            <div className={classNames(ClassNames.Text, "text-lg flex gap-2 h-[200px]")}>
                {Icons.Card} No payment methods
            </div>
            {/* <Card icon={{
                component: Icons.Card,
                bgClassName: "bg-emerald-500",
            }}>
                <div className="flex flex-col justify-between grow pt-2">
                    <div className="flex flex-col grow">
                        <div className={classNames(ClassNames.Text, "text-sm")}>Mastercard - 6084</div>
                        <div className={classNames(ClassNames.Text, "text-sm")}>Expires 08/2028</div>
                    </div>
                    <div className="flex justify-between items-center">
                        <DeleteButton />
                    </div>
                </div>
            </Card> */}
        </div>
    </Page>
}
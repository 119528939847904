import classNames from "classnames";
import { FC, useCallback, useRef, useState } from "react";
import { CancelButton } from "../../components/button";
import { CreateCard, Error } from "../../components/card";
import { ClassNames } from "../../components/classes";
import { IDropdownItem } from "../../components/dropdown";
import { Search } from "../../components/search";
import { PanelSelector } from "../../components/selector";
import { Icons } from "../../config/icons";
import { DigitalOceanOAuthIntegrationsLoginCard } from "./digital-ocean-integrations/digital-ocean-integrations-card";
import { CreateDockerIOIntegrationsForm } from "./docker-io-integrations/docker-io-integrations-card";
import { GitHubOAuthIntegrationsLoginCard } from "./github-integrations/github-integrations-card";
import { GoogleOAuthIntegrationsLoginCard } from "./google/google-integrations-card";
import { CreateMailIntegrationsForm } from "./mail/mail-integrations-card";

export type ICreateIntegrationsCardProps = {
    isCreating?: boolean;
    refetch?: () => void;
}

enum Panels {
    STARTING = "STARTING",
    GITHUB = "GITHUB",
    DOCKER_IO = "DOCKER_IO",
    DIGITAL_OCEAN = "DIGITAL_OCEAN",
    MAIL = "MAIL",
    GOOGLE = "GOOGLE",
    AWS = "AWS",
    AZURE = "AZURE",
    FLY_IO = "FLY_IO",
}

const integrations: IDropdownItem[] = [
    {
        id: Panels.GITHUB,
        label: "GitHub",
        icon: Icons.Logos.GitHub,
    },
    {
        id: Panels.DOCKER_IO,
        label: "Docker IO",
        icon: Icons.Logos.DockerIO,
    },
    {
        id: Panels.DIGITAL_OCEAN,
        label: "Digital Ocean",
        icon: Icons.Logos.DigitalOcean,
    },
    {
        id: Panels.GOOGLE,
        label: "Google",
        icon: Icons.Logos.Google,
    },
    {
        id: Panels.MAIL,
        label: "Mail",
        icon: Icons.Mail,
    },
]

export const CreateIntegrationsCard: FC<ICreateIntegrationsCardProps> = (props) => {
    const [error, setError] = useState("");
    const [selectedPanelId, setSelectedPanelId] = useState<string>(Panels.STARTING);
    const ref = useRef<Function>();

    const handleSelect = useCallback((item: IDropdownItem) => {
        setSelectedPanelId(item.id)
    }, []);

    return (
        <>
            <CreateCard isExpanded={props.isCreating} label="Integration" icon={{
                component: Icons.Add,
                bgClassName: "bg-blue-500",
            }} tag={<Error error={error} />} setToggleCallback={(toggle) => ref.current = toggle}>
                <PanelSelector selectedPanelId={selectedPanelId} panels={[
                    {
                        id: Panels.STARTING,
                        component: <div className="flex flex-col gap-1 my-4 grow">
                            <div className={classNames(ClassNames.Text, "text-lg my-2")}>Create integration</div>
                            <Search label="Search your integration" className="flex-grow" items={integrations} isOpen={true} onSelect={handleSelect} noItemsLabel="No integrations found" />
                            <div className="flex justify-end">
                                <CancelButton onClick={() => ref.current?.()} />
                            </div>
                        </div>,
                    },
                    {
                        id: Panels.DOCKER_IO,
                        component: <CreateDockerIOIntegrationsForm
                            onCancel={() => setSelectedPanelId(Panels.STARTING)}
                            onComplete={() => {
                                setSelectedPanelId(Panels.STARTING);
                                ref.current?.();
                                props.refetch?.();
                            }}
                            onError={setError} />
                    },
                    {
                        id: Panels.GITHUB,
                        component: <GitHubOAuthIntegrationsLoginCard onCancel={() => setSelectedPanelId(Panels.STARTING)} />
                    },
                    {
                        id: Panels.DIGITAL_OCEAN,
                        component: <DigitalOceanOAuthIntegrationsLoginCard onCancel={() => setSelectedPanelId(Panels.STARTING)} />
                    },
                    {
                        id: Panels.GOOGLE,
                        component: <GoogleOAuthIntegrationsLoginCard onCancel={() => setSelectedPanelId(Panels.STARTING)} />
                    },
                    {
                        id: Panels.MAIL,
                        component: <CreateMailIntegrationsForm
                        onCancel={() => setSelectedPanelId(Panels.STARTING)}
                        onComplete={() => {
                            setSelectedPanelId(Panels.STARTING);
                            ref.current?.();
                            props.refetch?.();
                        }}
                        onError={setError} />
                    },
                ]} />
            </CreateCard>
        </>
    )
}
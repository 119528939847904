import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { User } from '../generated/graphql';

export enum UserRole {
	EmployeeRole = "employee",
	LeadRole     = "lead",
	AdminRole    = "admin",
}

type IJwtToken = {
  accessToken: string;
  refreshToken: string;
}

type IAuthStatus = "pending" | "success" | "failed" | "logging-out";

export type IAuthState = {
  status: IAuthStatus;
  token?: IJwtToken;
  user?: Omit<User, "Email" | "Tier" | "Usage">;
}

const initialState: IAuthState = {
  status: "pending",
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<IJwtToken>) => {
      state.token = action.payload;
    },
    setStatus: (state, action: PayloadAction<IAuthStatus>) => {
      state.status = action.payload;
    },
    setUser: (state, action: PayloadAction<IAuthState["user"]>) => {
      state.user = action.payload;
    }
  },
})

export const AuthActions = authSlice.actions;
export const authReducers = authSlice.reducer;
import classNames from "classnames";
import { filter } from "lodash";
import { FC, ReactElement, useCallback, useContext, useState } from "react";
import { Position, useReactFlow } from "reactflow";
import { twMerge } from "tailwind-merge";
import { v4 } from "uuid";
import { ActionButton } from "../../../components/button";
import { Card, Error } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { CustomHandle } from "../../../components/graph/custom-handle";
import { IGraphCardProps } from "../../../components/graph/graph";
import { createEdge, createNode } from "../../../components/graph/utils";
import { Loading } from "../../../components/loading";
import { Icons } from "../../../config/icons";
import { HookStepType } from "../../../generated/graphql";
import { HookGraphViewContext } from "./context";
import { Tooltip } from "../../../components/tooltip";


type IHooksStepCard<T extends unknown = any> = {
    label: string;
    className?: string;
    status?: string;
    children: ReactElement;
    disableActions?: boolean;
} & IGraphCardProps<T>;

export const HooksStepCard: FC<IHooksStepCard> = (props) => {
    const { getNodes, setNodes, setEdges } = useReactFlow();
    const { cache } = useContext(HookGraphViewContext);
    const [error, setError] = useState<string>();

    const handleNewStep = useCallback(() => {
        const validState = cache[props.id].isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError(undefined);
        const nodes = getNodes();
        if (filter(nodes, node => node.type === HookStepType.Starting).length > 0) {
            return;
        }
        const nodeId = v4();
        setNodes(nodes => [
            ...nodes,
            createNode({
                id: nodeId,
                type: HookStepType.Starting,
                data: undefined,
            }, {
                x: props.xPos + (nodes[nodes.length - 1]?.width ?? 400) + 200,
                y: props.yPos,
            })
        ]);
        setEdges(edges => [
            ...edges,
            createEdge(props.id, nodeId),
        ]);
    }, [props, getNodes, setNodes, setEdges, cache]);

    return (
        <>
            <CustomHandle type="target" position={Position.Left} />
            <Card icon={{
                component: Icons.Add,
                bgClassName: "bg-teal-500",
            }} className={twMerge(classNames("relative w-[300px] h-fit group/hook-step-selector", props.className))} tag={<div className="flex flex-col gap-1 items-end">
                {
                    props.data?.Status != null &&
                    <Tooltip tooltip={props.data.Message}><div className={classNames("flex flex-row gap-1 items-center", {
                        "text-green-500": props.data.Status === "Success",
                        "text-red-500": props.data.Status === "Error",
                        "text-blue-500": props.data.Status === "Started",
                        "text-slate-500": props.data.Status === "Cancelled",
                        "text-neutral-500": props.data.Status === "Skipped",
                    })}>
                        {props.data.Status === "Success" && Icons.CheckCircle}
                        {props.data.Status === "Error" && Icons.SadSmile}
                        {props.data.Status === "Cancelled" && Icons.Cancel}
                        {props.data.Status === "Skipped" && Icons.Skip}
                        {props.data.Status === "Started" && <Loading size="sm" hideText={true} />}
                        {props.data.Status}
                    </div></Tooltip>
                }
                <Error error={error} />
            </div>}>
                <div className="flex flex-col grow my-2 gap-2">
                    <div className="flex flex-col">
                        <div className={classNames(ClassNames.Text, "text-sm font-bold")}>{props.label}</div>
                        <div className="w-full border-[1px] border-neutral-100" />
                    </div>
                    {props.children}
                </div>
                {
                    !props.disableActions && <div className="absolute top-1/2 -translate-y-1/2 -right-14 opacity-0 transition-all group-hover/hook-step-selector:opacity-100">
                        <ActionButton className="w-8 h-8" icon={Icons.Plus} onClick={handleNewStep} />
                    </div>
                }
            </Card>
            <CustomHandle type="source" position={Position.Right} />
        </>
    )
}
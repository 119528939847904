import { FC, useContext, useEffect } from "react"
import { ICodeEditorSuggestion } from "../../../../components/editor"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { CreateGitPullRequestCommentHookStep, GitPullRequestCommentHookStep, HookStepType } from "../../../../generated/graphql"
import { HookGraphViewContext } from "../context"
import { HooksStepCard } from "../hooks-step-card"
import { HandlebarCodeEditor, useGetHandlebarSuggestions } from "./handlebar-code-editor"


function getDefaultForm(suggestions: ICodeEditorSuggestion[], gitPullRequestComment?: GitPullRequestCommentHookStep): IFormVariable[] {
    return [
        {
            name: "message",
            label: "Message",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Message is required",
            defaultValue: gitPullRequestComment?.Message,
            onRender: (value: string, setValue: (content: string) => void) => {
                return <HandlebarCodeEditor value={value} setValue={setValue} suggestions={suggestions} />
            },
        },
    ];
}

export type IGitPullRequestCommentHookStep = {
    Type: HookStepType.GitPullRequestComment;
    GitPullRequestComment: CreateGitPullRequestCommentHookStep;
};

export type ICreateGitPullRequestCommentHookStep = IGitPullRequestCommentHookStep;

export const GitPullRequestCommentHookStepCard: FC<IGraphCardProps<GitPullRequestCommentHookStep>> = (props) => {
    const { setCacheItem } = useContext(HookGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertGitPullRequestCommentFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const suggestions = useGetHandlebarSuggestions(props.id);

    return (
        <HooksStepCard label="Git pull request comment" {...props} className="h-[250px] w-[400px]">
            <Form variables={getDefaultForm(suggestions, props.data)} {...formProps} />
        </HooksStepCard>
    )
}

const convertGitPullRequestCommentFormToStep = (form: IForm): ICreateGitPullRequestCommentHookStep => {
    return {
        Type: HookStepType.GitPullRequestComment,
        GitPullRequestComment: {
            Type: "GitHub",
            Message: form.message as string,
        }
    }
}
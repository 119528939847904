import { map } from "lodash";
import { FC, useCallback, useRef, useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { CancelButton, DeleteButton, EditButton, SubmitButton, UpdateButton } from "../../../components/button";
import { AdjustableCard, Error, CreateCard, GraphBasedExpandableCard } from "../../../components/card";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { GetDockerRegistryImagesDocument, GetRegistriesDocument, GetRegistryImageDocument, GetRegistryImagesDocument, RegistryImage, useCreateRegistryImageMutation, useDeleteRegistryImageMutation, useUpdateRegistryImageMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import classNames from "classnames";
import { ClassNames } from "../../../components/classes";

export const CONTAINER_REGISTRY_IMAGE_ICON = {
    component: Icons.Container.Image.Default,
    bgClassName: "bg-teal-500",
};

function getDefaultForm(navigate: NavigateFunction, registryImage?: RegistryImage): IFormVariable[] {    
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: registryImage?.Name,
        },
        {
            name: "registryId",
            label: "Registry",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Registry is required",
            defaultValue: registryImage?.Registry?.Id,
            query: GetRegistriesDocument,
            transform(data) {
                return map(data?.Registry ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Container.Registry.Default,
                }));
            },
            defaultIcon: Icons.Container.Registry.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a registry" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Container.CreateRegistry.path);
                },
            }
        },
        {
            name: "image",
            label: "Image",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Image is required",
            defaultValue: registryImage?.Image,
            query: GetDockerRegistryImagesDocument,
            transform(data) {
                return map(data?.DockerRegistryImage ?? [], (Image) => ({
                    id: Image,
                    label: Image,
                    icon: Icons.Container.Image.Default,
                }));
            },
            defaultIcon: Icons.Container.Image.Default,
            isLazy: true,
            shouldQuery(form) {
                if (form.registryId == null) {
                    return [false, {}];
                }
                return [form.registryId.length !== 0, { id: form.registryId }];
            },
            dropdownProps: {
                noItemsLabel: "No images found",
            },
            allowCustomValues: true,
        },
    ]
}

type ICreateRegistryImageCardProps = {
    isCreating?: boolean;
    refetch: () => void;
}

export const CreateRegistryImageCard: FC<ICreateRegistryImageCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createRegistryImage, { loading }] = useCreateRegistryImageMutation();
    const ref = useRef<Function>();
    const navigate = useNavigate();
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createRegistryImage({
            variables: {
                name: form.name,
                image: form.image,
                registryId: form.registryId,
            },
            onCompleted: () => {
                notify("Registry image created successfully", "success");
                ref.current?.();
                props.refetch();
            },
            onError: () => {
                notify("Unable to create registry image", "error");
            }
        });
    }, [isFormValid, getForm, createRegistryImage, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating} label="Registry image" icon={{
                component: Icons.Add,
                bgClassName: CONTAINER_REGISTRY_IMAGE_ICON.bgClassName,
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<Error error={error} />}>
                <div className="flex flex-col gap-1 grow">
                    <div className={classNames(ClassNames.Text, "text-lg my-1")}>Create registry image</div>
                    <Form variables={getDefaultForm(navigate)} {...formProps} />
                </div>
                <div className="flex justify-end items-center gap-2">
                    <CancelButton onClick={handleClose} />
                    <SubmitButton loading={loading} onClick={handleSubmit} />
                </div>
            </CreateCard>
        </>
    )
}

export const RegistryImageIntro: FC<{ registryImage: RegistryImage }> = (props) => {
    return <div className="flex flex-col grow mt-2">
        <div className={classNames(ClassNames.Text, "text-md")}>
            {props.registryImage.Name}
        </div>
        <div className={classNames(ClassNames.Text, "text-xs mt-2")}>
            <strong>Image:</strong> {props.registryImage.Image}
        </div>
    </div>
}


type RegistryImageCardProps = {
    registryImage: RegistryImage;
    refetch: () => void;
}

export const RegistryImageCard: FC<RegistryImageCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteRegistryImage, { loading: deleteLoading }] = useDeleteRegistryImageMutation();
    const [updateRegistryImage, { loading: updateLoading }] = useUpdateRegistryImageMutation();
    const navigate = useNavigate();
    const toggleCardExpansion = useRef<Function>();
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const [searchParams, ] = useSearchParams();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        updateRegistryImage({
            variables: {
                id: props.registryImage.Id,
                name: form.name,
                image: form.image,
                registryId: form.registryId,
            },
            onCompleted: () => {
                notify("Registry image updated successfully", "success");
                props.refetch();
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update registry image", "error");
            }
        });
    }, [isFormValid, getForm, updateRegistryImage, props]);

    const handleDelete = useCallback(() => {
        deleteRegistryImage({
            variables: {
                id: props.registryImage.Id,
            },
            onCompleted: () => {
                notify("Registry image deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete registry image", "error");
            },
        });
    }, [props, deleteRegistryImage]);

    return <GraphBasedExpandableCard id={props.registryImage.Id} type={GraphElements.RegistryImage} icon={CONTAINER_REGISTRY_IMAGE_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<Error error={error} />} highlight={searchParams.get("id") === props.registryImage.Id}>
        <>
            <div className="flex flex-col grow">
                <div className={classNames(ClassNames.Text, "text-md mt-2")}>
                    {props.registryImage.Name}
                </div>
            </div>
            <div className="flex">
                <EditButton onClick={() => toggleCardExpansion.current?.(true)} />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(navigate, props.registryImage)} {...formProps} />
            </div>
            <div className="flex justify-between items-center">
                <DeleteButton loading={deleteLoading} onClick={handleDelete} />
                <div className="flex items-center gap-2">
                    <CancelButton onClick={handleCancel} />
                    <UpdateButton loading={updateLoading} onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const RegistryImageDashboardCard: FC<{ registryImage: RegistryImage }> = (props) => {
    return <AdjustableCard showTools={true} icon={CONTAINER_REGISTRY_IMAGE_ICON}>
        <RegistryImageIntro registryImage={props.registryImage} />
    </AdjustableCard>
}

export const transformRegistryImageData: IDataTransform<RegistryImage>  = (data: { RegistryImage: RegistryImage[]}) => data?.RegistryImage ?? [];

export const RegistryImageGraphCard: FC<IGraphCardProps<RegistryImage>> = (props) => {
    return <GraphCardLoader loader={GetRegistryImageDocument} transform={(data) => transformRegistryImageData(data)[0]} {...props}>
        {(data: RegistryImage) => (<RegistryImageDashboardCard registryImage={data} />)}
    </GraphCardLoader>;
}

export const AddRegistryImageDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Registry Image"
        query={GetRegistryImagesDocument}
        transform={transformRegistryImageData} type={GraphElements.RegistryImage}
        icon={Icons.Container.Image.Default}
        link={InternalRoutes.Container.CreateImage.path} />
    );
}
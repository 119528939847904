import { filter, map } from "lodash"
import { FC, useContext, useEffect } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { IDropdownItem } from "../../../../components/dropdown"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { InternalRoutes } from "../../../../config/internal-routes"
import { CreateDockerPushFlowStep, DockerPushFlowStep, GetRegistryImagesDocument } from "../../../../generated/graphql"
import { transformRegistryImageData } from "../../../container/registry-image/registry-image-card"
import { FlowGraphViewContext } from "../context"
import { FlowElements, FlowStepCard } from "../flow-step-card"


const generatedTagsDropdownItems: IDropdownItem[] = [
    { id: "ReplaceLatest", label: 'Replace "latest"' },
    { id: "IncrementMajorVersion", label: "Increment major semver version" },
    { id: "IncrementMinorVersion", label: "Increment minor semver version" },
    { id: "IncrementPatchVersion", label: "Increment patch semver version" },
    { id: "IncrementMajorVersionAndLatest", label: "Increment major semver & latest version" },
    { id: "IncrementMinorVersionAndLatest", label: "Increment minor semver & latest version" },
    { id: "IncrementPatchVersionAndLatest", label: "Increment patch semver & latest version" },
];

const getDefaultForm = (navigate: NavigateFunction, dockerPush?: DockerPushFlowStep): IFormVariable[] => {
    return [
        {
            name: "imageId",
            label: "Registry Image",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Registry image is required",
            query: GetRegistryImagesDocument,
            transform(data) {
                return map(filter(transformRegistryImageData(data), image => image.Registry?.Type === "DockerIO"), ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Container.Image.Default,
                }));
            },
            defaultIcon: Icons.Container.Image.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a registry image" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Container.CreateImage.path);
                },
            },
            defaultValue: dockerPush?.RegistryImage?.Id,
        },
        {
            name: "tag",
            label: "Generated tag",
            type: "dropdown",
            fieldType: "text",
            validate: (cpu: string) => cpu.length > 0,
            defaultIcon: Icons.Container.Image.Default,
            errorMessage: "Tag is required",
            dropdownProps: {
                items: generatedTagsDropdownItems,
                scrollContainerClassName: "h-32 max-h-32",
            },
            defaultValue: dockerPush?.GeneratedTagType,
        },
        {
            name: "prefix",
            label: "Prefix (optional)",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            defaultValue: dockerPush?.Prefix,
            hide: (form) => form.tag === "ReplaceLatest",
        },
        {
            name: "suffix",
            label: "Suffix (optional)",
            fieldType: "text",
            validate: () => true,
            errorMessage: "",
            defaultValue: dockerPush?.Suffix,
            hide: (form) => form.tag === "ReplaceLatest",
        },
    ]
}

export type ICreateDockerPushFlowStep = {
    Type: FlowElements.DockerPush;
    DockerPush: CreateDockerPushFlowStep;
}

export const DockerPushFlowStepCard: FC<IGraphCardProps<DockerPushFlowStep>> = (props) => {
    const { setCacheItem } = useContext(FlowGraphViewContext);
    const navigate = useNavigate();
    const [formProps, formCallbacks] = useFormHook();

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertDockerPushFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlowStepCard label="Docker push" {...props}>
            <div className="flex flex-col grow gap-1">
                <Form variables={getDefaultForm(navigate, props.data)} {...formProps} />
            </div>
        </FlowStepCard>
    )
}

const convertDockerPushFormToStep = (form: IForm): ICreateDockerPushFlowStep => {
    return {
        Type: FlowElements.DockerPush,
        DockerPush: {
            RegistryImageId: form.imageId as string,
            GeneratedTagType: form.tag as string,
            Prefix: form.prefix as string,
            Suffix: form.suffix as string,
        },
    }
}
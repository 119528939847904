import { FC } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { Cluster, useGetClustersQuery } from "../../../generated/graphql";
import { ClusterCard, CreateClusterCard } from "./cluster-card";

type IClusterPageProps = {
    isCreating?: boolean;
}

export const ClusterPage: FC<IClusterPageProps> = (props) => {
    const getAllClustersResponse = useGetClustersQuery();

    return (
        <Page routes={[InternalRoutes.Deployment.Cluster]}>
            <CreateClusterCard isCreating={props.isCreating} refetch={getAllClustersResponse.refetch} />
            {
                getAllClustersResponse.data?.Cluster.map(cluster => (
                    <ClusterCard key={cluster.Id} cluster={cluster as Cluster} refetch={getAllClustersResponse.refetch} />
                ))
            }
        </Page>
    )
}
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AssignUserRoleRequest = {
  Id: Scalars['String']['input'];
  Roles: Array<Scalars['String']['input']>;
};

export type BasicUserInfo = {
  __typename?: 'BasicUserInfo';
  FirstName: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Image: Scalars['String']['output'];
  LastName: Scalars['String']['output'];
};

export type Cluster = {
  __typename?: 'Cluster';
  DigitalOcean?: Maybe<DigitalOceanIntegration>;
  Google?: Maybe<GoogleIntegration>;
  Id: Scalars['String']['output'];
  IntegrationClusterData?: Maybe<IntegrationClusterData>;
  IntegrationClusterId: Scalars['String']['output'];
  IntegrationClusterName: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type ClusterRequest = {
  Id: Scalars['String']['input'];
};

export type Container = {
  __typename?: 'Container';
  ContainerResource?: Maybe<ContainerResource>;
  EnvironmentVariableId?: Maybe<Scalars['String']['output']>;
  Ports: Array<Maybe<Scalars['Int']['output']>>;
  RegistryImageId: Scalars['String']['output'];
  Tag: Scalars['String']['output'];
  Volumes?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContainerResource = {
  __typename?: 'ContainerResource';
  LimitsCPU?: Maybe<Scalars['Int']['output']>;
  LimitsMemory?: Maybe<Scalars['Int']['output']>;
  RequestsCPU?: Maybe<Scalars['Int']['output']>;
  RequestsMemory?: Maybe<Scalars['Int']['output']>;
};

export type ContainerResourceRequest = {
  LimitsCPU?: InputMaybe<Scalars['Int']['input']>;
  LimitsMemory?: InputMaybe<Scalars['Int']['input']>;
  RequestsCPU?: InputMaybe<Scalars['Int']['input']>;
  RequestsMemory?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateClusterGoogleClusterDataRequest = {
  ProjectId: Scalars['String']['input'];
};

export type CreateClusterRequest = {
  GoogleClusterData?: InputMaybe<CreateClusterGoogleClusterDataRequest>;
  IntegrationClusterId: Scalars['String']['input'];
  IntegrationId: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};

export type CreateContainerRequest = {
  EnvironmentVariableId?: InputMaybe<Scalars['String']['input']>;
  Ports: Array<Scalars['Int']['input']>;
  RegistryImageId: Scalars['String']['input'];
  Resource?: InputMaybe<ContainerResourceRequest>;
  Tag: Scalars['String']['input'];
  Volumes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateDockerBuildFlowStep = {
  DockerFileContent: Scalars['String']['input'];
};

export type CreateDockerIoRegistryIntegrationRequest = {
  Name: Scalars['String']['input'];
  Password: Scalars['String']['input'];
  Username: Scalars['String']['input'];
};

export type CreateDockerPushFlowStep = {
  GeneratedTagType: Scalars['String']['input'];
  Prefix: Scalars['String']['input'];
  RegistryImageId: Scalars['String']['input'];
  Suffix: Scalars['String']['input'];
};

export type CreateDomainRequest = {
  Domains: Array<Scalars['String']['input']>;
  IssuerEmail: Scalars['String']['input'];
  Name: Scalars['String']['input'];
};

export type CreateEnvironmentVariableItem = {
  Key: Scalars['String']['input'];
  Value: Scalars['String']['input'];
};

export type CreateEnvironmentVariableRequest = {
  Name: Scalars['String']['input'];
  Variables: Array<CreateEnvironmentVariableItem>;
};

export type CreateFlowOperationHookStep = {
  FlowId: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};

export type CreateFlowRequest = {
  Name: Scalars['String']['input'];
  Resource: ContainerResourceRequest;
  Steps: Array<CreateFlowStep>;
};

export type CreateFlowShareableStatusResponse = {
  __typename?: 'CreateFlowShareableStatusResponse';
  Link: Scalars['String']['output'];
};

export type CreateFlowStep = {
  DockerBuild?: InputMaybe<CreateDockerBuildFlowStep>;
  DockerPush?: InputMaybe<CreateDockerPushFlowStep>;
  GitChangeBranch?: InputMaybe<CreateGitChangeBranchFlowStep>;
  GitPull?: InputMaybe<CreateGitPullFlowStep>;
  Type: Scalars['String']['input'];
};

export type CreateGitChangeBranchFlowStep = {
  BranchName: Scalars['String']['input'];
};

export type CreateGitPullFlowStep = {
  IntegrationId: Scalars['String']['input'];
  RepositoryName: Scalars['String']['input'];
  Type: Scalars['String']['input'];
  Url: Scalars['String']['input'];
};

export type CreateGitPullRequestCommentHookStep = {
  Message: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};

export type CreateGitReleaseHookStep = {
  FlowId: Scalars['String']['input'];
  GenerateReleaseNotes: Scalars['Boolean']['input'];
  IncludePRInformation: Scalars['Boolean']['input'];
  IsDraft: Scalars['Boolean']['input'];
  Message: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};

export type CreateGitWebHookHookStep = {
  Branches: Array<Scalars['String']['input']>;
  Events: Array<Scalars['String']['input']>;
  IntegrationId: Scalars['String']['input'];
  RepositoryName: Scalars['String']['input'];
  Type: Scalars['String']['input'];
  Url: Scalars['String']['input'];
};

export type CreateHookRequest = {
  Name: Scalars['String']['input'];
  Steps: Array<CreateHookStep>;
};

export type CreateHookStep = {
  Dependents: Array<Scalars['String']['input']>;
  FlowOperation?: InputMaybe<CreateFlowOperationHookStep>;
  GitPullRequestComment?: InputMaybe<CreateGitPullRequestCommentHookStep>;
  GitRelease?: InputMaybe<CreateGitReleaseHookStep>;
  GitWebHook?: InputMaybe<CreateGitWebHookHookStep>;
  Mail?: InputMaybe<CreateMailHookStep>;
  NodeId: Scalars['String']['input'];
  OperationResult?: InputMaybe<CreateOperationResultHookStep>;
  QuickContainerOperation?: InputMaybe<CreateQuickContainerOperationHookStep>;
  References: Array<Scalars['String']['input']>;
  Type: Scalars['String']['input'];
};

export type CreateMailHookStep = {
  Body: Scalars['String']['input'];
  MailId: Scalars['String']['input'];
  Subject: Scalars['String']['input'];
  To: Array<Scalars['String']['input']>;
};

export type CreateMailIntegrationRequest = {
  Email: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Password: Scalars['String']['input'];
  SmtpPort: Scalars['Int']['input'];
  SmtpServer: Scalars['String']['input'];
};

export type CreateOperationResultHookStep = {
  Condition: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};

export type CreateQuickContainerOperationHookStep = {
  FlowId: Scalars['String']['input'];
  QuickContainerId: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};

export type CreateQuickContainerRequest = {
  ClusterId?: InputMaybe<Scalars['String']['input']>;
  Containers: Array<CreateContainerRequest>;
  DomainId?: InputMaybe<Scalars['String']['input']>;
  Instances?: InputMaybe<Scalars['Int']['input']>;
  Name: Scalars['String']['input'];
  OnlyCreate?: InputMaybe<Scalars['Boolean']['input']>;
  Size?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateRegistryImageRequest = {
  Image: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  RegistryId: Scalars['String']['input'];
};

export type CreateRegistryRequest = {
  DockerIOId?: InputMaybe<Scalars['String']['input']>;
  Name: Scalars['String']['input'];
  Namespace?: InputMaybe<Scalars['String']['input']>;
  Type: Scalars['String']['input'];
};

export type CreateRepositoryRequest = {
  Name: Scalars['String']['input'];
};

export type CreateUserRequest = {
  Email: Scalars['String']['input'];
  FirstName: Scalars['String']['input'];
  LastName: Scalars['String']['input'];
  Roles: Array<Scalars['String']['input']>;
};

export type DeleteFlowRequest = {
  Id: Scalars['String']['input'];
};

export type DeleteHookRequest = {
  Id: Scalars['String']['input'];
};

export type DigitalOceanIntegration = {
  __typename?: 'DigitalOceanIntegration';
  Expiry: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Scopes: Array<Scalars['String']['output']>;
};

export type DigitalOceanIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type DockerBuildFlowStep = {
  __typename?: 'DockerBuildFlowStep';
  DockerFileContent: Scalars['String']['output'];
  Id: Scalars['String']['output'];
};

export type DockerIoRegistryIntegration = {
  __typename?: 'DockerIORegistryIntegration';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Password: Scalars['String']['output'];
  Username: Scalars['String']['output'];
};

export type DockerIoRegistryIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type DockerPushFlowStep = {
  __typename?: 'DockerPushFlowStep';
  GeneratedTagType: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Prefix: Scalars['String']['output'];
  RegistryImage: RegistryImage;
  Suffix: Scalars['String']['output'];
};

export type Domain = {
  __typename?: 'Domain';
  Domains: Array<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  IssuerEmail: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type DomainRequest = {
  Id: Scalars['String']['input'];
};

export type EnvironmentVariable = {
  __typename?: 'EnvironmentVariable';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Variables: Array<EnvironmentVariableItem>;
};

export type EnvironmentVariableItem = {
  __typename?: 'EnvironmentVariableItem';
  Key: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

export type EnvironmentVariableRequest = {
  Id: Scalars['String']['input'];
};

export type Flow = {
  __typename?: 'Flow';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Resource: ContainerResource;
};

export type FlowOperationHookStep = {
  __typename?: 'FlowOperationHookStep';
  Flow: Flow;
  Id: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type FlowRequest = {
  Id: Scalars['String']['input'];
};

export type FlowRun = {
  __typename?: 'FlowRun';
  Id: Scalars['String']['output'];
  Logs: Scalars['String']['output'];
  PublishedImages: Array<Scalars['String']['output']>;
  Status: Scalars['String']['output'];
};

export type FlowRunStatus = {
  __typename?: 'FlowRunStatus';
  Id: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

export type FlowStep = {
  __typename?: 'FlowStep';
  DockerBuild?: Maybe<DockerBuildFlowStep>;
  DockerPush?: Maybe<DockerPushFlowStep>;
  GitChangeBranch?: Maybe<GitChangeBranchFlowStep>;
  GitPull?: Maybe<GitPullFlowStep>;
  Id: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type GetOAuthUrlRequest = {
  Id?: InputMaybe<Scalars['String']['input']>;
  Name: Scalars['String']['input'];
  Scopes: Array<Scalars['String']['input']>;
  Type: Scalars['String']['input'];
};

export type GetOAuthUrlResponse = {
  __typename?: 'GetOAuthUrlResponse';
  Url: Scalars['String']['output'];
};

export type GitChangeBranchFlowStep = {
  __typename?: 'GitChangeBranchFlowStep';
  BranchName: Scalars['String']['output'];
  Id: Scalars['String']['output'];
};

export type GitHubIntegration = {
  __typename?: 'GitHubIntegration';
  Expiry: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Scopes: Array<Scalars['String']['output']>;
};

export type GitHubIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type GitHubRepository = {
  __typename?: 'GitHubRepository';
  Name: Scalars['String']['output'];
  Owner: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type GitHubRepositoryBranch = {
  __typename?: 'GitHubRepositoryBranch';
  Name: Scalars['String']['output'];
};

export type GitHubRepositoryPullRequest = {
  __typename?: 'GitHubRepositoryPullRequest';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type GitPullFlowStep = {
  __typename?: 'GitPullFlowStep';
  GitHubIntegration?: Maybe<GitHubIntegration>;
  Id: Scalars['String']['output'];
  RepositoryName: Scalars['String']['output'];
  Type: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type GitPullRequestCommentHookStep = {
  __typename?: 'GitPullRequestCommentHookStep';
  Id: Scalars['String']['output'];
  Message: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type GitReleaseHookStep = {
  __typename?: 'GitReleaseHookStep';
  Flow: Flow;
  GenerateReleaseNotes: Scalars['Boolean']['output'];
  Id: Scalars['String']['output'];
  IncludePRInformation: Scalars['Boolean']['output'];
  IsDraft: Scalars['Boolean']['output'];
  Message: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type GitWebHookHookStep = {
  __typename?: 'GitWebHookHookStep';
  Branches: Array<Scalars['String']['output']>;
  Events: Array<Scalars['String']['output']>;
  GitHubIntegration?: Maybe<GitHubIntegration>;
  Id: Scalars['String']['output'];
  RepositoryName: Scalars['String']['output'];
  Type: Scalars['String']['output'];
  Url: Scalars['String']['output'];
};

export type GoogleIntegration = {
  __typename?: 'GoogleIntegration';
  Expiry: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Scopes: Array<Scalars['String']['output']>;
};

export type GoogleIntegrationClusterData = {
  __typename?: 'GoogleIntegrationClusterData';
  ProjectId: Scalars['String']['output'];
};

export type GoogleIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type GraphNode = {
  __typename?: 'GraphNode';
  Dependents: Array<GraphNode>;
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  References: Array<GraphNode>;
  Type: Scalars['String']['output'];
};

export type HasPermissionsDigitalOceanIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsDigitalOceanIntegrationResponse = {
  __typename?: 'HasPermissionsDigitalOceanIntegrationResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsDockerIoRegistryIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsDockerIoRegistryIntegrationResponse = {
  __typename?: 'HasPermissionsDockerIORegistryIntegrationResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsGitHubIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsGitHubIntegrationResponse = {
  __typename?: 'HasPermissionsGitHubIntegrationResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsGoogleIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsGoogleIntegrationResponse = {
  __typename?: 'HasPermissionsGoogleIntegrationResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsMailIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsMailIntegrationResponse = {
  __typename?: 'HasPermissionsMailIntegrationResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnClusterRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnClusterResponse = {
  __typename?: 'HasPermissionsOnClusterResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnDomainRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnDomainResponse = {
  __typename?: 'HasPermissionsOnDomainResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnEnvironmentVariableRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnEnvironmentVariableResponse = {
  __typename?: 'HasPermissionsOnEnvironmentVariableResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnFlowRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnFlowResponse = {
  __typename?: 'HasPermissionsOnFlowResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnHookRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnHookResponse = {
  __typename?: 'HasPermissionsOnHookResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnQuickContainerRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnQuickContainerResponse = {
  __typename?: 'HasPermissionsOnQuickContainerResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnRegistryRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnRegistryResponse = {
  __typename?: 'HasPermissionsOnRegistryResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type HasPermissionsOnRepositoryRequest = {
  Id: Scalars['String']['input'];
};

export type HasPermissionsOnRepositoryResponse = {
  __typename?: 'HasPermissionsOnRepositoryResponse';
  HasPermission: Scalars['Boolean']['output'];
  RoleType: Scalars['String']['output'];
};

export type Hook = {
  __typename?: 'Hook';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type HookRun = {
  __typename?: 'HookRun';
  Id: Scalars['String']['output'];
  Status: Scalars['String']['output'];
  Steps: Array<HookRunStep>;
};

export type HookRunStep = {
  __typename?: 'HookRunStep';
  Id: Scalars['String']['output'];
  Message?: Maybe<Scalars['String']['output']>;
  Status: Scalars['String']['output'];
  Step: HookStep;
};

export type HookStep = {
  __typename?: 'HookStep';
  Dependents: Array<Scalars['String']['output']>;
  FlowOperation?: Maybe<FlowOperationHookStep>;
  GitPullRequestComment?: Maybe<GitPullRequestCommentHookStep>;
  GitRelease?: Maybe<GitReleaseHookStep>;
  GitWebHook?: Maybe<GitWebHookHookStep>;
  Id: Scalars['String']['output'];
  Mail?: Maybe<MailHookStep>;
  NodeId: Scalars['String']['output'];
  OperationResult?: Maybe<OperationResultHookStep>;
  QuickContainerOperation?: Maybe<QuickContainerOperationHookStep>;
  References: Array<Scalars['String']['output']>;
  Type: HookStepType;
};

export enum HookStepType {
  FlowOperation = 'FlowOperation',
  GitPullRequestComment = 'GitPullRequestComment',
  GitRelease = 'GitRelease',
  GitWebHook = 'GitWebHook',
  Mail = 'Mail',
  OperationResult = 'OperationResult',
  QuickContainerOperation = 'QuickContainerOperation',
  Starting = 'Starting'
}

export type IdAndNamePair = {
  __typename?: 'IdAndNamePair';
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type Integration = {
  __typename?: 'Integration';
  DigitalOceanIntegration: Array<DigitalOceanIntegration>;
  DockerIORegistryIntegration: Array<DockerIoRegistryIntegration>;
  GitHubIntegration: Array<GitHubIntegration>;
  GoogleIntegration: Array<GoogleIntegration>;
  MailIntegration: Array<MailIntegration>;
};

export type IntegrationClusterData = {
  __typename?: 'IntegrationClusterData';
  Google?: Maybe<GoogleIntegrationClusterData>;
};

export type ListenFlowRunStatusRequest = {
  FlowId: Scalars['String']['input'];
};

export type ListenQuickContainerMetricsRequest = {
  Id: Scalars['String']['input'];
  InstanceNames: Array<Scalars['String']['input']>;
};

export type ListenQuickContainerStatusRequest = {
  Ids: Array<Scalars['String']['input']>;
};

export type ListenRegistryStatusRequest = {
  Ids: Array<Scalars['String']['input']>;
};

export type ListenRepositoryStatusRequest = {
  Ids: Array<Scalars['String']['input']>;
};

export type MailHookStep = {
  __typename?: 'MailHookStep';
  Body: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  MailIntegration: MailIntegration;
  Subject: Scalars['String']['output'];
  To: Array<Scalars['String']['output']>;
};

export type MailIntegration = {
  __typename?: 'MailIntegration';
  Email: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Password: Scalars['String']['output'];
  SmtpPort: Scalars['Int']['output'];
  SmtpServer: Scalars['String']['output'];
};

export type MailIntegrationRequest = {
  Id: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AssignUserRole: User;
  CloneRepository: Repository;
  CompleteAccountSetup: User;
  CreateCluster: Cluster;
  CreateDockerIORegistryIntegration: DockerIoRegistryIntegration;
  CreateDomain: Domain;
  CreateEnvironmentVariable: EnvironmentVariable;
  CreateFlow: Flow;
  CreateFlowShareableStatus: CreateFlowShareableStatusResponse;
  CreateHook: Hook;
  CreateMailIntegration: MailIntegration;
  CreateQuickContainer: QuickContainer;
  CreateRegistry: Registry;
  CreateRegistryImage: RegistryImage;
  CreateRepository: Repository;
  CreateUser: User;
  DeleteCluster: Cluster;
  DeleteDigitalOceanIntegration: DigitalOceanIntegration;
  DeleteDockerIORegistryIntegration: DockerIoRegistryIntegration;
  DeleteDomain: Domain;
  DeleteEnvironmentVariable: EnvironmentVariable;
  DeleteFlow: Flow;
  DeleteGitHubIntegration: GitHubIntegration;
  DeleteGoogleIntegration: GoogleIntegration;
  DeleteHook: Hook;
  DeleteMailIntegration: MailIntegration;
  DeleteQuickContainer: QuickContainer;
  DeleteRegistry: Registry;
  DeleteRegistryImage: RegistryImage;
  DeleteRepository: Repository;
  DeleteUser: User;
  GetOAuthUrl: GetOAuthUrlResponse;
  HasPermissionsDigitalOceanIntegration: HasPermissionsDigitalOceanIntegrationResponse;
  HasPermissionsDockerIORegistryIntegration: HasPermissionsDockerIoRegistryIntegrationResponse;
  HasPermissionsGitHubIntegration: HasPermissionsGitHubIntegrationResponse;
  HasPermissionsGoogleIntegration: HasPermissionsGoogleIntegrationResponse;
  HasPermissionsMailIntegration: HasPermissionsMailIntegrationResponse;
  HasPermissionsOnCluster: HasPermissionsOnClusterResponse;
  HasPermissionsOnDomain: HasPermissionsOnDomainResponse;
  HasPermissionsOnEnvironmentVariable: HasPermissionsOnEnvironmentVariableResponse;
  HasPermissionsOnFlow: HasPermissionsOnFlowResponse;
  HasPermissionsOnHook: HasPermissionsOnHookResponse;
  HasPermissionsOnQuickContainer: HasPermissionsOnQuickContainerResponse;
  HasPermissionsOnRegistry: HasPermissionsOnRegistryResponse;
  HasPermissionsOnRepository: HasPermissionsOnRepositoryResponse;
  RestartQuickContainer: QuickContainer;
  RunFlow: FlowRun;
  RunHook: HookRun;
  StartQuickContainer: QuickContainer;
  StartRepository: Repository;
  StopQuickContainer: QuickContainer;
  StopRepository: Repository;
  UpdateCluster: Cluster;
  UpdateDockerIORegistryIntegration: DockerIoRegistryIntegration;
  UpdateDomain: Domain;
  UpdateEnvironmentVariable: EnvironmentVariable;
  UpdateFlow: Flow;
  UpdateHook: Hook;
  UpdateMailIntegration: MailIntegration;
  UpdatePermissions: Array<Permission>;
  UpdatePhoto: User;
  UpdateQuickContainer: QuickContainer;
  UpdateQuickContainerIncomingConnections: Array<QuickContainerIncomingConnection>;
  UpdateRegistry: Registry;
  UpdateRegistryImage: RegistryImage;
  UpdateTier: Tier;
};


export type MutationAssignUserRoleArgs = {
  request: AssignUserRoleRequest;
};


export type MutationCloneRepositoryArgs = {
  request: RepositoryRequest;
};


export type MutationCreateClusterArgs = {
  request: CreateClusterRequest;
};


export type MutationCreateDockerIoRegistryIntegrationArgs = {
  request: CreateDockerIoRegistryIntegrationRequest;
};


export type MutationCreateDomainArgs = {
  request: CreateDomainRequest;
};


export type MutationCreateEnvironmentVariableArgs = {
  request: CreateEnvironmentVariableRequest;
};


export type MutationCreateFlowArgs = {
  request: CreateFlowRequest;
};


export type MutationCreateFlowShareableStatusArgs = {
  request: FlowRequest;
};


export type MutationCreateHookArgs = {
  request: CreateHookRequest;
};


export type MutationCreateMailIntegrationArgs = {
  request: CreateMailIntegrationRequest;
};


export type MutationCreateQuickContainerArgs = {
  request: CreateQuickContainerRequest;
};


export type MutationCreateRegistryArgs = {
  request: CreateRegistryRequest;
};


export type MutationCreateRegistryImageArgs = {
  request: CreateRegistryImageRequest;
};


export type MutationCreateRepositoryArgs = {
  request: CreateRepositoryRequest;
};


export type MutationCreateUserArgs = {
  request: CreateUserRequest;
};


export type MutationDeleteClusterArgs = {
  request: ClusterRequest;
};


export type MutationDeleteDigitalOceanIntegrationArgs = {
  request: DigitalOceanIntegrationRequest;
};


export type MutationDeleteDockerIoRegistryIntegrationArgs = {
  request: DockerIoRegistryIntegrationRequest;
};


export type MutationDeleteDomainArgs = {
  request: DomainRequest;
};


export type MutationDeleteEnvironmentVariableArgs = {
  request: EnvironmentVariableRequest;
};


export type MutationDeleteFlowArgs = {
  request: DeleteFlowRequest;
};


export type MutationDeleteGitHubIntegrationArgs = {
  request: GitHubIntegrationRequest;
};


export type MutationDeleteGoogleIntegrationArgs = {
  request: GoogleIntegrationRequest;
};


export type MutationDeleteHookArgs = {
  request: DeleteHookRequest;
};


export type MutationDeleteMailIntegrationArgs = {
  request: MailIntegrationRequest;
};


export type MutationDeleteQuickContainerArgs = {
  request: QuickContainerRequest;
};


export type MutationDeleteRegistryArgs = {
  request: RegistryRequest;
};


export type MutationDeleteRegistryImageArgs = {
  request: RegistryImageRequest;
};


export type MutationDeleteRepositoryArgs = {
  request: RepositoryRequest;
};


export type MutationDeleteUserArgs = {
  request: UserRequest;
};


export type MutationGetOAuthUrlArgs = {
  request: GetOAuthUrlRequest;
};


export type MutationHasPermissionsDigitalOceanIntegrationArgs = {
  request: HasPermissionsDigitalOceanIntegrationRequest;
};


export type MutationHasPermissionsDockerIoRegistryIntegrationArgs = {
  request: HasPermissionsDockerIoRegistryIntegrationRequest;
};


export type MutationHasPermissionsGitHubIntegrationArgs = {
  request: HasPermissionsGitHubIntegrationRequest;
};


export type MutationHasPermissionsGoogleIntegrationArgs = {
  request: HasPermissionsGoogleIntegrationRequest;
};


export type MutationHasPermissionsMailIntegrationArgs = {
  request: HasPermissionsMailIntegrationRequest;
};


export type MutationHasPermissionsOnClusterArgs = {
  request: HasPermissionsOnClusterRequest;
};


export type MutationHasPermissionsOnDomainArgs = {
  request: HasPermissionsOnDomainRequest;
};


export type MutationHasPermissionsOnEnvironmentVariableArgs = {
  request: HasPermissionsOnEnvironmentVariableRequest;
};


export type MutationHasPermissionsOnFlowArgs = {
  request: HasPermissionsOnFlowRequest;
};


export type MutationHasPermissionsOnHookArgs = {
  request: HasPermissionsOnHookRequest;
};


export type MutationHasPermissionsOnQuickContainerArgs = {
  request: HasPermissionsOnQuickContainerRequest;
};


export type MutationHasPermissionsOnRegistryArgs = {
  request: HasPermissionsOnRegistryRequest;
};


export type MutationHasPermissionsOnRepositoryArgs = {
  request: HasPermissionsOnRepositoryRequest;
};


export type MutationRestartQuickContainerArgs = {
  request: QuickContainerRequest;
};


export type MutationRunFlowArgs = {
  request: FlowRequest;
};


export type MutationRunHookArgs = {
  request: RunHookRequest;
};


export type MutationStartQuickContainerArgs = {
  request: QuickContainerRequest;
};


export type MutationStartRepositoryArgs = {
  request: RepositoryRequest;
};


export type MutationStopQuickContainerArgs = {
  request: QuickContainerRequest;
};


export type MutationStopRepositoryArgs = {
  request: RepositoryRequest;
};


export type MutationUpdateClusterArgs = {
  request: UpdateClusterRequest;
};


export type MutationUpdateDockerIoRegistryIntegrationArgs = {
  request: UpdateDockerIoRegistryIntegrationRequest;
};


export type MutationUpdateDomainArgs = {
  request: UpdateDomainRequest;
};


export type MutationUpdateEnvironmentVariableArgs = {
  request: UpdateEnvironmentVariableRequest;
};


export type MutationUpdateFlowArgs = {
  request: UpdateFlowRequest;
};


export type MutationUpdateHookArgs = {
  request: UpdateHookRequest;
};


export type MutationUpdateMailIntegrationArgs = {
  request: UpdateMailIntegrationRequest;
};


export type MutationUpdatePermissionsArgs = {
  id: Scalars['String']['input'];
  permissions: Array<UpdatePermission>;
  type: Scalars['String']['input'];
};


export type MutationUpdatePhotoArgs = {
  request: UpdatePhotoRequest;
};


export type MutationUpdateQuickContainerArgs = {
  request: UpdateQuickContainerRequest;
};


export type MutationUpdateQuickContainerIncomingConnectionsArgs = {
  request: UpdateQuickContainerIncomingConnectionsRequest;
};


export type MutationUpdateRegistryArgs = {
  request: UpdateRegistryRequest;
};


export type MutationUpdateRegistryImageArgs = {
  request: UpdateRegistryImageRequest;
};


export type MutationUpdateTierArgs = {
  tierType: Scalars['String']['input'];
};

export type OperationResultHookStep = {
  __typename?: 'OperationResultHookStep';
  Condition: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  Type: Scalars['String']['output'];
  User?: Maybe<BasicUserInfo>;
};

export type Query = {
  __typename?: 'Query';
  AIChat: Scalars['String']['output'];
  AllRoles: Array<Scalars['String']['output']>;
  Cluster: Array<Cluster>;
  DigitalOceanIntegration: Array<DigitalOceanIntegration>;
  DigitalOceanKubernetesClusters: Array<IdAndNamePair>;
  DockerIORegistryIntegration: Array<DockerIoRegistryIntegration>;
  DockerRegistryImage: Array<Scalars['String']['output']>;
  DockerRegistryImageTag: Array<Scalars['String']['output']>;
  Domain: Array<Domain>;
  EnvironmentVariable: Array<EnvironmentVariable>;
  Flow: Array<Flow>;
  FlowRuns: Array<FlowRun>;
  FlowSteps: Array<FlowStep>;
  GitHubIntegration: Array<GitHubIntegration>;
  GitHubRepository: Array<GitHubRepository>;
  GitHubRepositoryBranch: Array<GitHubRepositoryBranch>;
  GitHubRepositoryPullRequest: Array<GitHubRepositoryPullRequest>;
  GoogleIntegration: Array<GoogleIntegration>;
  GoogleKubernetesClusters: Array<IdAndNamePair>;
  GoogleProjects: Array<IdAndNamePair>;
  GraphNode: Array<GraphNode>;
  GraphNodeDependents: Array<GraphNode>;
  GraphNodeReferences: Array<GraphNode>;
  Hook: Array<Hook>;
  HookRuns: Array<HookRun>;
  HookSteps: Array<HookStep>;
  Integration: Integration;
  MailIntegration: Array<MailIntegration>;
  MyUser: User;
  Permissions: Array<Permission>;
  QuickContainer: Array<QuickContainer>;
  QuickContainerIncomingConnections: Array<QuickContainerIncomingConnection>;
  QuickContainerInstances: Array<Scalars['String']['output']>;
  QuickContainerLogs: Array<QuickContainerLogs>;
  QuickContainerMetrics: Array<QuickContainerMetrics>;
  Registry: Array<Registry>;
  RegistryImage: Array<RegistryImage>;
  Repository: Array<Repository>;
  Search: SearchResponse;
  SearchUsers: Array<BasicUserInfo>;
  Tier: Tier;
  Usage: Usage;
  User: Array<User>;
};


export type QueryAiChatArgs = {
  prompt: Scalars['String']['input'];
  systemPrompt: Scalars['String']['input'];
};


export type QueryClusterArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDigitalOceanIntegrationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDigitalOceanKubernetesClustersArgs = {
  id: Scalars['String']['input'];
};


export type QueryDockerIoRegistryIntegrationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDockerRegistryImageArgs = {
  id: Scalars['String']['input'];
};


export type QueryDockerRegistryImageTagArgs = {
  id: Scalars['String']['input'];
  image: Scalars['String']['input'];
};


export type QueryDomainArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEnvironmentVariableArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFlowArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFlowRunsArgs = {
  flowId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFlowStepsArgs = {
  flowId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGitHubIntegrationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGitHubRepositoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryGitHubRepositoryBranchArgs = {
  id: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type QueryGitHubRepositoryPullRequestArgs = {
  id: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type QueryGoogleIntegrationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGoogleKubernetesClustersArgs = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type QueryGoogleProjectsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGraphNodeArgs = {
  id: Scalars['String']['input'];
  nodeType: Scalars['String']['input'];
};


export type QueryGraphNodeDependentsArgs = {
  id: Scalars['String']['input'];
  nodeType: Scalars['String']['input'];
};


export type QueryGraphNodeReferencesArgs = {
  id: Scalars['String']['input'];
  nodeType: Scalars['String']['input'];
};


export type QueryHookArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHookRunsArgs = {
  hookId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHookStepsArgs = {
  hookId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMailIntegrationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPermissionsArgs = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryQuickContainerArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryQuickContainerIncomingConnectionsArgs = {
  id: Scalars['String']['input'];
};


export type QueryQuickContainerInstancesArgs = {
  id: Scalars['String']['input'];
};


export type QueryQuickContainerLogsArgs = {
  id: Scalars['String']['input'];
  instanceName: Scalars['String']['input'];
};


export type QueryQuickContainerMetricsArgs = {
  id: Scalars['String']['input'];
  instanceNames: Array<Scalars['String']['input']>;
};


export type QueryRegistryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRegistryImageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRepositoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchArgs = {
  search: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QuerySearchUsersArgs = {
  search: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type QuickContainer = {
  __typename?: 'QuickContainer';
  Cluster?: Maybe<Cluster>;
  Containers: Array<Container>;
  Domain?: Maybe<Domain>;
  Id: Scalars['String']['output'];
  Instances?: Maybe<Scalars['Int']['output']>;
  Name: Scalars['String']['output'];
  Size?: Maybe<Scalars['Int']['output']>;
  Status: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type QuickContainerIncomingConnection = {
  __typename?: 'QuickContainerIncomingConnection';
  EnvironmentVariable?: Maybe<Scalars['String']['output']>;
  QuickContainer: QuickContainer;
};

export type QuickContainerLogs = {
  __typename?: 'QuickContainerLogs';
  Logs: Scalars['String']['output'];
  Name: Scalars['String']['output'];
};

export type QuickContainerMetrics = {
  __typename?: 'QuickContainerMetrics';
  CPU: Scalars['Int']['output'];
  InstanceName: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  RAM: Scalars['Int']['output'];
  ReasonForRestart?: Maybe<Scalars['String']['output']>;
  ReasonForWaiting?: Maybe<Scalars['String']['output']>;
  Restarts: Scalars['Int']['output'];
  Time: Scalars['Int']['output'];
};

export type QuickContainerOperationHookStep = {
  __typename?: 'QuickContainerOperationHookStep';
  Flow: Flow;
  Id: Scalars['String']['output'];
  QuickContainer: QuickContainer;
  Type: Scalars['String']['output'];
};

export type QuickContainerRequest = {
  Id: Scalars['String']['input'];
};

export type QuickContainerStatus = {
  __typename?: 'QuickContainerStatus';
  Id: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

export type Registry = {
  __typename?: 'Registry';
  DockerIO?: Maybe<DockerIoRegistryIntegration>;
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Namespace?: Maybe<Scalars['String']['output']>;
  Status: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};

export type RegistryImage = {
  __typename?: 'RegistryImage';
  Id: Scalars['String']['output'];
  Image: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Registry?: Maybe<Registry>;
};

export type RegistryImageRequest = {
  Id: Scalars['String']['input'];
};

export type RegistryRequest = {
  Id: Scalars['String']['input'];
};

export type RegistryStatus = {
  __typename?: 'RegistryStatus';
  Id: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

export type Repository = {
  __typename?: 'Repository';
  Container?: Maybe<Container>;
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

export type RepositoryRequest = {
  Id: Scalars['String']['input'];
};

export type RepositoryStatus = {
  __typename?: 'RepositoryStatus';
  Id: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

export type RunHookRequest = {
  Id: Scalars['String']['input'];
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  Items: Array<IdAndNamePair>;
};

export type Subscription = {
  __typename?: 'Subscription';
  ListenFlowRunStatus: FlowRunStatus;
  ListenQuickContainerMetrics: QuickContainerMetrics;
  ListenQuickContainerStatus: QuickContainerStatus;
  ListenRegistryStatus: RegistryStatus;
  ListenRepositoryStatus: RepositoryStatus;
};


export type SubscriptionListenFlowRunStatusArgs = {
  request: ListenFlowRunStatusRequest;
};


export type SubscriptionListenQuickContainerMetricsArgs = {
  request: ListenQuickContainerMetricsRequest;
};


export type SubscriptionListenQuickContainerStatusArgs = {
  request: ListenQuickContainerStatusRequest;
};


export type SubscriptionListenRegistryStatusArgs = {
  request: ListenRegistryStatusRequest;
};


export type SubscriptionListenRepositoryStatusArgs = {
  request: ListenRepositoryStatusRequest;
};

export type Tier = {
  __typename?: 'Tier';
  CPU: Scalars['Int']['output'];
  Clusters: Scalars['Int']['output'];
  CreatedAt: Scalars['String']['output'];
  Domains: Scalars['Int']['output'];
  EnvironmentVariables: Scalars['Int']['output'];
  Flows: Scalars['Int']['output'];
  Hooks: Scalars['Int']['output'];
  Id: Scalars['String']['output'];
  QuickContainers: Scalars['Int']['output'];
  RAM: Scalars['Int']['output'];
  Storage: Scalars['Int']['output'];
  TierType: Scalars['String']['output'];
  UpdatedAt: Scalars['String']['output'];
};

export type UpdateClusterRequest = {
  Id: Scalars['String']['input'];
  Name: Scalars['String']['input'];
};

export type UpdateDockerIoRegistryIntegrationRequest = {
  Id: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Password: Scalars['String']['input'];
  Username: Scalars['String']['input'];
};

export type UpdateDomainRequest = {
  Domains: Array<Scalars['String']['input']>;
  Id: Scalars['String']['input'];
  IssuerEmail: Scalars['String']['input'];
  Name: Scalars['String']['input'];
};

export type UpdateEnvironmentVariableRequest = {
  Id: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Variables: Array<CreateEnvironmentVariableItem>;
};

export type UpdateFlowRequest = {
  Id: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Resource: ContainerResourceRequest;
  Steps: Array<CreateFlowStep>;
};

export type UpdateHookRequest = {
  Id: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Steps: Array<CreateHookStep>;
};

export type UpdateMailIntegrationRequest = {
  Email: Scalars['String']['input'];
  Id: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Password: Scalars['String']['input'];
  SmtpPort: Scalars['Int']['input'];
  SmtpServer: Scalars['String']['input'];
};

export type UpdatePermission = {
  Type: Scalars['String']['input'];
  UserId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhotoRequest = {
  Image: Scalars['String']['input'];
};

export type UpdateQuickContainerIncomingConnection = {
  EnvironmentVariable?: InputMaybe<Scalars['String']['input']>;
  QuickContainerId: Scalars['String']['input'];
};

export type UpdateQuickContainerIncomingConnectionsRequest = {
  Connections: Array<UpdateQuickContainerIncomingConnection>;
  Id: Scalars['String']['input'];
};

export type UpdateQuickContainerRequest = {
  ClusterId?: InputMaybe<Scalars['String']['input']>;
  Containers: Array<CreateContainerRequest>;
  DomainId?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['String']['input'];
  Instances?: InputMaybe<Scalars['Int']['input']>;
  Name: Scalars['String']['input'];
  Size?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRegistryImageRequest = {
  Id: Scalars['String']['input'];
  Image: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  RegistryId: Scalars['String']['input'];
};

export type UpdateRegistryRequest = {
  DockerIOId?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Namespace?: InputMaybe<Scalars['String']['input']>;
  Type: Scalars['String']['input'];
};

export type Usage = {
  __typename?: 'Usage';
  CPU: Scalars['Int']['output'];
  Clusters: Scalars['Int']['output'];
  Domains: Scalars['Int']['output'];
  EnvironmentVariables: Scalars['Int']['output'];
  Flows: Scalars['Int']['output'];
  Hooks: Scalars['Int']['output'];
  Id: Scalars['String']['output'];
  QuickContainers: Scalars['Int']['output'];
  RAM: Scalars['Int']['output'];
  Storage: Scalars['Int']['output'];
};

export type User = {
  __typename?: 'User';
  AccountSetupComplete: Scalars['Boolean']['output'];
  Email: Scalars['String']['output'];
  FirstName: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  Image?: Maybe<Scalars['String']['output']>;
  LastName: Scalars['String']['output'];
  Roles: Array<Scalars['String']['output']>;
  Tier: Tier;
  Usage: Usage;
};

export type UserRequest = {
  Id: Scalars['String']['input'];
};

export type GetPermissionsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type GetPermissionsQuery = { __typename?: 'Query', Permissions: Array<{ __typename?: 'Permission', Type: string, User?: { __typename?: 'BasicUserInfo', Id: string, FirstName: string, LastName: string, Image: string } | null }> };

export type UpdatePermissionsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
  permissions: Array<UpdatePermission> | UpdatePermission;
}>;


export type UpdatePermissionsMutation = { __typename?: 'Mutation', UpdatePermissions: Array<{ __typename?: 'Permission', Type: string, User?: { __typename?: 'BasicUserInfo', Id: string, FirstName: string, LastName: string } | null }> };

export type SearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type SearchQuery = { __typename?: 'Query', Search: { __typename?: 'SearchResponse', Items: Array<{ __typename?: 'IdAndNamePair', Id: string, Name: string }> } };

export type CompleteAccountSetupMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteAccountSetupMutation = { __typename?: 'Mutation', CompleteAccountSetup: { __typename?: 'User', Id: string } };

export type GetMyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUserQuery = { __typename?: 'Query', MyUser: { __typename?: 'User', Id: string, FirstName: string, LastName: string, Roles: Array<string>, Image?: string | null, AccountSetupComplete: boolean, Tier: { __typename?: 'Tier', TierType: string, CreatedAt: string, UpdatedAt: string } } };

export type UpdatePhotoMutationVariables = Exact<{
  image: Scalars['String']['input'];
}>;


export type UpdatePhotoMutation = { __typename?: 'Mutation', UpdatePhoto: { __typename?: 'User', Id: string, FirstName: string, LastName: string, Roles: Array<string> } };

export type GetTierQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTierQuery = { __typename?: 'Query', Tier: { __typename?: 'Tier', Id: string, TierType: string, CPU: number, RAM: number, Storage: number, Flows: number, Hooks: number, Domains: number, EnvironmentVariables: number, QuickContainers: number, Clusters: number } };

export type GetUsageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsageQuery = { __typename?: 'Query', Usage: { __typename?: 'Usage', Id: string, CPU: number, RAM: number, Storage: number, Flows: number, Hooks: number, Domains: number, EnvironmentVariables: number, QuickContainers: number, Clusters: number } };

export type UpdateTierMutationVariables = Exact<{
  tierType: Scalars['String']['input'];
}>;


export type UpdateTierMutation = { __typename?: 'Mutation', UpdateTier: { __typename?: 'Tier', Id: string, TierType: string, CPU: number, RAM: number, Storage: number, Flows: number, Hooks: number, Domains: number, EnvironmentVariables: number, QuickContainers: number, Clusters: number } };

export type AssignUserRoleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AssignUserRoleMutation = { __typename?: 'Mutation', AssignUserRole: { __typename?: 'User', Id: string, FirstName: string, LastName: string, Roles: Array<string> } };

export type CreateUserMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', CreateUser: { __typename?: 'User', Id: string, FirstName: string, LastName: string, Roles: Array<string> } };

export type DeleteUserMutationVariables = Exact<{
  Id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', DeleteUser: { __typename?: 'User', Id: string } };

export type GetAllRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRolesQuery = { __typename?: 'Query', AllRoles: Array<string> };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', User: Array<{ __typename?: 'User', Id: string, FirstName: string, LastName: string, Email: string, Roles: Array<string> }> };

export type SearchUsersQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchUsersQuery = { __typename?: 'Query', SearchUsers: Array<{ __typename?: 'BasicUserInfo', Id: string, FirstName: string, LastName: string, Image: string }> };

export type AiChatQueryVariables = Exact<{
  prompt: Scalars['String']['input'];
  systemPrompt: Scalars['String']['input'];
}>;


export type AiChatQuery = { __typename?: 'Query', AIChat: string };

export type CreateFlowShareableStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type CreateFlowShareableStatusMutation = { __typename?: 'Mutation', CreateFlowShareableStatus: { __typename?: 'CreateFlowShareableStatusResponse', Link: string } };

export type CreateFlowMutationVariables = Exact<{
  name: Scalars['String']['input'];
  resource: ContainerResourceRequest;
  steps: Array<CreateFlowStep> | CreateFlowStep;
}>;


export type CreateFlowMutation = { __typename?: 'Mutation', CreateFlow: { __typename?: 'Flow', Id: string, Name: string, Resource: { __typename?: 'ContainerResource', LimitsMemory?: number | null, LimitsCPU?: number | null, RequestsMemory?: number | null, RequestsCPU?: number | null } } };

export type DeleteFlowMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteFlowMutation = { __typename?: 'Mutation', DeleteFlow: { __typename?: 'Flow', Id: string, Name: string } };

export type GetFlowRunsQueryVariables = Exact<{
  flowId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetFlowRunsQuery = { __typename?: 'Query', FlowRuns: Array<{ __typename?: 'FlowRun', Id: string, Status: string, Logs: string, PublishedImages: Array<string> }> };

export type GetFlowStepsQueryVariables = Exact<{
  flowId: Scalars['String']['input'];
}>;


export type GetFlowStepsQuery = { __typename?: 'Query', FlowSteps: Array<{ __typename?: 'FlowStep', Id: string, Type: string, GitPull?: { __typename?: 'GitPullFlowStep', Id: string, Type: string, RepositoryName: string, Url: string, GitHubIntegration?: { __typename?: 'GitHubIntegration', Id: string, Name: string } | null } | null, GitChangeBranch?: { __typename?: 'GitChangeBranchFlowStep', Id: string, BranchName: string } | null, DockerBuild?: { __typename?: 'DockerBuildFlowStep', Id: string, DockerFileContent: string } | null, DockerPush?: { __typename?: 'DockerPushFlowStep', Id: string, GeneratedTagType: string, Prefix: string, Suffix: string, RegistryImage: { __typename?: 'RegistryImage', Id: string, Name: string } } | null }> };

export type GetFlowsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFlowsQuery = { __typename?: 'Query', Flow: Array<{ __typename?: 'Flow', Id: string, Name: string, Resource: { __typename?: 'ContainerResource', LimitsMemory?: number | null, LimitsCPU?: number | null, RequestsMemory?: number | null, RequestsCPU?: number | null } }> };

export type GetFlowQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetFlowQuery = { __typename?: 'Query', Flow: Array<{ __typename?: 'Flow', Id: string, Name: string }> };

export type ListenFlowRunStatusSubscriptionVariables = Exact<{
  flowId: Scalars['String']['input'];
}>;


export type ListenFlowRunStatusSubscription = { __typename?: 'Subscription', ListenFlowRunStatus: { __typename?: 'FlowRunStatus', Id: string, Status: string } };

export type RunFlowMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RunFlowMutation = { __typename?: 'Mutation', RunFlow: { __typename?: 'FlowRun', Id: string, Status: string } };

export type UpdateFlowMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  steps: Array<CreateFlowStep> | CreateFlowStep;
  resource: ContainerResourceRequest;
}>;


export type UpdateFlowMutation = { __typename?: 'Mutation', UpdateFlow: { __typename?: 'Flow', Id: string, Name: string, Resource: { __typename?: 'ContainerResource', LimitsMemory?: number | null, LimitsCPU?: number | null, RequestsMemory?: number | null, RequestsCPU?: number | null } } };

export type CreateHookMutationVariables = Exact<{
  name: Scalars['String']['input'];
  steps: Array<CreateHookStep> | CreateHookStep;
}>;


export type CreateHookMutation = { __typename?: 'Mutation', CreateHook: { __typename?: 'Hook', Id: string, Name: string } };

export type DeleteHookMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteHookMutation = { __typename?: 'Mutation', DeleteHook: { __typename?: 'Hook', Id: string, Name: string } };

export type GetHookRunsQueryVariables = Exact<{
  hookId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetHookRunsQuery = { __typename?: 'Query', HookRuns: Array<{ __typename?: 'HookRun', Id: string, Status: string }> };

export type GetHookStepsQueryVariables = Exact<{
  hookId: Scalars['String']['input'];
}>;


export type GetHookStepsQuery = { __typename?: 'Query', HookSteps: Array<{ __typename?: 'HookStep', Id: string, Type: HookStepType, NodeId: string, Dependents: Array<string>, References: Array<string>, GitWebHook?: { __typename?: 'GitWebHookHookStep', Id: string, Type: string, RepositoryName: string, Url: string, Branches: Array<string>, Events: Array<string>, GitHubIntegration?: { __typename?: 'GitHubIntegration', Id: string, Name: string } | null } | null, FlowOperation?: { __typename?: 'FlowOperationHookStep', Id: string, Type: string, Flow: { __typename?: 'Flow', Id: string, Name: string } } | null, OperationResult?: { __typename?: 'OperationResultHookStep', Id: string, Type: string, Condition: string } | null, QuickContainerOperation?: { __typename?: 'QuickContainerOperationHookStep', Id: string, Type: string, QuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string }, Flow: { __typename?: 'Flow', Id: string, Name: string } } | null, GitPullRequestComment?: { __typename?: 'GitPullRequestCommentHookStep', Id: string, Type: string, Message: string } | null, GitRelease?: { __typename?: 'GitReleaseHookStep', Id: string, Type: string, Message: string, IsDraft: boolean, GenerateReleaseNotes: boolean, IncludePRInformation: boolean, Flow: { __typename?: 'Flow', Id: string, Name: string } } | null, Mail?: { __typename?: 'MailHookStep', Id: string, To: Array<string>, Subject: string, Body: string, MailIntegration: { __typename?: 'MailIntegration', Id: string, Name: string } } | null }> };

export type GetHooksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHooksQuery = { __typename?: 'Query', Hook: Array<{ __typename?: 'Hook', Id: string, Name: string }> };

export type GetHookRunQueryVariables = Exact<{
  hookId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type GetHookRunQuery = { __typename?: 'Query', HookRuns: Array<{ __typename?: 'HookRun', Id: string, Status: string, Steps: Array<{ __typename?: 'HookRunStep', Id: string, Status: string, Message?: string | null, Step: { __typename?: 'HookStep', Id: string, Type: HookStepType, NodeId: string, Dependents: Array<string>, References: Array<string>, GitWebHook?: { __typename?: 'GitWebHookHookStep', Id: string, RepositoryName: string, Url: string, Branches: Array<string>, Events: Array<string>, GitHubIntegration?: { __typename?: 'GitHubIntegration', Id: string, Name: string } | null } | null, FlowOperation?: { __typename?: 'FlowOperationHookStep', Id: string, Type: string, Flow: { __typename?: 'Flow', Id: string, Name: string } } | null, OperationResult?: { __typename?: 'OperationResultHookStep', Id: string, Type: string, Condition: string } | null, QuickContainerOperation?: { __typename?: 'QuickContainerOperationHookStep', Id: string, Type: string, QuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string }, Flow: { __typename?: 'Flow', Id: string, Name: string } } | null, GitPullRequestComment?: { __typename?: 'GitPullRequestCommentHookStep', Id: string, Type: string, Message: string } | null, GitRelease?: { __typename?: 'GitReleaseHookStep', Id: string, Type: string, Message: string, IsDraft: boolean, GenerateReleaseNotes: boolean, IncludePRInformation: boolean, Flow: { __typename?: 'Flow', Id: string, Name: string } } | null, Mail?: { __typename?: 'MailHookStep', Id: string, To: Array<string>, Subject: string, Body: string, MailIntegration: { __typename?: 'MailIntegration', Id: string, Name: string } } | null } }> }> };

export type GetHookQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetHookQuery = { __typename?: 'Query', Hook: Array<{ __typename?: 'Hook', Id: string, Name: string }> };

export type RunHookMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RunHookMutation = { __typename?: 'Mutation', RunHook: { __typename?: 'HookRun', Id: string, Status: string } };

export type UpdateHookMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  steps: Array<CreateHookStep> | CreateHookStep;
}>;


export type UpdateHookMutation = { __typename?: 'Mutation', UpdateHook: { __typename?: 'Hook', Id: string, Name: string } };

export type CreateDomainMutationVariables = Exact<{
  name: Scalars['String']['input'];
  issuerEmail: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type CreateDomainMutation = { __typename?: 'Mutation', CreateDomain: { __typename?: 'Domain', Id: string, Name: string, IssuerEmail: string, Domains: Array<string> } };

export type DeleteDomainMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteDomainMutation = { __typename?: 'Mutation', DeleteDomain: { __typename?: 'Domain', Id: string } };

export type GetDomainsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDomainsQuery = { __typename?: 'Query', Domain: Array<{ __typename?: 'Domain', Id: string, Name: string, IssuerEmail: string, Domains: Array<string> }> };

export type GetDomainQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetDomainQuery = { __typename?: 'Query', Domain: Array<{ __typename?: 'Domain', Id: string, Name: string, IssuerEmail: string, Domains: Array<string> }> };

export type UpdateDomainMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  issuerEmail: Scalars['String']['input'];
  domains: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type UpdateDomainMutation = { __typename?: 'Mutation', UpdateDomain: { __typename?: 'Domain', Id: string, Name: string, IssuerEmail: string, Domains: Array<string> } };

export type CreateEnvironmentVariableMutationVariables = Exact<{
  name: Scalars['String']['input'];
  variables: Array<CreateEnvironmentVariableItem> | CreateEnvironmentVariableItem;
}>;


export type CreateEnvironmentVariableMutation = { __typename?: 'Mutation', CreateEnvironmentVariable: { __typename?: 'EnvironmentVariable', Id: string, Name: string } };

export type DeleteEnvironmentVariableMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteEnvironmentVariableMutation = { __typename?: 'Mutation', DeleteEnvironmentVariable: { __typename?: 'EnvironmentVariable', Id: string } };

export type GetEnvironmentVariablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnvironmentVariablesQuery = { __typename?: 'Query', EnvironmentVariable: Array<{ __typename?: 'EnvironmentVariable', Id: string, Name: string, Variables: Array<{ __typename?: 'EnvironmentVariableItem', Key: string, Value: string }> }> };

export type GetEnvironmentVariableQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEnvironmentVariableQuery = { __typename?: 'Query', EnvironmentVariable: Array<{ __typename?: 'EnvironmentVariable', Id: string, Name: string, Variables: Array<{ __typename?: 'EnvironmentVariableItem', Key: string, Value: string }> }> };

export type UpdateEnvironmentVariableMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  variables: Array<CreateEnvironmentVariableItem> | CreateEnvironmentVariableItem;
}>;


export type UpdateEnvironmentVariableMutation = { __typename?: 'Mutation', UpdateEnvironmentVariable: { __typename?: 'EnvironmentVariable', Id: string, Name: string } };

export type CreateRegistryImageMutationVariables = Exact<{
  name: Scalars['String']['input'];
  image: Scalars['String']['input'];
  registryId: Scalars['String']['input'];
}>;


export type CreateRegistryImageMutation = { __typename?: 'Mutation', CreateRegistryImage: { __typename?: 'RegistryImage', Id: string, Name: string, Image: string } };

export type DeleteRegistryImageMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteRegistryImageMutation = { __typename?: 'Mutation', DeleteRegistryImage: { __typename?: 'RegistryImage', Id: string } };

export type GetDockerImageTagsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  image: Scalars['String']['input'];
}>;


export type GetDockerImageTagsQuery = { __typename?: 'Query', DockerRegistryImageTag: Array<string> };

export type GetDockerRegistryImagesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetDockerRegistryImagesQuery = { __typename?: 'Query', DockerRegistryImage: Array<string> };

export type GetRegistryImageQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetRegistryImageQuery = { __typename?: 'Query', RegistryImage: Array<{ __typename?: 'RegistryImage', Id: string, Name: string, Image: string, Registry?: { __typename?: 'Registry', Id: string, Name: string, Namespace?: string | null, DockerIO?: { __typename?: 'DockerIORegistryIntegration', Username: string } | null } | null }> };

export type GetRegistryImagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegistryImagesQuery = { __typename?: 'Query', RegistryImage: Array<{ __typename?: 'RegistryImage', Id: string, Name: string, Image: string, Registry?: { __typename?: 'Registry', Id: string, Name: string, Type: string, Namespace?: string | null, DockerIO?: { __typename?: 'DockerIORegistryIntegration', Username: string } | null } | null }> };

export type UpdateRegistryImageMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  image: Scalars['String']['input'];
  registryId: Scalars['String']['input'];
}>;


export type UpdateRegistryImageMutation = { __typename?: 'Mutation', UpdateRegistryImage: { __typename?: 'RegistryImage', Id: string, Name: string, Image: string } };

export type CreateRegistryMutationVariables = Exact<{
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  dockerIOId?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateRegistryMutation = { __typename?: 'Mutation', CreateRegistry: { __typename?: 'Registry', Id: string, Name: string, Type: string, Status: string } };

export type DeleteRegistryMutationVariables = Exact<{
  Id: Scalars['String']['input'];
}>;


export type DeleteRegistryMutation = { __typename?: 'Mutation', DeleteRegistry: { __typename?: 'Registry', Id: string } };

export type GetDockerIoRegistryIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDockerIoRegistryIntegrationsQuery = { __typename?: 'Query', DockerIORegistryIntegration: Array<{ __typename?: 'DockerIORegistryIntegration', Id: string, Name: string, Username: string, Password: string }> };

export type GetRegistryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetRegistryQuery = { __typename?: 'Query', Registry: Array<{ __typename?: 'Registry', Id: string, Name: string, Type: string, Status: string, Namespace?: string | null, DockerIO?: { __typename?: 'DockerIORegistryIntegration', Id: string, Name: string } | null }> };

export type GetRegistriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRegistriesQuery = { __typename?: 'Query', Registry: Array<{ __typename?: 'Registry', Id: string, Name: string, Type: string, Status: string, Namespace?: string | null, DockerIO?: { __typename?: 'DockerIORegistryIntegration', Id: string, Name: string } | null }> };

export type UpdateRegistryMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  dockerIOId?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateRegistryMutation = { __typename?: 'Mutation', UpdateRegistry: { __typename?: 'Registry', Id: string, Name: string, Type: string, Status: string } };

export type GetGraphNodeDependentsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  nodeType: Scalars['String']['input'];
}>;


export type GetGraphNodeDependentsQuery = { __typename?: 'Query', GraphNodeDependents: Array<{ __typename?: 'GraphNode', Id: string, Name: string, Type: string }> };

export type GetGraphNodeReferencesQueryVariables = Exact<{
  id: Scalars['String']['input'];
  nodeType: Scalars['String']['input'];
}>;


export type GetGraphNodeReferencesQuery = { __typename?: 'Query', GraphNodeReferences: Array<{ __typename?: 'GraphNode', Id: string, Name: string, Type: string }> };

export type CreateClusterMutationVariables = Exact<{
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  integrationId: Scalars['String']['input'];
  integrationClusterId: Scalars['String']['input'];
  googleClusterData?: InputMaybe<CreateClusterGoogleClusterDataRequest>;
}>;


export type CreateClusterMutation = { __typename?: 'Mutation', CreateCluster: { __typename?: 'Cluster', Id: string, Name: string, Type: string, IntegrationClusterId: string, IntegrationClusterName: string, DigitalOcean?: { __typename?: 'DigitalOceanIntegration', Id: string, Name: string } | null } };

export type DeleteClusterMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteClusterMutation = { __typename?: 'Mutation', DeleteCluster: { __typename?: 'Cluster', Id: string } };

export type GetClustersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClustersQuery = { __typename?: 'Query', Cluster: Array<{ __typename?: 'Cluster', Id: string, Name: string, Type: string, IntegrationClusterId: string, IntegrationClusterName: string, DigitalOcean?: { __typename?: 'DigitalOceanIntegration', Id: string, Name: string } | null, Google?: { __typename?: 'GoogleIntegration', Id: string, Name: string } | null, IntegrationClusterData?: { __typename?: 'IntegrationClusterData', Google?: { __typename?: 'GoogleIntegrationClusterData', ProjectId: string } | null } | null }> };

export type GetDigitalOceanKubernetesClustersQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetDigitalOceanKubernetesClustersQuery = { __typename?: 'Query', DigitalOceanKubernetesClusters: Array<{ __typename?: 'IdAndNamePair', Id: string, Name: string }> };

export type GetGoogleKubernetesClustersQueryVariables = Exact<{
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
}>;


export type GetGoogleKubernetesClustersQuery = { __typename?: 'Query', GoogleKubernetesClusters: Array<{ __typename?: 'IdAndNamePair', Id: string, Name: string }> };

export type GetGoogleProjectsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetGoogleProjectsQuery = { __typename?: 'Query', GoogleProjects: Array<{ __typename?: 'IdAndNamePair', Id: string, Name: string }> };

export type GetClusterQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetClusterQuery = { __typename?: 'Query', Cluster: Array<{ __typename?: 'Cluster', Id: string, Name: string, Type: string, IntegrationClusterId: string, IntegrationClusterName: string, DigitalOcean?: { __typename?: 'DigitalOceanIntegration', Id: string, Name: string } | null, Google?: { __typename?: 'GoogleIntegration', Id: string, Name: string } | null, IntegrationClusterData?: { __typename?: 'IntegrationClusterData', Google?: { __typename?: 'GoogleIntegrationClusterData', ProjectId: string } | null } | null }> };

export type UpdateClusterMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateClusterMutation = { __typename?: 'Mutation', UpdateCluster: { __typename?: 'Cluster', Id: string, Name: string } };

export type CreateQuickContainerMutationVariables = Exact<{
  name: Scalars['String']['input'];
  domainId?: InputMaybe<Scalars['String']['input']>;
  clusterId?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  instances?: InputMaybe<Scalars['Int']['input']>;
  onlyCreate?: InputMaybe<Scalars['Boolean']['input']>;
  containers: Array<CreateContainerRequest> | CreateContainerRequest;
}>;


export type CreateQuickContainerMutation = { __typename?: 'Mutation', CreateQuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string, Status: string } };

export type DeleteQuickContainerMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteQuickContainerMutation = { __typename?: 'Mutation', DeleteQuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string, Type: string, Status: string } };

export type GetQuickContainerQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetQuickContainerQuery = { __typename?: 'Query', QuickContainer: Array<{ __typename?: 'QuickContainer', Id: string, Name: string, Status: string, Size?: number | null, Instances?: number | null, Containers: Array<{ __typename?: 'Container', RegistryImageId: string, Tag: string, Ports: Array<number | null>, Volumes?: Array<string> | null, EnvironmentVariableId?: string | null, ContainerResource?: { __typename?: 'ContainerResource', LimitsMemory?: number | null, LimitsCPU?: number | null, RequestsMemory?: number | null, RequestsCPU?: number | null } | null }>, Domain?: { __typename?: 'Domain', Id: string, Name: string, Domains: Array<string> } | null, Cluster?: { __typename?: 'Cluster', Id: string, Name: string } | null }> };

export type GetQuickContainerIncomingConnectionsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetQuickContainerIncomingConnectionsQuery = { __typename?: 'Query', QuickContainerIncomingConnections: Array<{ __typename?: 'QuickContainerIncomingConnection', EnvironmentVariable?: string | null, QuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string } }> };

export type GetQuickContainerInstanceLogsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  instanceName: Scalars['String']['input'];
}>;


export type GetQuickContainerInstanceLogsQuery = { __typename?: 'Query', QuickContainerLogs: Array<{ __typename?: 'QuickContainerLogs', Name: string, Logs: string }> };

export type GetQuickContainerInstancesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetQuickContainerInstancesQuery = { __typename?: 'Query', QuickContainerInstances: Array<string> };

export type GetQuickContainerStatusSubscriptionVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetQuickContainerStatusSubscription = { __typename?: 'Subscription', ListenQuickContainerStatus: { __typename?: 'QuickContainerStatus', Id: string, Status: string } };

export type GetQuickContainersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuickContainersQuery = { __typename?: 'Query', QuickContainer: Array<{ __typename?: 'QuickContainer', Id: string, Name: string, Status: string, Size?: number | null, Instances?: number | null, Containers: Array<{ __typename?: 'Container', RegistryImageId: string, Tag: string, Ports: Array<number | null>, Volumes?: Array<string> | null, EnvironmentVariableId?: string | null, ContainerResource?: { __typename?: 'ContainerResource', LimitsMemory?: number | null, LimitsCPU?: number | null, RequestsMemory?: number | null, RequestsCPU?: number | null } | null }>, Domain?: { __typename?: 'Domain', Id: string, Name: string, Domains: Array<string> } | null, Cluster?: { __typename?: 'Cluster', Id: string, Name: string } | null }> };

export type RestartQuickContainerMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RestartQuickContainerMutation = { __typename?: 'Mutation', RestartQuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string, Type: string, Status: string } };

export type StartQuickContainerMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type StartQuickContainerMutation = { __typename?: 'Mutation', StartQuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string, Type: string, Status: string } };

export type StopQuickContainerMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type StopQuickContainerMutation = { __typename?: 'Mutation', StopQuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string, Type: string, Status: string } };

export type GetQuickContainerMetricsSubscriptionVariables = Exact<{
  id: Scalars['String']['input'];
  instanceNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetQuickContainerMetricsSubscription = { __typename?: 'Subscription', ListenQuickContainerMetrics: { __typename?: 'QuickContainerMetrics', InstanceName: string, Name: string, CPU: number, RAM: number, Time: number, Restarts: number, ReasonForRestart?: string | null, ReasonForWaiting?: string | null } };

export type UpdateQuickContainerIncomingConnectionsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  connections: Array<UpdateQuickContainerIncomingConnection> | UpdateQuickContainerIncomingConnection;
}>;


export type UpdateQuickContainerIncomingConnectionsMutation = { __typename?: 'Mutation', UpdateQuickContainerIncomingConnections: Array<{ __typename?: 'QuickContainerIncomingConnection', EnvironmentVariable?: string | null, QuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string } }> };

export type UpdateQuickContainerMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  domainId?: InputMaybe<Scalars['String']['input']>;
  clusterId?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  instances?: InputMaybe<Scalars['Int']['input']>;
  containers: Array<CreateContainerRequest> | CreateContainerRequest;
}>;


export type UpdateQuickContainerMutation = { __typename?: 'Mutation', UpdateQuickContainer: { __typename?: 'QuickContainer', Id: string, Name: string, Status: string } };

export type GetOAuthUrlMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetOAuthUrlMutation = { __typename?: 'Mutation', GetOAuthUrl: { __typename?: 'GetOAuthUrlResponse', Url: string } };

export type DeleteDigitalOceanIntegrationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteDigitalOceanIntegrationMutation = { __typename?: 'Mutation', DeleteDigitalOceanIntegration: { __typename?: 'DigitalOceanIntegration', Id: string } };

export type GetDigitalOceanIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDigitalOceanIntegrationsQuery = { __typename?: 'Query', DigitalOceanIntegration: Array<{ __typename?: 'DigitalOceanIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }> };

export type GetDigitalOceanIntegrationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetDigitalOceanIntegrationQuery = { __typename?: 'Query', DigitalOceanIntegration: Array<{ __typename?: 'DigitalOceanIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }> };

export type CreateDockerIoRegistryIntegrationMutationVariables = Exact<{
  name: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type CreateDockerIoRegistryIntegrationMutation = { __typename?: 'Mutation', CreateDockerIORegistryIntegration: { __typename?: 'DockerIORegistryIntegration', Id: string, Name: string, Username: string, Password: string } };

export type DeleteDockerIoRegistryIntegrationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteDockerIoRegistryIntegrationMutation = { __typename?: 'Mutation', DeleteDockerIORegistryIntegration: { __typename?: 'DockerIORegistryIntegration', Id: string, Name: string, Username: string, Password: string } };

export type GetAllDockerIoRegistryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDockerIoRegistryQuery = { __typename?: 'Query', DockerIORegistryIntegration: Array<{ __typename?: 'DockerIORegistryIntegration', Id: string, Name: string, Username: string, Password: string }> };

export type GetOneDockerIoRegistryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOneDockerIoRegistryQuery = { __typename?: 'Query', DockerIORegistryIntegration: Array<{ __typename?: 'DockerIORegistryIntegration', Id: string, Name: string, Username: string, Password: string }> };

export type UpdateDockerIoRegistryIntegrationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type UpdateDockerIoRegistryIntegrationMutation = { __typename?: 'Mutation', UpdateDockerIORegistryIntegration: { __typename?: 'DockerIORegistryIntegration', Id: string, Name: string, Username: string, Password: string } };

export type GetIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIntegrationsQuery = { __typename?: 'Query', Integration: { __typename?: 'Integration', DockerIORegistryIntegration: Array<{ __typename?: 'DockerIORegistryIntegration', Id: string, Name: string, Username: string, Password: string }>, GitHubIntegration: Array<{ __typename?: 'GitHubIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }>, DigitalOceanIntegration: Array<{ __typename?: 'DigitalOceanIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }>, GoogleIntegration: Array<{ __typename?: 'GoogleIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }>, MailIntegration: Array<{ __typename?: 'MailIntegration', Id: string, Name: string, Email: string, Password: string, SmtpServer: string, SmtpPort: number }> } };

export type DeleteGitHubIntegrationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteGitHubIntegrationMutation = { __typename?: 'Mutation', DeleteGitHubIntegration: { __typename?: 'GitHubIntegration', Id: string } };

export type GetGitHubIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGitHubIntegrationsQuery = { __typename?: 'Query', GitHubIntegration: Array<{ __typename?: 'GitHubIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }> };

export type GetGitHubRepositoriesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetGitHubRepositoriesQuery = { __typename?: 'Query', GitHubRepository: Array<{ __typename?: 'GitHubRepository', Name: string, Url: string }> };

export type GetGitHubRepositoryBranchesQueryVariables = Exact<{
  id: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;


export type GetGitHubRepositoryBranchesQuery = { __typename?: 'Query', GitHubRepositoryBranch: Array<{ __typename?: 'GitHubRepositoryBranch', Name: string }> };

export type GetGitHubIntegrationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetGitHubIntegrationQuery = { __typename?: 'Query', GitHubIntegration: Array<{ __typename?: 'GitHubIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }> };

export type DeleteGoogleIntegrationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteGoogleIntegrationMutation = { __typename?: 'Mutation', DeleteGoogleIntegration: { __typename?: 'GoogleIntegration', Id: string } };

export type GetGoogleIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGoogleIntegrationsQuery = { __typename?: 'Query', GoogleIntegration: Array<{ __typename?: 'GoogleIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }> };

export type GetGoogleIntegrationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetGoogleIntegrationQuery = { __typename?: 'Query', GoogleIntegration: Array<{ __typename?: 'GoogleIntegration', Id: string, Name: string, Expiry: string, Scopes: Array<string> }> };

export type CreateMailIntegrationMutationVariables = Exact<{
  request: CreateMailIntegrationRequest;
}>;


export type CreateMailIntegrationMutation = { __typename?: 'Mutation', CreateMailIntegration: { __typename?: 'MailIntegration', Id: string, Name: string, Email: string, Password: string, SmtpServer: string, SmtpPort: number } };

export type DeleteMailIntegrationMutationVariables = Exact<{
  request: MailIntegrationRequest;
}>;


export type DeleteMailIntegrationMutation = { __typename?: 'Mutation', DeleteMailIntegration: { __typename?: 'MailIntegration', Id: string, Name: string, Email: string, Password: string, SmtpServer: string, SmtpPort: number } };

export type GetMailIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMailIntegrationsQuery = { __typename?: 'Query', MailIntegration: Array<{ __typename?: 'MailIntegration', Id: string, Name: string, Email: string, Password: string, SmtpServer: string, SmtpPort: number }> };

export type GetMailIntegrationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMailIntegrationQuery = { __typename?: 'Query', MailIntegration: Array<{ __typename?: 'MailIntegration', Id: string, Name: string, Email: string, Password: string, SmtpServer: string, SmtpPort: number }> };

export type UpdateMailIntegrationMutationVariables = Exact<{
  request: UpdateMailIntegrationRequest;
}>;


export type UpdateMailIntegrationMutation = { __typename?: 'Mutation', UpdateMailIntegration: { __typename?: 'MailIntegration', Id: string, Name: string, Email: string, Password: string, SmtpServer: string, SmtpPort: number } };


export const GetPermissionsDocument = gql`
    query GetPermissions($id: String!, $type: String!) {
  Permissions(id: $id, type: $type) {
    Type
    User {
      Id
      FirstName
      LastName
      Image
    }
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables> & ({ variables: GetPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export function useGetPermissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsSuspenseQueryHookResult = ReturnType<typeof useGetPermissionsSuspenseQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const UpdatePermissionsDocument = gql`
    mutation UpdatePermissions($id: String!, $type: String!, $permissions: [UpdatePermission!]!) {
  UpdatePermissions(id: $id, type: $type, permissions: $permissions) {
    Type
    User {
      Id
      FirstName
      LastName
    }
  }
}
    `;
export type UpdatePermissionsMutationFn = Apollo.MutationFunction<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>;

/**
 * __useUpdatePermissionsMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionsMutation, { data, loading, error }] = useUpdatePermissionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useUpdatePermissionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>(UpdatePermissionsDocument, options);
      }
export type UpdatePermissionsMutationHookResult = ReturnType<typeof useUpdatePermissionsMutation>;
export type UpdatePermissionsMutationResult = Apollo.MutationResult<UpdatePermissionsMutation>;
export type UpdatePermissionsMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionsMutation, UpdatePermissionsMutationVariables>;
export const SearchDocument = gql`
    query Search($search: String!, $type: String!) {
  Search(search: $search, type: $type) {
    Items {
      Id
      Name
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables> & ({ variables: SearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export function useSearchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const CompleteAccountSetupDocument = gql`
    mutation CompleteAccountSetup {
  CompleteAccountSetup {
    Id
  }
}
    `;
export type CompleteAccountSetupMutationFn = Apollo.MutationFunction<CompleteAccountSetupMutation, CompleteAccountSetupMutationVariables>;

/**
 * __useCompleteAccountSetupMutation__
 *
 * To run a mutation, you first call `useCompleteAccountSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAccountSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAccountSetupMutation, { data, loading, error }] = useCompleteAccountSetupMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteAccountSetupMutation(baseOptions?: Apollo.MutationHookOptions<CompleteAccountSetupMutation, CompleteAccountSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteAccountSetupMutation, CompleteAccountSetupMutationVariables>(CompleteAccountSetupDocument, options);
      }
export type CompleteAccountSetupMutationHookResult = ReturnType<typeof useCompleteAccountSetupMutation>;
export type CompleteAccountSetupMutationResult = Apollo.MutationResult<CompleteAccountSetupMutation>;
export type CompleteAccountSetupMutationOptions = Apollo.BaseMutationOptions<CompleteAccountSetupMutation, CompleteAccountSetupMutationVariables>;
export const GetMyUserDocument = gql`
    query GetMyUser {
  MyUser {
    Id
    FirstName
    LastName
    Roles
    Image
    Tier {
      TierType
      CreatedAt
      UpdatedAt
    }
    AccountSetupComplete
  }
}
    `;

/**
 * __useGetMyUserQuery__
 *
 * To run a query within a React component, call `useGetMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserQuery(baseOptions?: Apollo.QueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument, options);
      }
export function useGetMyUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument, options);
        }
export function useGetMyUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyUserQuery, GetMyUserQueryVariables>(GetMyUserDocument, options);
        }
export type GetMyUserQueryHookResult = ReturnType<typeof useGetMyUserQuery>;
export type GetMyUserLazyQueryHookResult = ReturnType<typeof useGetMyUserLazyQuery>;
export type GetMyUserSuspenseQueryHookResult = ReturnType<typeof useGetMyUserSuspenseQuery>;
export type GetMyUserQueryResult = Apollo.QueryResult<GetMyUserQuery, GetMyUserQueryVariables>;
export const UpdatePhotoDocument = gql`
    mutation UpdatePhoto($image: String!) {
  UpdatePhoto(request: {Image: $image}) {
    Id
    FirstName
    LastName
    Roles
  }
}
    `;
export type UpdatePhotoMutationFn = Apollo.MutationFunction<UpdatePhotoMutation, UpdatePhotoMutationVariables>;

/**
 * __useUpdatePhotoMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoMutation, { data, loading, error }] = useUpdatePhotoMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdatePhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhotoMutation, UpdatePhotoMutationVariables>(UpdatePhotoDocument, options);
      }
export type UpdatePhotoMutationHookResult = ReturnType<typeof useUpdatePhotoMutation>;
export type UpdatePhotoMutationResult = Apollo.MutationResult<UpdatePhotoMutation>;
export type UpdatePhotoMutationOptions = Apollo.BaseMutationOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>;
export const GetTierDocument = gql`
    query GetTier {
  Tier {
    Id
    TierType
    CPU
    RAM
    Storage
    Flows
    Hooks
    Domains
    EnvironmentVariables
    QuickContainers
    Clusters
  }
}
    `;

/**
 * __useGetTierQuery__
 *
 * To run a query within a React component, call `useGetTierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTierQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTierQuery(baseOptions?: Apollo.QueryHookOptions<GetTierQuery, GetTierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTierQuery, GetTierQueryVariables>(GetTierDocument, options);
      }
export function useGetTierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTierQuery, GetTierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTierQuery, GetTierQueryVariables>(GetTierDocument, options);
        }
export function useGetTierSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTierQuery, GetTierQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTierQuery, GetTierQueryVariables>(GetTierDocument, options);
        }
export type GetTierQueryHookResult = ReturnType<typeof useGetTierQuery>;
export type GetTierLazyQueryHookResult = ReturnType<typeof useGetTierLazyQuery>;
export type GetTierSuspenseQueryHookResult = ReturnType<typeof useGetTierSuspenseQuery>;
export type GetTierQueryResult = Apollo.QueryResult<GetTierQuery, GetTierQueryVariables>;
export const GetUsageDocument = gql`
    query GetUsage {
  Usage {
    Id
    CPU
    RAM
    Storage
    Flows
    Hooks
    Domains
    EnvironmentVariables
    QuickContainers
    Clusters
  }
}
    `;

/**
 * __useGetUsageQuery__
 *
 * To run a query within a React component, call `useGetUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsageQuery(baseOptions?: Apollo.QueryHookOptions<GetUsageQuery, GetUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsageQuery, GetUsageQueryVariables>(GetUsageDocument, options);
      }
export function useGetUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsageQuery, GetUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsageQuery, GetUsageQueryVariables>(GetUsageDocument, options);
        }
export function useGetUsageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsageQuery, GetUsageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsageQuery, GetUsageQueryVariables>(GetUsageDocument, options);
        }
export type GetUsageQueryHookResult = ReturnType<typeof useGetUsageQuery>;
export type GetUsageLazyQueryHookResult = ReturnType<typeof useGetUsageLazyQuery>;
export type GetUsageSuspenseQueryHookResult = ReturnType<typeof useGetUsageSuspenseQuery>;
export type GetUsageQueryResult = Apollo.QueryResult<GetUsageQuery, GetUsageQueryVariables>;
export const UpdateTierDocument = gql`
    mutation UpdateTier($tierType: String!) {
  UpdateTier(tierType: $tierType) {
    Id
    TierType
    CPU
    RAM
    Storage
    Flows
    Hooks
    Domains
    EnvironmentVariables
    QuickContainers
    Clusters
  }
}
    `;
export type UpdateTierMutationFn = Apollo.MutationFunction<UpdateTierMutation, UpdateTierMutationVariables>;

/**
 * __useUpdateTierMutation__
 *
 * To run a mutation, you first call `useUpdateTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTierMutation, { data, loading, error }] = useUpdateTierMutation({
 *   variables: {
 *      tierType: // value for 'tierType'
 *   },
 * });
 */
export function useUpdateTierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTierMutation, UpdateTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTierMutation, UpdateTierMutationVariables>(UpdateTierDocument, options);
      }
export type UpdateTierMutationHookResult = ReturnType<typeof useUpdateTierMutation>;
export type UpdateTierMutationResult = Apollo.MutationResult<UpdateTierMutation>;
export type UpdateTierMutationOptions = Apollo.BaseMutationOptions<UpdateTierMutation, UpdateTierMutationVariables>;
export const AssignUserRoleDocument = gql`
    mutation AssignUserRole($id: String!, $roles: [String!]!) {
  AssignUserRole(request: {Id: $id, Roles: $roles}) {
    Id
    FirstName
    LastName
    Roles
  }
}
    `;
export type AssignUserRoleMutationFn = Apollo.MutationFunction<AssignUserRoleMutation, AssignUserRoleMutationVariables>;

/**
 * __useAssignUserRoleMutation__
 *
 * To run a mutation, you first call `useAssignUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserRoleMutation, { data, loading, error }] = useAssignUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAssignUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssignUserRoleMutation, AssignUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignUserRoleMutation, AssignUserRoleMutationVariables>(AssignUserRoleDocument, options);
      }
export type AssignUserRoleMutationHookResult = ReturnType<typeof useAssignUserRoleMutation>;
export type AssignUserRoleMutationResult = Apollo.MutationResult<AssignUserRoleMutation>;
export type AssignUserRoleMutationOptions = Apollo.BaseMutationOptions<AssignUserRoleMutation, AssignUserRoleMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $roles: [String!]!) {
  CreateUser(
    request: {FirstName: $firstName, LastName: $lastName, Email: $email, Roles: $roles}
  ) {
    Id
    FirstName
    LastName
    Roles
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($Id: String!) {
  DeleteUser(request: {Id: $Id}) {
    Id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetAllRolesDocument = gql`
    query GetAllRoles {
  AllRoles
}
    `;

/**
 * __useGetAllRolesQuery__
 *
 * To run a query within a React component, call `useGetAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options);
      }
export function useGetAllRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options);
        }
export function useGetAllRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options);
        }
export type GetAllRolesQueryHookResult = ReturnType<typeof useGetAllRolesQuery>;
export type GetAllRolesLazyQueryHookResult = ReturnType<typeof useGetAllRolesLazyQuery>;
export type GetAllRolesSuspenseQueryHookResult = ReturnType<typeof useGetAllRolesSuspenseQuery>;
export type GetAllRolesQueryResult = Apollo.QueryResult<GetAllRolesQuery, GetAllRolesQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  User {
    Id
    FirstName
    LastName
    Email
    Roles
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export function useGetAllUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersSuspenseQueryHookResult = ReturnType<typeof useGetAllUsersSuspenseQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($search: String!) {
  SearchUsers(search: $search) {
    Id
    FirstName
    LastName
    Image
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables> & ({ variables: SearchUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export function useSearchUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersSuspenseQueryHookResult = ReturnType<typeof useSearchUsersSuspenseQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const AiChatDocument = gql`
    query AIChat($prompt: String!, $systemPrompt: String!) {
  AIChat(prompt: $prompt, systemPrompt: $systemPrompt)
}
    `;

/**
 * __useAiChatQuery__
 *
 * To run a query within a React component, call `useAiChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiChatQuery({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      systemPrompt: // value for 'systemPrompt'
 *   },
 * });
 */
export function useAiChatQuery(baseOptions: Apollo.QueryHookOptions<AiChatQuery, AiChatQueryVariables> & ({ variables: AiChatQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiChatQuery, AiChatQueryVariables>(AiChatDocument, options);
      }
export function useAiChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiChatQuery, AiChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiChatQuery, AiChatQueryVariables>(AiChatDocument, options);
        }
export function useAiChatSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AiChatQuery, AiChatQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AiChatQuery, AiChatQueryVariables>(AiChatDocument, options);
        }
export type AiChatQueryHookResult = ReturnType<typeof useAiChatQuery>;
export type AiChatLazyQueryHookResult = ReturnType<typeof useAiChatLazyQuery>;
export type AiChatSuspenseQueryHookResult = ReturnType<typeof useAiChatSuspenseQuery>;
export type AiChatQueryResult = Apollo.QueryResult<AiChatQuery, AiChatQueryVariables>;
export const CreateFlowShareableStatusDocument = gql`
    mutation CreateFlowShareableStatus($id: String!) {
  CreateFlowShareableStatus(request: {Id: $id}) {
    Link
  }
}
    `;
export type CreateFlowShareableStatusMutationFn = Apollo.MutationFunction<CreateFlowShareableStatusMutation, CreateFlowShareableStatusMutationVariables>;

/**
 * __useCreateFlowShareableStatusMutation__
 *
 * To run a mutation, you first call `useCreateFlowShareableStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlowShareableStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlowShareableStatusMutation, { data, loading, error }] = useCreateFlowShareableStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateFlowShareableStatusMutation(baseOptions?: Apollo.MutationHookOptions<CreateFlowShareableStatusMutation, CreateFlowShareableStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFlowShareableStatusMutation, CreateFlowShareableStatusMutationVariables>(CreateFlowShareableStatusDocument, options);
      }
export type CreateFlowShareableStatusMutationHookResult = ReturnType<typeof useCreateFlowShareableStatusMutation>;
export type CreateFlowShareableStatusMutationResult = Apollo.MutationResult<CreateFlowShareableStatusMutation>;
export type CreateFlowShareableStatusMutationOptions = Apollo.BaseMutationOptions<CreateFlowShareableStatusMutation, CreateFlowShareableStatusMutationVariables>;
export const CreateFlowDocument = gql`
    mutation CreateFlow($name: String!, $resource: ContainerResourceRequest!, $steps: [CreateFlowStep!]!) {
  CreateFlow(request: {Name: $name, Steps: $steps, Resource: $resource}) {
    Id
    Name
    Resource {
      LimitsMemory
      LimitsCPU
      RequestsMemory
      RequestsCPU
    }
  }
}
    `;
export type CreateFlowMutationFn = Apollo.MutationFunction<CreateFlowMutation, CreateFlowMutationVariables>;

/**
 * __useCreateFlowMutation__
 *
 * To run a mutation, you first call `useCreateFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFlowMutation, { data, loading, error }] = useCreateFlowMutation({
 *   variables: {
 *      name: // value for 'name'
 *      resource: // value for 'resource'
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useCreateFlowMutation(baseOptions?: Apollo.MutationHookOptions<CreateFlowMutation, CreateFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFlowMutation, CreateFlowMutationVariables>(CreateFlowDocument, options);
      }
export type CreateFlowMutationHookResult = ReturnType<typeof useCreateFlowMutation>;
export type CreateFlowMutationResult = Apollo.MutationResult<CreateFlowMutation>;
export type CreateFlowMutationOptions = Apollo.BaseMutationOptions<CreateFlowMutation, CreateFlowMutationVariables>;
export const DeleteFlowDocument = gql`
    mutation DeleteFlow($id: String!) {
  DeleteFlow(request: {Id: $id}) {
    Id
    Name
  }
}
    `;
export type DeleteFlowMutationFn = Apollo.MutationFunction<DeleteFlowMutation, DeleteFlowMutationVariables>;

/**
 * __useDeleteFlowMutation__
 *
 * To run a mutation, you first call `useDeleteFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFlowMutation, { data, loading, error }] = useDeleteFlowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFlowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFlowMutation, DeleteFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFlowMutation, DeleteFlowMutationVariables>(DeleteFlowDocument, options);
      }
export type DeleteFlowMutationHookResult = ReturnType<typeof useDeleteFlowMutation>;
export type DeleteFlowMutationResult = Apollo.MutationResult<DeleteFlowMutation>;
export type DeleteFlowMutationOptions = Apollo.BaseMutationOptions<DeleteFlowMutation, DeleteFlowMutationVariables>;
export const GetFlowRunsDocument = gql`
    query GetFlowRuns($flowId: String!, $id: String) {
  FlowRuns(flowId: $flowId, id: $id) {
    Id
    Status
    Logs
    PublishedImages
  }
}
    `;

/**
 * __useGetFlowRunsQuery__
 *
 * To run a query within a React component, call `useGetFlowRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowRunsQuery({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFlowRunsQuery(baseOptions: Apollo.QueryHookOptions<GetFlowRunsQuery, GetFlowRunsQueryVariables> & ({ variables: GetFlowRunsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlowRunsQuery, GetFlowRunsQueryVariables>(GetFlowRunsDocument, options);
      }
export function useGetFlowRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlowRunsQuery, GetFlowRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlowRunsQuery, GetFlowRunsQueryVariables>(GetFlowRunsDocument, options);
        }
export function useGetFlowRunsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFlowRunsQuery, GetFlowRunsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFlowRunsQuery, GetFlowRunsQueryVariables>(GetFlowRunsDocument, options);
        }
export type GetFlowRunsQueryHookResult = ReturnType<typeof useGetFlowRunsQuery>;
export type GetFlowRunsLazyQueryHookResult = ReturnType<typeof useGetFlowRunsLazyQuery>;
export type GetFlowRunsSuspenseQueryHookResult = ReturnType<typeof useGetFlowRunsSuspenseQuery>;
export type GetFlowRunsQueryResult = Apollo.QueryResult<GetFlowRunsQuery, GetFlowRunsQueryVariables>;
export const GetFlowStepsDocument = gql`
    query GetFlowSteps($flowId: String!) {
  FlowSteps(flowId: $flowId) {
    Id
    Type
    GitPull {
      Id
      Type
      RepositoryName
      Url
      GitHubIntegration {
        Id
        Name
      }
    }
    GitChangeBranch {
      Id
      BranchName
    }
    DockerBuild {
      Id
      DockerFileContent
    }
    DockerPush {
      Id
      GeneratedTagType
      RegistryImage {
        Id
        Name
      }
      Prefix
      Suffix
    }
  }
}
    `;

/**
 * __useGetFlowStepsQuery__
 *
 * To run a query within a React component, call `useGetFlowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowStepsQuery({
 *   variables: {
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useGetFlowStepsQuery(baseOptions: Apollo.QueryHookOptions<GetFlowStepsQuery, GetFlowStepsQueryVariables> & ({ variables: GetFlowStepsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlowStepsQuery, GetFlowStepsQueryVariables>(GetFlowStepsDocument, options);
      }
export function useGetFlowStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlowStepsQuery, GetFlowStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlowStepsQuery, GetFlowStepsQueryVariables>(GetFlowStepsDocument, options);
        }
export function useGetFlowStepsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFlowStepsQuery, GetFlowStepsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFlowStepsQuery, GetFlowStepsQueryVariables>(GetFlowStepsDocument, options);
        }
export type GetFlowStepsQueryHookResult = ReturnType<typeof useGetFlowStepsQuery>;
export type GetFlowStepsLazyQueryHookResult = ReturnType<typeof useGetFlowStepsLazyQuery>;
export type GetFlowStepsSuspenseQueryHookResult = ReturnType<typeof useGetFlowStepsSuspenseQuery>;
export type GetFlowStepsQueryResult = Apollo.QueryResult<GetFlowStepsQuery, GetFlowStepsQueryVariables>;
export const GetFlowsDocument = gql`
    query GetFlows {
  Flow {
    Id
    Name
    Resource {
      LimitsMemory
      LimitsCPU
      RequestsMemory
      RequestsCPU
    }
  }
}
    `;

/**
 * __useGetFlowsQuery__
 *
 * To run a query within a React component, call `useGetFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFlowsQuery(baseOptions?: Apollo.QueryHookOptions<GetFlowsQuery, GetFlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlowsQuery, GetFlowsQueryVariables>(GetFlowsDocument, options);
      }
export function useGetFlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlowsQuery, GetFlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlowsQuery, GetFlowsQueryVariables>(GetFlowsDocument, options);
        }
export function useGetFlowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFlowsQuery, GetFlowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFlowsQuery, GetFlowsQueryVariables>(GetFlowsDocument, options);
        }
export type GetFlowsQueryHookResult = ReturnType<typeof useGetFlowsQuery>;
export type GetFlowsLazyQueryHookResult = ReturnType<typeof useGetFlowsLazyQuery>;
export type GetFlowsSuspenseQueryHookResult = ReturnType<typeof useGetFlowsSuspenseQuery>;
export type GetFlowsQueryResult = Apollo.QueryResult<GetFlowsQuery, GetFlowsQueryVariables>;
export const GetFlowDocument = gql`
    query GetFlow($id: String!) {
  Flow(id: $id) {
    Id
    Name
  }
}
    `;

/**
 * __useGetFlowQuery__
 *
 * To run a query within a React component, call `useGetFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFlowQuery(baseOptions: Apollo.QueryHookOptions<GetFlowQuery, GetFlowQueryVariables> & ({ variables: GetFlowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlowQuery, GetFlowQueryVariables>(GetFlowDocument, options);
      }
export function useGetFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlowQuery, GetFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlowQuery, GetFlowQueryVariables>(GetFlowDocument, options);
        }
export function useGetFlowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFlowQuery, GetFlowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFlowQuery, GetFlowQueryVariables>(GetFlowDocument, options);
        }
export type GetFlowQueryHookResult = ReturnType<typeof useGetFlowQuery>;
export type GetFlowLazyQueryHookResult = ReturnType<typeof useGetFlowLazyQuery>;
export type GetFlowSuspenseQueryHookResult = ReturnType<typeof useGetFlowSuspenseQuery>;
export type GetFlowQueryResult = Apollo.QueryResult<GetFlowQuery, GetFlowQueryVariables>;
export const ListenFlowRunStatusDocument = gql`
    subscription ListenFlowRunStatus($flowId: String!) {
  ListenFlowRunStatus(request: {FlowId: $flowId}) {
    Id
    Status
  }
}
    `;

/**
 * __useListenFlowRunStatusSubscription__
 *
 * To run a query within a React component, call `useListenFlowRunStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenFlowRunStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenFlowRunStatusSubscription({
 *   variables: {
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useListenFlowRunStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<ListenFlowRunStatusSubscription, ListenFlowRunStatusSubscriptionVariables> & ({ variables: ListenFlowRunStatusSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ListenFlowRunStatusSubscription, ListenFlowRunStatusSubscriptionVariables>(ListenFlowRunStatusDocument, options);
      }
export type ListenFlowRunStatusSubscriptionHookResult = ReturnType<typeof useListenFlowRunStatusSubscription>;
export type ListenFlowRunStatusSubscriptionResult = Apollo.SubscriptionResult<ListenFlowRunStatusSubscription>;
export const RunFlowDocument = gql`
    mutation RunFlow($id: String!) {
  RunFlow(request: {Id: $id}) {
    Id
    Status
  }
}
    `;
export type RunFlowMutationFn = Apollo.MutationFunction<RunFlowMutation, RunFlowMutationVariables>;

/**
 * __useRunFlowMutation__
 *
 * To run a mutation, you first call `useRunFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runFlowMutation, { data, loading, error }] = useRunFlowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunFlowMutation(baseOptions?: Apollo.MutationHookOptions<RunFlowMutation, RunFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunFlowMutation, RunFlowMutationVariables>(RunFlowDocument, options);
      }
export type RunFlowMutationHookResult = ReturnType<typeof useRunFlowMutation>;
export type RunFlowMutationResult = Apollo.MutationResult<RunFlowMutation>;
export type RunFlowMutationOptions = Apollo.BaseMutationOptions<RunFlowMutation, RunFlowMutationVariables>;
export const UpdateFlowDocument = gql`
    mutation UpdateFlow($id: String!, $name: String!, $steps: [CreateFlowStep!]!, $resource: ContainerResourceRequest!) {
  UpdateFlow(request: {Id: $id, Name: $name, Steps: $steps, Resource: $resource}) {
    Id
    Name
    Resource {
      LimitsMemory
      LimitsCPU
      RequestsMemory
      RequestsCPU
    }
  }
}
    `;
export type UpdateFlowMutationFn = Apollo.MutationFunction<UpdateFlowMutation, UpdateFlowMutationVariables>;

/**
 * __useUpdateFlowMutation__
 *
 * To run a mutation, you first call `useUpdateFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowMutation, { data, loading, error }] = useUpdateFlowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      steps: // value for 'steps'
 *      resource: // value for 'resource'
 *   },
 * });
 */
export function useUpdateFlowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFlowMutation, UpdateFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFlowMutation, UpdateFlowMutationVariables>(UpdateFlowDocument, options);
      }
export type UpdateFlowMutationHookResult = ReturnType<typeof useUpdateFlowMutation>;
export type UpdateFlowMutationResult = Apollo.MutationResult<UpdateFlowMutation>;
export type UpdateFlowMutationOptions = Apollo.BaseMutationOptions<UpdateFlowMutation, UpdateFlowMutationVariables>;
export const CreateHookDocument = gql`
    mutation CreateHook($name: String!, $steps: [CreateHookStep!]!) {
  CreateHook(request: {Name: $name, Steps: $steps}) {
    Id
    Name
  }
}
    `;
export type CreateHookMutationFn = Apollo.MutationFunction<CreateHookMutation, CreateHookMutationVariables>;

/**
 * __useCreateHookMutation__
 *
 * To run a mutation, you first call `useCreateHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHookMutation, { data, loading, error }] = useCreateHookMutation({
 *   variables: {
 *      name: // value for 'name'
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useCreateHookMutation(baseOptions?: Apollo.MutationHookOptions<CreateHookMutation, CreateHookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHookMutation, CreateHookMutationVariables>(CreateHookDocument, options);
      }
export type CreateHookMutationHookResult = ReturnType<typeof useCreateHookMutation>;
export type CreateHookMutationResult = Apollo.MutationResult<CreateHookMutation>;
export type CreateHookMutationOptions = Apollo.BaseMutationOptions<CreateHookMutation, CreateHookMutationVariables>;
export const DeleteHookDocument = gql`
    mutation DeleteHook($id: String!) {
  DeleteHook(request: {Id: $id}) {
    Id
    Name
  }
}
    `;
export type DeleteHookMutationFn = Apollo.MutationFunction<DeleteHookMutation, DeleteHookMutationVariables>;

/**
 * __useDeleteHookMutation__
 *
 * To run a mutation, you first call `useDeleteHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHookMutation, { data, loading, error }] = useDeleteHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHookMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHookMutation, DeleteHookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHookMutation, DeleteHookMutationVariables>(DeleteHookDocument, options);
      }
export type DeleteHookMutationHookResult = ReturnType<typeof useDeleteHookMutation>;
export type DeleteHookMutationResult = Apollo.MutationResult<DeleteHookMutation>;
export type DeleteHookMutationOptions = Apollo.BaseMutationOptions<DeleteHookMutation, DeleteHookMutationVariables>;
export const GetHookRunsDocument = gql`
    query GetHookRuns($hookId: String!, $id: String) {
  HookRuns(hookId: $hookId, id: $id) {
    Id
    Status
  }
}
    `;

/**
 * __useGetHookRunsQuery__
 *
 * To run a query within a React component, call `useGetHookRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHookRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHookRunsQuery({
 *   variables: {
 *      hookId: // value for 'hookId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHookRunsQuery(baseOptions: Apollo.QueryHookOptions<GetHookRunsQuery, GetHookRunsQueryVariables> & ({ variables: GetHookRunsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHookRunsQuery, GetHookRunsQueryVariables>(GetHookRunsDocument, options);
      }
export function useGetHookRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHookRunsQuery, GetHookRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHookRunsQuery, GetHookRunsQueryVariables>(GetHookRunsDocument, options);
        }
export function useGetHookRunsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHookRunsQuery, GetHookRunsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHookRunsQuery, GetHookRunsQueryVariables>(GetHookRunsDocument, options);
        }
export type GetHookRunsQueryHookResult = ReturnType<typeof useGetHookRunsQuery>;
export type GetHookRunsLazyQueryHookResult = ReturnType<typeof useGetHookRunsLazyQuery>;
export type GetHookRunsSuspenseQueryHookResult = ReturnType<typeof useGetHookRunsSuspenseQuery>;
export type GetHookRunsQueryResult = Apollo.QueryResult<GetHookRunsQuery, GetHookRunsQueryVariables>;
export const GetHookStepsDocument = gql`
    query GetHookSteps($hookId: String!) {
  HookSteps(hookId: $hookId) {
    Id
    Type
    NodeId
    Dependents
    References
    GitWebHook {
      Id
      Type
      RepositoryName
      Url
      Branches
      Events
      GitHubIntegration {
        Id
        Name
      }
    }
    FlowOperation {
      Id
      Type
      Flow {
        Id
        Name
      }
    }
    OperationResult {
      Id
      Type
      Condition
    }
    QuickContainerOperation {
      Id
      Type
      QuickContainer {
        Id
        Name
      }
      Flow {
        Id
        Name
      }
    }
    GitPullRequestComment {
      Id
      Type
      Message
    }
    GitRelease {
      Id
      Type
      Flow {
        Id
        Name
      }
      Message
      IsDraft
      GenerateReleaseNotes
      IncludePRInformation
    }
    Mail {
      Id
      To
      Subject
      Body
      MailIntegration {
        Id
        Name
      }
    }
  }
}
    `;

/**
 * __useGetHookStepsQuery__
 *
 * To run a query within a React component, call `useGetHookStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHookStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHookStepsQuery({
 *   variables: {
 *      hookId: // value for 'hookId'
 *   },
 * });
 */
export function useGetHookStepsQuery(baseOptions: Apollo.QueryHookOptions<GetHookStepsQuery, GetHookStepsQueryVariables> & ({ variables: GetHookStepsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHookStepsQuery, GetHookStepsQueryVariables>(GetHookStepsDocument, options);
      }
export function useGetHookStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHookStepsQuery, GetHookStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHookStepsQuery, GetHookStepsQueryVariables>(GetHookStepsDocument, options);
        }
export function useGetHookStepsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHookStepsQuery, GetHookStepsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHookStepsQuery, GetHookStepsQueryVariables>(GetHookStepsDocument, options);
        }
export type GetHookStepsQueryHookResult = ReturnType<typeof useGetHookStepsQuery>;
export type GetHookStepsLazyQueryHookResult = ReturnType<typeof useGetHookStepsLazyQuery>;
export type GetHookStepsSuspenseQueryHookResult = ReturnType<typeof useGetHookStepsSuspenseQuery>;
export type GetHookStepsQueryResult = Apollo.QueryResult<GetHookStepsQuery, GetHookStepsQueryVariables>;
export const GetHooksDocument = gql`
    query GetHooks {
  Hook {
    Id
    Name
  }
}
    `;

/**
 * __useGetHooksQuery__
 *
 * To run a query within a React component, call `useGetHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHooksQuery(baseOptions?: Apollo.QueryHookOptions<GetHooksQuery, GetHooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHooksQuery, GetHooksQueryVariables>(GetHooksDocument, options);
      }
export function useGetHooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHooksQuery, GetHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHooksQuery, GetHooksQueryVariables>(GetHooksDocument, options);
        }
export function useGetHooksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHooksQuery, GetHooksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHooksQuery, GetHooksQueryVariables>(GetHooksDocument, options);
        }
export type GetHooksQueryHookResult = ReturnType<typeof useGetHooksQuery>;
export type GetHooksLazyQueryHookResult = ReturnType<typeof useGetHooksLazyQuery>;
export type GetHooksSuspenseQueryHookResult = ReturnType<typeof useGetHooksSuspenseQuery>;
export type GetHooksQueryResult = Apollo.QueryResult<GetHooksQuery, GetHooksQueryVariables>;
export const GetHookRunDocument = gql`
    query GetHookRun($hookId: String!, $id: String!) {
  HookRuns(hookId: $hookId, id: $id) {
    Id
    Status
    Steps {
      Id
      Status
      Message
      Step {
        Id
        Type
        NodeId
        Dependents
        References
        GitWebHook {
          Id
          RepositoryName
          GitHubIntegration {
            Id
            Name
          }
          RepositoryName
          Url
          Branches
          Events
        }
        FlowOperation {
          Id
          Type
          Flow {
            Id
            Name
          }
        }
        OperationResult {
          Id
          Type
          Condition
        }
        QuickContainerOperation {
          Id
          Type
          QuickContainer {
            Id
            Name
          }
          Flow {
            Id
            Name
          }
        }
        GitPullRequestComment {
          Id
          Type
          Message
        }
        GitRelease {
          Id
          Type
          Flow {
            Id
            Name
          }
          Message
          IsDraft
          GenerateReleaseNotes
          IncludePRInformation
        }
        Mail {
          Id
          To
          Subject
          Body
          MailIntegration {
            Id
            Name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetHookRunQuery__
 *
 * To run a query within a React component, call `useGetHookRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHookRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHookRunQuery({
 *   variables: {
 *      hookId: // value for 'hookId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHookRunQuery(baseOptions: Apollo.QueryHookOptions<GetHookRunQuery, GetHookRunQueryVariables> & ({ variables: GetHookRunQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHookRunQuery, GetHookRunQueryVariables>(GetHookRunDocument, options);
      }
export function useGetHookRunLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHookRunQuery, GetHookRunQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHookRunQuery, GetHookRunQueryVariables>(GetHookRunDocument, options);
        }
export function useGetHookRunSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHookRunQuery, GetHookRunQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHookRunQuery, GetHookRunQueryVariables>(GetHookRunDocument, options);
        }
export type GetHookRunQueryHookResult = ReturnType<typeof useGetHookRunQuery>;
export type GetHookRunLazyQueryHookResult = ReturnType<typeof useGetHookRunLazyQuery>;
export type GetHookRunSuspenseQueryHookResult = ReturnType<typeof useGetHookRunSuspenseQuery>;
export type GetHookRunQueryResult = Apollo.QueryResult<GetHookRunQuery, GetHookRunQueryVariables>;
export const GetHookDocument = gql`
    query GetHook($id: String!) {
  Hook(id: $id) {
    Id
    Name
  }
}
    `;

/**
 * __useGetHookQuery__
 *
 * To run a query within a React component, call `useGetHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHookQuery(baseOptions: Apollo.QueryHookOptions<GetHookQuery, GetHookQueryVariables> & ({ variables: GetHookQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHookQuery, GetHookQueryVariables>(GetHookDocument, options);
      }
export function useGetHookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHookQuery, GetHookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHookQuery, GetHookQueryVariables>(GetHookDocument, options);
        }
export function useGetHookSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHookQuery, GetHookQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHookQuery, GetHookQueryVariables>(GetHookDocument, options);
        }
export type GetHookQueryHookResult = ReturnType<typeof useGetHookQuery>;
export type GetHookLazyQueryHookResult = ReturnType<typeof useGetHookLazyQuery>;
export type GetHookSuspenseQueryHookResult = ReturnType<typeof useGetHookSuspenseQuery>;
export type GetHookQueryResult = Apollo.QueryResult<GetHookQuery, GetHookQueryVariables>;
export const RunHookDocument = gql`
    mutation RunHook($id: String!) {
  RunHook(request: {Id: $id}) {
    Id
    Status
  }
}
    `;
export type RunHookMutationFn = Apollo.MutationFunction<RunHookMutation, RunHookMutationVariables>;

/**
 * __useRunHookMutation__
 *
 * To run a mutation, you first call `useRunHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runHookMutation, { data, loading, error }] = useRunHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunHookMutation(baseOptions?: Apollo.MutationHookOptions<RunHookMutation, RunHookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunHookMutation, RunHookMutationVariables>(RunHookDocument, options);
      }
export type RunHookMutationHookResult = ReturnType<typeof useRunHookMutation>;
export type RunHookMutationResult = Apollo.MutationResult<RunHookMutation>;
export type RunHookMutationOptions = Apollo.BaseMutationOptions<RunHookMutation, RunHookMutationVariables>;
export const UpdateHookDocument = gql`
    mutation UpdateHook($id: String!, $name: String!, $steps: [CreateHookStep!]!) {
  UpdateHook(request: {Id: $id, Name: $name, Steps: $steps}) {
    Id
    Name
  }
}
    `;
export type UpdateHookMutationFn = Apollo.MutationFunction<UpdateHookMutation, UpdateHookMutationVariables>;

/**
 * __useUpdateHookMutation__
 *
 * To run a mutation, you first call `useUpdateHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHookMutation, { data, loading, error }] = useUpdateHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      steps: // value for 'steps'
 *   },
 * });
 */
export function useUpdateHookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHookMutation, UpdateHookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHookMutation, UpdateHookMutationVariables>(UpdateHookDocument, options);
      }
export type UpdateHookMutationHookResult = ReturnType<typeof useUpdateHookMutation>;
export type UpdateHookMutationResult = Apollo.MutationResult<UpdateHookMutation>;
export type UpdateHookMutationOptions = Apollo.BaseMutationOptions<UpdateHookMutation, UpdateHookMutationVariables>;
export const CreateDomainDocument = gql`
    mutation CreateDomain($name: String!, $issuerEmail: String!, $domains: [String!]!) {
  CreateDomain(
    request: {Name: $name, IssuerEmail: $issuerEmail, Domains: $domains}
  ) {
    Id
    Name
    IssuerEmail
    Domains
  }
}
    `;
export type CreateDomainMutationFn = Apollo.MutationFunction<CreateDomainMutation, CreateDomainMutationVariables>;

/**
 * __useCreateDomainMutation__
 *
 * To run a mutation, you first call `useCreateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomainMutation, { data, loading, error }] = useCreateDomainMutation({
 *   variables: {
 *      name: // value for 'name'
 *      issuerEmail: // value for 'issuerEmail'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useCreateDomainMutation(baseOptions?: Apollo.MutationHookOptions<CreateDomainMutation, CreateDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDomainMutation, CreateDomainMutationVariables>(CreateDomainDocument, options);
      }
export type CreateDomainMutationHookResult = ReturnType<typeof useCreateDomainMutation>;
export type CreateDomainMutationResult = Apollo.MutationResult<CreateDomainMutation>;
export type CreateDomainMutationOptions = Apollo.BaseMutationOptions<CreateDomainMutation, CreateDomainMutationVariables>;
export const DeleteDomainDocument = gql`
    mutation DeleteDomain($id: String!) {
  DeleteDomain(request: {Id: $id}) {
    Id
  }
}
    `;
export type DeleteDomainMutationFn = Apollo.MutationFunction<DeleteDomainMutation, DeleteDomainMutationVariables>;

/**
 * __useDeleteDomainMutation__
 *
 * To run a mutation, you first call `useDeleteDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainMutation, { data, loading, error }] = useDeleteDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDomainMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDomainMutation, DeleteDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDomainMutation, DeleteDomainMutationVariables>(DeleteDomainDocument, options);
      }
export type DeleteDomainMutationHookResult = ReturnType<typeof useDeleteDomainMutation>;
export type DeleteDomainMutationResult = Apollo.MutationResult<DeleteDomainMutation>;
export type DeleteDomainMutationOptions = Apollo.BaseMutationOptions<DeleteDomainMutation, DeleteDomainMutationVariables>;
export const GetDomainsDocument = gql`
    query GetDomains {
  Domain {
    Id
    Name
    IssuerEmail
    Domains
  }
}
    `;

/**
 * __useGetDomainsQuery__
 *
 * To run a query within a React component, call `useGetDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDomainsQuery(baseOptions?: Apollo.QueryHookOptions<GetDomainsQuery, GetDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDomainsQuery, GetDomainsQueryVariables>(GetDomainsDocument, options);
      }
export function useGetDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDomainsQuery, GetDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDomainsQuery, GetDomainsQueryVariables>(GetDomainsDocument, options);
        }
export function useGetDomainsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDomainsQuery, GetDomainsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDomainsQuery, GetDomainsQueryVariables>(GetDomainsDocument, options);
        }
export type GetDomainsQueryHookResult = ReturnType<typeof useGetDomainsQuery>;
export type GetDomainsLazyQueryHookResult = ReturnType<typeof useGetDomainsLazyQuery>;
export type GetDomainsSuspenseQueryHookResult = ReturnType<typeof useGetDomainsSuspenseQuery>;
export type GetDomainsQueryResult = Apollo.QueryResult<GetDomainsQuery, GetDomainsQueryVariables>;
export const GetDomainDocument = gql`
    query GetDomain($id: String!) {
  Domain(id: $id) {
    Id
    Name
    IssuerEmail
    Domains
  }
}
    `;

/**
 * __useGetDomainQuery__
 *
 * To run a query within a React component, call `useGetDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDomainQuery(baseOptions: Apollo.QueryHookOptions<GetDomainQuery, GetDomainQueryVariables> & ({ variables: GetDomainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDomainQuery, GetDomainQueryVariables>(GetDomainDocument, options);
      }
export function useGetDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDomainQuery, GetDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDomainQuery, GetDomainQueryVariables>(GetDomainDocument, options);
        }
export function useGetDomainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDomainQuery, GetDomainQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDomainQuery, GetDomainQueryVariables>(GetDomainDocument, options);
        }
export type GetDomainQueryHookResult = ReturnType<typeof useGetDomainQuery>;
export type GetDomainLazyQueryHookResult = ReturnType<typeof useGetDomainLazyQuery>;
export type GetDomainSuspenseQueryHookResult = ReturnType<typeof useGetDomainSuspenseQuery>;
export type GetDomainQueryResult = Apollo.QueryResult<GetDomainQuery, GetDomainQueryVariables>;
export const UpdateDomainDocument = gql`
    mutation UpdateDomain($id: String!, $name: String!, $issuerEmail: String!, $domains: [String!]!) {
  UpdateDomain(
    request: {Id: $id, Name: $name, IssuerEmail: $issuerEmail, Domains: $domains}
  ) {
    Id
    Name
    IssuerEmail
    Domains
  }
}
    `;
export type UpdateDomainMutationFn = Apollo.MutationFunction<UpdateDomainMutation, UpdateDomainMutationVariables>;

/**
 * __useUpdateDomainMutation__
 *
 * To run a mutation, you first call `useUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainMutation, { data, loading, error }] = useUpdateDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      issuerEmail: // value for 'issuerEmail'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useUpdateDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDomainMutation, UpdateDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDomainMutation, UpdateDomainMutationVariables>(UpdateDomainDocument, options);
      }
export type UpdateDomainMutationHookResult = ReturnType<typeof useUpdateDomainMutation>;
export type UpdateDomainMutationResult = Apollo.MutationResult<UpdateDomainMutation>;
export type UpdateDomainMutationOptions = Apollo.BaseMutationOptions<UpdateDomainMutation, UpdateDomainMutationVariables>;
export const CreateEnvironmentVariableDocument = gql`
    mutation CreateEnvironmentVariable($name: String!, $variables: [CreateEnvironmentVariableItem!]!) {
  CreateEnvironmentVariable(request: {Name: $name, Variables: $variables}) {
    Id
    Name
  }
}
    `;
export type CreateEnvironmentVariableMutationFn = Apollo.MutationFunction<CreateEnvironmentVariableMutation, CreateEnvironmentVariableMutationVariables>;

/**
 * __useCreateEnvironmentVariableMutation__
 *
 * To run a mutation, you first call `useCreateEnvironmentVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnvironmentVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnvironmentVariableMutation, { data, loading, error }] = useCreateEnvironmentVariableMutation({
 *   variables: {
 *      name: // value for 'name'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useCreateEnvironmentVariableMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnvironmentVariableMutation, CreateEnvironmentVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnvironmentVariableMutation, CreateEnvironmentVariableMutationVariables>(CreateEnvironmentVariableDocument, options);
      }
export type CreateEnvironmentVariableMutationHookResult = ReturnType<typeof useCreateEnvironmentVariableMutation>;
export type CreateEnvironmentVariableMutationResult = Apollo.MutationResult<CreateEnvironmentVariableMutation>;
export type CreateEnvironmentVariableMutationOptions = Apollo.BaseMutationOptions<CreateEnvironmentVariableMutation, CreateEnvironmentVariableMutationVariables>;
export const DeleteEnvironmentVariableDocument = gql`
    mutation DeleteEnvironmentVariable($id: String!) {
  DeleteEnvironmentVariable(request: {Id: $id}) {
    Id
  }
}
    `;
export type DeleteEnvironmentVariableMutationFn = Apollo.MutationFunction<DeleteEnvironmentVariableMutation, DeleteEnvironmentVariableMutationVariables>;

/**
 * __useDeleteEnvironmentVariableMutation__
 *
 * To run a mutation, you first call `useDeleteEnvironmentVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnvironmentVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnvironmentVariableMutation, { data, loading, error }] = useDeleteEnvironmentVariableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEnvironmentVariableMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEnvironmentVariableMutation, DeleteEnvironmentVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEnvironmentVariableMutation, DeleteEnvironmentVariableMutationVariables>(DeleteEnvironmentVariableDocument, options);
      }
export type DeleteEnvironmentVariableMutationHookResult = ReturnType<typeof useDeleteEnvironmentVariableMutation>;
export type DeleteEnvironmentVariableMutationResult = Apollo.MutationResult<DeleteEnvironmentVariableMutation>;
export type DeleteEnvironmentVariableMutationOptions = Apollo.BaseMutationOptions<DeleteEnvironmentVariableMutation, DeleteEnvironmentVariableMutationVariables>;
export const GetEnvironmentVariablesDocument = gql`
    query GetEnvironmentVariables {
  EnvironmentVariable {
    Id
    Name
    Variables {
      Key
      Value
    }
  }
}
    `;

/**
 * __useGetEnvironmentVariablesQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnvironmentVariablesQuery(baseOptions?: Apollo.QueryHookOptions<GetEnvironmentVariablesQuery, GetEnvironmentVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnvironmentVariablesQuery, GetEnvironmentVariablesQueryVariables>(GetEnvironmentVariablesDocument, options);
      }
export function useGetEnvironmentVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvironmentVariablesQuery, GetEnvironmentVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnvironmentVariablesQuery, GetEnvironmentVariablesQueryVariables>(GetEnvironmentVariablesDocument, options);
        }
export function useGetEnvironmentVariablesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEnvironmentVariablesQuery, GetEnvironmentVariablesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEnvironmentVariablesQuery, GetEnvironmentVariablesQueryVariables>(GetEnvironmentVariablesDocument, options);
        }
export type GetEnvironmentVariablesQueryHookResult = ReturnType<typeof useGetEnvironmentVariablesQuery>;
export type GetEnvironmentVariablesLazyQueryHookResult = ReturnType<typeof useGetEnvironmentVariablesLazyQuery>;
export type GetEnvironmentVariablesSuspenseQueryHookResult = ReturnType<typeof useGetEnvironmentVariablesSuspenseQuery>;
export type GetEnvironmentVariablesQueryResult = Apollo.QueryResult<GetEnvironmentVariablesQuery, GetEnvironmentVariablesQueryVariables>;
export const GetEnvironmentVariableDocument = gql`
    query GetEnvironmentVariable($id: String!) {
  EnvironmentVariable(id: $id) {
    Id
    Name
    Variables {
      Key
      Value
    }
  }
}
    `;

/**
 * __useGetEnvironmentVariableQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentVariableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentVariableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentVariableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnvironmentVariableQuery(baseOptions: Apollo.QueryHookOptions<GetEnvironmentVariableQuery, GetEnvironmentVariableQueryVariables> & ({ variables: GetEnvironmentVariableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnvironmentVariableQuery, GetEnvironmentVariableQueryVariables>(GetEnvironmentVariableDocument, options);
      }
export function useGetEnvironmentVariableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvironmentVariableQuery, GetEnvironmentVariableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnvironmentVariableQuery, GetEnvironmentVariableQueryVariables>(GetEnvironmentVariableDocument, options);
        }
export function useGetEnvironmentVariableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEnvironmentVariableQuery, GetEnvironmentVariableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEnvironmentVariableQuery, GetEnvironmentVariableQueryVariables>(GetEnvironmentVariableDocument, options);
        }
export type GetEnvironmentVariableQueryHookResult = ReturnType<typeof useGetEnvironmentVariableQuery>;
export type GetEnvironmentVariableLazyQueryHookResult = ReturnType<typeof useGetEnvironmentVariableLazyQuery>;
export type GetEnvironmentVariableSuspenseQueryHookResult = ReturnType<typeof useGetEnvironmentVariableSuspenseQuery>;
export type GetEnvironmentVariableQueryResult = Apollo.QueryResult<GetEnvironmentVariableQuery, GetEnvironmentVariableQueryVariables>;
export const UpdateEnvironmentVariableDocument = gql`
    mutation UpdateEnvironmentVariable($id: String!, $name: String!, $variables: [CreateEnvironmentVariableItem!]!) {
  UpdateEnvironmentVariable(
    request: {Id: $id, Name: $name, Variables: $variables}
  ) {
    Id
    Name
  }
}
    `;
export type UpdateEnvironmentVariableMutationFn = Apollo.MutationFunction<UpdateEnvironmentVariableMutation, UpdateEnvironmentVariableMutationVariables>;

/**
 * __useUpdateEnvironmentVariableMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentVariableMutation, { data, loading, error }] = useUpdateEnvironmentVariableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useUpdateEnvironmentVariableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentVariableMutation, UpdateEnvironmentVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentVariableMutation, UpdateEnvironmentVariableMutationVariables>(UpdateEnvironmentVariableDocument, options);
      }
export type UpdateEnvironmentVariableMutationHookResult = ReturnType<typeof useUpdateEnvironmentVariableMutation>;
export type UpdateEnvironmentVariableMutationResult = Apollo.MutationResult<UpdateEnvironmentVariableMutation>;
export type UpdateEnvironmentVariableMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentVariableMutation, UpdateEnvironmentVariableMutationVariables>;
export const CreateRegistryImageDocument = gql`
    mutation CreateRegistryImage($name: String!, $image: String!, $registryId: String!) {
  CreateRegistryImage(
    request: {Name: $name, Image: $image, RegistryId: $registryId}
  ) {
    Id
    Name
    Image
  }
}
    `;
export type CreateRegistryImageMutationFn = Apollo.MutationFunction<CreateRegistryImageMutation, CreateRegistryImageMutationVariables>;

/**
 * __useCreateRegistryImageMutation__
 *
 * To run a mutation, you first call `useCreateRegistryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistryImageMutation, { data, loading, error }] = useCreateRegistryImageMutation({
 *   variables: {
 *      name: // value for 'name'
 *      image: // value for 'image'
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useCreateRegistryImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegistryImageMutation, CreateRegistryImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegistryImageMutation, CreateRegistryImageMutationVariables>(CreateRegistryImageDocument, options);
      }
export type CreateRegistryImageMutationHookResult = ReturnType<typeof useCreateRegistryImageMutation>;
export type CreateRegistryImageMutationResult = Apollo.MutationResult<CreateRegistryImageMutation>;
export type CreateRegistryImageMutationOptions = Apollo.BaseMutationOptions<CreateRegistryImageMutation, CreateRegistryImageMutationVariables>;
export const DeleteRegistryImageDocument = gql`
    mutation DeleteRegistryImage($id: String!) {
  DeleteRegistryImage(request: {Id: $id}) {
    Id
  }
}
    `;
export type DeleteRegistryImageMutationFn = Apollo.MutationFunction<DeleteRegistryImageMutation, DeleteRegistryImageMutationVariables>;

/**
 * __useDeleteRegistryImageMutation__
 *
 * To run a mutation, you first call `useDeleteRegistryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistryImageMutation, { data, loading, error }] = useDeleteRegistryImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRegistryImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegistryImageMutation, DeleteRegistryImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegistryImageMutation, DeleteRegistryImageMutationVariables>(DeleteRegistryImageDocument, options);
      }
export type DeleteRegistryImageMutationHookResult = ReturnType<typeof useDeleteRegistryImageMutation>;
export type DeleteRegistryImageMutationResult = Apollo.MutationResult<DeleteRegistryImageMutation>;
export type DeleteRegistryImageMutationOptions = Apollo.BaseMutationOptions<DeleteRegistryImageMutation, DeleteRegistryImageMutationVariables>;
export const GetDockerImageTagsDocument = gql`
    query GetDockerImageTags($id: String!, $image: String!) {
  DockerRegistryImageTag(id: $id, image: $image)
}
    `;

/**
 * __useGetDockerImageTagsQuery__
 *
 * To run a query within a React component, call `useGetDockerImageTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDockerImageTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDockerImageTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useGetDockerImageTagsQuery(baseOptions: Apollo.QueryHookOptions<GetDockerImageTagsQuery, GetDockerImageTagsQueryVariables> & ({ variables: GetDockerImageTagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDockerImageTagsQuery, GetDockerImageTagsQueryVariables>(GetDockerImageTagsDocument, options);
      }
export function useGetDockerImageTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDockerImageTagsQuery, GetDockerImageTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDockerImageTagsQuery, GetDockerImageTagsQueryVariables>(GetDockerImageTagsDocument, options);
        }
export function useGetDockerImageTagsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDockerImageTagsQuery, GetDockerImageTagsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDockerImageTagsQuery, GetDockerImageTagsQueryVariables>(GetDockerImageTagsDocument, options);
        }
export type GetDockerImageTagsQueryHookResult = ReturnType<typeof useGetDockerImageTagsQuery>;
export type GetDockerImageTagsLazyQueryHookResult = ReturnType<typeof useGetDockerImageTagsLazyQuery>;
export type GetDockerImageTagsSuspenseQueryHookResult = ReturnType<typeof useGetDockerImageTagsSuspenseQuery>;
export type GetDockerImageTagsQueryResult = Apollo.QueryResult<GetDockerImageTagsQuery, GetDockerImageTagsQueryVariables>;
export const GetDockerRegistryImagesDocument = gql`
    query GetDockerRegistryImages($id: String!) {
  DockerRegistryImage(id: $id)
}
    `;

/**
 * __useGetDockerRegistryImagesQuery__
 *
 * To run a query within a React component, call `useGetDockerRegistryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDockerRegistryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDockerRegistryImagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDockerRegistryImagesQuery(baseOptions: Apollo.QueryHookOptions<GetDockerRegistryImagesQuery, GetDockerRegistryImagesQueryVariables> & ({ variables: GetDockerRegistryImagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDockerRegistryImagesQuery, GetDockerRegistryImagesQueryVariables>(GetDockerRegistryImagesDocument, options);
      }
export function useGetDockerRegistryImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDockerRegistryImagesQuery, GetDockerRegistryImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDockerRegistryImagesQuery, GetDockerRegistryImagesQueryVariables>(GetDockerRegistryImagesDocument, options);
        }
export function useGetDockerRegistryImagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDockerRegistryImagesQuery, GetDockerRegistryImagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDockerRegistryImagesQuery, GetDockerRegistryImagesQueryVariables>(GetDockerRegistryImagesDocument, options);
        }
export type GetDockerRegistryImagesQueryHookResult = ReturnType<typeof useGetDockerRegistryImagesQuery>;
export type GetDockerRegistryImagesLazyQueryHookResult = ReturnType<typeof useGetDockerRegistryImagesLazyQuery>;
export type GetDockerRegistryImagesSuspenseQueryHookResult = ReturnType<typeof useGetDockerRegistryImagesSuspenseQuery>;
export type GetDockerRegistryImagesQueryResult = Apollo.QueryResult<GetDockerRegistryImagesQuery, GetDockerRegistryImagesQueryVariables>;
export const GetRegistryImageDocument = gql`
    query GetRegistryImage($id: String!) {
  RegistryImage(id: $id) {
    Id
    Name
    Image
    Registry {
      Id
      Name
      DockerIO {
        Username
      }
      Namespace
    }
  }
}
    `;

/**
 * __useGetRegistryImageQuery__
 *
 * To run a query within a React component, call `useGetRegistryImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryImageQuery(baseOptions: Apollo.QueryHookOptions<GetRegistryImageQuery, GetRegistryImageQueryVariables> & ({ variables: GetRegistryImageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegistryImageQuery, GetRegistryImageQueryVariables>(GetRegistryImageDocument, options);
      }
export function useGetRegistryImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegistryImageQuery, GetRegistryImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegistryImageQuery, GetRegistryImageQueryVariables>(GetRegistryImageDocument, options);
        }
export function useGetRegistryImageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRegistryImageQuery, GetRegistryImageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRegistryImageQuery, GetRegistryImageQueryVariables>(GetRegistryImageDocument, options);
        }
export type GetRegistryImageQueryHookResult = ReturnType<typeof useGetRegistryImageQuery>;
export type GetRegistryImageLazyQueryHookResult = ReturnType<typeof useGetRegistryImageLazyQuery>;
export type GetRegistryImageSuspenseQueryHookResult = ReturnType<typeof useGetRegistryImageSuspenseQuery>;
export type GetRegistryImageQueryResult = Apollo.QueryResult<GetRegistryImageQuery, GetRegistryImageQueryVariables>;
export const GetRegistryImagesDocument = gql`
    query GetRegistryImages {
  RegistryImage {
    Id
    Name
    Image
    Registry {
      Id
      Name
      Type
      DockerIO {
        Username
      }
      Namespace
    }
  }
}
    `;

/**
 * __useGetRegistryImagesQuery__
 *
 * To run a query within a React component, call `useGetRegistryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistryImagesQuery(baseOptions?: Apollo.QueryHookOptions<GetRegistryImagesQuery, GetRegistryImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegistryImagesQuery, GetRegistryImagesQueryVariables>(GetRegistryImagesDocument, options);
      }
export function useGetRegistryImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegistryImagesQuery, GetRegistryImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegistryImagesQuery, GetRegistryImagesQueryVariables>(GetRegistryImagesDocument, options);
        }
export function useGetRegistryImagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRegistryImagesQuery, GetRegistryImagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRegistryImagesQuery, GetRegistryImagesQueryVariables>(GetRegistryImagesDocument, options);
        }
export type GetRegistryImagesQueryHookResult = ReturnType<typeof useGetRegistryImagesQuery>;
export type GetRegistryImagesLazyQueryHookResult = ReturnType<typeof useGetRegistryImagesLazyQuery>;
export type GetRegistryImagesSuspenseQueryHookResult = ReturnType<typeof useGetRegistryImagesSuspenseQuery>;
export type GetRegistryImagesQueryResult = Apollo.QueryResult<GetRegistryImagesQuery, GetRegistryImagesQueryVariables>;
export const UpdateRegistryImageDocument = gql`
    mutation UpdateRegistryImage($id: String!, $name: String!, $image: String!, $registryId: String!) {
  UpdateRegistryImage(
    request: {Id: $id, Name: $name, Image: $image, RegistryId: $registryId}
  ) {
    Id
    Name
    Image
  }
}
    `;
export type UpdateRegistryImageMutationFn = Apollo.MutationFunction<UpdateRegistryImageMutation, UpdateRegistryImageMutationVariables>;

/**
 * __useUpdateRegistryImageMutation__
 *
 * To run a mutation, you first call `useUpdateRegistryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistryImageMutation, { data, loading, error }] = useUpdateRegistryImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      image: // value for 'image'
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useUpdateRegistryImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegistryImageMutation, UpdateRegistryImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegistryImageMutation, UpdateRegistryImageMutationVariables>(UpdateRegistryImageDocument, options);
      }
export type UpdateRegistryImageMutationHookResult = ReturnType<typeof useUpdateRegistryImageMutation>;
export type UpdateRegistryImageMutationResult = Apollo.MutationResult<UpdateRegistryImageMutation>;
export type UpdateRegistryImageMutationOptions = Apollo.BaseMutationOptions<UpdateRegistryImageMutation, UpdateRegistryImageMutationVariables>;
export const CreateRegistryDocument = gql`
    mutation CreateRegistry($name: String!, $type: String!, $dockerIOId: String, $namespace: String) {
  CreateRegistry(
    request: {Name: $name, Type: $type, DockerIOId: $dockerIOId, Namespace: $namespace}
  ) {
    Id
    Name
    Type
    Status
  }
}
    `;
export type CreateRegistryMutationFn = Apollo.MutationFunction<CreateRegistryMutation, CreateRegistryMutationVariables>;

/**
 * __useCreateRegistryMutation__
 *
 * To run a mutation, you first call `useCreateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistryMutation, { data, loading, error }] = useCreateRegistryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      dockerIOId: // value for 'dockerIOId'
 *      namespace: // value for 'namespace'
 *   },
 * });
 */
export function useCreateRegistryMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegistryMutation, CreateRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegistryMutation, CreateRegistryMutationVariables>(CreateRegistryDocument, options);
      }
export type CreateRegistryMutationHookResult = ReturnType<typeof useCreateRegistryMutation>;
export type CreateRegistryMutationResult = Apollo.MutationResult<CreateRegistryMutation>;
export type CreateRegistryMutationOptions = Apollo.BaseMutationOptions<CreateRegistryMutation, CreateRegistryMutationVariables>;
export const DeleteRegistryDocument = gql`
    mutation DeleteRegistry($Id: String!) {
  DeleteRegistry(request: {Id: $Id}) {
    Id
  }
}
    `;
export type DeleteRegistryMutationFn = Apollo.MutationFunction<DeleteRegistryMutation, DeleteRegistryMutationVariables>;

/**
 * __useDeleteRegistryMutation__
 *
 * To run a mutation, you first call `useDeleteRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistryMutation, { data, loading, error }] = useDeleteRegistryMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteRegistryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegistryMutation, DeleteRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegistryMutation, DeleteRegistryMutationVariables>(DeleteRegistryDocument, options);
      }
export type DeleteRegistryMutationHookResult = ReturnType<typeof useDeleteRegistryMutation>;
export type DeleteRegistryMutationResult = Apollo.MutationResult<DeleteRegistryMutation>;
export type DeleteRegistryMutationOptions = Apollo.BaseMutationOptions<DeleteRegistryMutation, DeleteRegistryMutationVariables>;
export const GetDockerIoRegistryIntegrationsDocument = gql`
    query GetDockerIORegistryIntegrations {
  DockerIORegistryIntegration {
    Id
    Name
    Username
    Password
  }
}
    `;

/**
 * __useGetDockerIoRegistryIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetDockerIoRegistryIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDockerIoRegistryIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDockerIoRegistryIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDockerIoRegistryIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetDockerIoRegistryIntegrationsQuery, GetDockerIoRegistryIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDockerIoRegistryIntegrationsQuery, GetDockerIoRegistryIntegrationsQueryVariables>(GetDockerIoRegistryIntegrationsDocument, options);
      }
export function useGetDockerIoRegistryIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDockerIoRegistryIntegrationsQuery, GetDockerIoRegistryIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDockerIoRegistryIntegrationsQuery, GetDockerIoRegistryIntegrationsQueryVariables>(GetDockerIoRegistryIntegrationsDocument, options);
        }
export function useGetDockerIoRegistryIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDockerIoRegistryIntegrationsQuery, GetDockerIoRegistryIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDockerIoRegistryIntegrationsQuery, GetDockerIoRegistryIntegrationsQueryVariables>(GetDockerIoRegistryIntegrationsDocument, options);
        }
export type GetDockerIoRegistryIntegrationsQueryHookResult = ReturnType<typeof useGetDockerIoRegistryIntegrationsQuery>;
export type GetDockerIoRegistryIntegrationsLazyQueryHookResult = ReturnType<typeof useGetDockerIoRegistryIntegrationsLazyQuery>;
export type GetDockerIoRegistryIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetDockerIoRegistryIntegrationsSuspenseQuery>;
export type GetDockerIoRegistryIntegrationsQueryResult = Apollo.QueryResult<GetDockerIoRegistryIntegrationsQuery, GetDockerIoRegistryIntegrationsQueryVariables>;
export const GetRegistryDocument = gql`
    query GetRegistry($id: String!) {
  Registry(id: $id) {
    Id
    Name
    Type
    Status
    DockerIO {
      Id
      Name
    }
    Namespace
  }
}
    `;

/**
 * __useGetRegistryQuery__
 *
 * To run a query within a React component, call `useGetRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryQuery(baseOptions: Apollo.QueryHookOptions<GetRegistryQuery, GetRegistryQueryVariables> & ({ variables: GetRegistryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegistryQuery, GetRegistryQueryVariables>(GetRegistryDocument, options);
      }
export function useGetRegistryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegistryQuery, GetRegistryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegistryQuery, GetRegistryQueryVariables>(GetRegistryDocument, options);
        }
export function useGetRegistrySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRegistryQuery, GetRegistryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRegistryQuery, GetRegistryQueryVariables>(GetRegistryDocument, options);
        }
export type GetRegistryQueryHookResult = ReturnType<typeof useGetRegistryQuery>;
export type GetRegistryLazyQueryHookResult = ReturnType<typeof useGetRegistryLazyQuery>;
export type GetRegistrySuspenseQueryHookResult = ReturnType<typeof useGetRegistrySuspenseQuery>;
export type GetRegistryQueryResult = Apollo.QueryResult<GetRegistryQuery, GetRegistryQueryVariables>;
export const GetRegistriesDocument = gql`
    query GetRegistries {
  Registry {
    Id
    Name
    Type
    Status
    DockerIO {
      Id
      Name
    }
    Namespace
  }
}
    `;

/**
 * __useGetRegistriesQuery__
 *
 * To run a query within a React component, call `useGetRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistriesQuery(baseOptions?: Apollo.QueryHookOptions<GetRegistriesQuery, GetRegistriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegistriesQuery, GetRegistriesQueryVariables>(GetRegistriesDocument, options);
      }
export function useGetRegistriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegistriesQuery, GetRegistriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegistriesQuery, GetRegistriesQueryVariables>(GetRegistriesDocument, options);
        }
export function useGetRegistriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRegistriesQuery, GetRegistriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRegistriesQuery, GetRegistriesQueryVariables>(GetRegistriesDocument, options);
        }
export type GetRegistriesQueryHookResult = ReturnType<typeof useGetRegistriesQuery>;
export type GetRegistriesLazyQueryHookResult = ReturnType<typeof useGetRegistriesLazyQuery>;
export type GetRegistriesSuspenseQueryHookResult = ReturnType<typeof useGetRegistriesSuspenseQuery>;
export type GetRegistriesQueryResult = Apollo.QueryResult<GetRegistriesQuery, GetRegistriesQueryVariables>;
export const UpdateRegistryDocument = gql`
    mutation UpdateRegistry($id: String!, $name: String!, $type: String!, $dockerIOId: String, $namespace: String) {
  UpdateRegistry(
    request: {Id: $id, Name: $name, Type: $type, DockerIOId: $dockerIOId, Namespace: $namespace}
  ) {
    Id
    Name
    Type
    Status
  }
}
    `;
export type UpdateRegistryMutationFn = Apollo.MutationFunction<UpdateRegistryMutation, UpdateRegistryMutationVariables>;

/**
 * __useUpdateRegistryMutation__
 *
 * To run a mutation, you first call `useUpdateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistryMutation, { data, loading, error }] = useUpdateRegistryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      dockerIOId: // value for 'dockerIOId'
 *      namespace: // value for 'namespace'
 *   },
 * });
 */
export function useUpdateRegistryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegistryMutation, UpdateRegistryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegistryMutation, UpdateRegistryMutationVariables>(UpdateRegistryDocument, options);
      }
export type UpdateRegistryMutationHookResult = ReturnType<typeof useUpdateRegistryMutation>;
export type UpdateRegistryMutationResult = Apollo.MutationResult<UpdateRegistryMutation>;
export type UpdateRegistryMutationOptions = Apollo.BaseMutationOptions<UpdateRegistryMutation, UpdateRegistryMutationVariables>;
export const GetGraphNodeDependentsDocument = gql`
    query GetGraphNodeDependents($id: String!, $nodeType: String!) {
  GraphNodeDependents(id: $id, nodeType: $nodeType) {
    Id
    Name
    Type
  }
}
    `;

/**
 * __useGetGraphNodeDependentsQuery__
 *
 * To run a query within a React component, call `useGetGraphNodeDependentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphNodeDependentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphNodeDependentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      nodeType: // value for 'nodeType'
 *   },
 * });
 */
export function useGetGraphNodeDependentsQuery(baseOptions: Apollo.QueryHookOptions<GetGraphNodeDependentsQuery, GetGraphNodeDependentsQueryVariables> & ({ variables: GetGraphNodeDependentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphNodeDependentsQuery, GetGraphNodeDependentsQueryVariables>(GetGraphNodeDependentsDocument, options);
      }
export function useGetGraphNodeDependentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphNodeDependentsQuery, GetGraphNodeDependentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphNodeDependentsQuery, GetGraphNodeDependentsQueryVariables>(GetGraphNodeDependentsDocument, options);
        }
export function useGetGraphNodeDependentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraphNodeDependentsQuery, GetGraphNodeDependentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraphNodeDependentsQuery, GetGraphNodeDependentsQueryVariables>(GetGraphNodeDependentsDocument, options);
        }
export type GetGraphNodeDependentsQueryHookResult = ReturnType<typeof useGetGraphNodeDependentsQuery>;
export type GetGraphNodeDependentsLazyQueryHookResult = ReturnType<typeof useGetGraphNodeDependentsLazyQuery>;
export type GetGraphNodeDependentsSuspenseQueryHookResult = ReturnType<typeof useGetGraphNodeDependentsSuspenseQuery>;
export type GetGraphNodeDependentsQueryResult = Apollo.QueryResult<GetGraphNodeDependentsQuery, GetGraphNodeDependentsQueryVariables>;
export const GetGraphNodeReferencesDocument = gql`
    query GetGraphNodeReferences($id: String!, $nodeType: String!) {
  GraphNodeReferences(id: $id, nodeType: $nodeType) {
    Id
    Name
    Type
  }
}
    `;

/**
 * __useGetGraphNodeReferencesQuery__
 *
 * To run a query within a React component, call `useGetGraphNodeReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphNodeReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphNodeReferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      nodeType: // value for 'nodeType'
 *   },
 * });
 */
export function useGetGraphNodeReferencesQuery(baseOptions: Apollo.QueryHookOptions<GetGraphNodeReferencesQuery, GetGraphNodeReferencesQueryVariables> & ({ variables: GetGraphNodeReferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphNodeReferencesQuery, GetGraphNodeReferencesQueryVariables>(GetGraphNodeReferencesDocument, options);
      }
export function useGetGraphNodeReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphNodeReferencesQuery, GetGraphNodeReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphNodeReferencesQuery, GetGraphNodeReferencesQueryVariables>(GetGraphNodeReferencesDocument, options);
        }
export function useGetGraphNodeReferencesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGraphNodeReferencesQuery, GetGraphNodeReferencesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGraphNodeReferencesQuery, GetGraphNodeReferencesQueryVariables>(GetGraphNodeReferencesDocument, options);
        }
export type GetGraphNodeReferencesQueryHookResult = ReturnType<typeof useGetGraphNodeReferencesQuery>;
export type GetGraphNodeReferencesLazyQueryHookResult = ReturnType<typeof useGetGraphNodeReferencesLazyQuery>;
export type GetGraphNodeReferencesSuspenseQueryHookResult = ReturnType<typeof useGetGraphNodeReferencesSuspenseQuery>;
export type GetGraphNodeReferencesQueryResult = Apollo.QueryResult<GetGraphNodeReferencesQuery, GetGraphNodeReferencesQueryVariables>;
export const CreateClusterDocument = gql`
    mutation CreateCluster($name: String!, $type: String!, $integrationId: String!, $integrationClusterId: String!, $googleClusterData: CreateClusterGoogleClusterDataRequest) {
  CreateCluster(
    request: {Name: $name, Type: $type, IntegrationId: $integrationId, IntegrationClusterId: $integrationClusterId, GoogleClusterData: $googleClusterData}
  ) {
    Id
    Name
    Type
    DigitalOcean {
      Id
      Name
    }
    IntegrationClusterId
    IntegrationClusterName
  }
}
    `;
export type CreateClusterMutationFn = Apollo.MutationFunction<CreateClusterMutation, CreateClusterMutationVariables>;

/**
 * __useCreateClusterMutation__
 *
 * To run a mutation, you first call `useCreateClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClusterMutation, { data, loading, error }] = useCreateClusterMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      integrationId: // value for 'integrationId'
 *      integrationClusterId: // value for 'integrationClusterId'
 *      googleClusterData: // value for 'googleClusterData'
 *   },
 * });
 */
export function useCreateClusterMutation(baseOptions?: Apollo.MutationHookOptions<CreateClusterMutation, CreateClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClusterMutation, CreateClusterMutationVariables>(CreateClusterDocument, options);
      }
export type CreateClusterMutationHookResult = ReturnType<typeof useCreateClusterMutation>;
export type CreateClusterMutationResult = Apollo.MutationResult<CreateClusterMutation>;
export type CreateClusterMutationOptions = Apollo.BaseMutationOptions<CreateClusterMutation, CreateClusterMutationVariables>;
export const DeleteClusterDocument = gql`
    mutation DeleteCluster($id: String!) {
  DeleteCluster(request: {Id: $id}) {
    Id
  }
}
    `;
export type DeleteClusterMutationFn = Apollo.MutationFunction<DeleteClusterMutation, DeleteClusterMutationVariables>;

/**
 * __useDeleteClusterMutation__
 *
 * To run a mutation, you first call `useDeleteClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClusterMutation, { data, loading, error }] = useDeleteClusterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClusterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClusterMutation, DeleteClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClusterMutation, DeleteClusterMutationVariables>(DeleteClusterDocument, options);
      }
export type DeleteClusterMutationHookResult = ReturnType<typeof useDeleteClusterMutation>;
export type DeleteClusterMutationResult = Apollo.MutationResult<DeleteClusterMutation>;
export type DeleteClusterMutationOptions = Apollo.BaseMutationOptions<DeleteClusterMutation, DeleteClusterMutationVariables>;
export const GetClustersDocument = gql`
    query GetClusters {
  Cluster {
    Id
    Name
    Type
    DigitalOcean {
      Id
      Name
    }
    Google {
      Id
      Name
    }
    IntegrationClusterId
    IntegrationClusterName
    IntegrationClusterData {
      Google {
        ProjectId
      }
    }
  }
}
    `;

/**
 * __useGetClustersQuery__
 *
 * To run a query within a React component, call `useGetClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClustersQuery(baseOptions?: Apollo.QueryHookOptions<GetClustersQuery, GetClustersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClustersQuery, GetClustersQueryVariables>(GetClustersDocument, options);
      }
export function useGetClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClustersQuery, GetClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClustersQuery, GetClustersQueryVariables>(GetClustersDocument, options);
        }
export function useGetClustersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClustersQuery, GetClustersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClustersQuery, GetClustersQueryVariables>(GetClustersDocument, options);
        }
export type GetClustersQueryHookResult = ReturnType<typeof useGetClustersQuery>;
export type GetClustersLazyQueryHookResult = ReturnType<typeof useGetClustersLazyQuery>;
export type GetClustersSuspenseQueryHookResult = ReturnType<typeof useGetClustersSuspenseQuery>;
export type GetClustersQueryResult = Apollo.QueryResult<GetClustersQuery, GetClustersQueryVariables>;
export const GetDigitalOceanKubernetesClustersDocument = gql`
    query GetDigitalOceanKubernetesClusters($id: String!) {
  DigitalOceanKubernetesClusters(id: $id) {
    Id
    Name
  }
}
    `;

/**
 * __useGetDigitalOceanKubernetesClustersQuery__
 *
 * To run a query within a React component, call `useGetDigitalOceanKubernetesClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalOceanKubernetesClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalOceanKubernetesClustersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDigitalOceanKubernetesClustersQuery(baseOptions: Apollo.QueryHookOptions<GetDigitalOceanKubernetesClustersQuery, GetDigitalOceanKubernetesClustersQueryVariables> & ({ variables: GetDigitalOceanKubernetesClustersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDigitalOceanKubernetesClustersQuery, GetDigitalOceanKubernetesClustersQueryVariables>(GetDigitalOceanKubernetesClustersDocument, options);
      }
export function useGetDigitalOceanKubernetesClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDigitalOceanKubernetesClustersQuery, GetDigitalOceanKubernetesClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDigitalOceanKubernetesClustersQuery, GetDigitalOceanKubernetesClustersQueryVariables>(GetDigitalOceanKubernetesClustersDocument, options);
        }
export function useGetDigitalOceanKubernetesClustersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDigitalOceanKubernetesClustersQuery, GetDigitalOceanKubernetesClustersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDigitalOceanKubernetesClustersQuery, GetDigitalOceanKubernetesClustersQueryVariables>(GetDigitalOceanKubernetesClustersDocument, options);
        }
export type GetDigitalOceanKubernetesClustersQueryHookResult = ReturnType<typeof useGetDigitalOceanKubernetesClustersQuery>;
export type GetDigitalOceanKubernetesClustersLazyQueryHookResult = ReturnType<typeof useGetDigitalOceanKubernetesClustersLazyQuery>;
export type GetDigitalOceanKubernetesClustersSuspenseQueryHookResult = ReturnType<typeof useGetDigitalOceanKubernetesClustersSuspenseQuery>;
export type GetDigitalOceanKubernetesClustersQueryResult = Apollo.QueryResult<GetDigitalOceanKubernetesClustersQuery, GetDigitalOceanKubernetesClustersQueryVariables>;
export const GetGoogleKubernetesClustersDocument = gql`
    query GetGoogleKubernetesClusters($id: String!, $projectId: String!) {
  GoogleKubernetesClusters(id: $id, projectId: $projectId) {
    Id
    Name
  }
}
    `;

/**
 * __useGetGoogleKubernetesClustersQuery__
 *
 * To run a query within a React component, call `useGetGoogleKubernetesClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleKubernetesClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleKubernetesClustersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetGoogleKubernetesClustersQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleKubernetesClustersQuery, GetGoogleKubernetesClustersQueryVariables> & ({ variables: GetGoogleKubernetesClustersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleKubernetesClustersQuery, GetGoogleKubernetesClustersQueryVariables>(GetGoogleKubernetesClustersDocument, options);
      }
export function useGetGoogleKubernetesClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleKubernetesClustersQuery, GetGoogleKubernetesClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleKubernetesClustersQuery, GetGoogleKubernetesClustersQueryVariables>(GetGoogleKubernetesClustersDocument, options);
        }
export function useGetGoogleKubernetesClustersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGoogleKubernetesClustersQuery, GetGoogleKubernetesClustersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGoogleKubernetesClustersQuery, GetGoogleKubernetesClustersQueryVariables>(GetGoogleKubernetesClustersDocument, options);
        }
export type GetGoogleKubernetesClustersQueryHookResult = ReturnType<typeof useGetGoogleKubernetesClustersQuery>;
export type GetGoogleKubernetesClustersLazyQueryHookResult = ReturnType<typeof useGetGoogleKubernetesClustersLazyQuery>;
export type GetGoogleKubernetesClustersSuspenseQueryHookResult = ReturnType<typeof useGetGoogleKubernetesClustersSuspenseQuery>;
export type GetGoogleKubernetesClustersQueryResult = Apollo.QueryResult<GetGoogleKubernetesClustersQuery, GetGoogleKubernetesClustersQueryVariables>;
export const GetGoogleProjectsDocument = gql`
    query GetGoogleProjects($id: String!) {
  GoogleProjects(id: $id) {
    Id
    Name
  }
}
    `;

/**
 * __useGetGoogleProjectsQuery__
 *
 * To run a query within a React component, call `useGetGoogleProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGoogleProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleProjectsQuery, GetGoogleProjectsQueryVariables> & ({ variables: GetGoogleProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleProjectsQuery, GetGoogleProjectsQueryVariables>(GetGoogleProjectsDocument, options);
      }
export function useGetGoogleProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleProjectsQuery, GetGoogleProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleProjectsQuery, GetGoogleProjectsQueryVariables>(GetGoogleProjectsDocument, options);
        }
export function useGetGoogleProjectsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGoogleProjectsQuery, GetGoogleProjectsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGoogleProjectsQuery, GetGoogleProjectsQueryVariables>(GetGoogleProjectsDocument, options);
        }
export type GetGoogleProjectsQueryHookResult = ReturnType<typeof useGetGoogleProjectsQuery>;
export type GetGoogleProjectsLazyQueryHookResult = ReturnType<typeof useGetGoogleProjectsLazyQuery>;
export type GetGoogleProjectsSuspenseQueryHookResult = ReturnType<typeof useGetGoogleProjectsSuspenseQuery>;
export type GetGoogleProjectsQueryResult = Apollo.QueryResult<GetGoogleProjectsQuery, GetGoogleProjectsQueryVariables>;
export const GetClusterDocument = gql`
    query GetCluster($id: String!) {
  Cluster(id: $id) {
    Id
    Name
    Type
    DigitalOcean {
      Id
      Name
    }
    Google {
      Id
      Name
    }
    IntegrationClusterId
    IntegrationClusterName
    IntegrationClusterData {
      Google {
        ProjectId
      }
    }
  }
}
    `;

/**
 * __useGetClusterQuery__
 *
 * To run a query within a React component, call `useGetClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClusterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClusterQuery(baseOptions: Apollo.QueryHookOptions<GetClusterQuery, GetClusterQueryVariables> & ({ variables: GetClusterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
      }
export function useGetClusterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClusterQuery, GetClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
        }
export function useGetClusterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClusterQuery, GetClusterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
        }
export type GetClusterQueryHookResult = ReturnType<typeof useGetClusterQuery>;
export type GetClusterLazyQueryHookResult = ReturnType<typeof useGetClusterLazyQuery>;
export type GetClusterSuspenseQueryHookResult = ReturnType<typeof useGetClusterSuspenseQuery>;
export type GetClusterQueryResult = Apollo.QueryResult<GetClusterQuery, GetClusterQueryVariables>;
export const UpdateClusterDocument = gql`
    mutation UpdateCluster($id: String!, $name: String!) {
  UpdateCluster(request: {Id: $id, Name: $name}) {
    Id
    Name
  }
}
    `;
export type UpdateClusterMutationFn = Apollo.MutationFunction<UpdateClusterMutation, UpdateClusterMutationVariables>;

/**
 * __useUpdateClusterMutation__
 *
 * To run a mutation, you first call `useUpdateClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClusterMutation, { data, loading, error }] = useUpdateClusterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateClusterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClusterMutation, UpdateClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClusterMutation, UpdateClusterMutationVariables>(UpdateClusterDocument, options);
      }
export type UpdateClusterMutationHookResult = ReturnType<typeof useUpdateClusterMutation>;
export type UpdateClusterMutationResult = Apollo.MutationResult<UpdateClusterMutation>;
export type UpdateClusterMutationOptions = Apollo.BaseMutationOptions<UpdateClusterMutation, UpdateClusterMutationVariables>;
export const CreateQuickContainerDocument = gql`
    mutation CreateQuickContainer($name: String!, $domainId: String, $clusterId: String, $size: Int, $instances: Int, $onlyCreate: Boolean, $containers: [CreateContainerRequest!]!) {
  CreateQuickContainer(
    request: {Name: $name, DomainId: $domainId, ClusterId: $clusterId, Size: $size, Instances: $instances, Containers: $containers, OnlyCreate: $onlyCreate}
  ) {
    Id
    Name
    Status
  }
}
    `;
export type CreateQuickContainerMutationFn = Apollo.MutationFunction<CreateQuickContainerMutation, CreateQuickContainerMutationVariables>;

/**
 * __useCreateQuickContainerMutation__
 *
 * To run a mutation, you first call `useCreateQuickContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickContainerMutation, { data, loading, error }] = useCreateQuickContainerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      domainId: // value for 'domainId'
 *      clusterId: // value for 'clusterId'
 *      size: // value for 'size'
 *      instances: // value for 'instances'
 *      onlyCreate: // value for 'onlyCreate'
 *      containers: // value for 'containers'
 *   },
 * });
 */
export function useCreateQuickContainerMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuickContainerMutation, CreateQuickContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuickContainerMutation, CreateQuickContainerMutationVariables>(CreateQuickContainerDocument, options);
      }
export type CreateQuickContainerMutationHookResult = ReturnType<typeof useCreateQuickContainerMutation>;
export type CreateQuickContainerMutationResult = Apollo.MutationResult<CreateQuickContainerMutation>;
export type CreateQuickContainerMutationOptions = Apollo.BaseMutationOptions<CreateQuickContainerMutation, CreateQuickContainerMutationVariables>;
export const DeleteQuickContainerDocument = gql`
    mutation DeleteQuickContainer($id: String!) {
  DeleteQuickContainer(request: {Id: $id}) {
    Id
    Name
    Type
    Status
  }
}
    `;
export type DeleteQuickContainerMutationFn = Apollo.MutationFunction<DeleteQuickContainerMutation, DeleteQuickContainerMutationVariables>;

/**
 * __useDeleteQuickContainerMutation__
 *
 * To run a mutation, you first call `useDeleteQuickContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuickContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuickContainerMutation, { data, loading, error }] = useDeleteQuickContainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuickContainerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuickContainerMutation, DeleteQuickContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuickContainerMutation, DeleteQuickContainerMutationVariables>(DeleteQuickContainerDocument, options);
      }
export type DeleteQuickContainerMutationHookResult = ReturnType<typeof useDeleteQuickContainerMutation>;
export type DeleteQuickContainerMutationResult = Apollo.MutationResult<DeleteQuickContainerMutation>;
export type DeleteQuickContainerMutationOptions = Apollo.BaseMutationOptions<DeleteQuickContainerMutation, DeleteQuickContainerMutationVariables>;
export const GetQuickContainerDocument = gql`
    query GetQuickContainer($id: String!) {
  QuickContainer(id: $id) {
    Id
    Name
    Status
    Containers {
      RegistryImageId
      Tag
      Ports
      Volumes
      ContainerResource {
        LimitsMemory
        LimitsCPU
        RequestsMemory
        RequestsCPU
      }
      EnvironmentVariableId
    }
    Domain {
      Id
      Name
      Domains
    }
    Cluster {
      Id
      Name
    }
    Size
    Instances
  }
}
    `;

/**
 * __useGetQuickContainerQuery__
 *
 * To run a query within a React component, call `useGetQuickContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickContainerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuickContainerQuery(baseOptions: Apollo.QueryHookOptions<GetQuickContainerQuery, GetQuickContainerQueryVariables> & ({ variables: GetQuickContainerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickContainerQuery, GetQuickContainerQueryVariables>(GetQuickContainerDocument, options);
      }
export function useGetQuickContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickContainerQuery, GetQuickContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickContainerQuery, GetQuickContainerQueryVariables>(GetQuickContainerDocument, options);
        }
export function useGetQuickContainerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuickContainerQuery, GetQuickContainerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuickContainerQuery, GetQuickContainerQueryVariables>(GetQuickContainerDocument, options);
        }
export type GetQuickContainerQueryHookResult = ReturnType<typeof useGetQuickContainerQuery>;
export type GetQuickContainerLazyQueryHookResult = ReturnType<typeof useGetQuickContainerLazyQuery>;
export type GetQuickContainerSuspenseQueryHookResult = ReturnType<typeof useGetQuickContainerSuspenseQuery>;
export type GetQuickContainerQueryResult = Apollo.QueryResult<GetQuickContainerQuery, GetQuickContainerQueryVariables>;
export const GetQuickContainerIncomingConnectionsDocument = gql`
    query GetQuickContainerIncomingConnections($id: String!) {
  QuickContainerIncomingConnections(id: $id) {
    QuickContainer {
      Id
      Name
    }
    EnvironmentVariable
  }
}
    `;

/**
 * __useGetQuickContainerIncomingConnectionsQuery__
 *
 * To run a query within a React component, call `useGetQuickContainerIncomingConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickContainerIncomingConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickContainerIncomingConnectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuickContainerIncomingConnectionsQuery(baseOptions: Apollo.QueryHookOptions<GetQuickContainerIncomingConnectionsQuery, GetQuickContainerIncomingConnectionsQueryVariables> & ({ variables: GetQuickContainerIncomingConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickContainerIncomingConnectionsQuery, GetQuickContainerIncomingConnectionsQueryVariables>(GetQuickContainerIncomingConnectionsDocument, options);
      }
export function useGetQuickContainerIncomingConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickContainerIncomingConnectionsQuery, GetQuickContainerIncomingConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickContainerIncomingConnectionsQuery, GetQuickContainerIncomingConnectionsQueryVariables>(GetQuickContainerIncomingConnectionsDocument, options);
        }
export function useGetQuickContainerIncomingConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuickContainerIncomingConnectionsQuery, GetQuickContainerIncomingConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuickContainerIncomingConnectionsQuery, GetQuickContainerIncomingConnectionsQueryVariables>(GetQuickContainerIncomingConnectionsDocument, options);
        }
export type GetQuickContainerIncomingConnectionsQueryHookResult = ReturnType<typeof useGetQuickContainerIncomingConnectionsQuery>;
export type GetQuickContainerIncomingConnectionsLazyQueryHookResult = ReturnType<typeof useGetQuickContainerIncomingConnectionsLazyQuery>;
export type GetQuickContainerIncomingConnectionsSuspenseQueryHookResult = ReturnType<typeof useGetQuickContainerIncomingConnectionsSuspenseQuery>;
export type GetQuickContainerIncomingConnectionsQueryResult = Apollo.QueryResult<GetQuickContainerIncomingConnectionsQuery, GetQuickContainerIncomingConnectionsQueryVariables>;
export const GetQuickContainerInstanceLogsDocument = gql`
    query GetQuickContainerInstanceLogs($id: String!, $instanceName: String!) {
  QuickContainerLogs(id: $id, instanceName: $instanceName) {
    Name
    Logs
  }
}
    `;

/**
 * __useGetQuickContainerInstanceLogsQuery__
 *
 * To run a query within a React component, call `useGetQuickContainerInstanceLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickContainerInstanceLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickContainerInstanceLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      instanceName: // value for 'instanceName'
 *   },
 * });
 */
export function useGetQuickContainerInstanceLogsQuery(baseOptions: Apollo.QueryHookOptions<GetQuickContainerInstanceLogsQuery, GetQuickContainerInstanceLogsQueryVariables> & ({ variables: GetQuickContainerInstanceLogsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickContainerInstanceLogsQuery, GetQuickContainerInstanceLogsQueryVariables>(GetQuickContainerInstanceLogsDocument, options);
      }
export function useGetQuickContainerInstanceLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickContainerInstanceLogsQuery, GetQuickContainerInstanceLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickContainerInstanceLogsQuery, GetQuickContainerInstanceLogsQueryVariables>(GetQuickContainerInstanceLogsDocument, options);
        }
export function useGetQuickContainerInstanceLogsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuickContainerInstanceLogsQuery, GetQuickContainerInstanceLogsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuickContainerInstanceLogsQuery, GetQuickContainerInstanceLogsQueryVariables>(GetQuickContainerInstanceLogsDocument, options);
        }
export type GetQuickContainerInstanceLogsQueryHookResult = ReturnType<typeof useGetQuickContainerInstanceLogsQuery>;
export type GetQuickContainerInstanceLogsLazyQueryHookResult = ReturnType<typeof useGetQuickContainerInstanceLogsLazyQuery>;
export type GetQuickContainerInstanceLogsSuspenseQueryHookResult = ReturnType<typeof useGetQuickContainerInstanceLogsSuspenseQuery>;
export type GetQuickContainerInstanceLogsQueryResult = Apollo.QueryResult<GetQuickContainerInstanceLogsQuery, GetQuickContainerInstanceLogsQueryVariables>;
export const GetQuickContainerInstancesDocument = gql`
    query GetQuickContainerInstances($id: String!) {
  QuickContainerInstances(id: $id)
}
    `;

/**
 * __useGetQuickContainerInstancesQuery__
 *
 * To run a query within a React component, call `useGetQuickContainerInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickContainerInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickContainerInstancesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuickContainerInstancesQuery(baseOptions: Apollo.QueryHookOptions<GetQuickContainerInstancesQuery, GetQuickContainerInstancesQueryVariables> & ({ variables: GetQuickContainerInstancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickContainerInstancesQuery, GetQuickContainerInstancesQueryVariables>(GetQuickContainerInstancesDocument, options);
      }
export function useGetQuickContainerInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickContainerInstancesQuery, GetQuickContainerInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickContainerInstancesQuery, GetQuickContainerInstancesQueryVariables>(GetQuickContainerInstancesDocument, options);
        }
export function useGetQuickContainerInstancesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuickContainerInstancesQuery, GetQuickContainerInstancesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuickContainerInstancesQuery, GetQuickContainerInstancesQueryVariables>(GetQuickContainerInstancesDocument, options);
        }
export type GetQuickContainerInstancesQueryHookResult = ReturnType<typeof useGetQuickContainerInstancesQuery>;
export type GetQuickContainerInstancesLazyQueryHookResult = ReturnType<typeof useGetQuickContainerInstancesLazyQuery>;
export type GetQuickContainerInstancesSuspenseQueryHookResult = ReturnType<typeof useGetQuickContainerInstancesSuspenseQuery>;
export type GetQuickContainerInstancesQueryResult = Apollo.QueryResult<GetQuickContainerInstancesQuery, GetQuickContainerInstancesQueryVariables>;
export const GetQuickContainerStatusDocument = gql`
    subscription GetQuickContainerStatus($ids: [String!]!) {
  ListenQuickContainerStatus(request: {Ids: $ids}) {
    Id
    Status
  }
}
    `;

/**
 * __useGetQuickContainerStatusSubscription__
 *
 * To run a query within a React component, call `useGetQuickContainerStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickContainerStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickContainerStatusSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetQuickContainerStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetQuickContainerStatusSubscription, GetQuickContainerStatusSubscriptionVariables> & ({ variables: GetQuickContainerStatusSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetQuickContainerStatusSubscription, GetQuickContainerStatusSubscriptionVariables>(GetQuickContainerStatusDocument, options);
      }
export type GetQuickContainerStatusSubscriptionHookResult = ReturnType<typeof useGetQuickContainerStatusSubscription>;
export type GetQuickContainerStatusSubscriptionResult = Apollo.SubscriptionResult<GetQuickContainerStatusSubscription>;
export const GetQuickContainersDocument = gql`
    query GetQuickContainers {
  QuickContainer {
    Id
    Name
    Status
    Containers {
      RegistryImageId
      Tag
      Ports
      Volumes
      ContainerResource {
        LimitsMemory
        LimitsCPU
        RequestsMemory
        RequestsCPU
      }
      EnvironmentVariableId
    }
    Domain {
      Id
      Name
      Domains
    }
    Cluster {
      Id
      Name
    }
    Size
    Instances
  }
}
    `;

/**
 * __useGetQuickContainersQuery__
 *
 * To run a query within a React component, call `useGetQuickContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickContainersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuickContainersQuery(baseOptions?: Apollo.QueryHookOptions<GetQuickContainersQuery, GetQuickContainersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickContainersQuery, GetQuickContainersQueryVariables>(GetQuickContainersDocument, options);
      }
export function useGetQuickContainersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickContainersQuery, GetQuickContainersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickContainersQuery, GetQuickContainersQueryVariables>(GetQuickContainersDocument, options);
        }
export function useGetQuickContainersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuickContainersQuery, GetQuickContainersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuickContainersQuery, GetQuickContainersQueryVariables>(GetQuickContainersDocument, options);
        }
export type GetQuickContainersQueryHookResult = ReturnType<typeof useGetQuickContainersQuery>;
export type GetQuickContainersLazyQueryHookResult = ReturnType<typeof useGetQuickContainersLazyQuery>;
export type GetQuickContainersSuspenseQueryHookResult = ReturnType<typeof useGetQuickContainersSuspenseQuery>;
export type GetQuickContainersQueryResult = Apollo.QueryResult<GetQuickContainersQuery, GetQuickContainersQueryVariables>;
export const RestartQuickContainerDocument = gql`
    mutation RestartQuickContainer($id: String!) {
  RestartQuickContainer(request: {Id: $id}) {
    Id
    Name
    Type
    Status
  }
}
    `;
export type RestartQuickContainerMutationFn = Apollo.MutationFunction<RestartQuickContainerMutation, RestartQuickContainerMutationVariables>;

/**
 * __useRestartQuickContainerMutation__
 *
 * To run a mutation, you first call `useRestartQuickContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartQuickContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartQuickContainerMutation, { data, loading, error }] = useRestartQuickContainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestartQuickContainerMutation(baseOptions?: Apollo.MutationHookOptions<RestartQuickContainerMutation, RestartQuickContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartQuickContainerMutation, RestartQuickContainerMutationVariables>(RestartQuickContainerDocument, options);
      }
export type RestartQuickContainerMutationHookResult = ReturnType<typeof useRestartQuickContainerMutation>;
export type RestartQuickContainerMutationResult = Apollo.MutationResult<RestartQuickContainerMutation>;
export type RestartQuickContainerMutationOptions = Apollo.BaseMutationOptions<RestartQuickContainerMutation, RestartQuickContainerMutationVariables>;
export const StartQuickContainerDocument = gql`
    mutation StartQuickContainer($id: String!) {
  StartQuickContainer(request: {Id: $id}) {
    Id
    Name
    Type
    Status
  }
}
    `;
export type StartQuickContainerMutationFn = Apollo.MutationFunction<StartQuickContainerMutation, StartQuickContainerMutationVariables>;

/**
 * __useStartQuickContainerMutation__
 *
 * To run a mutation, you first call `useStartQuickContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartQuickContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startQuickContainerMutation, { data, loading, error }] = useStartQuickContainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartQuickContainerMutation(baseOptions?: Apollo.MutationHookOptions<StartQuickContainerMutation, StartQuickContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartQuickContainerMutation, StartQuickContainerMutationVariables>(StartQuickContainerDocument, options);
      }
export type StartQuickContainerMutationHookResult = ReturnType<typeof useStartQuickContainerMutation>;
export type StartQuickContainerMutationResult = Apollo.MutationResult<StartQuickContainerMutation>;
export type StartQuickContainerMutationOptions = Apollo.BaseMutationOptions<StartQuickContainerMutation, StartQuickContainerMutationVariables>;
export const StopQuickContainerDocument = gql`
    mutation StopQuickContainer($id: String!) {
  StopQuickContainer(request: {Id: $id}) {
    Id
    Name
    Type
    Status
  }
}
    `;
export type StopQuickContainerMutationFn = Apollo.MutationFunction<StopQuickContainerMutation, StopQuickContainerMutationVariables>;

/**
 * __useStopQuickContainerMutation__
 *
 * To run a mutation, you first call `useStopQuickContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopQuickContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopQuickContainerMutation, { data, loading, error }] = useStopQuickContainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStopQuickContainerMutation(baseOptions?: Apollo.MutationHookOptions<StopQuickContainerMutation, StopQuickContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopQuickContainerMutation, StopQuickContainerMutationVariables>(StopQuickContainerDocument, options);
      }
export type StopQuickContainerMutationHookResult = ReturnType<typeof useStopQuickContainerMutation>;
export type StopQuickContainerMutationResult = Apollo.MutationResult<StopQuickContainerMutation>;
export type StopQuickContainerMutationOptions = Apollo.BaseMutationOptions<StopQuickContainerMutation, StopQuickContainerMutationVariables>;
export const GetQuickContainerMetricsDocument = gql`
    subscription GetQuickContainerMetrics($id: String!, $instanceNames: [String!]!) {
  ListenQuickContainerMetrics(request: {Id: $id, InstanceNames: $instanceNames}) {
    InstanceName
    Name
    CPU
    RAM
    Time
    Restarts
    ReasonForRestart
    ReasonForWaiting
  }
}
    `;

/**
 * __useGetQuickContainerMetricsSubscription__
 *
 * To run a query within a React component, call `useGetQuickContainerMetricsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickContainerMetricsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickContainerMetricsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      instanceNames: // value for 'instanceNames'
 *   },
 * });
 */
export function useGetQuickContainerMetricsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetQuickContainerMetricsSubscription, GetQuickContainerMetricsSubscriptionVariables> & ({ variables: GetQuickContainerMetricsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetQuickContainerMetricsSubscription, GetQuickContainerMetricsSubscriptionVariables>(GetQuickContainerMetricsDocument, options);
      }
export type GetQuickContainerMetricsSubscriptionHookResult = ReturnType<typeof useGetQuickContainerMetricsSubscription>;
export type GetQuickContainerMetricsSubscriptionResult = Apollo.SubscriptionResult<GetQuickContainerMetricsSubscription>;
export const UpdateQuickContainerIncomingConnectionsDocument = gql`
    mutation UpdateQuickContainerIncomingConnections($id: String!, $connections: [UpdateQuickContainerIncomingConnection!]!) {
  UpdateQuickContainerIncomingConnections(
    request: {Id: $id, Connections: $connections}
  ) {
    QuickContainer {
      Id
      Name
    }
    EnvironmentVariable
  }
}
    `;
export type UpdateQuickContainerIncomingConnectionsMutationFn = Apollo.MutationFunction<UpdateQuickContainerIncomingConnectionsMutation, UpdateQuickContainerIncomingConnectionsMutationVariables>;

/**
 * __useUpdateQuickContainerIncomingConnectionsMutation__
 *
 * To run a mutation, you first call `useUpdateQuickContainerIncomingConnectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickContainerIncomingConnectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickContainerIncomingConnectionsMutation, { data, loading, error }] = useUpdateQuickContainerIncomingConnectionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      connections: // value for 'connections'
 *   },
 * });
 */
export function useUpdateQuickContainerIncomingConnectionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuickContainerIncomingConnectionsMutation, UpdateQuickContainerIncomingConnectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuickContainerIncomingConnectionsMutation, UpdateQuickContainerIncomingConnectionsMutationVariables>(UpdateQuickContainerIncomingConnectionsDocument, options);
      }
export type UpdateQuickContainerIncomingConnectionsMutationHookResult = ReturnType<typeof useUpdateQuickContainerIncomingConnectionsMutation>;
export type UpdateQuickContainerIncomingConnectionsMutationResult = Apollo.MutationResult<UpdateQuickContainerIncomingConnectionsMutation>;
export type UpdateQuickContainerIncomingConnectionsMutationOptions = Apollo.BaseMutationOptions<UpdateQuickContainerIncomingConnectionsMutation, UpdateQuickContainerIncomingConnectionsMutationVariables>;
export const UpdateQuickContainerDocument = gql`
    mutation UpdateQuickContainer($id: String!, $name: String!, $domainId: String, $clusterId: String, $size: Int, $instances: Int, $containers: [CreateContainerRequest!]!) {
  UpdateQuickContainer(
    request: {Id: $id, Name: $name, DomainId: $domainId, ClusterId: $clusterId, Size: $size, Instances: $instances, Containers: $containers}
  ) {
    Id
    Name
    Status
  }
}
    `;
export type UpdateQuickContainerMutationFn = Apollo.MutationFunction<UpdateQuickContainerMutation, UpdateQuickContainerMutationVariables>;

/**
 * __useUpdateQuickContainerMutation__
 *
 * To run a mutation, you first call `useUpdateQuickContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickContainerMutation, { data, loading, error }] = useUpdateQuickContainerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      domainId: // value for 'domainId'
 *      clusterId: // value for 'clusterId'
 *      size: // value for 'size'
 *      instances: // value for 'instances'
 *      containers: // value for 'containers'
 *   },
 * });
 */
export function useUpdateQuickContainerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuickContainerMutation, UpdateQuickContainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuickContainerMutation, UpdateQuickContainerMutationVariables>(UpdateQuickContainerDocument, options);
      }
export type UpdateQuickContainerMutationHookResult = ReturnType<typeof useUpdateQuickContainerMutation>;
export type UpdateQuickContainerMutationResult = Apollo.MutationResult<UpdateQuickContainerMutation>;
export type UpdateQuickContainerMutationOptions = Apollo.BaseMutationOptions<UpdateQuickContainerMutation, UpdateQuickContainerMutationVariables>;
export const GetOAuthUrlDocument = gql`
    mutation GetOAuthUrl($id: String, $name: String!, $type: String!, $scopes: [String!]!) {
  GetOAuthUrl(request: {Id: $id, Type: $type, Name: $name, Scopes: $scopes}) {
    Url
  }
}
    `;
export type GetOAuthUrlMutationFn = Apollo.MutationFunction<GetOAuthUrlMutation, GetOAuthUrlMutationVariables>;

/**
 * __useGetOAuthUrlMutation__
 *
 * To run a mutation, you first call `useGetOAuthUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOAuthUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOAuthUrlMutation, { data, loading, error }] = useGetOAuthUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      scopes: // value for 'scopes'
 *   },
 * });
 */
export function useGetOAuthUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetOAuthUrlMutation, GetOAuthUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetOAuthUrlMutation, GetOAuthUrlMutationVariables>(GetOAuthUrlDocument, options);
      }
export type GetOAuthUrlMutationHookResult = ReturnType<typeof useGetOAuthUrlMutation>;
export type GetOAuthUrlMutationResult = Apollo.MutationResult<GetOAuthUrlMutation>;
export type GetOAuthUrlMutationOptions = Apollo.BaseMutationOptions<GetOAuthUrlMutation, GetOAuthUrlMutationVariables>;
export const DeleteDigitalOceanIntegrationDocument = gql`
    mutation DeleteDigitalOceanIntegration($id: String!) {
  DeleteDigitalOceanIntegration(request: {Id: $id}) {
    Id
  }
}
    `;
export type DeleteDigitalOceanIntegrationMutationFn = Apollo.MutationFunction<DeleteDigitalOceanIntegrationMutation, DeleteDigitalOceanIntegrationMutationVariables>;

/**
 * __useDeleteDigitalOceanIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalOceanIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalOceanIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalOceanIntegrationMutation, { data, loading, error }] = useDeleteDigitalOceanIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDigitalOceanIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDigitalOceanIntegrationMutation, DeleteDigitalOceanIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDigitalOceanIntegrationMutation, DeleteDigitalOceanIntegrationMutationVariables>(DeleteDigitalOceanIntegrationDocument, options);
      }
export type DeleteDigitalOceanIntegrationMutationHookResult = ReturnType<typeof useDeleteDigitalOceanIntegrationMutation>;
export type DeleteDigitalOceanIntegrationMutationResult = Apollo.MutationResult<DeleteDigitalOceanIntegrationMutation>;
export type DeleteDigitalOceanIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteDigitalOceanIntegrationMutation, DeleteDigitalOceanIntegrationMutationVariables>;
export const GetDigitalOceanIntegrationsDocument = gql`
    query GetDigitalOceanIntegrations {
  DigitalOceanIntegration {
    Id
    Name
    Expiry
    Scopes
  }
}
    `;

/**
 * __useGetDigitalOceanIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetDigitalOceanIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalOceanIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalOceanIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDigitalOceanIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetDigitalOceanIntegrationsQuery, GetDigitalOceanIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDigitalOceanIntegrationsQuery, GetDigitalOceanIntegrationsQueryVariables>(GetDigitalOceanIntegrationsDocument, options);
      }
export function useGetDigitalOceanIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDigitalOceanIntegrationsQuery, GetDigitalOceanIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDigitalOceanIntegrationsQuery, GetDigitalOceanIntegrationsQueryVariables>(GetDigitalOceanIntegrationsDocument, options);
        }
export function useGetDigitalOceanIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDigitalOceanIntegrationsQuery, GetDigitalOceanIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDigitalOceanIntegrationsQuery, GetDigitalOceanIntegrationsQueryVariables>(GetDigitalOceanIntegrationsDocument, options);
        }
export type GetDigitalOceanIntegrationsQueryHookResult = ReturnType<typeof useGetDigitalOceanIntegrationsQuery>;
export type GetDigitalOceanIntegrationsLazyQueryHookResult = ReturnType<typeof useGetDigitalOceanIntegrationsLazyQuery>;
export type GetDigitalOceanIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetDigitalOceanIntegrationsSuspenseQuery>;
export type GetDigitalOceanIntegrationsQueryResult = Apollo.QueryResult<GetDigitalOceanIntegrationsQuery, GetDigitalOceanIntegrationsQueryVariables>;
export const GetDigitalOceanIntegrationDocument = gql`
    query GetDigitalOceanIntegration($id: String!) {
  DigitalOceanIntegration(id: $id) {
    Id
    Name
    Expiry
    Scopes
  }
}
    `;

/**
 * __useGetDigitalOceanIntegrationQuery__
 *
 * To run a query within a React component, call `useGetDigitalOceanIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalOceanIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalOceanIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDigitalOceanIntegrationQuery(baseOptions: Apollo.QueryHookOptions<GetDigitalOceanIntegrationQuery, GetDigitalOceanIntegrationQueryVariables> & ({ variables: GetDigitalOceanIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDigitalOceanIntegrationQuery, GetDigitalOceanIntegrationQueryVariables>(GetDigitalOceanIntegrationDocument, options);
      }
export function useGetDigitalOceanIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDigitalOceanIntegrationQuery, GetDigitalOceanIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDigitalOceanIntegrationQuery, GetDigitalOceanIntegrationQueryVariables>(GetDigitalOceanIntegrationDocument, options);
        }
export function useGetDigitalOceanIntegrationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDigitalOceanIntegrationQuery, GetDigitalOceanIntegrationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDigitalOceanIntegrationQuery, GetDigitalOceanIntegrationQueryVariables>(GetDigitalOceanIntegrationDocument, options);
        }
export type GetDigitalOceanIntegrationQueryHookResult = ReturnType<typeof useGetDigitalOceanIntegrationQuery>;
export type GetDigitalOceanIntegrationLazyQueryHookResult = ReturnType<typeof useGetDigitalOceanIntegrationLazyQuery>;
export type GetDigitalOceanIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetDigitalOceanIntegrationSuspenseQuery>;
export type GetDigitalOceanIntegrationQueryResult = Apollo.QueryResult<GetDigitalOceanIntegrationQuery, GetDigitalOceanIntegrationQueryVariables>;
export const CreateDockerIoRegistryIntegrationDocument = gql`
    mutation CreateDockerIORegistryIntegration($name: String!, $username: String!, $password: String!) {
  CreateDockerIORegistryIntegration(
    request: {Name: $name, Username: $username, Password: $password}
  ) {
    Id
    Name
    Username
    Password
  }
}
    `;
export type CreateDockerIoRegistryIntegrationMutationFn = Apollo.MutationFunction<CreateDockerIoRegistryIntegrationMutation, CreateDockerIoRegistryIntegrationMutationVariables>;

/**
 * __useCreateDockerIoRegistryIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateDockerIoRegistryIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDockerIoRegistryIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDockerIoRegistryIntegrationMutation, { data, loading, error }] = useCreateDockerIoRegistryIntegrationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateDockerIoRegistryIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDockerIoRegistryIntegrationMutation, CreateDockerIoRegistryIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDockerIoRegistryIntegrationMutation, CreateDockerIoRegistryIntegrationMutationVariables>(CreateDockerIoRegistryIntegrationDocument, options);
      }
export type CreateDockerIoRegistryIntegrationMutationHookResult = ReturnType<typeof useCreateDockerIoRegistryIntegrationMutation>;
export type CreateDockerIoRegistryIntegrationMutationResult = Apollo.MutationResult<CreateDockerIoRegistryIntegrationMutation>;
export type CreateDockerIoRegistryIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateDockerIoRegistryIntegrationMutation, CreateDockerIoRegistryIntegrationMutationVariables>;
export const DeleteDockerIoRegistryIntegrationDocument = gql`
    mutation DeleteDockerIORegistryIntegration($id: String!) {
  DeleteDockerIORegistryIntegration(request: {Id: $id}) {
    Id
    Name
    Username
    Password
  }
}
    `;
export type DeleteDockerIoRegistryIntegrationMutationFn = Apollo.MutationFunction<DeleteDockerIoRegistryIntegrationMutation, DeleteDockerIoRegistryIntegrationMutationVariables>;

/**
 * __useDeleteDockerIoRegistryIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteDockerIoRegistryIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDockerIoRegistryIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDockerIoRegistryIntegrationMutation, { data, loading, error }] = useDeleteDockerIoRegistryIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDockerIoRegistryIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDockerIoRegistryIntegrationMutation, DeleteDockerIoRegistryIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDockerIoRegistryIntegrationMutation, DeleteDockerIoRegistryIntegrationMutationVariables>(DeleteDockerIoRegistryIntegrationDocument, options);
      }
export type DeleteDockerIoRegistryIntegrationMutationHookResult = ReturnType<typeof useDeleteDockerIoRegistryIntegrationMutation>;
export type DeleteDockerIoRegistryIntegrationMutationResult = Apollo.MutationResult<DeleteDockerIoRegistryIntegrationMutation>;
export type DeleteDockerIoRegistryIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteDockerIoRegistryIntegrationMutation, DeleteDockerIoRegistryIntegrationMutationVariables>;
export const GetAllDockerIoRegistryDocument = gql`
    query GetAllDockerIORegistry {
  DockerIORegistryIntegration {
    Id
    Name
    Username
    Password
  }
}
    `;

/**
 * __useGetAllDockerIoRegistryQuery__
 *
 * To run a query within a React component, call `useGetAllDockerIoRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDockerIoRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDockerIoRegistryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDockerIoRegistryQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDockerIoRegistryQuery, GetAllDockerIoRegistryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDockerIoRegistryQuery, GetAllDockerIoRegistryQueryVariables>(GetAllDockerIoRegistryDocument, options);
      }
export function useGetAllDockerIoRegistryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDockerIoRegistryQuery, GetAllDockerIoRegistryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDockerIoRegistryQuery, GetAllDockerIoRegistryQueryVariables>(GetAllDockerIoRegistryDocument, options);
        }
export function useGetAllDockerIoRegistrySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllDockerIoRegistryQuery, GetAllDockerIoRegistryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllDockerIoRegistryQuery, GetAllDockerIoRegistryQueryVariables>(GetAllDockerIoRegistryDocument, options);
        }
export type GetAllDockerIoRegistryQueryHookResult = ReturnType<typeof useGetAllDockerIoRegistryQuery>;
export type GetAllDockerIoRegistryLazyQueryHookResult = ReturnType<typeof useGetAllDockerIoRegistryLazyQuery>;
export type GetAllDockerIoRegistrySuspenseQueryHookResult = ReturnType<typeof useGetAllDockerIoRegistrySuspenseQuery>;
export type GetAllDockerIoRegistryQueryResult = Apollo.QueryResult<GetAllDockerIoRegistryQuery, GetAllDockerIoRegistryQueryVariables>;
export const GetOneDockerIoRegistryDocument = gql`
    query GetOneDockerIORegistry($id: String!) {
  DockerIORegistryIntegration(id: $id) {
    Id
    Name
    Username
    Password
  }
}
    `;

/**
 * __useGetOneDockerIoRegistryQuery__
 *
 * To run a query within a React component, call `useGetOneDockerIoRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneDockerIoRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneDockerIoRegistryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneDockerIoRegistryQuery(baseOptions: Apollo.QueryHookOptions<GetOneDockerIoRegistryQuery, GetOneDockerIoRegistryQueryVariables> & ({ variables: GetOneDockerIoRegistryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneDockerIoRegistryQuery, GetOneDockerIoRegistryQueryVariables>(GetOneDockerIoRegistryDocument, options);
      }
export function useGetOneDockerIoRegistryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneDockerIoRegistryQuery, GetOneDockerIoRegistryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneDockerIoRegistryQuery, GetOneDockerIoRegistryQueryVariables>(GetOneDockerIoRegistryDocument, options);
        }
export function useGetOneDockerIoRegistrySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneDockerIoRegistryQuery, GetOneDockerIoRegistryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneDockerIoRegistryQuery, GetOneDockerIoRegistryQueryVariables>(GetOneDockerIoRegistryDocument, options);
        }
export type GetOneDockerIoRegistryQueryHookResult = ReturnType<typeof useGetOneDockerIoRegistryQuery>;
export type GetOneDockerIoRegistryLazyQueryHookResult = ReturnType<typeof useGetOneDockerIoRegistryLazyQuery>;
export type GetOneDockerIoRegistrySuspenseQueryHookResult = ReturnType<typeof useGetOneDockerIoRegistrySuspenseQuery>;
export type GetOneDockerIoRegistryQueryResult = Apollo.QueryResult<GetOneDockerIoRegistryQuery, GetOneDockerIoRegistryQueryVariables>;
export const UpdateDockerIoRegistryIntegrationDocument = gql`
    mutation UpdateDockerIORegistryIntegration($id: String!, $name: String!, $username: String!, $password: String!) {
  UpdateDockerIORegistryIntegration(
    request: {Id: $id, Name: $name, Username: $username, Password: $password}
  ) {
    Id
    Name
    Username
    Password
  }
}
    `;
export type UpdateDockerIoRegistryIntegrationMutationFn = Apollo.MutationFunction<UpdateDockerIoRegistryIntegrationMutation, UpdateDockerIoRegistryIntegrationMutationVariables>;

/**
 * __useUpdateDockerIoRegistryIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateDockerIoRegistryIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDockerIoRegistryIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDockerIoRegistryIntegrationMutation, { data, loading, error }] = useUpdateDockerIoRegistryIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateDockerIoRegistryIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDockerIoRegistryIntegrationMutation, UpdateDockerIoRegistryIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDockerIoRegistryIntegrationMutation, UpdateDockerIoRegistryIntegrationMutationVariables>(UpdateDockerIoRegistryIntegrationDocument, options);
      }
export type UpdateDockerIoRegistryIntegrationMutationHookResult = ReturnType<typeof useUpdateDockerIoRegistryIntegrationMutation>;
export type UpdateDockerIoRegistryIntegrationMutationResult = Apollo.MutationResult<UpdateDockerIoRegistryIntegrationMutation>;
export type UpdateDockerIoRegistryIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateDockerIoRegistryIntegrationMutation, UpdateDockerIoRegistryIntegrationMutationVariables>;
export const GetIntegrationsDocument = gql`
    query GetIntegrations {
  Integration {
    DockerIORegistryIntegration {
      Id
      Name
      Username
      Password
    }
    GitHubIntegration {
      Id
      Name
      Expiry
      Scopes
    }
    DigitalOceanIntegration {
      Id
      Name
      Expiry
      Scopes
    }
    GoogleIntegration {
      Id
      Name
      Expiry
      Scopes
    }
    MailIntegration {
      Id
      Name
      Email
      Password
      SmtpServer
      SmtpPort
    }
  }
}
    `;

/**
 * __useGetIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetIntegrationsQuery, GetIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationsQuery, GetIntegrationsQueryVariables>(GetIntegrationsDocument, options);
      }
export function useGetIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationsQuery, GetIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationsQuery, GetIntegrationsQueryVariables>(GetIntegrationsDocument, options);
        }
export function useGetIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetIntegrationsQuery, GetIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIntegrationsQuery, GetIntegrationsQueryVariables>(GetIntegrationsDocument, options);
        }
export type GetIntegrationsQueryHookResult = ReturnType<typeof useGetIntegrationsQuery>;
export type GetIntegrationsLazyQueryHookResult = ReturnType<typeof useGetIntegrationsLazyQuery>;
export type GetIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationsSuspenseQuery>;
export type GetIntegrationsQueryResult = Apollo.QueryResult<GetIntegrationsQuery, GetIntegrationsQueryVariables>;
export const DeleteGitHubIntegrationDocument = gql`
    mutation DeleteGitHubIntegration($id: String!) {
  DeleteGitHubIntegration(request: {Id: $id}) {
    Id
  }
}
    `;
export type DeleteGitHubIntegrationMutationFn = Apollo.MutationFunction<DeleteGitHubIntegrationMutation, DeleteGitHubIntegrationMutationVariables>;

/**
 * __useDeleteGitHubIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteGitHubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGitHubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGitHubIntegrationMutation, { data, loading, error }] = useDeleteGitHubIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGitHubIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGitHubIntegrationMutation, DeleteGitHubIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGitHubIntegrationMutation, DeleteGitHubIntegrationMutationVariables>(DeleteGitHubIntegrationDocument, options);
      }
export type DeleteGitHubIntegrationMutationHookResult = ReturnType<typeof useDeleteGitHubIntegrationMutation>;
export type DeleteGitHubIntegrationMutationResult = Apollo.MutationResult<DeleteGitHubIntegrationMutation>;
export type DeleteGitHubIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteGitHubIntegrationMutation, DeleteGitHubIntegrationMutationVariables>;
export const GetGitHubIntegrationsDocument = gql`
    query GetGitHubIntegrations {
  GitHubIntegration {
    Id
    Name
    Expiry
    Scopes
  }
}
    `;

/**
 * __useGetGitHubIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetGitHubIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGitHubIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGitHubIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGitHubIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetGitHubIntegrationsQuery, GetGitHubIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubIntegrationsQuery, GetGitHubIntegrationsQueryVariables>(GetGitHubIntegrationsDocument, options);
      }
export function useGetGitHubIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubIntegrationsQuery, GetGitHubIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubIntegrationsQuery, GetGitHubIntegrationsQueryVariables>(GetGitHubIntegrationsDocument, options);
        }
export function useGetGitHubIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGitHubIntegrationsQuery, GetGitHubIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubIntegrationsQuery, GetGitHubIntegrationsQueryVariables>(GetGitHubIntegrationsDocument, options);
        }
export type GetGitHubIntegrationsQueryHookResult = ReturnType<typeof useGetGitHubIntegrationsQuery>;
export type GetGitHubIntegrationsLazyQueryHookResult = ReturnType<typeof useGetGitHubIntegrationsLazyQuery>;
export type GetGitHubIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetGitHubIntegrationsSuspenseQuery>;
export type GetGitHubIntegrationsQueryResult = Apollo.QueryResult<GetGitHubIntegrationsQuery, GetGitHubIntegrationsQueryVariables>;
export const GetGitHubRepositoriesDocument = gql`
    query GetGitHubRepositories($id: String!) {
  GitHubRepository(id: $id) {
    Name
    Url
  }
}
    `;

/**
 * __useGetGitHubRepositoriesQuery__
 *
 * To run a query within a React component, call `useGetGitHubRepositoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGitHubRepositoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGitHubRepositoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGitHubRepositoriesQuery(baseOptions: Apollo.QueryHookOptions<GetGitHubRepositoriesQuery, GetGitHubRepositoriesQueryVariables> & ({ variables: GetGitHubRepositoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubRepositoriesQuery, GetGitHubRepositoriesQueryVariables>(GetGitHubRepositoriesDocument, options);
      }
export function useGetGitHubRepositoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubRepositoriesQuery, GetGitHubRepositoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubRepositoriesQuery, GetGitHubRepositoriesQueryVariables>(GetGitHubRepositoriesDocument, options);
        }
export function useGetGitHubRepositoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGitHubRepositoriesQuery, GetGitHubRepositoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubRepositoriesQuery, GetGitHubRepositoriesQueryVariables>(GetGitHubRepositoriesDocument, options);
        }
export type GetGitHubRepositoriesQueryHookResult = ReturnType<typeof useGetGitHubRepositoriesQuery>;
export type GetGitHubRepositoriesLazyQueryHookResult = ReturnType<typeof useGetGitHubRepositoriesLazyQuery>;
export type GetGitHubRepositoriesSuspenseQueryHookResult = ReturnType<typeof useGetGitHubRepositoriesSuspenseQuery>;
export type GetGitHubRepositoriesQueryResult = Apollo.QueryResult<GetGitHubRepositoriesQuery, GetGitHubRepositoriesQueryVariables>;
export const GetGitHubRepositoryBranchesDocument = gql`
    query GetGitHubRepositoryBranches($id: String!, $url: String!) {
  GitHubRepositoryBranch(id: $id, url: $url) {
    Name
  }
}
    `;

/**
 * __useGetGitHubRepositoryBranchesQuery__
 *
 * To run a query within a React component, call `useGetGitHubRepositoryBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGitHubRepositoryBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGitHubRepositoryBranchesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetGitHubRepositoryBranchesQuery(baseOptions: Apollo.QueryHookOptions<GetGitHubRepositoryBranchesQuery, GetGitHubRepositoryBranchesQueryVariables> & ({ variables: GetGitHubRepositoryBranchesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubRepositoryBranchesQuery, GetGitHubRepositoryBranchesQueryVariables>(GetGitHubRepositoryBranchesDocument, options);
      }
export function useGetGitHubRepositoryBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubRepositoryBranchesQuery, GetGitHubRepositoryBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubRepositoryBranchesQuery, GetGitHubRepositoryBranchesQueryVariables>(GetGitHubRepositoryBranchesDocument, options);
        }
export function useGetGitHubRepositoryBranchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGitHubRepositoryBranchesQuery, GetGitHubRepositoryBranchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubRepositoryBranchesQuery, GetGitHubRepositoryBranchesQueryVariables>(GetGitHubRepositoryBranchesDocument, options);
        }
export type GetGitHubRepositoryBranchesQueryHookResult = ReturnType<typeof useGetGitHubRepositoryBranchesQuery>;
export type GetGitHubRepositoryBranchesLazyQueryHookResult = ReturnType<typeof useGetGitHubRepositoryBranchesLazyQuery>;
export type GetGitHubRepositoryBranchesSuspenseQueryHookResult = ReturnType<typeof useGetGitHubRepositoryBranchesSuspenseQuery>;
export type GetGitHubRepositoryBranchesQueryResult = Apollo.QueryResult<GetGitHubRepositoryBranchesQuery, GetGitHubRepositoryBranchesQueryVariables>;
export const GetGitHubIntegrationDocument = gql`
    query GetGitHubIntegration($id: String!) {
  GitHubIntegration(id: $id) {
    Id
    Name
    Expiry
    Scopes
  }
}
    `;

/**
 * __useGetGitHubIntegrationQuery__
 *
 * To run a query within a React component, call `useGetGitHubIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGitHubIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGitHubIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGitHubIntegrationQuery(baseOptions: Apollo.QueryHookOptions<GetGitHubIntegrationQuery, GetGitHubIntegrationQueryVariables> & ({ variables: GetGitHubIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGitHubIntegrationQuery, GetGitHubIntegrationQueryVariables>(GetGitHubIntegrationDocument, options);
      }
export function useGetGitHubIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGitHubIntegrationQuery, GetGitHubIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGitHubIntegrationQuery, GetGitHubIntegrationQueryVariables>(GetGitHubIntegrationDocument, options);
        }
export function useGetGitHubIntegrationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGitHubIntegrationQuery, GetGitHubIntegrationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGitHubIntegrationQuery, GetGitHubIntegrationQueryVariables>(GetGitHubIntegrationDocument, options);
        }
export type GetGitHubIntegrationQueryHookResult = ReturnType<typeof useGetGitHubIntegrationQuery>;
export type GetGitHubIntegrationLazyQueryHookResult = ReturnType<typeof useGetGitHubIntegrationLazyQuery>;
export type GetGitHubIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetGitHubIntegrationSuspenseQuery>;
export type GetGitHubIntegrationQueryResult = Apollo.QueryResult<GetGitHubIntegrationQuery, GetGitHubIntegrationQueryVariables>;
export const DeleteGoogleIntegrationDocument = gql`
    mutation DeleteGoogleIntegration($id: String!) {
  DeleteGoogleIntegration(request: {Id: $id}) {
    Id
  }
}
    `;
export type DeleteGoogleIntegrationMutationFn = Apollo.MutationFunction<DeleteGoogleIntegrationMutation, DeleteGoogleIntegrationMutationVariables>;

/**
 * __useDeleteGoogleIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteGoogleIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGoogleIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoogleIntegrationMutation, { data, loading, error }] = useDeleteGoogleIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGoogleIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGoogleIntegrationMutation, DeleteGoogleIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGoogleIntegrationMutation, DeleteGoogleIntegrationMutationVariables>(DeleteGoogleIntegrationDocument, options);
      }
export type DeleteGoogleIntegrationMutationHookResult = ReturnType<typeof useDeleteGoogleIntegrationMutation>;
export type DeleteGoogleIntegrationMutationResult = Apollo.MutationResult<DeleteGoogleIntegrationMutation>;
export type DeleteGoogleIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteGoogleIntegrationMutation, DeleteGoogleIntegrationMutationVariables>;
export const GetGoogleIntegrationsDocument = gql`
    query GetGoogleIntegrations {
  GoogleIntegration {
    Id
    Name
    Expiry
    Scopes
  }
}
    `;

/**
 * __useGetGoogleIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetGoogleIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetGoogleIntegrationsQuery, GetGoogleIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleIntegrationsQuery, GetGoogleIntegrationsQueryVariables>(GetGoogleIntegrationsDocument, options);
      }
export function useGetGoogleIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleIntegrationsQuery, GetGoogleIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleIntegrationsQuery, GetGoogleIntegrationsQueryVariables>(GetGoogleIntegrationsDocument, options);
        }
export function useGetGoogleIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGoogleIntegrationsQuery, GetGoogleIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGoogleIntegrationsQuery, GetGoogleIntegrationsQueryVariables>(GetGoogleIntegrationsDocument, options);
        }
export type GetGoogleIntegrationsQueryHookResult = ReturnType<typeof useGetGoogleIntegrationsQuery>;
export type GetGoogleIntegrationsLazyQueryHookResult = ReturnType<typeof useGetGoogleIntegrationsLazyQuery>;
export type GetGoogleIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetGoogleIntegrationsSuspenseQuery>;
export type GetGoogleIntegrationsQueryResult = Apollo.QueryResult<GetGoogleIntegrationsQuery, GetGoogleIntegrationsQueryVariables>;
export const GetGoogleIntegrationDocument = gql`
    query GetGoogleIntegration($id: String!) {
  GoogleIntegration(id: $id) {
    Id
    Name
    Expiry
    Scopes
  }
}
    `;

/**
 * __useGetGoogleIntegrationQuery__
 *
 * To run a query within a React component, call `useGetGoogleIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGoogleIntegrationQuery(baseOptions: Apollo.QueryHookOptions<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables> & ({ variables: GetGoogleIntegrationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables>(GetGoogleIntegrationDocument, options);
      }
export function useGetGoogleIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables>(GetGoogleIntegrationDocument, options);
        }
export function useGetGoogleIntegrationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables>(GetGoogleIntegrationDocument, options);
        }
export type GetGoogleIntegrationQueryHookResult = ReturnType<typeof useGetGoogleIntegrationQuery>;
export type GetGoogleIntegrationLazyQueryHookResult = ReturnType<typeof useGetGoogleIntegrationLazyQuery>;
export type GetGoogleIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetGoogleIntegrationSuspenseQuery>;
export type GetGoogleIntegrationQueryResult = Apollo.QueryResult<GetGoogleIntegrationQuery, GetGoogleIntegrationQueryVariables>;
export const CreateMailIntegrationDocument = gql`
    mutation CreateMailIntegration($request: CreateMailIntegrationRequest!) {
  CreateMailIntegration(request: $request) {
    Id
    Name
    Email
    Password
    SmtpServer
    SmtpPort
  }
}
    `;
export type CreateMailIntegrationMutationFn = Apollo.MutationFunction<CreateMailIntegrationMutation, CreateMailIntegrationMutationVariables>;

/**
 * __useCreateMailIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateMailIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMailIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMailIntegrationMutation, { data, loading, error }] = useCreateMailIntegrationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateMailIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateMailIntegrationMutation, CreateMailIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMailIntegrationMutation, CreateMailIntegrationMutationVariables>(CreateMailIntegrationDocument, options);
      }
export type CreateMailIntegrationMutationHookResult = ReturnType<typeof useCreateMailIntegrationMutation>;
export type CreateMailIntegrationMutationResult = Apollo.MutationResult<CreateMailIntegrationMutation>;
export type CreateMailIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateMailIntegrationMutation, CreateMailIntegrationMutationVariables>;
export const DeleteMailIntegrationDocument = gql`
    mutation DeleteMailIntegration($request: MailIntegrationRequest!) {
  DeleteMailIntegration(request: $request) {
    Id
    Name
    Email
    Password
    SmtpServer
    SmtpPort
  }
}
    `;
export type DeleteMailIntegrationMutationFn = Apollo.MutationFunction<DeleteMailIntegrationMutation, DeleteMailIntegrationMutationVariables>;

/**
 * __useDeleteMailIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteMailIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailIntegrationMutation, { data, loading, error }] = useDeleteMailIntegrationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteMailIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMailIntegrationMutation, DeleteMailIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMailIntegrationMutation, DeleteMailIntegrationMutationVariables>(DeleteMailIntegrationDocument, options);
      }
export type DeleteMailIntegrationMutationHookResult = ReturnType<typeof useDeleteMailIntegrationMutation>;
export type DeleteMailIntegrationMutationResult = Apollo.MutationResult<DeleteMailIntegrationMutation>;
export type DeleteMailIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteMailIntegrationMutation, DeleteMailIntegrationMutationVariables>;
export const GetMailIntegrationsDocument = gql`
    query GetMailIntegrations {
  MailIntegration {
    Id
    Name
    Email
    Password
    SmtpServer
    SmtpPort
  }
}
    `;

/**
 * __useGetMailIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetMailIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMailIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMailIntegrationsQuery, GetMailIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailIntegrationsQuery, GetMailIntegrationsQueryVariables>(GetMailIntegrationsDocument, options);
      }
export function useGetMailIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailIntegrationsQuery, GetMailIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailIntegrationsQuery, GetMailIntegrationsQueryVariables>(GetMailIntegrationsDocument, options);
        }
export function useGetMailIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMailIntegrationsQuery, GetMailIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailIntegrationsQuery, GetMailIntegrationsQueryVariables>(GetMailIntegrationsDocument, options);
        }
export type GetMailIntegrationsQueryHookResult = ReturnType<typeof useGetMailIntegrationsQuery>;
export type GetMailIntegrationsLazyQueryHookResult = ReturnType<typeof useGetMailIntegrationsLazyQuery>;
export type GetMailIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetMailIntegrationsSuspenseQuery>;
export type GetMailIntegrationsQueryResult = Apollo.QueryResult<GetMailIntegrationsQuery, GetMailIntegrationsQueryVariables>;
export const GetMailIntegrationDocument = gql`
    query GetMailIntegration($id: String) {
  MailIntegration(id: $id) {
    Id
    Name
    Email
    Password
    SmtpServer
    SmtpPort
  }
}
    `;

/**
 * __useGetMailIntegrationQuery__
 *
 * To run a query within a React component, call `useGetMailIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMailIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<GetMailIntegrationQuery, GetMailIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMailIntegrationQuery, GetMailIntegrationQueryVariables>(GetMailIntegrationDocument, options);
      }
export function useGetMailIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMailIntegrationQuery, GetMailIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMailIntegrationQuery, GetMailIntegrationQueryVariables>(GetMailIntegrationDocument, options);
        }
export function useGetMailIntegrationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMailIntegrationQuery, GetMailIntegrationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMailIntegrationQuery, GetMailIntegrationQueryVariables>(GetMailIntegrationDocument, options);
        }
export type GetMailIntegrationQueryHookResult = ReturnType<typeof useGetMailIntegrationQuery>;
export type GetMailIntegrationLazyQueryHookResult = ReturnType<typeof useGetMailIntegrationLazyQuery>;
export type GetMailIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetMailIntegrationSuspenseQuery>;
export type GetMailIntegrationQueryResult = Apollo.QueryResult<GetMailIntegrationQuery, GetMailIntegrationQueryVariables>;
export const UpdateMailIntegrationDocument = gql`
    mutation UpdateMailIntegration($request: UpdateMailIntegrationRequest!) {
  UpdateMailIntegration(request: $request) {
    Id
    Name
    Email
    Password
    SmtpServer
    SmtpPort
  }
}
    `;
export type UpdateMailIntegrationMutationFn = Apollo.MutationFunction<UpdateMailIntegrationMutation, UpdateMailIntegrationMutationVariables>;

/**
 * __useUpdateMailIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateMailIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailIntegrationMutation, { data, loading, error }] = useUpdateMailIntegrationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateMailIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMailIntegrationMutation, UpdateMailIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMailIntegrationMutation, UpdateMailIntegrationMutationVariables>(UpdateMailIntegrationDocument, options);
      }
export type UpdateMailIntegrationMutationHookResult = ReturnType<typeof useUpdateMailIntegrationMutation>;
export type UpdateMailIntegrationMutationResult = Apollo.MutationResult<UpdateMailIntegrationMutation>;
export type UpdateMailIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateMailIntegrationMutation, UpdateMailIntegrationMutationVariables>;
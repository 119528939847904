import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, createElement, FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../config/icons";
import { InternalRoutes } from "../../config/internal-routes";
import { useCompleteAccountSetupMutation, useGetMyUserQuery, useUpdateTierMutation } from "../../generated/graphql";
import { TierSelection } from "../../page/account/tier";
import { ClassNames } from "../classes";
import { InputWithlabel } from "../input";
import { Loading } from "../loading";
import { CommandPalette, useCommandPalette } from "../searchbar/command-palette";

type IGettingStartedStep = {
    hasCompleted: boolean;
    onPrev?: () => void;
    onNext?: () => void;
};

export const SelectingTierStep: FC<IGettingStartedStep> = ({ onNext, hasCompleted }) => {
    const { loading } = useGetMyUserQuery({
        onCompleted(data) {
            if (!hasCompleted && !data.MyUser.AccountSetupComplete && data.MyUser.Tier != null && data.MyUser.Tier.TierType !== "") {
                onNext?.();
            }
        },
    });
    const [updateTier, { loading: updateTierLoading }] = useUpdateTierMutation();
    const [showTier, setShowTier] = useState(false);

    const handleClick = useCallback((tierType: string) => {
        updateTier({
            variables: {
                tierType,
            },
            onCompleted() {
                setShowTier(false);
                onNext?.();
            }
        });
    }, [onNext, updateTier]);

    if (loading) {
        return <Loading />
    }

    return (
        <div className="flex flex-col gap-6 w-full h-full">
            <AnimatePresence mode="wait">
                { showTier && <TierSelection loading={updateTierLoading} onClick={handleClick} onCancel={() => setShowTier(false)} />}
            </AnimatePresence>
            <div className={classNames(ClassNames.Text, "text-3xl font-semibold")}>Step 1: Select Your Subscription Tier</div>
            <div className={classNames(ClassNames.Text, "text-lg leading-relaxed")}>
                To get started, take advantage of our <span className="font-bold">two-week trial</span> with limited access to all features. 
                If you'd like extended access, you can upgrade to one of our available tiers: 
                <span className="font-bold"> Essential</span>, 
                <span className="font-bold"> Startups</span> (fixed cost), or our usage-based plans:
                <span className="font-bold"> Professional</span> and 
                <span className="font-bold"> Enterprise</span>. Contact our team for assistance with professional plans.
            </div>
            <div className="flex gap-4 items-center">
                <div className={classNames("w-full h-[50px]", ClassNames.Button, ClassNames.Text)} onClick={() => handleClick("Trial")}>
                    Start Free Trial
                    {cloneElement(Icons.CircleRightArrow, {
                        className: "w-4 h-4",
                    })}
                </div>
                <div className={classNames("w-full h-[50px]", ClassNames.Button, ClassNames.Text)} onClick={() => setShowTier(true)}>
                    View Plans
                    {cloneElement(Icons.CircleRightArrow, {
                        className: "w-4 h-4",
                    })}
                </div>
            </div>
        </div>
    );
};

const deployingTemplates = [
    // Static Documentation Sites
    { label: "Docusaurus", command: "docker run -e SITE_NAME= -e SITE_VERSION= docusaurus/docusaurus" },
    { label: "MkDocs", command: "docker run -e SITE_NAME= -e SITE_THEME= squidfunk/mkdocs-material" },
    { label: "Sphinx", command: "docker run -e PROJECT_NAME= -e AUTHOR_NAME= sphinxdoc/sphinx" },
    { label: "VitePress", command: "docker run -e BASE_URL= -e OUT_DIR= vitepress/vitepress" },
    { label: "Hugo", command: "docker run -e BASE_URL= -e THEME= klakegg/hugo" },
    { label: "Jekyll", command: "docker run -e BASE_URL= -e THEME= jekyll/jekyll" },
  
    // Blog Platforms
    { label: "Ghost Blog", command: "docker run -e NODE_ENV= -e URL= ghost" },
    { label: "WordPress", command: "docker run -e WORDPRESS_DB_USER= -e WORDPRESS_DB_PASSWORD= -e WORDPRESS_DB_HOST= wordpress" },
    { label: "Netlify CMS (SSG)", command: "docker run -e REPO_URL= -e BRANCH= netlifycms/netlifycms" },
    { label: "11ty (Eleventy)", command: "docker run -e INPUT_DIR= -e OUTPUT_DIR= 11ty/eleventy" },
    { label: "Grav CMS", command: "docker run -e SITE_TITLE= -e ADMIN_USER= -e ADMIN_PASSWORD= gravcms/grav" },
  
    // Podcast Landing Pages
    { label: "PodcastGenerator", command: "docker run -e PODCAST_TITLE= -e PODCAST_DESCRIPTION= podcastgenerator/podcastgenerator" },
    { label: "Podlove", command: "docker run -e SITE_URL= -e PODCAST_FEED_URL= podlove/podlove" },

    // Podcast Landing Pages
    { label: "Clidey WhoDB", command: "docker run -e WHODB_POSTGRES= clidey/whodb" },
  ];
  

export const DeployingQuicklyStep: FC<IGettingStartedStep> = ({ onNext, onPrev }) => {
    const [command, setCommand] = useState("");
    const commandPaletteProps = useCommandPalette({
        command: command,
    });
    const navigate = useNavigate();
    const [completeAccountSetup, { loading }] = useCompleteAccountSetupMutation({
        onCompleted() {
            window.location.reload();
        },
    });

    const handleSkip = useCallback(() => {
        completeAccountSetup();
    }, [completeAccountSetup]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (commandPaletteProps.commandExecutionResults.length > 0 && commandPaletteProps.commandExecutionResults.every(result => result.status === "done")) {
            interval = setTimeout(() => {
                navigate(InternalRoutes.Deployment.QuickContainer.path);
                completeAccountSetup();
            }, 2000);
        };
        return () => {
            clearTimeout(interval);
        }
    }, [commandPaletteProps.commandExecutionResults, completeAccountSetup, navigate]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col gap-6 justify-center w-full h-full">
            <motion.div className="absolute top-0 pt-8 -right-[100px] translate-x-full w-[350px] h-full flex flex-col"
                initial={{ opacity: 0, }}
                animate={{ opacity: 1, transition: { duration: 0.3, delay: 1 } }}
                exit={{ opacity: 0 }}>
                <div className={classNames(ClassNames.Text, ClassNames.Underline, "text-lg mb-4")}>
                    Deploy Templates
                </div>
                <div className="flex flex-col gap-1 overflow-y-auto h-full">
                    {
                        deployingTemplates.map(template => (
                            <div className={classNames(ClassNames.Text, "text-3xl flex gap-1 items-center transition-all hover:gap-2 cursor-pointer")} onClick={() => setCommand(template.command)}>
                                {template.label} {Icons.CircleRightArrow}
                            </div>
                        ))
                    }
                </div>
            </motion.div>
            <div className={classNames(ClassNames.Text, "text-3xl font-semibold")}>Step 2: Deploy Your First Container</div>
            <div className="flex flex-col items-center w-full gap-8">
                <div className={classNames(ClassNames.Text, "text-lg leading-relaxed", ClassNames.Underline, "pb-8")}>
                    Get started quickly by choosing one of our pre-configured container templates. Alternatively, you can provide your own custom configuration using a Docker command.
                </div>
                <div className={classNames(ClassNames.Text, "w-full")}>
                    Enter your custom Docker run command below:
                </div>
                <div className="relative w-full">
                    <InputWithlabel
                        label="Docker Command"
                        placeholder="e.g. docker run -e WHODB_POSTGRES={} -p 8080 clidey/whodb"
                        value={command}
                        setValue={setCommand}
                    />
                    <CommandPalette className="z-10 -top-[10px] -translate-y-full" {...commandPaletteProps}
                        command={command} setCommand={setCommand} show={commandPaletteProps.runCommandStatus}
                        onClose={() => commandPaletteProps.setRunCommandStatus(false)} />
                </div>
                <div className="flex justify-between items-center w-full gap-4">
                    <div className={classNames("h-[50px] w-full max-w-[150px]",ClassNames.Button,ClassNames.Text)} onClick={onPrev}>
                        {cloneElement(Icons.CircleRightArrow, {
                            className: "w-4 h-4 -rotate-180",
                        })}
                        Back
                    </div>
                    <div className="flex gap-1 items-center grow justify-end">
                        <div className={classNames("h-[50px] w-full max-w-[100px]", ClassNames.Button, ClassNames.Text)} onClick={handleSkip}>
                            {cloneElement(Icons.Skip, {
                                className: "w-4 h-4 -rotate-45",
                            })}
                            Skip
                        </div>
                        <div className={classNames("h-[50px] w-full max-w-[250px]", ClassNames.Button, ClassNames.Text)} onClick={commandPaletteProps.handleRunCommand}>
                            {cloneElement(Icons.Rocket, {
                                className: "w-4 h-4",
                            })}
                            Deploy Container
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const GettingStartedSteps = [
    { id: "SelectingTierStep", component: SelectingTierStep },
    { id: "DeployingQuicklyStep", component: DeployingQuicklyStep },
];

export const GettingStartedPage: FC = () => {
    const [completedSteps, setCompletedSteps] = useState(new Set<number>());
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = useCallback(() => {
        setCompletedSteps(steps => steps.add(activeStep));
        setActiveStep(Math.min(activeStep + 1, GettingStartedSteps.length - 1));
    }, [activeStep]);

    const handlePrev = useCallback(() => {
        setActiveStep((s) => Math.max(s - 1, 0))
    }, []);

    return <motion.div className="fixed top-0 right-0 h-full w-full bg-white/70 dark:bg-black/70 backdrop-blur-lg z-50 flex flex-col justify-center items-center gap-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
        <div className="flex gap-4 items-center w-full max-w-3xl">
            <div className="flex flex-col gap-2 w-full">
                <div className={classNames(ClassNames.Text, "text-5xl font-bold")}>Welcome To Clidey</div>
                <div className={classNames(ClassNames.Text, "text-3xl")}>To get started quickly, let's follow a few steps.</div>
            </div>
            <img src="/images/logo.svg" alt="logo" width="60" />
        </div>
        <div className="flex flex-col gap-2 relative w-full max-w-3xl min-h-[350px] pt-8">
            <div className="absolute top-0 -left-[100px] h-full w-[10px] flex flex-col gap-2">
                <motion.div
                    key="highlight"
                    className="h-full w-full bg-neutral-900 dark:bg-neutral-500 rounded-lg"
                    style={{ height: `calc(100%/${GettingStartedSteps.length})`, }}
                    initial={false}
                    animate={{ y: `calc(calc(${activeStep}*100%)/${GettingStartedSteps.length})`,}}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                />
            </div>
            <motion.div key={activeStep}
                className="flex w-full"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.4 }}>
            {createElement(GettingStartedSteps[activeStep].component, {
                hasCompleted: completedSteps.has(activeStep),
                onNext: handleNext,
                onPrev: handlePrev,
            })}
            </motion.div>
        </div>
    </motion.div>
}
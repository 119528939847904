import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Page } from "../../components/container";
import { InternalRoutes } from "../../config/internal-routes";
import { DigitalOceanIntegration, DockerIoRegistryIntegration, GitHubIntegration, GoogleIntegration, MailIntegration, useGetIntegrationsQuery } from "../../generated/graphql";
import { notify } from "../../store/function";
import { DigitalOceanIntegrationsCard } from "./digital-ocean-integrations/digital-ocean-integrations-card";
import { DockerIOIntegrationsCard } from "./docker-io-integrations/docker-io-integrations-card";
import { GitHubIntegrationsCard } from "./github-integrations/github-integrations-card";
import { GoogleIntegrationsCard } from "./google/google-integrations-card";
import { CreateIntegrationsCard } from "./integrations-card";
import { MailIntegrationsCard } from "./mail/mail-integrations-card";

type IIntegrationsPageProps = {
    isCreating?: boolean;
}

export const IntegrationsPage: FC<IIntegrationsPageProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, refetch } = useGetIntegrationsQuery();

    useEffect(() => {
        if (searchParams.has("success")) {
            notify(searchParams.get("success")!, "success");
            searchParams.delete("success");
            setSearchParams(searchParams);
            refetch();
        }
        if (searchParams.has("error")) {
            notify(searchParams.get("error")!, "error");
            searchParams.delete("error");
            setSearchParams(searchParams);
        }
    }, [searchParams, refetch, setSearchParams]);

    return (
        <Page routes={[InternalRoutes.Integrations.Integrations]}>
            <CreateIntegrationsCard isCreating={props.isCreating} refetch={refetch} />
            {data?.Integration.DockerIORegistryIntegration.map((dockerIORegistry: DockerIoRegistryIntegration) => (
                <DockerIOIntegrationsCard key={dockerIORegistry.Id} dockerIORegistry={dockerIORegistry} refetch={refetch} />
            ))}
            {data?.Integration.GitHubIntegration.map((githubIntegration: GitHubIntegration) => (
                <GitHubIntegrationsCard key={githubIntegration.Id} githubIntegration={githubIntegration} refetch={refetch} />
            ))}
            {data?.Integration.DigitalOceanIntegration.map((digitalOceanIntegration: DigitalOceanIntegration) => (
                <DigitalOceanIntegrationsCard key={digitalOceanIntegration.Id} digitalOceanIntegration={digitalOceanIntegration} refetch={refetch} />
            ))}
            {data?.Integration.GoogleIntegration.map((googleIntegration: GoogleIntegration) => (
                <GoogleIntegrationsCard key={googleIntegration.Id} googleIntegration={googleIntegration} refetch={refetch} />
            ))}
            {data?.Integration.MailIntegration.map((mailIntegration: MailIntegration) => (
                <MailIntegrationsCard key={mailIntegration.Id} mailIntegration={mailIntegration} refetch={refetch} />
            ))}
        </Page>
    )
}
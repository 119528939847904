import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CreateCard } from "../../../components/card";
import { Page } from "../../../components/container";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { useGetHooksQuery } from "../../../generated/graphql";
import { CI_CD_HOOK_ICON, HookCard } from "./hooks-card";

export const HooksPage: FC = () => {
    const navigate = useNavigate();
    const getHooksResponse = useGetHooksQuery();
    return (
        <Page routes={[InternalRoutes.CI_CD.Hooks]}>
            <CreateCard label="Hook" onClick={() => navigate(InternalRoutes.CI_CD.CreateHook.path)} icon={{
                component: Icons.Add,
                bgClassName: CI_CD_HOOK_ICON.bgClassName,
            }} /> 
            {
                getHooksResponse.data?.Hook?.map(hook => (
                    <HookCard key={hook.Id} hook={hook} refetch={getHooksResponse.refetch} />
                ))
            }
        </Page>
    )
}
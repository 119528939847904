import { createContext } from "react";
import { IValidationCallback } from "../../../components/form";
import { CreateFlowStep } from "../../../generated/graphql";

export type IFlowGraphData = {
    getForm: () => CreateFlowStep;
    isFormValid: IValidationCallback;
}
export type IFlowGraphViewCache = Record<string, IFlowGraphData>;
export type ISetCacheItem = (key: string, data: IFlowGraphData) => void;

export type IFlowGraphViewData = {
    cache: IFlowGraphViewCache;
    setCacheItem: ISetCacheItem;
    removeCacheItem: (key: string) => void;
}

export const FlowGraphViewContext = createContext<IFlowGraphViewData>({} as IFlowGraphViewData);

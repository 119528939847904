import { FC } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { useGetDomainsQuery } from "../../../generated/graphql";
import { CreateDomainCard, DomainCard } from "./domain-card";

type IDomainPageProps = {
    isCreating?: boolean;
}

export const DomainPage: FC<IDomainPageProps> = (props) => {
    const getAllDomainsResponse = useGetDomainsQuery();

    return (
        <Page routes={[InternalRoutes.Config.Domain]}>
            <CreateDomainCard isCreating={props.isCreating} refetch={getAllDomainsResponse.refetch} />
            {
                getAllDomainsResponse.data?.Domain.map(domain => (
                    <DomainCard key={domain.Id} domain={domain} refetch={getAllDomainsResponse.refetch} />
                ))
            }
        </Page>
    )
}
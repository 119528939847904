import classNames from "classnames";
import { find, forEach } from "lodash";
import { ChangeEvent, cloneElement, FC, FocusEvent, useCallback, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useImmer } from "use-immer";
import { Icons } from "../../config/icons";
import { BasicUserInfo, GetPermissionsQuery, useGetPermissionsLazyQuery, User, useSearchUsersLazyQuery, useUpdatePermissionsMutation } from "../../generated/graphql";
import { notify } from "../../store/function";
import { useAppSelector } from "../../store/hooks";
import { toTitleCase } from "../../utils/functions";
import { CancelButton, UpdateButton } from "../button";
import { Dropdown, IDropdownItem } from "../dropdown";
import { Loading } from "../loading";
import { Search } from "../search";
import { ClassNames } from "../classes";

enum Permission {
    VIEWER="VIEWER",
    EDITOR="EDITOR",
    OWNER="OWNER",
}

const permissionOptions: IDropdownItem[] = [
    {
        id: Permission.VIEWER,
        label: "Viewer",
    },
    {
        id: Permission.EDITOR,
        label: "Editor",
    },
    {
        id: Permission.OWNER,
        label: "Owner",
    },
]

type IUserCard = {
    type: string;
    currentUserId?: string;
    user: BasicUserInfo;
    onUpdate: (type: string) => void;
    onDelete: () => void;
}

const UserImage: FC<{ image?: User["Image"] }> = ({ image }) => {
    if (image == null || image === "") {
        return <div className="w-6 h-6 bg-neutral-800 rounded-full flex items-center justify-center">
            {cloneElement(Icons.User, {
                className: "w-4 h-4 stroke-white translate-x-[0px] translate-y-[0px]",
            })}
        </div>
    }
    return <img className="h-6 w-6 rounded-full object-cover bg-slate-800" src={image} alt="user profile" />;
}

const UserCard: FC<IUserCard> = (props) => {
    const isMe = useMemo(() => {
        return props.currentUserId === props.user.Id;
    }, [props.currentUserId, props.user]);

    const handleUpdate = useCallback((item: IDropdownItem) => {
        props.onUpdate(item.id);
    }, [props]);

    return <div className="flex items-center justify-between border-[1px] border-neutral-200 dark:border-white/10 rounded-lg shadow-sm p-2">
        <div className="flex gap-2 items-center">
            <UserImage image={props.user.Image} />
            <span className={classNames(ClassNames.Text, "text-xs")}>
                { isMe ? "Me" : `${props.user.FirstName} ${props.user.LastName}`}
            </span>
        </div>
        <Dropdown items={permissionOptions} disabled={isMe} onClick={handleUpdate} clearable={true} onClear={props.onDelete} clearText="Unshare" portal={true}>
            <div className={classNames(ClassNames.Text, "flex text-sm gap-2 items-center justify-center cursor-pointer")}>
                <span className={classNames("text-xs", {
                    "text-neutral-400 dark:text-neutral-500": isMe,
                })}>{toTitleCase(props.type)}</span>
                {cloneElement(Icons.DownCaret, {
                    className: twMerge(classNames("w-4 h-4", {
                        "stroke-neutral-300 dark:stroke-neutral-600": isMe,
                    }))
                })}
            </div>
        </Dropdown>
    </div>
}

type IPermissionCard = {
    id: string;
    type: string;
    onCancel?: () => void;
}

export const PermissionCard: FC<IPermissionCard> = ({ id, type, onCancel }) => {
    const [permissions, setPermissions] = useImmer<GetPermissionsQuery["Permissions"]>([]);
    const [searchText, setSearchText] = useState("");
    const [search, { data: searchData }] = useSearchUsersLazyQuery();
    const currentUserId = useAppSelector(state => state.auth.user?.Id);
    const [getPermissions, { loading } ] = useGetPermissionsLazyQuery();
    const [updatePermissions, { loading: updatePermissionLoading }] = useUpdatePermissionsMutation();

    const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        if (e.target.value.length <= 1) {
            return;
        }
        search({
            variables: {
                search: e.target.value,
            },
        });
    }, [search]);

    const handleBlur = useCallback((e: FocusEvent<HTMLElement>) => {
        if (e.relatedTarget?.tagName === "BUTTON") {
            return;
        }
        setSearchText("");
    }, []);

    const handleSelect = useCallback((item: IDropdownItem) => {
        setPermissions(p => {
            p.push({
                Type: Permission.VIEWER,
                User: searchData?.SearchUsers?.find((user) => user.Id === item.id),
            });
        });
        setSearchText("");
    }, [searchData?.SearchUsers, setPermissions]);

    const handleUpdate = useCallback(() => {
        if (updatePermissionLoading) {
            return;
        }
        updatePermissions({
            variables: {
                id,
                type,
                permissions: permissions.map((permission) => ({
                    Type: permission.Type,
                    UserId: permission.User?.Id,
                })),
            },
            onCompleted() {
                notify("Permissions updated successfully", "success");
            },
        });
    }, [id, permissions, type, updatePermissions, updatePermissionLoading]);

    const items = useMemo(() => {
        if (searchData?.SearchUsers == null) {
            return [];
        }
        const searchItems: IDropdownItem[] = [];
        forEach(searchData?.SearchUsers ?? [], (user) => {
            if (user.Id === currentUserId) {
                return;
            }
            if (find(permissions, permission => permission.User?.Id === user.Id) != null) {
                return;
            }
            searchItems.push({
                id: user.Id,
                label: `${user.FirstName} ${user.LastName}`,
                icon: <UserImage image={user.Image} />,
            });
        });
        return searchItems;
    }, [currentUserId, searchData?.SearchUsers, permissions]);

    const children = useMemo(() => {
        return permissions.map((permission, i) => {
            if (permission.User != null)  {
                return <UserCard type={permission.Type} user={permission.User} currentUserId={currentUserId} onUpdate={(type: string) => {
                    setPermissions(permissions => {
                        permissions[i].Type = type;
                    });
                }} onDelete={() => {
                    setPermissions(permissions => permissions.filter((_, index) => index !== i));
                }} />
            }
            return <></>
        })
    }, [currentUserId, permissions, setPermissions]);

    useEffect(() => {
        getPermissions({
            variables: {
                id,
                type,
            },
            onCompleted(data) {
                setPermissions(data?.Permissions ?? [])
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="flex flex-col overflow-hidden grow">
        <div className={classNames(ClassNames.Text, "mt-4")}>
            Permissions
        </div>
        {
            loading
            ? <Loading />
            : <>
                <Search label="Search users..." controlled={true} isOpen={searchText.length > 1} items={items} inputProps={{
                    value: searchText,
                    onChange: handleSearchChange,
                    onBlur: handleBlur,
                }} onSelect={handleSelect} noItemsLabel="No users found..." actionLabel="Share" className="mt-2" />
                <div className="overflow-y-auto mb-4 grow">
                    <div className="flex flex-col gap-2 mt-2">
                        {children}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="flex justify-between items-center w-full">
                        <CancelButton onClick={onCancel} />
                        <UpdateButton loading={updatePermissionLoading} onClick={handleUpdate} />
                    </div>
                </div>
            </>
        }
    </div>
}
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { forEach, map } from "lodash";
import { cloneElement, FC, useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Edge, getIncomers, getOutgoers, Node, ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import { useImmer } from "use-immer";
import { AnimatedButton, DeleteButton, UpdateButton } from "../../../components/button";
import { ClassNames } from "../../../components/classes";
import { LoadingPage, Page } from "../../../components/container";
import { IGraphInstance } from "../../../components/graph/graph";
import { createEdge, createNode } from "../../../components/graph/utils";
import { InputWithlabel } from "../../../components/input";
import { Pill } from "../../../components/pill";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { CreateHookStep, Hook, HookRun, HookStepType, useDeleteHookMutation, useGetHookLazyQuery, useGetHookRunsQuery, useGetHookStepsLazyQuery, useRunHookMutation, useUpdateHookMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { HookGraphViewContext, IHookGraphData, IHookGraphViewCache } from "./context";
import { HookRunCard, HookRunPreview } from "./hook-run-card";
import { GraphHooksCard } from "./hooks-card";


const ExploreHook: FC<{ hook: Hook, refetch: () => void }> = ({ hook, refetch }) => {
    const [getHookSteps, ] = useGetHookStepsLazyQuery();
    const [updateHook, { loading: updateLoading }] = useUpdateHookMutation();
    const [deleteHook, { loading: deleteLoading }] = useDeleteHookMutation();
    const [cache, setCache] = useImmer<IHookGraphViewCache>({});
    const [name, setName] = useState(hook.Name);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const reactFlowRef = useRef<IGraphInstance>();
    const [runHook, { loading: runHookLoading }] = useRunHookMutation();
    const { data: hookRuns, refetch: getHookRunsRefetch } = useGetHookRunsQuery({
        variables: {
            hookId: hook.Id,
        }
    });
    const [activeHookRun, setActiveHookRun] = useState<[number, HookRun]>();
    const navigate = useNavigate();

    const handleOnReady = useCallback((instance: IGraphInstance) => {
        reactFlowRef.current = instance;
    }, []);

    useEffect(() => {
        getHookSteps({
            variables: {
                hookId: hook.Id,
            },
            onCompleted(data) {
                const hookSteps = data?.HookSteps ?? [];
                const newNodes: Node[] = hookSteps.map((hookStep, i) => {
                    const node = createNode({
                        id: hookStep.NodeId,
                        type: hookStep.Type,
                        data: {},
                    }, {
                        x: i * 400,
                        y: 0,
                    });
                    switch(hookStep.Type) {
                        case HookStepType.GitWebHook:
                            node.data = hookStep.GitWebHook;
                            break;
                        case HookStepType.FlowOperation:
                            node.data = hookStep.FlowOperation;
                            break;
                        case HookStepType.OperationResult:
                            node.data = hookStep.OperationResult;
                            break;
                        case HookStepType.QuickContainerOperation:
                            node.data = hookStep.QuickContainerOperation;
                            break;
                        case HookStepType.GitPullRequestComment:
                            node.data = hookStep.GitPullRequestComment;
                            break;
                        case HookStepType.GitRelease:
                            node.data = hookStep.GitRelease;
                            break;
                        case HookStepType.Mail:
                            node.data = hookStep.Mail;
                            break;
                    }
                    return node;
                }) ?? [];
                setNodes(newNodes);
                const newEdges: Edge[] = [];
                forEach(hookSteps, (hookStep) => {
                    forEach(hookStep.Dependents, dependent => {
                        newEdges.push(createEdge(hookStep.NodeId, dependent));
                    });
                });
                setEdges(newEdges);
                setTimeout(() => {
                    reactFlowRef.current?.layout("dagre");
                }, 300);
            },
        });
    }, [getHookSteps, hook.Id, setNodes, setEdges]);

    const handleUpdate = useCallback(() => {
        const steps: CreateHookStep[] = nodes.map(nd => {
            const nodeData = cache[nd.id].getForm();
            const references = map(getIncomers(nd, nodes, edges), node => node.id);
            const dependents = map(getOutgoers(nd, nodes, edges), node => node.id);
            return {
                ...nodeData,
                NodeId: nd.id,
                References: references,
                Dependents: dependents,
            }
        });
        updateHook({
            variables: {
                id: hook.Id,
                name,
                steps,
            },
            onCompleted: () => {
                notify("Hook updated successfully", "success");
                getHookRunsRefetch();
            },
            onError: () => {
                notify("Unable to update hook", "error");
            },
        });
    }, [nodes, updateHook, hook.Id, name, cache, edges, getHookRunsRefetch]);

    const handleDelete = useCallback(() => {
        deleteHook({
            variables: {
                id: hook.Id,
            },
            onCompleted: () => {
                notify("Hook deleted successfully", "success");
                navigate(InternalRoutes.CI_CD.Hooks.path);
            },
            onError: () => {
                notify("Unable to delete hook", "error");
            },
        });
    }, [deleteHook, hook.Id, navigate]);

    const handleSetCacheItem = useCallback((key: string, value: IHookGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    const handleRun = useCallback(() => {
        runHook({
            variables: {
                id: hook.Id,
            },
            onCompleted() {
                notify("Running flow!", "success");
                getHookRunsRefetch();
            },
        });
    }, [runHook, hook.Id, getHookRunsRefetch]);

    return <>
        <AnimatePresence mode="wait">
            {activeHookRun != null && hookRuns?.HookRuns != null && <motion.div className="fixed top-0 left-0 h-full w-2/3 bg-white/5 dark:bg-black/5 backdrop-blur-3xl p-8 z-10"
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 100 }}
                exit={{ x: -100, opacity: 0 }}>
                <div className="flex flex-col gap-4 h-full">
                    <div className="flex gap-2 items-center justify-between">
                        <div className={classNames(ClassNames.Text, "text-3xl")}>
                            {`Run ${hookRuns.HookRuns.length - activeHookRun[0]}`}
                        </div>
                        <div onClick={() => setActiveHookRun(undefined)}>
                            {cloneElement(Icons.Cancel, {
                                className: classNames(ClassNames.Text, "w-8 h-8 cursor-pointer transition-all hover:scale-125")
                            })}
                        </div>
                    </div>
                    <HookRunPreview hookId={hook.Id} hookRun={activeHookRun[1]} />
                </div>
            </motion.div>}
        </AnimatePresence>
        <div className="flex flex-col gap-8 w-full h-full">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                    <div className={classNames(ClassNames.Text, "text-3xl")}>
                        {hook.Name}
                    </div>
                    {
                        hookRuns?.HookRuns?.[0]?.Status != null &&
                        <Pill id={hookRuns.HookRuns[0].Status} label={hookRuns.HookRuns[0].Status} />
                    }
                </div>
                <div className="flex items-center gap-2">
                    <DeleteButton onClick={handleDelete} loading={deleteLoading} />
                </div>
            </div>
            <div className="flex gap-8 h-[80vh]">
                <div className="flex flex-col gap-2 w-3/4">
                    <div className="flex justify-start gap-4 w-full">
                        <InputWithlabel label="Name" value={name} setValue={setName} />
                        <div className="flex gap-2 grow items-start justify-end mt-1">
                            <UpdateButton loading={updateLoading} onClick={handleUpdate} />
                        </div>
                    </div>
                    <HookGraphViewContext.Provider value={{
                        cache,
                        setCacheItem: handleSetCacheItem,
                        removeCacheItem: handleRemoveCacheItem,
                    }}>
                            <ReactFlowProvider>
                                <GraphHooksCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                                    edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} onReady={handleOnReady} />
                            </ReactFlowProvider>
                    </HookGraphViewContext.Provider>
                </div>
                <div className="flex flex-col gap-4 w-1/4">
                    <div className="flex justify-between items-center">
                        <div className={classNames(ClassNames.Text, "text-2xl")}>Previous runs</div>
                        <AnimatedButton label="Run" icon={Icons.Play} onClick={handleRun} loading={runHookLoading} />
                    </div>
                    <div className="flex flex-col gap-2 overflow-y-auto h-full">
                        {
                            hookRuns?.HookRuns != null && hookRuns.HookRuns.length === 0
                            ? <div className="flex flex-col h-full items-center justify-center gap-4 text-lg">
                                <div className={classNames(ClassNames.Text, "flex items-center gap-2")}>
                                    {cloneElement(Icons.Play, {
                                        className: "w-6 h-6",
                                    })} 
                                    Start by running the Hook
                                </div>
                                <AnimatedButton label="Run" icon={Icons.Play} onClick={handleRun} loading={runHookLoading} />
                            </div>
                            : hookRuns?.HookRuns.map((hookRun, i) => (
                                <HookRunCard key={hookRun.Id} name={`Run ${hookRuns.HookRuns.length - i}`} hookRun={hookRun} onClick={() => setActiveHookRun([i, hookRun as HookRun])} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}



export const ExploreHooksPage: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [getHook, { data: quickContainer, loading, refetch }] = useGetHookLazyQuery()
    const navigate = useNavigate();

    useEffect(() => {
        if (id == null) {
            return;
        }
        getHook({
            variables: {
                id,
            },
            onError() {
                navigate(InternalRoutes.CI_CD.Hooks.path);
            },
        });
    }, [getHook, id, navigate]);

    if (id == null) {
        return <Navigate to={InternalRoutes.Dashboard.path} />
    }

    if (loading || quickContainer?.Hook == null) {
        return <LoadingPage />
    }

    return <Page routes={[InternalRoutes.CI_CD.Hooks, InternalRoutes.CI_CD.ExploreHooks]}>
        <ExploreHook hook={quickContainer.Hook[0] as Hook} refetch={refetch} />
    </Page>
}
import classNames from "classnames";
import { motion } from "framer-motion";
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GraphElements } from "../../config/constants";
import { getGlobalIcon, getGlobalLink } from "../../config/global";
import { Icons } from "../../config/icons";
import { useSearchLazyQuery } from "../../generated/graphql";
import { UserRole } from "../../store/auth";
import { CommonActions } from "../../store/common";
import { useAppSelector } from "../../store/hooks";
import { createRedirectLink, createStub, toTitleCase } from "../../utils/functions";
import { IDropdownItem } from "../dropdown";
import { Search } from "../search";
import { sidebarRoutes } from "../sidebar/sidebar";
import { TOP_BAR_ACTION_CLASS_NAME } from "../theme-provider";
import { CommandPalette, useCommandPalette } from "./command-palette";


function getItems(isAdmin: boolean): IDropdownItem[] {
    const items: IDropdownItem[] = sidebarRoutes.filter(route => isAdmin || route.title !== "Admin")
        .filter(route => !(route.title.includes("User") || route.title.includes("Integration")))
        .flatMap(sidebarRoute => sidebarRoute.routes.map(route => ({
            id: route.path,
            label: route.name,
            icon: route.icon,
            extra: {
                type: "route",
            },
            nested: !(route.name.startsWith("Create") || route.name.startsWith("Add") || route.name.includes("User")),
        })));

    items.push({ id: GraphElements.DockerIORegistryIntegration, label: toTitleCase(GraphElements.DockerIORegistryIntegration), icon: getGlobalIcon(GraphElements.DockerIORegistryIntegration).component, nested: true });
    items.push({ id: GraphElements.GitHubIntegration, label: toTitleCase(GraphElements.GitHubIntegration), icon: getGlobalIcon(GraphElements.GitHubIntegration).component, nested: true });
    items.push({ id: GraphElements.DigitalOceanIntegration, label: toTitleCase(GraphElements.DigitalOceanIntegration), icon: getGlobalIcon(GraphElements.DigitalOceanIntegration).component, nested: true });
    items.push({ id: GraphElements.GoogleIntegration, label: toTitleCase(GraphElements.GoogleIntegration), icon: getGlobalIcon(GraphElements.GoogleIntegration).component, nested: true });
    items.push({ id: GraphElements.MailIntegration, label: toTitleCase(GraphElements.MailIntegration), icon: getGlobalIcon(GraphElements.MailIntegration).component, nested: true });
    
    return items;
}

const RUN_COMMAND = {
    id: "RUN_COMMAND",
    label: "Run command",
    icon: Icons.Play,
    fixed: true,
};


export const GlobalSearchBar = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const globalOpen = useAppSelector(state => state.common.globalSearch);
    const [open, setOpen] = useState(globalOpen);
    const navigate = useNavigate();
    const roles = useAppSelector(state => state.auth.user?.Roles ?? []);
    const [currentSearch, setCurrentSearch] = useState("");
    const currentSearchRef = useRef<string>("");
    const dispatch = useDispatch();
    const [tags, setTags] = useState<string[]>();
    const [search, ] = useSearchLazyQuery();
    const [items, setItems] = useState<IDropdownItem[]>([]);
    const [backspaceCount, setBackspaceCount] = useState(0);
    const commandPaletteProps = useCommandPalette({
        command: currentSearch,
    });

    const handleClose = useCallback(() => {
        if (open) {
            setOpen(false);
        }
        commandPaletteProps.resetSteps();
    }, [commandPaletteProps, open]);


    const handleSelect = useCallback((item: IDropdownItem) => {
        if (item === RUN_COMMAND) {
            commandPaletteProps.handleRunCommand();
            return;
        }
        if (item.extra?.type === "route") {
            navigate(item.id);
            handleClose();
        }
    }, [commandPaletteProps, navigate, handleClose]);

    const handleClick = useCallback(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 200);
        setOpen(true);
    }, [inputRef]);

    const handleResetDropdownItems = useCallback(() => {
        const defaultItems = getItems(roles.includes(UserRole.AdminRole));
        if (commandPaletteProps.isValidCommand(currentSearch)) {
            defaultItems.unshift(RUN_COMMAND);
        }
        setItems(defaultItems);
    }, [commandPaletteProps, currentSearch, roles]);

    useEffect(() => {
        handleResetDropdownItems();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        setItems([]);
        if (e.key === "Escape") {
            handleClose();
        } else if (e.key === "Backspace" && currentSearch.length === 0) {
            if (backspaceCount >= 1) {
                setTags(undefined);
                e.preventDefault();
                e.stopPropagation();
                setBackspaceCount(0);
            } else {
                setBackspaceCount(c => c+1);
            }
        }
        if (tags == null || tags.length === 0) {
            handleResetDropdownItems();
        } else {
            const type = createStub(tags[0]);
            search({
                variables: {
                    search: currentSearch,
                    type,
                },
                onCompleted(data) {
                    setItems(data.Search.Items.map(item => ({
                        id: createRedirectLink(getGlobalLink(type as GraphElements), item.Id),
                        label: item.Name,
                        icon: getGlobalIcon(type as GraphElements).component,
                        extra: {
                            type: "route",
                        },
                    })));
                },
            })
        }
    }, [backspaceCount, currentSearch, handleClose, handleResetDropdownItems, search, tags]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCurrentSearch(e.target.value);
        currentSearchRef.current = e.target.value;
        commandPaletteProps.resetSteps();
    }, [commandPaletteProps]);

    useEffect(() => {
        if (globalOpen) {
            handleClick();
        }
    }, [globalOpen, handleClick]);

    useEffect(() => {
        if (!open) {
            dispatch(CommonActions.setGlobalSearch(false));
        }
    }, [dispatch, open]);

    useHotkeys('meta+k', () => {
        handleClick();
    }, [handleClick]);

    const handleTabSelection = useCallback((item: IDropdownItem) => {
        if (item == null || !item.nested) {
            return;
        }
        setCurrentSearch("");
        setTags([item.label]);
    }, []);

    const handleTagRemove = useCallback((_tag: string) => {
        setTags(undefined);
        handleResetDropdownItems();
    }, [handleResetDropdownItems]);

    return createPortal(<>
            <div className={classNames("fixed inset-0", {
                "hidden": !open,
            })} onClick={handleClose}>
            </div>
            <motion.div layout className={classNames("w-[350px] h-fit backdrop-blur-md z-30 rounded-lg", {
                "shadow-2xl": open,
            })} initial="close" variants={{
                open: {
                    opacity: 1,
                    display: "block",
                    width: `${Math.min(650, window.innerWidth - 20)}px`,
                },
                close: {
                    opacity: 1,
                    width: "180px",
                }
            }} animate={open ? "open" : "close"}>
                <div className="relative">
                    <div className="relative" onClick={handleClick}>
                        <Search tags={tags} onTagRemove={handleTagRemove} items={items} label={open ? "Search or run commands" : "Command Palette"} actionLabel="Go to"
                            onTab={handleTabSelection}
                            actionIcon={Icons.CircleRightArrow} onSelect={handleSelect} isOpen={open && !commandPaletteProps.runCommandStatus}
                            controlled={true} inputProps={{
                                ref: inputRef,
                                className: classNames("transition-all", {
                                    "cursor-pointer rounded-3xl hover:pl-3": !open,
                                }),
                                disabled: !open,
                                onKeyUp: handleKeyUp,
                                onChange: handleChange,
                            }} noItemsLabel="No items or command found" />
                    </div>
                    <CommandPalette command={currentSearch}
                        setCommand={setCurrentSearch} {...commandPaletteProps} show={open && commandPaletteProps.runCommandStatus} />
                </div>
            </motion.div>
    </>, document.querySelector(`.${TOP_BAR_ACTION_CLASS_NAME}`)!)
}
import { FC, useCallback, useContext, useMemo } from "react"
import { useReactFlow } from "reactflow"
import { CodeEditor, ICodeEditorSuggestion } from "../../../../components/editor"
import { Label } from "../../../../components/input"
import { Tooltip } from "../../../../components/tooltip"
import { Icons } from "../../../../config/icons"
import { HookStepType } from "../../../../generated/graphql"
import { getAllReferencesRecursively, getExposedVariableSuggestions } from "../../../../utils/functions"
import { HookGraphViewContext } from "../context"
import { FLOW_OPERATION_HOOK_STEP_EXPOSED_VARIABLES } from "./flow-operation-hook-step-card"
import { ClassNames } from "../../../../components/classes"

type IHandlebarCodeEditor = {
    value: string;
    setValue: (value: string) => void;
    suggestions: ICodeEditorSuggestion[];
}

export const HandlebarCodeEditor: FC<IHandlebarCodeEditor> = ({ value, setValue, suggestions }) => {
    const onSuggestionHover = useCallback((suggestion: ICodeEditorSuggestion, enter: boolean) => {
        const nodeId = suggestion.label.split(".")[1];
        const node = document.querySelector(`div[data-id="${nodeId}"]`)?.querySelector(".group\\/hook-step-selector");
        if (node == null) {
            return;
        }
        if (enter) {
            node?.classList.remove("shadow-sm");
            node?.classList.add("shadow-2xl");
        } else {
            node?.classList.remove("shadow-2xl");
            node?.classList.add("shadow-sm");
        }
    }, []);

    return <div className="flex flex-col grow gap-2">
        <div className="flex justify-between items-center">
            <Label label="Message" />
            <Tooltip tooltip="You can use handlebars to get variable information from previous steps to put in the message. Markdown is supported.">
                <span className={ClassNames.Text}>{Icons.Info}</span>
            </Tooltip>
        </div>
        <CodeEditor value={value} setValue={setValue} language="hooks-handlebars" suggestions={suggestions} onSuggestionHover={onSuggestionHover} />
    </div>;
}

export const useGetHandlebarSuggestions = (id: string) => {
    const { getNode, getNodes, getEdges } = useReactFlow();
    const { cache } = useContext(HookGraphViewContext);

    const suggestions = useMemo(() => {
        const suggestions: ICodeEditorSuggestion[] = [];
        const node = getNode(id)!;
        const references = getAllReferencesRecursively(node, getNodes(), getEdges());
        for (let reference of references) {
            if (reference.id in cache) {
                const formValue = cache[reference.id].getForm();
                if (formValue.Type === HookStepType.FlowOperation) {
                    suggestions.push(...getExposedVariableSuggestions(HookStepType.FlowOperation, reference.id, FLOW_OPERATION_HOOK_STEP_EXPOSED_VARIABLES));
                }
            }
        }

        return suggestions;
    }, [cache, getEdges, getNode, getNodes, id]);

    return suggestions;
}
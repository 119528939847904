export const CLIDEY_ACCESS_TOKEN = "CLIDEY_AT";
export const CLIDEY_REFRESH_TOKEN = "CLIDEY_RT";

export enum DashboardElements {
    GitHubPullRequests = "github-pull-requests",
}

export enum GraphElements {
    QuickContainer = "quick-container",
    Registry = "registry",
    RegistryImage = "registry-image",
    Flow = "flow",
    Hook = "hook",
    Domain = "domain",
    Cluster = "cluster",
    EnvironmentVariable = "environment-variable",
    DockerIORegistryIntegration = "docker-io-registry-integration",
    GitHubIntegration = "github-version-control-integration",
    DigitalOceanIntegration = "digitalocean-cloud-provider-integration",
    GoogleIntegration = "google-cloud-provider-integration",
    MailIntegration = "mail-communication-integration"
}
import classNames from "classnames";
import { FC, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactFlowProps, useReactFlow } from "reactflow";
import { AnimatedButton } from "../../../components/button";
import { AdjustableCard, GraphBasedExpandableCard } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { Graph, IGraphCardProps, IGraphProps } from "../../../components/graph/graph";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes, navigateWithPathParameters } from "../../../config/internal-routes";
import { GetHookDocument, GetHooksDocument, Hook, HookStepType } from "../../../generated/graphql";
import { getCenterPosition } from "../../../utils/functions";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { FlowOperationHookStepCard, ICreateFlowOperationHookStep } from "./steps/flow-operation-hook-step-card";
import { GitPullRequestCommentHookStepCard, ICreateGitPullRequestCommentHookStep } from "./steps/git-pr-comment-hook-step-card";
import { GitReleaseHookStepCard, ICreateGitReleaseHookStep } from "./steps/git-release-hook-step-card";
import { GitWebHookHookStepCard, ICreateGitWebHookHookStep } from "./steps/git-webhook-hook-step-card";
import { ICreateOperationResultHookStep, OperationResultHookStepCard } from "./steps/operation-result-hook-step-card";
import { ICreateQuickContainerOperationHookStep, QuickContainerOperationHookStepCard } from "./steps/quick-container-operation-hook-step-card";
import { StartingSelectorHooksStep } from "./steps/starting-selector-hooks-node";
import { ICreateMailHookStep, MailHookStepCard } from "./steps/mail-hook-step-card";

export const CI_CD_HOOK_ICON = {
    component: Icons.CI_CD.Hooks.Default,
    bgClassName: "bg-orange-500",
}

type IGraphHooksCardProps = Pick<IGraphProps, "nodes" | "setNodes" | "onNodesChange" | "edges" | "setEdges" | "onEdgesChange" | "onReady"> & ReactFlowProps;

export const GraphHooksCard: FC<IGraphHooksCardProps> = ({ nodes, setNodes, onNodesChange, edges, setEdges, onEdgesChange, ...props}) => {
    const { setViewport } = useReactFlow();
    const nodeTypes = useMemo(() => ({
        [HookStepType.Starting]: StartingSelectorHooksStep,
        [HookStepType.GitWebHook]: GitWebHookHookStepCard,
        [HookStepType.FlowOperation]: FlowOperationHookStepCard,
        [HookStepType.OperationResult]: OperationResultHookStepCard,
        [HookStepType.QuickContainerOperation]: QuickContainerOperationHookStepCard,
        [HookStepType.GitPullRequestComment]: GitPullRequestCommentHookStepCard,
        [HookStepType.GitRelease]: GitReleaseHookStepCard,
        [HookStepType.Mail]: MailHookStepCard,
    }), []);

    const handleInit = useCallback(() => {
        if (nodes.length === 0) {
            return;
        }
        setViewport(getCenterPosition(nodes[0], window.innerHeight, window.innerWidth / 2));
    }, [nodes, setViewport]);

    return (
        <Graph nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            minZoom={0.5}
            maxZoom={1}
            onInit={handleInit}
            {...props} />
    )
}

export type ICreateHookStep = (ICreateGitWebHookHookStep | ICreateFlowOperationHookStep
    | ICreateOperationResultHookStep | ICreateQuickContainerOperationHookStep
    | ICreateGitPullRequestCommentHookStep | ICreateGitReleaseHookStep | ICreateMailHookStep);

type IHookCardProps = {
    hook: Hook;
    refetch?: () => void;
}

export const HookCard: FC<IHookCardProps> = (props) => {
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();

    return <GraphBasedExpandableCard id={props.hook.Id} type={GraphElements.Hook} icon={CI_CD_HOOK_ICON} highlight={searchParams.get("id") === props.hook.Id}>
            <>
                <div className={classNames(ClassNames.Text, "grow text-lg mt-2")}>{props.hook.Name}</div>
                <div className="flex flex-row items-center justify-end">
                    <AnimatedButton label="Explore" icon={Icons.CircleRightArrow} onClick={() => navigateWithPathParameters(navigate, InternalRoutes.CI_CD.ExploreHooks.path, { id: props.hook.Id })} />
                </div>
            </>
            <div></div>
        </GraphBasedExpandableCard>
}

export const HookDashboardCard: FC<{ hook: Hook }> = (props) => {
    return <AdjustableCard showTools={true} icon={CI_CD_HOOK_ICON}>
        <div className="flex flex-col grow mt-2">
            <div className={classNames(ClassNames.Text, "text-md")}>
                {props.hook.Name}
            </div>
        </div>
    </AdjustableCard>
}

export const transformHookData: IDataTransform<Hook>  = (data: { Hook: Hook[]}) => data.Hook;

export const HookGraphCard: FC<IGraphCardProps<Hook>> = (props) => {
    return <GraphCardLoader loader={GetHookDocument} transform={(data) => transformHookData(data)[0]} {...props}>
        {(data: Hook) => (<HookDashboardCard hook={data} />)}
    </GraphCardLoader>;
}

export const AddHookDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Hooks"
        query={GetHooksDocument}
        transform={transformHookData} type={GraphElements.Hook}
        icon={Icons.CI_CD.Hooks.Default}
        link={InternalRoutes.CI_CD.CreateHook.path} />
    );
}
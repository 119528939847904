import { FC, useContext, useEffect } from "react";
import { CodeEditor } from "../../../../components/editor";
import { Form, IForm, IFormGrid, useFormHook } from "../../../../components/form";
import { IGraphCardProps } from "../../../../components/graph/graph";
import { Tooltip } from "../../../../components/tooltip";
import { Icons } from "../../../../config/icons";
import { FlowGraphViewContext } from "../context";
import { FlowElements, FlowStepCard } from "../flow-step-card";
import classNames from "classnames";
import { ClassNames } from "../../../../components/classes";

// const DOCKER_SYSTEM_PROMPT = `You are an expert in creating Dockerfile build configurations.
// Context: There is a previous docker build state, cloner, where the git repository is mounted in /app. For each COPY step, mount files from there. 
// Based on user requirements, respond with only Dockerfile build contents. Include all of the details in the build configuration. Do not respond with anything else.
// Here is an example response:

// FROM node:12.0.1
// RUN apk add git
// EXPOSE 3000

// `;

function getDefaultForm(dockerBuild?: IDockerBuild): IFormGrid {
    return [
        {
            name: "dockerfile",
            label: "Dockerfile",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Dockerfile is required",
            defaultValue: dockerBuild?.DockerFileContent,
            onRender: (value: string, setValue: (content: string) => void) => (
                <CodeEditor language="dockerfile" value={value} setValue={setValue} options={{
                    lineNumbers: "off",
                }} />
            )
        },
    ]
}

type IDockerBuild = {
    DockerFileContent: string;
}

export type ICreateDockerBuildFlowFlowStep = {
    Type: FlowElements.DockerBuild;
    DockerBuild: IDockerBuild;
}

export const DockerBuildFlowStepCard: FC<IGraphCardProps<IDockerBuild>> = (props) => {
    const { setCacheItem } = useContext(FlowGraphViewContext);
    const [formProps, formCallbacks] = useFormHook();

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertDockerBuildFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleResponse = useCallback((text: string) => {
    //     formCallbacks.setForm("dockerfile", text);
    // }, [formCallbacks]);

    return (
        <FlowStepCard label="Docker build" {...props} className="h-[400px] w-[500px]">
            <>
                <div className="flex flex-col grow">
                    <div className="flex flex-row justify-between">
                        <strong><label className={classNames(ClassNames.Text, "text-xs")}>Dockerfile</label></strong>
                        <div className="flex gap-1 items-center">
                            {/* <AiBubble placeholder="Ask co-pilot to write Dockerfile" onResponse={handleResponse} systemPrompt={DOCKER_SYSTEM_PROMPT} /> */}
                            <Tooltip tooltip={"The git repo is mounted on /app from --cloner step"}>
                                <span className={ClassNames.Text}>{Icons.Info}</span>
                            </Tooltip>
                        </div>
                    </div>
                    <Form variables={getDefaultForm(props.data)} {...formProps} />
                </div>
            </>
        </FlowStepCard>
    )
}


const convertDockerBuildFormToStep = (form: IForm): ICreateDockerBuildFlowFlowStep => {
    return {
        Type: FlowElements.DockerBuild,
        DockerBuild: {
            DockerFileContent: form.dockerfile as string,
        }
    }
}
import classNames from "classnames";
import { ChangeEventHandler, DetailedHTMLProps, FC, InputHTMLAttributes, KeyboardEvent, KeyboardEventHandler, ReactElement, cloneElement, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { twMerge } from "tailwind-merge";
import { Icons } from "../config/icons";
import { CommonActions } from "../store/common";
import { ClassNames } from "./classes";

export const Label: FC<{ label: string, className?: string }> = ({ label, className }) => {
    return <strong><label className={twMerge(classNames(ClassNames.Text, "text-xs mt-2", className))}>{label}</label></strong>
}

type InputProps = {
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    placeholder?: string;
    value: string;
    setValue?: (value: string) => void;
    type?: "text" | "password";
    multiline?: boolean;
    autoHeight?: boolean;
    onSubmit?: () => void;
}

const TextArea: FC<InputProps> = ({ value, setValue, type, placeholder, inputProps = {}, autoHeight, onSubmit }) => {
    const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        setValue?.(e.target.value);
    }, [setValue]);

    const handleInput: KeyboardEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        if (autoHeight) {
            const target = e.target as HTMLTextAreaElement;
            target.style.height = 'inherit';
            target.style.height = `${Math.min(target.scrollHeight, 300)}px`;
        }
    },[autoHeight]);

    const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        if (e.ctrlKey && e.key === "Enter") {
            onSubmit?.();
        }
    }, [onSubmit]);

    return <textarea placeholder={placeholder} name={type} cols={40} rows={1}
        value={value} onChange={handleTextChange}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        className={twMerge(classNames(ClassNames.Text, "transition-all resize-none overflow-hidden appearance-none border border-neutral-200 dark:border-neutral-200/10 rounded-lg w-full p-1 leading-tight outline-none text-sm h-[34px] px-2", inputProps.className))} />
};

export const Input: FC<InputProps> = ({ value, setValue, type, placeholder, multiline, inputProps = {}, autoHeight, onSubmit }) => {
    const dispatch = useDispatch();

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        setValue?.(e.target.value);
        inputProps.onChange?.(e);
    }, [inputProps, setValue]);

    const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        if (e.metaKey && e.key === "k") {
            dispatch(CommonActions.setGlobalSearch(true));
            return e.preventDefault();
        }
        inputProps.onKeyDown?.(e);
    }, [dispatch, inputProps]);
    
    if (multiline) {
        return <TextArea value={value} setValue={setValue} type={type} placeholder={placeholder}
                    inputProps={inputProps} autoHeight={autoHeight} onSubmit={onSubmit} />
    }
    return <input type={type} placeholder={placeholder}
        value={value}  {...inputProps} onChange={handleChange} onKeyDown={handleKeyDown}
        className={twMerge(classNames(ClassNames.Input, inputProps.className, {
            "opacity-50 pointer-events-none": inputProps.disabled,
        }))} />
}

type InputWithLabelProps = {
    label: string;
    labelClassName?: string;
    className?: string;
    icon?: ReactElement;
} & InputProps;

export const InputWithlabel: FC<InputWithLabelProps> = ({ className, value, setValue, label, type = "text", placeholder = `Enter ${label.toLowerCase()}`, inputProps, labelClassName, icon }) => {
    const [hide, setHide] = useState(true);

    const handleShow = useCallback(() => {
        setHide(status => !status);
    }, []);

    const inputType = type === "password" ? hide ? "password" : "text" : type;

    return <div className={classNames("flex flex-col gap-1", className)}>
        <div className="flex gap-1 items-center">
            {icon != null && cloneElement(icon, {
                className: classNames("w-4 h-4", ClassNames.Text),
            })}
            <Label className={classNames(labelClassName, {
                [ClassNames.DisabledText]: inputProps?.disabled,  
            })} label={label} />
        </div>
        <div className="relative">
            <Input type={inputType} value={value} setValue={setValue} inputProps={inputProps} placeholder={placeholder} />
            {type === "password" && cloneElement(hide ? Icons.Show : Icons.Hide, {
                className: classNames("w-4 h-4 absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer transition-all hover:scale-110", ClassNames.DisabledText),
                onClick: handleShow,
            })}
        </div>
    </div>
}

type ICheckboxInputProps = {
    className?: string;
    checked?: boolean;
    onChecked?: (checked: boolean) => void;
    disabled?: boolean;
}

export const CheckboxInput: FC<ICheckboxInputProps> = (props) => {
    return <input type="checkbox" checked={props.checked} onChange={e => props.onChecked?.(e.target.checked)} disabled={props.disabled}
                className={classNames("w-4 h-4 text-teal-600 bg-neutral-100 border-neutral-300 rounded focus:ring-teal-500 focus:ring-2", props.className)} />
}

type ICheckboxInputWithLabelProps = {
    label: string;
    labelClassName?: string;
} & ICheckboxInputProps;

export const CheckboxInputWithLabel: FC<ICheckboxInputWithLabelProps> = (props) => {
    return  <div className="flex gap-2 items-center justify-between py-1 pr-1">
        <Label className={classNames(props.labelClassName, {
            [ClassNames.DisabledText]: props.disabled,  
        })} label={props.label} />
        <CheckboxInput checked={props.checked} disabled={props.disabled} onChecked={props.onChecked} />
    </div>
}
import classNames from "classnames";
import { FC, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AnimatedButtonDropdown } from "../../../components/button";
import { AdjustableCard, GraphBasedExpandableCard } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { IGraphCardProps } from "../../../components/graph/graph";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes, navigateWithPathParameters } from "../../../config/internal-routes";
import { Flow, GetFlowDocument, GetFlowsDocument, useCreateFlowShareableStatusMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { copyToClipboard } from "../../../utils/functions";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";

export const CI_CD_FLOW_ICON = {
    component: Icons.CI_CD.Flow.Default,
    bgClassName: "bg-orange-500",
}

type IFlowCardProps = {
    flow: Flow;
    refetch?: () => void;
}

export const FlowCard: FC<IFlowCardProps> = (props) => {
    const navigate = useNavigate();
    const [searchParams, ] = useSearchParams();
    const [createFlowShareableStatus, ] = useCreateFlowShareableStatusMutation();

    const handleCopyStatusLink = useCallback(() => {
        createFlowShareableStatus({
            variables: {
                id: props.flow.Id,
            },
            onCompleted(data) {
                if (data?.CreateFlowShareableStatus?.Link == null) {
                    return notify("Unable to copy status shareable link", "error");
                }
                copyToClipboard(data.CreateFlowShareableStatus.Link);
                notify("Copied status shareable link", "success");
            },
        });
    }, [createFlowShareableStatus, props.flow.Id]);

    const exploreButtons = useMemo(() => {
        return [
            {
                label: "Explore",
                icon: Icons.CircleRightArrow,
                onClick: () => navigateWithPathParameters(navigate, InternalRoutes.CI_CD.ExploreFlow.path, { id: props.flow.Id }),
                iconClassName: "w-3 h-3",
            },
            {
                label:"Copy status link",
                icon: Icons.Clipboard,
                onClick: handleCopyStatusLink,
            },
        ]
    }, [handleCopyStatusLink, navigate, props.flow.Id]);
    
    
    return (
        <GraphBasedExpandableCard className="group/flow" id={props.flow.Id} type={GraphElements.Flow} icon={{
            component: Icons.CI_CD.Flow.Default,
            bgClassName: CI_CD_FLOW_ICON.bgClassName,
        }}
            highlight={searchParams.get("id") === props.flow.Id}
            bgClassName={CI_CD_FLOW_ICON.bgClassName}>
            <>
                <div className={classNames(ClassNames.Text, "grow text-lg mt-2")}>{props.flow.Name}</div>
                <div className="flex flex-row items-center justify-end">
                    <AnimatedButtonDropdown buttons={exploreButtons} />
                </div>
            </>
            <div></div>
        </GraphBasedExpandableCard>
    );
}

export const FlowDashboardCard: FC<{ flow: Flow }> = (props) => {
    return <AdjustableCard showTools={true} icon={CI_CD_FLOW_ICON}>
        <div className="flex flex-col grow mt-2">
            <div className={classNames(ClassNames.Text, "text-md")}>
                {props.flow.Name}
            </div>
        </div>
    </AdjustableCard>
}

export const transformFlowData: IDataTransform<Flow>  = (data: { Flow: Flow[]}) => data.Flow;

export const FlowGraphCard: FC<IGraphCardProps<Flow>> = (props) => {
    return <GraphCardLoader loader={GetFlowDocument} transform={(data) => transformFlowData(data)[0]} {...props}>
        {(data: Flow) => (<FlowDashboardCard flow={data} />)}
    </GraphCardLoader>;
}

export const AddFlowDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Flows"
        query={GetFlowsDocument}
        transform={transformFlowData} type={GraphElements.Flow}
        icon={Icons.CI_CD.Flow.Default}
        link={InternalRoutes.CI_CD.CreateFlow.path} />
    );
}
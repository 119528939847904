import { configureStore } from '@reduxjs/toolkit'
import { repositoryReducers } from './repository'
import { authReducers } from './auth'
import { commonReducers } from './common'
import { globalReducers } from './global'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

export const reduxStore = configureStore({
  reducer: {
    auth: authReducers,
    repository: repositoryReducers,
    common: commonReducers,
    global: persistReducer({ key: "global", storage, }, globalReducers),
  },
})


export const reduxStorePersistor = persistStore(reduxStore);
export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch
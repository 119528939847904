import { FC } from "react";
import { Page } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { RegistryImage, useGetRegistryImagesQuery } from "../../../generated/graphql";
import { CreateRegistryImageCard, RegistryImageCard } from "./registry-image-card";

type IRegistryImagePageProps = {
    isCreating?: boolean;
}

export const RegistryImagePage: FC<IRegistryImagePageProps> = (props) => {
    const getAllRegistryImagesQueryResponse = useGetRegistryImagesQuery();
    return <Page routes={[InternalRoutes.Container.Image]}>
        <CreateRegistryImageCard isCreating={props.isCreating} refetch={getAllRegistryImagesQueryResponse.refetch} />
        {
            getAllRegistryImagesQueryResponse.data?.RegistryImage.map(registryImage => (
                <RegistryImageCard key={registryImage.Id} registryImage={registryImage as RegistryImage} refetch={getAllRegistryImagesQueryResponse.refetch} />
            ))
        }
    </Page>
}
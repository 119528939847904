import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { debounce } from "lodash";
import { FC, cloneElement, useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClassNames } from "./classes";
import { twMerge } from "tailwind-merge";

type IRoute = {
    icon?: React.ReactElement;
    name: string;
    path: string;
}

type IRouteProps = {
    title: string;
    icon: React.ReactElement;
    defaultPath?: string;
    routes?: IRoute[];
    collapse?: boolean;
};

export const SideMenu: FC<IRouteProps> = (props) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const status = hover ? "show" : "hide";
    const { pathname } = useLocation();

    const handleMouseEnter = useMemo(() => {
        return debounce(() => setHover(true));
    }, []);

    const handleMouseLeave = useMemo(() => {
        return debounce(() => setHover(false));
    }, []);

    const handleClick = useCallback(() => {
        if (props.defaultPath == null) {
            return;
        }
        navigate(props.defaultPath);
    }, [navigate, props.defaultPath]);

    const hasChildren = useMemo(() => {
        if (props.routes == null) {
            if (props.defaultPath == null) {
                return false;
            }
            return pathname.startsWith(props.defaultPath);
        }
        return props.routes.find(route => pathname.startsWith(route.path)) != null;
    }, [pathname, props.defaultPath, props.routes]);

    return <div className={classNames("flex items-center", {
        "justify-center": props.collapse,
    })}  onMouseEnter={handleMouseEnter} onMouseOver={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <AnimatePresence mode="wait">
            <div className={twMerge(classNames(ClassNames.Text, "cursor-default text-md inline-flex gap-1 transition-all hover:gap-2 relative w-full py-4 rounded-md hover:bg-black/10 dark:hover:bg-white/10", {
                "cursor-pointer": props.defaultPath != null,
                "bg-black/5 dark:bg-white/5": hasChildren,
                "px-2": !props.collapse,
                "justify-center": props.collapse,
            }))} onClick={handleClick}>
                {cloneElement(props.icon, {
                    className: classNames("transition-all", {
                        "w-4 h-4": !props.collapse,
                        "w-6 h-6 hover:scale-110": props.collapse,
                    })
                })}
                { props.collapse ? null : props.title }
                {
                    props.routes != null &&
                    <motion.div className="absolute z-40 divide-y rounded-lg shadow-lg min-w-[250px] backdrop-blur-lg bg-white/50 dark:bg-white/5 left-[100%] -top-[20px] border border-neutral-200 dark:border-neutral-200/10" variants={{
                        hide: {
                            scale: 0.9,
                            opacity: 0,
                            x: 10,
                            transition: {
                                duration: 0.1,
                            },
                            transitionEnd: {
                                display: "none",
                            }
                        },
                        show: {
                            scale: 1,
                            opacity: 100,
                            x: 0,
                            display: "flex",
                        }
                    }} initial={status} animate={status}>
                        <ul className="py-2 px-2 text-sm flex flex-col justify-center w-full">
                            {props.routes.map(route => (
                                <Link className={classNames(ClassNames.Text, "flex items-center gap-1 transition-all hover:gap-2 hover:bg-neutral-100 dark:hover:bg-neutral-100/10 w-full rounded-md pl-2 py-2")} key={route.path} to={route.path}>
                                    {route.icon && cloneElement(route.icon, {
                                        className: "w-4 h-4"
                                    })}
                                    {route.name}
                                </Link>
                            ))}
                        </ul>
                    </motion.div>
                }
            </div>
        </AnimatePresence>
    </div>
}
import classNames from "classnames";
import { cloneElement, FC, useCallback, useState } from "react";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../config/icons";
import { AnimatedButton, CancelButton } from "../../components/button";
import { motion } from "framer-motion";
import { Pill } from "../../components/pill";

const tiers = [
    {
        name: "Trial",
        features: [
            "100 milli core CPU",
            "200MB RAM",
            "Everything in Essential for 2 weeks",
        ],
        price: 0,
    },
    {
        name: "Essential",
        features: [
            "250 milli core CPU",
            "500MB RAM",
            "Up to 1 Domain",
            "Up to 5 Environment Variables",
            "Up to 2 Flow",
            "Up to 2 Hooks",
            "Up to 5 Deployments",
        ],
        price: 10,
    },
    {
        name: "Startup",
        features: [
            "500 milli core CPU",
            "1GB RAM",
            "Upto 10GB persistent storage",
            "Up to 3 Domain",
            "No limit on Environment Variables",
            "Up to 5 Flow",
            "Up to 5 Hooks",
            "Up to 10 Deployments",
            "Up to 3 external clusters",
        ],
        price: 25,
        popular: true,
    },
    {
        name: "Professional",
        features: [
            "Usage based pricing",
            "No limit on Domain",
            "No limit on Environment Variables",
            "No limit on Flow",
            "No limit on Hooks",
            "No limit on Deployments",
            "Team Permissions",
        ],
    },
    {
        name: "Enterprise",
        features: [
            "Usage based pricing",
            "No limit on Domain",
            "No limit on Environment Variables",
            "No limit on Flow",
            "NO limit on Hooks",
            "No limit on Deployments",
            "Team Permissions",
            "24/7 Support",
        ],
    }
];

export const TierSelection: FC<{ loading?: boolean, onClick?: (tierType: string) => void, onCancel?: () => void }> = ({ loading, onClick, onCancel }) => {
    const [lastClicked, setLastClicked] = useState<string>();

    const handleClick = useCallback((tierType: string) => {
        setLastClicked(tierType);
        onClick?.(tierType);
    }, [onClick]);
    
    return (
        <motion.div className="fixed inset-0 bg-white/50 dark:bg-black/50 backdrop-blur-lg flex justify-center items-center z-50"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 100, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}>
            <div className="flex flex-col gap-4">
                <div className={classNames(ClassNames.Text, "text-4xl flex gap-2 items-center")}>
                    Choose Your Tier
                    {cloneElement(Icons.RightChevron, {
                        className: "w-8 h-8",
                    })}
                </div>
                <div className="flex gap-4 items-center">
                    {tiers.map((tier) => (
                        <div key={tier.name} className={classNames("flex flex-col gap-4 border border-white/5 w-[350px] h-[650px] bg-black/5 dark:bg-white/5 rounded-3xl p-8 relative", {
                            "border-teal-500": tier.popular,
                        })}>
                            {tier.popular && <div className="absolute top-4 right-4">
                                <Pill id="popular" label="Popular" />
                            </div>}
                            <div className={classNames(ClassNames.Text, "text-3xl")}>
                                {tier.name}
                            </div>
                            <div className="w-full border-b border-black/20 dark:border-white/20" />
                            <div className="flex flex-col gap-4 grow">
                                {tier.features.map((feature) => (
                                    <div key={feature} className={classNames(ClassNames.Text, "flex gap-2")}>
                                        {Icons.CheckCircle}
                                        <div className="text-lg">
                                            {feature}
                                        </div>
                                    </div>
                                ))}
                                <div className="grow flex flex-col justify-end gap-8">
                                    {
                                        tier.price != null &&
                                        <div className={classNames(ClassNames.Text, "text-4xl flex justify-center items-end")}>
                                            ${tier.price} <span className="text-lg">/month</span>
                                        </div>
                                    }
                                    {
                                        onClick != null &&
                                        <div className="flex justify-end items-center">
                                            {(tier.name === "Trial") && <AnimatedButton loading={lastClicked === tier.name && loading} disabled={loading} label="Get Started For Free" icon={Icons.RightChevron} onClick={() => handleClick(tier.name)} />}
                                            {tier.name !== "Trial" && <AnimatedButton loading={lastClicked === tier.name && loading} disabled={loading} label="Choose" icon={Icons.RightChevron} onClick={() => handleClick(tier.name)} />}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {
                    onCancel != null &&
                    <div className="flex justify-end items-center">
                        <CancelButton onClick={onCancel} />
                    </div>
                }
            </div>
        </motion.div>
    );
};

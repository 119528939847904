import { includes } from "lodash";
import { DetailedHTMLProps, FC, InputHTMLAttributes, KeyboardEventHandler, ReactElement, ReactNode, cloneElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icons } from "../config/icons";
import { AnimatedButton } from "./button";
import { Dropdown, IDropdownItem, IDropdownProps } from "./dropdown";
import { Input } from "./input";
import { Pill } from "./pill";


type ISearchInputProps = {
    search: string;
    setSearch: (search: string) => void;
    placeholder?: string;
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    children?: ReactNode;
}

export const SearchInput: FC<ISearchInputProps> = ({ search, setSearch, placeholder, inputProps, children }) => {
    return (<div className="relative grow flex gap-2">
        {children}
        <Input value={search} setValue={setSearch} placeholder={placeholder} inputProps={{ autoFocus: true, ...inputProps }} />
        {cloneElement(Icons.Search, {
            className: "w-4 h-4 absolute right-2 top-1/2 -translate-y-1/2 stroke-neutral-500 dark:stroke-neutral-500 cursor-pointer transition-all rounded-full",
        })}
    </div>)
}

export type SearchProps = {
    label: string;
    items: IDropdownItem[];
    className?: string;
    isOpen?: boolean;
    selectedItem?: IDropdownItem | IDropdownItem[];
    onSelect?: (item: IDropdownItem) => void;
    noItemsLabel?: string;
    actionIcon?: ReactElement;
    actionLabel?: string;
    children?: ReactNode;
    dropdownProps?: Partial<IDropdownProps>;
    disabled?: boolean;
    controlled?: boolean;
    inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    tags?: string[];
    onTagRemove?: (tag: string) => void;
    onTab?: (item: IDropdownItem) => void;
}

export const Search: FC<SearchProps> = ({ label, items, className, selectedItem, isOpen, controlled, onSelect, noItemsLabel, actionIcon, actionLabel, children, dropdownProps = {}, disabled, inputProps, tags, onTab, onTagRemove }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
    const [shouldSearch, setShouldSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const tagContainerRef = useRef<HTMLDivElement>(null);
    const [searchInputLeftPadding, setSearchInputLeftPadding] = useState(0);

    const filteredItems = useMemo(() => {
        return items.filter(item => item.fixed || includes(item.id, search) || includes(item.label, search));
    }, [items, search]);

    const handleClick = useCallback(() => {
        setShouldSearch(true);
        setIsDropdownOpen(true);
    }, []);

    const handleSelect = useCallback((item: IDropdownItem) => {
        setShouldSearch(false);
        onSelect?.(item);
        setIsDropdownOpen(false);
        setSearch("");
    }, [onSelect]);

    const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
        if (event.key === "ArrowUp") {
            if (selectedIndex == null) {
                setSelectedIndex(filteredItems.length - 1);
            } else {
                setSelectedIndex((filteredItems.length + (selectedIndex - 1)) % filteredItems.length);
            }
        } else if (event.key === "ArrowDown") {
            if (selectedIndex == null) {
                setSelectedIndex(0);
            } else {
                setSelectedIndex((selectedIndex + 1) % filteredItems.length);
            }
        } else if (event.key === "Enter" && selectedIndex != null) {
            handleSelect(filteredItems[selectedIndex]);
        }
        inputProps?.onKeyUp?.(event);
    }, [selectedIndex, filteredItems, inputProps, handleSelect]);

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
        if (event.key === "Tab" && selectedIndex != null) {
            onTab?.(filteredItems[selectedIndex]);
            setSearch("");
            event.preventDefault();
            event.stopPropagation();
        }
        inputProps?.onKeyDown?.(event);
    }, [filteredItems, inputProps, onTab, selectedIndex]);
    
    const handleClearSelection = useCallback(() => {
        setSelectedIndex(undefined);
    }, []);

    useEffect(() => {
        if (tags == null) {
            return setSearchInputLeftPadding(0);
        }
        if (tagContainerRef.current) {
            const tagContainerWidth = tagContainerRef.current.offsetWidth;
            setSearchInputLeftPadding(tagContainerWidth + 16);
        }
    }, [tags]);

    const showTags = isOpen && tags != null && tags.length > 0;

    return <Dropdown {...dropdownProps} disabled={disabled} className={className} items={filteredItems} isOpen={controlled ? isOpen : isDropdownOpen} action={<div>
        <AnimatedButton label={actionLabel ?? "Select"} icon={actionIcon ?? Icons.CheckCircle} labelClassName="text-blue-800 dark:text-blue-300" iconClassName="text-blue-600 dark:text-blue-300" />
    </div>} onClick={disabled ? undefined : handleSelect} noItemsLabel={noItemsLabel}
        highlightedDropdownItem={selectedIndex == null ? undefined : filteredItems[selectedIndex]} onItemHover={handleClearSelection}
        selectedItems={selectedItem}>
        <>
            {
                selectedItem != null && !shouldSearch
                    ? <div onClick={disabled ? undefined : handleClick}>{children}</div>
                    : <SearchInput search={search} setSearch={setSearch} placeholder={showTags ? undefined : label} inputProps={{
                        ...inputProps,
                        onKeyUp: handleKeyUp,
                        onKeyDown: handleKeyDown,
                        style: {
                            paddingLeft: showTags ? Math.max(searchInputLeftPadding, 8) : 8,
                        },
                    }}>
                        {
                            showTags &&
                            <div ref={tagContainerRef} className="absolute top-1/2 left-2 -translate-y-1/2 flex gap-2">
                                {tags.map(tag => <Pill id={tag} label={tag} handleRemove={() => onTagRemove?.(tag)} />)}
                            </div>
                        }
                    </SearchInput>
            }
        </>
    </Dropdown>
}
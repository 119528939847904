import classNames from "classnames";
import { FC, cloneElement } from "react";
import { twMerge } from "tailwind-merge";
import { Icons } from "../config/icons";
import { ClassNames } from "./classes";

interface IPillProps {
    id: string;
    label: string;
    handleRemove?: (id: string) => void;
    className?: string;
    ellipses?: boolean;
}

export const Pill: FC<IPillProps> = (props) => {
    return (
        <div className={twMerge(classNames("cursor-default text-xs flex items-center px-3 py-1 rounded-full gap-1 transition-all bg-teal-100 dark:bg-teal-800 text-teal-800 dark:text-teal-500 hover:gap-2", {
            "bg-green-100 dark:bg-green-800": props.id === "Success",
            "bg-red-100 dark:bg-red-800": props.id === "Error" || props.id === "Failed",
            "bg-cyan-100 dark:bg-cyan-800": props.id === "Started",
            "bg-slate-200 dark:bg-slate-800": props.id === "Cancelled",
            "bg-blue-100 dark:bg-blue-800": props.id.endsWith("ing"),
        }), props.className)}>
            <div className={classNames(ClassNames.Text, {
                [ClassNames.Ellipses]: props.ellipses,
            })}>
                {props.label}
            </div>
            {
                props.handleRemove != null &&
                <div className="cursor-pointer transition-all hover:scale-125" onClick={() => props.handleRemove?.(props.id)}>
                    {cloneElement(Icons.Delete, {
                        className: "h-4 w-4 stroke-teal-800 dark:stroke-teal-500",
                    })}
                </div>
            }
        </div>
    )
}

type IPillsProps = {
    pills: IPillProps[];
    hideOverflow?: boolean;
    pillsCount?: number;
}

export const Pills: FC<IPillsProps> = ({
    pills,
    hideOverflow = true,
    pillsCount = 2,
}) => {
    return <>
        {
            hideOverflow
            ? <>
                {
                    pills.slice(0, pillsCount).map((pill) => (
                        <Pill {...pill} />
                    ))
                }
                {
                    pills.length > pillsCount &&
                    <Pill id={`${pills.length - pillsCount}+`} label={`${pills.length - pillsCount}+`} />
                }
            </>
            : pills.map((pill) => (
                <Pill {...pill} />
            ))
        }
    </>
}
import classNames from "classnames";
import { forEach, map } from "lodash";
import { FC, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Edge, Node, ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import { useImmer } from "use-immer";
import { AnimatedButton } from "../../../components/button";
import { Card } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { createEdge, createNode } from "../../../components/graph/utils";
import { Pill } from "../../../components/pill";
import { Icons } from "../../../config/icons";
import { HookRun, HookStepType, useGetHookRunLazyQuery } from "../../../generated/graphql";
import { HookGraphViewContext, IHookGraphData, IHookGraphViewCache } from "./context";
import { CI_CD_HOOK_ICON, GraphHooksCard } from "./hooks-card";
import { IGraphInstance } from "../../../components/graph/graph";

type IHookRunPreviewProps = {
    hookRun: Omit<HookRun, "Steps">;
    hookId: string;
}

export const HookRunPreview: FC<IHookRunPreviewProps> = ({ hookId, hookRun }) => {
    const [getOneHookSteps, ] = useGetHookRunLazyQuery();
    const [cache, setCache] = useImmer<IHookGraphViewCache>({});
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const navigate = useNavigate();
    const reactFlowRef = useRef<IGraphInstance>();

    const handleOnReady = useCallback((instance: IGraphInstance) => {
        reactFlowRef.current = instance;
    }, []);

    const handleSetCacheItem = useCallback((key: string, value: IHookGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    useEffect(() => {
        getOneHookSteps({
            variables: {
                hookId,
                id: hookRun.Id,
            },
            onCompleted(data) {
                const hookRunSteps = data?.HookRuns?.[0]?.Steps ?? [];
                const newNodes: Node[] = map(hookRunSteps, (hookRunStep, i: number) => {
                    const node = createNode({
                        id: hookRunStep.Step.NodeId,
                        type: hookRunStep.Step.Type,
                        data: {},
                    }, {
                        x: i * 400,
                        y: 0,
                    });
                    switch(hookRunStep.Step.Type) {
                        case HookStepType.GitWebHook:
                            node.data = hookRunStep.Step.GitWebHook;
                            break;
                        case HookStepType.FlowOperation:
                            node.data = hookRunStep.Step.FlowOperation;
                            break;
                        case HookStepType.OperationResult:
                            node.data = hookRunStep.Step.OperationResult;
                            break;
                        case HookStepType.QuickContainerOperation:
                            node.data = hookRunStep.Step.QuickContainerOperation;
                            break;
                        case HookStepType.GitPullRequestComment:
                            node.data = hookRunStep.Step.GitPullRequestComment;
                            break;
                        case HookStepType.GitRelease:
                            node.data = hookRunStep.Step.GitRelease;
                            break;
                        case HookStepType.Mail:
                            node.data = hookRunStep.Step.Mail;
                            break;
                    }
                    node.data = {...node.data, Status: hookRunStep.Status};
                    return node;
                }) ?? [];
                setNodes(newNodes);
                const newEdges: Edge[] = [];
                forEach(hookRunSteps, (hookRunStep) => {
                    forEach(hookRunStep.Step.Dependents, dependent => {
                        newEdges.push(createEdge(hookRunStep.Step.NodeId, dependent));
                    });
                });
                setEdges(newEdges);
                setTimeout(() => {
                    reactFlowRef.current?.layout("dagre");
                }, 300);
            },
        });
    }, [hookId, hookRun, navigate, getOneHookSteps, setNodes, setEdges]);

    return <HookGraphViewContext.Provider value={{
        cache,
        setCacheItem: handleSetCacheItem,
        removeCacheItem: handleRemoveCacheItem,
    }}>
        <ReactFlowProvider>
            <GraphHooksCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} onReady={handleOnReady} />
        </ReactFlowProvider>
    </HookGraphViewContext.Provider>
}

type IHookRunsCardProps = {
    name: string;
    hookRun: Omit<HookRun, "Steps">;
    onClick: () => void;
}

export const HookRunCard: FC<IHookRunsCardProps> = ({ name, hookRun, onClick }) => {
    return <div>
        <Card className="h-[150px] w-full" icon={CI_CD_HOOK_ICON}
            tag={<Pill id={hookRun.Status} label={hookRun.Status} />}>
            <div className={classNames(ClassNames.Text, "grow text-lg mt-2")}>{name}</div>
            <div className="flex flex-row items-center justify-end">
                <AnimatedButton label="Explore Run" icon={Icons.CircleRightArrow} onClick={onClick} />
            </div>
        </Card>
    </div>
}
import { createContext } from "react";
import { XYPosition } from "reactflow";
import { GraphElements } from "../../config/constants";
import { DigitalOceanIntegration, DockerIoRegistryIntegration, Domain, Flow, GitHubIntegration, GoogleIntegration, Hook, MailIntegration, QuickContainer, Registry, RegistryImage } from "../../generated/graphql";

export type IDataTransform<T extends unknown = any, V extends unknown = any> = (data: V) => T[]

export type IDashboardElement = ({ type: GraphElements.QuickContainer } & QuickContainer) |
    ({ type: GraphElements.Registry } & Registry) |
    ({ type: GraphElements.RegistryImage } & RegistryImage) |
    ({ type: GraphElements.DockerIORegistryIntegration } & DockerIoRegistryIntegration) |
    ({ type: GraphElements.Hook } & Hook) |
    ({ type: GraphElements.Flow } & Flow) |
    ({ type: GraphElements.Domain } & Domain) |
    ({ type: GraphElements.GitHubIntegration } & GitHubIntegration) |
    ({ type: GraphElements.DigitalOceanIntegration } & DigitalOceanIntegration) | 
    ({ type: GraphElements.GoogleIntegration } & GoogleIntegration) |
    ({ type: GraphElements.MailIntegration } & MailIntegration);


export type IAddDashboardElementGraphView = (id: string, type: GraphElements, data?: IDashboardElement, position?: XYPosition) => void;

type IGraphViewContext = {
    addDashboardElement: IAddDashboardElementGraphView;
    removeDashboardElement: (id: string) => void;
    addEdge: (fromId: string, toId: string) => void;
    selectedNodes: Record<string, boolean>;
}

export const GraphViewContext = createContext<IGraphViewContext>({} as IGraphViewContext);

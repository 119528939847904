import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactNode } from "react";
import { useAppSelector } from "../store/hooks";
import { Breadcrumb, IBreadcrumbRoute } from "./breadcrumb";
import { ClassNames } from "./classes";
import { Pill } from "./pill";
import { Loading } from "./loading";

type ContainerProps = {
    children: React.ReactElement;
}

export const Container: FC<ContainerProps> = ({ children }) => {
    const sideMenuCollapsed = useAppSelector(state => state.global.sideMenuCollapsed);

    return <div className="flex flex-1 flex-grow">
        <div className={classNames("absolute top-6 left-6 flex gap-1 items-center", {
            "hidden": sideMenuCollapsed,
        })}>
            <img src="/images/logo.svg" alt="logo" width="15" />
            <div className={classNames(ClassNames.Text, "text-md mt-[1px]")}>
                Clidey
            </div>
        </div>
        {children}
    </div>
}

type PageProps = {
    className?: string;
    children: ReactNode;
    routes?: IBreadcrumbRoute[];
    flag?: string;
}

export const Page: FC<PageProps> = (props) => {
    return <div className="flex px-8 pt-8 flex-col grow">
        <Breadcrumb routes={props.routes ?? []} active={props.routes?.at(-1)} />
        <div className="mb-8" />
        <div className={classNames("flex flex-row grow flex-wrap gap-2 justify-items-start content-start w-full max-h-[calc(100vh-100px)] overflow-y-auto", props.className)}>
            {props.children}
        </div>
        <AnimatePresence>
            {
                props.flag != null &&
                <motion.div className="absolute bottom-4 right-4 cursor-default" 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 100, }}
                    exit={{ opacity: 0 }}>
                    <Pill id={props.flag} label={props.flag} className="bg-orange-600 text-white transition-all opacity-50 hover:opacity-100" />
                </motion.div>
            }
        </AnimatePresence>
    </div>
}

export const LoadingPage: FC = () => {
    return  <div className="fixed inset-0 flex h-[100vh] w-[100vw] justify-center items-center backdrop-blur-md">
    <Loading />
  </div>
}
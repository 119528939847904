import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { notify } from "../store/function";
import { toTitleCase } from "../utils/functions";

const isTlsEnabled = window.location.protocol === "https:";

const httpLink = new HttpLink({
  uri: `${window.location.protocol}//${window.location.host}/api`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${isTlsEnabled ? "wss" : "ws"}://${window.location.host}/api`,
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      notify(toTitleCase(message), "error")
    );
  }
  // if (networkError) {
  //   notify(`Network error: ${networkError.message}`);
  // }
});


const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

export const graphqlClient = new ApolloClient({
  link: errorLink.concat(splitLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    // todo: fix for better caching / caching causes trouble for not queries what user requires
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  }
});

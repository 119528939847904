import { filter, map } from "lodash"
import { FC, useContext, useEffect, useMemo } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { Node, useEdges, useNodes, useReactFlow } from "reactflow"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { InternalRoutes } from "../../../../config/internal-routes"
import { CreateQuickContainerOperationHookStep, GetFlowsDocument, GetQuickContainersDocument, HookStepType, QuickContainerOperationHookStep } from "../../../../generated/graphql"
import { getAllReferencesRecursively } from "../../../../utils/functions"
import { HookGraphViewContext } from "../context"
import { HooksStepCard } from "../hooks-step-card"
import { ICreateFlowOperationHookStep } from "./flow-operation-hook-step-card"


function getDefaultForm(navigate: NavigateFunction, flowOperations?: ICreateFlowOperationHookStep[], quickContainerOperation?: QuickContainerOperationHookStep, disabled?: boolean): IFormVariable[] {
    return [
        {
            name: "quickContainerId",
            label: "Quick Container",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Quick container is required",
            query: GetQuickContainersDocument,
            transform(data) {
                return map(data?.QuickContainer ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                }));
            },
            defaultIcon: Icons.Deploy.QuickContainer.Default,
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create a quick container" },
                onDefaultItemClick() {
                    navigate(InternalRoutes.Deployment.CreatQuickContainer.path);
                },
            },
            defaultValue: quickContainerOperation?.QuickContainer?.Id,
            disabled,
        },
        {
            name: "flowId",
            label: "Image generating Flow",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Quick container is required",
            query: GetFlowsDocument,
            transform(data) {
                return filter(map(data?.Flow ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.CI_CD.Flow.Default,
                })), item => flowOperations?.find(operation => operation.FlowOperation?.FlowId === item.id) != null);
            },
            defaultValue: quickContainerOperation?.Flow?.Id,
            disabled,
        },
        {
            name: "operation",
            label: "Operation Type",
            type: "dropdown",
            fieldType: "text",
            validate: (events: string) => events.length > 0,
            defaultIcon: Icons.Calendar,
            errorMessage: "Operation type is required",
            dropdownProps: {
                items: [
                    {
                        id: "UpdateImageTag",
                        label: "Update image tag",
                    },
                ],
            },
            defaultValue: quickContainerOperation?.Type,
            disabled,
        },
    ]
}


export type ICreateQuickContainerOperationHookStep = {
    Type: HookStepType.QuickContainerOperation;
    QuickContainerOperation: CreateQuickContainerOperationHookStep;
}

export const QuickContainerOperationHookStepCard: FC<IGraphCardProps<QuickContainerOperationHookStep>> = (props) => {
    const { cache, setCacheItem } = useContext(HookGraphViewContext);
    const navigate = useNavigate();
    const [formProps, formCallbacks] = useFormHook();
    const { getNode } = useReactFlow();
    const nodes = useNodes();
    const edges = useEdges();
    const disabled = props.data?.Status != null;

    const referencesFlowOperationCache = useMemo(() => {
        const node = getNode(props.id);
        const flowOperations: ICreateFlowOperationHookStep[] = [];
        if (node == null) {
            return flowOperations;
        }
        const references: Node[] = getAllReferencesRecursively(node, nodes, edges);
        for (const reference of references) {
            if (reference.type !== HookStepType.FlowOperation) {
                continue;
            }
            const cacheValue = cache[reference.id]?.getForm();
            if (cacheValue == null) {
                continue;
            }
            flowOperations.push(cacheValue as ICreateFlowOperationHookStep);
        }
        return flowOperations;
    }, [nodes, edges, props, getNode, cache]);

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertQuickContainerOperationFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HooksStepCard label="Quick container operations" {...props} disableActions={disabled}>
            <Form variables={getDefaultForm(navigate, referencesFlowOperationCache, props.data, disabled)} {...formProps} />
        </HooksStepCard>
    )
}

const convertQuickContainerOperationFormToStep = (form: IForm): ICreateQuickContainerOperationHookStep => {
    return {
        Type: HookStepType.QuickContainerOperation,
        QuickContainerOperation: {
            Type: form.operation as string,
            FlowId: form.flowId as string,
            QuickContainerId: form.quickContainerId as string,
        }
    }
}
import classNames from "classnames";
import { FC } from "react";
import { AnimatedButton } from "../../../components/button";
import { Card } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { Pill } from "../../../components/pill";
import { Icons } from "../../../config/icons";
import { CI_CD_FLOW_ICON } from "./flow-card";
import { FlowRun } from "../../../generated/graphql";

type IFlowRunsCardProps = {
    name: string;
    flowRun: FlowRun;
    onClick?: () => void;
}

export const FlowRunCard: FC<IFlowRunsCardProps> = ({ name, flowRun, onClick }) => {
    return <div>
        <Card className="h-[150px] w-full" icon={CI_CD_FLOW_ICON}
            tag={<Pill id={flowRun.Status} label={flowRun.Status} />}>
            <div className={classNames(ClassNames.Text, "grow text-lg mt-2")}>{name}</div>
            <div className="flex flex-row items-center justify-end">
                <AnimatedButton label="Explore Logs" icon={Icons.CircleRightArrow} onClick={onClick} />
            </div>
        </Card>
    </div>
}
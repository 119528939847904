import classNames from "classnames";
import { motion } from "framer-motion";
import { isArray } from "lodash";
import { FC, ReactElement, cloneElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icons } from "../config/icons";
import { Loading } from "./loading";
import { ClassNames } from "./classes";
import { twMerge } from "tailwind-merge";

export type IDropdownItem<T extends unknown = any> = {
    id: string;
    label: string;
    icon?: ReactElement;
    fixed?: boolean;
    extra?: T;
    nested?: boolean;
};

export type IDropdownProps = {
    className?: string;
    isOpen?: boolean;
    children: ReactElement;
    items: IDropdownItem[];
    scrollContainerClassName?: string;
    onClick?: (item: IDropdownItem) => void;
    defaultItem?: Pick<IDropdownItem, "label" | "icon">;
    onDefaultItemClick?: () => void;
    action?: ReactElement;
    actionClassName?: string;
    noItemsLabel?: string;
    loading?: boolean;
    disabled?: boolean;
    highlightedDropdownItem?: IDropdownItem;
    clearable?: boolean;
    onClear?: () => void;
    clearText?: string;
    onItemHover?: () => void;
    portal?: boolean;
    selectedItems?: IDropdownItem | IDropdownItem[];
}

export const Dropdown: FC<IDropdownProps> = (props) => {
    const [controlled,] = useState(props.isOpen != null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const [hover, setHover] = useState(props.isOpen ?? false);

    const handleClick = useCallback((item: IDropdownItem) => {
        setHover(false);
        props.onClick?.(item);
    }, [props]);

    const isInView = useCallback((element: Element) => {
        const listContainerRefBottom = listContainerRef.current?.getBoundingClientRect();
        if (listContainerRefBottom == null) {
            return false;
        }
        const rect = element.getBoundingClientRect();
        return (rect.top >= listContainerRefBottom.top) && (rect.bottom <= listContainerRefBottom.bottom);
    }, [listContainerRef]);

    useEffect(() => {
        const hightlightedItem = props.highlightedDropdownItem;
        if (hightlightedItem == null || listRef.current == null) {
            return;
        }
        const index = props.items.findIndex(item => item.id === hightlightedItem.id);
        if (index === -1) {
            return;
        }
        const child = listRef.current.children[index];
        if (child == null || isInView(child)) {
            return;
        }
        listRef.current.children[index].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    }, [props.highlightedDropdownItem, props.items, isInView]);

    const handleClear = useCallback(() => {
        setHover(false);
        props.onClear?.();
    }, [props]);

    const updateScrollContainerStyling = useCallback(() => {
        if (listContainerRef.current == null || dropdownRef.current == null) {
            return;
        }
        const clientRect = dropdownRef.current.getBoundingClientRect();
        listContainerRef.current.style.top = `${clientRect.top + clientRect.height}px`;
    }, []);

    const handleHover = useCallback(() => {
        if (props.disabled || controlled) {
            return;
        }
        if (props.portal) {
            updateScrollContainerStyling();
        }
        setHover(true);
    }, [props.disabled, props.portal, controlled, updateScrollContainerStyling]);

    const selectedItemSet = useMemo(() => {
        if (props.selectedItems == null) {
            return new Set();
        }
        if (isArray(props.selectedItems)) {
            return new Set(props.selectedItems.filter(item => item != null).map(item => item.id) ?? []);
        }
        return new Set([props.selectedItems.id]);
    }, [props.selectedItems]);

    return (
        <div className={classNames("relative", props.className)} onMouseEnter={handleHover} onMouseLeave={props.disabled || controlled ? undefined : () => setHover(false)} ref={dropdownRef}>
            {props.loading ? <div className="border border-neutral-200 dark:border-neutral-200/10 rounded-md">
                <Loading loadingText="Loading" size="md" />
            </div> : 
            <>  {props.children}
                <div className={twMerge(classNames("z-10 divide-y rounded-lg shadow bg-white dark:bg-white/10 backdrop-blur-lg py-1 border border-neutral-200 dark:border-neutral-200/5 overflow-y-auto max-h-40", {
                    "hidden": controlled ? !props.isOpen : !hover,
                    "block animate-fade": controlled ? props.isOpen : hover,
                    "fixed w-fit min-w-[150px]": props.portal,
                    "absolute w-full min-w-[150px]": !props.portal,
                }, props.scrollContainerClassName))} ref={listContainerRef}>
                    <ul className="py-1 text-sm text-neutral-700 nowheel flex flex-col" ref={listRef} onMouseEnter={props.onItemHover}>
                        {
                            props.items.map((item) => (
                                <li key={item.id} className={classNames(ClassNames.Text, "group/item flex items-center gap-1 transition-all cursor-pointer relative hover:bg-black/10 py-1 mx-2 rounded-lg pl-1", {
                                    "hover:gap-2": props.action == null && item.icon != null,
                                    "bg-black/10": props.highlightedDropdownItem?.id === item.id,
                                })} onClick={() => handleClick(item)}>
                                    <div>{selectedItemSet.has(item.id) ? Icons.CheckCircle : item.icon}</div>
                                    <div>{item.label}</div>
                                    {props.action != null && <div className={classNames("absolute right-2 top-1/2 -translate-y-1/2 flex gap-2", {
                                        "opacity-0 group-hover/item:opacity-100": props.highlightedDropdownItem?.id !== item.id,
                                    })}>
                                        {item.nested && <div className={classNames(ClassNames.DisabledText, "flex gap-1 items-center")}>Hit {cloneElement(Icons.LeftRight, {
                                            className: "w-4 h-4",
                                        })} Tab to filter</div>}
                                        {cloneElement(props.action, {
                                            className: classNames("cursor-pointer transition-all", props.actionClassName, {
                                                "opacity-100": props.highlightedDropdownItem?.id === item.id,
                                            }),
                                        })}
                                    </div>}
                                </li>
                            ))
                        }
                        {
                            props.items.length === 0 && props.defaultItem != null &&
                            <li className={classNames(ClassNames.Text, "flex items-center gap-1 transition-all cursor-pointer hover:gap-2 px-2", {
                                "hover:scale-105": props.defaultItem.icon == null,
                            })} onClick={props.onDefaultItemClick}>
                                <div>{props.defaultItem.icon}</div>
                                <div>{props.defaultItem.label}</div>
                            </li>
                        }
                        {
                            props.items.length === 0 && props.defaultItem == null &&
                            <li className={classNames(ClassNames.Text, "flex items-center gap-1 px-2")} onClick={props.onDefaultItemClick}>
                                <div>{Icons.SadSmile}</div>
                                <div>{props.noItemsLabel}</div>
                            </li>
                        }
                        {
                            props.clearable &&
                            <li className="flex items-center gap-1 transition-all cursor-pointer hover:gap-2 py-1 px-2 text-rose-900 dark:text-rose-400" onClick={handleClear}>
                                <div>{Icons.Delete}</div>
                                <div>{props.clearText ?? "Clear"}</div>
                            </li>
                        }
                    </ul>
                </div>
            </>}
        </div>
    )
}

type IAnimatedDropdown = {
    items: React.ReactElement[];
    reverse?: boolean;
    className?: string;
}

export const AnimatedDropdown: FC<IAnimatedDropdown> = (props) => {
    const [hover, setHover] = useState(false);
    const status = hover ? "open" : "close";

    return <div className={classNames("relative transition-all hover:gap-2 justify-start", {
        "z-10": hover,
      }, props.className)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => setHover(false)}>
        {props.items[0]}
        <motion.ul className={classNames("absolute flex flex-col gap-1", {
            "bottom-[calc(100%+12px)] flex-col-reverse": props.reverse,
            "pt-4": !props.reverse,
        })} variants={{
            open: {
                opacity: 1,
                display: "flex",
            },
            close: {
                opacity: 0,
                transitionEnd: {
                    display: "none",
                },
            },
        }} animate={status} initial={status}>
            {
                props.items.slice(1, ).map((item, i) => (
                    <motion.li key={i} custom={i} variants={{
                    open: { opacity: 1, y: props.reverse ? 10 : -10, transition: { delay: i * 0.15 } },
                    close: { opacity: 0, y: props.reverse ? -10 : 10 },
                    }} animate={status} initial={status} className={classNames({
                        "pb-2": i === 0 && props.reverse,
                    })}>
                        {item}
                    </motion.li>
                ))
            }
        </motion.ul>
    </div>
}
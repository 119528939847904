import { FC } from "react";
import { Page } from "../../components/container";
import { InternalRoutes } from "../../config/internal-routes";
import { useGetAllUsersQuery } from "../../generated/graphql";
import { CreateUserCard, UserCard } from "./user-card";

export const UserPage: FC = () => {
  const { data, refetch } = useGetAllUsersQuery();
  return <Page routes={[InternalRoutes.Admin.User]}>
    <CreateUserCard refetch={refetch} />
    {
      data?.User.map((user) => (
        <UserCard key={user.Id} user={user} refetch={refetch} />
      ))
    }
  </Page>
}